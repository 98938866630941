import React, { useState, Component } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MoreInfoDialog, { Item } from "../../../Utils/MoreInfoDialog";
import { faPen, faInfo, faPlay, faPowerOff, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const moment = require("moment");


const TeamRow = ({ user, selectUser, enableUser, disableUser, userDeleteBtn, ...props }) => {

  const {filters} = useSelector(state => ({filters: state.filters}));
  const version = (user.device_info && user.device_info["app-version"]) ? user.device_info["app-version"][0] : 0;
  //console.log(filters.app_version, version);
  const upToDate = filters.app_version === version;
  const [showMembers, setShowMembers] = useState(false);
  const [showShifts, setshowShifts] = useState(false);

  return (
    <React.Fragment>
    <div className={"row "}>
    <div className={"col-md-12"}>

    <div className={"row ctable-row"}>
      <div className={"col-md-2"}>
        <div className={"agent-list-profile"}>
          <div className={"col-profile-ct"}>
            <span className={"agent-list-photo task-status status-id-2"}>{user.name}</span>
            {/*<span className={"agent-list-username"}>
              <span>{user.username}</span>
              <span className={"status-id-1"}>
                <abbr>{user.cluster}</abbr>
              </span>
            </span>*/}
          </div>
        </div>
      </div>
      
      <div className={"col-md-2"}>{user.email ? user.email : ""}</div>
      <div className={"col-md-2"}>{user.manager_username ? user.manager_username.toUpperCase() : ""}</div>
      {/*<div className={"col-md-1"}>{user.task_type ? user.task_type.toUpperCase() : ""}</div>
      <div className={"col-md-2"}>{user.phone}</div>
      <div className={"col-md-2 site-plan-col-date"}>
        <span>
          {user.last_online
            ? moment(user.last_online)
                .format("YYYY-MM-DD HH:mm")
                .toString()
            : "Jamais"}
        </span>
        {user.device_info && user.device_info["app-version"] ? (
          <span className={"app-version-tag "+(upToDate ? "btn-success" : "btn-danger")}>{user.device_info["app-version"][0]}</span>
        ) : (
          ""
        )}
      </div>
      <div className={"col-md-2"}>{user.sites}</div>*/}

      <div className={"col-md-2"}>{user.wilaya ? user.wilaya.toUpperCase() : ""}</div>
      <div className={"col-md-2"}>{user.owner ? user.owner.toUpperCase() : ""}</div>

      <div className={"col-md-2 task-details-col"}>
        <button onClick={() => setShowMembers(!showMembers)} className="task-action-btn" ><FontAwesomeIcon icon={faChevronDown}  /> Membres</button>
        {/*<button onClick={() => setshowShifts(!showShifts)} className="task-action-btn" ><FontAwesomeIcon icon={faChevronDown}  /> Shifts</button>

        <Link to={"/teams/" + user.team_id} className={"task-action-btn ml-1 "}>
          <FontAwesomeIcon icon={faInfo} /> Details
        </Link>*/}
        {user.status !== "deleted" ? (
          <>
            <MoreInfoDialog>
              <Item onClick={() => selectUser(user)}>
                <FontAwesomeIcon className={"mr-2 "} icon={faPen} /> Modifier
              </Item>
              {/*user.status === "disabled" ? (
                <Item onClick={() => enableUser(user.userid)}>
                  <FontAwesomeIcon className={"mr-2 "} icon={faPlay} /> Activer
                </Item>
              ) : (
                <Item onClick={() => disableUser(user.userid)}>
                  <FontAwesomeIcon className={"mr-2 "} icon={faPowerOff} /> Désactiver
                </Item>
              )*/}
              <Item onClick={() => userDeleteBtn(user.team_id)}>
                <FontAwesomeIcon className={"mr-2 "} icon={faTrash} /> Supprimer
              </Item>
            </MoreInfoDialog>
          </>
        ) : (
          <b>ACCOUNT DELETED</b>
        )}

      </div>
    </div>

      {!!showMembers && (
        <>
        <div className={"animateRow row"}>
          <div className={"col-md-2"}><b>Username</b></div>
          <div className={"col-md-1"}><b>Agent Id</b></div>
          <div className={"col-md-2"}><b>Matricule</b></div>
          <div className={"col-md-3"}><b>Nom</b></div>
          <div className={"col-md-2"}><b>Téléphone</b></div>
          <div className={"col-md-2"}><b>Email</b></div>
        </div>
          <div className={"animateRow row"}>
          {user.agents.map(agent => (
            <>
              <div className={"col-md-2"}>
                <div className={"agent-list-profile"}>
                  <div className={"col-profile-ct"}>
                    <span className={"agent-list-photo task-status status-id-2"}>{agent.agent_username}</span>
                  </div>
                </div>
              </div>
              <div className={"col-md-1"}>{agent.agent_id}</div>
              <div className={"col-md-2"}>{agent.agent_matricule}</div>
              <div className={"col-md-3"}>{agent.agent_first_name + " " + agent.agent_last_name}</div>
              <div className={"col-md-2"}>{agent.agent_phone}</div>
              <div className={"col-md-2"}>{agent.agent_email}</div>
            </>
          ))}
          </div>
        </>
      )}

      {!!showShifts && (
        <>
        <div className={"animateRow row"}>
          <div className={"col-md-2"}><b>Nom</b></div>
          {/*<div className={"col-md-2"}><b>Id</b></div>*/}
          <div className={"col-md-2"}><b>De</b></div>
          <div className={"col-md-2"}><b>A</b></div>
          <div className={"col-md-3"}><b>Du</b></div>
          <div className={"col-md-3"}><b>Au</b></div>
        </div>
          <div className={"animateRow row"}>
          {user.shifts.map(shift => (
            <>
              <div className={"col-md-2"}>
                <div className={"agent-list-profile"}>
                  <div className={"col-profile-ct"}>
                    <span className={"agent-list-photo task-status status-id-2"}>{shift.shift_name}</span>
                  </div>
                </div>
              </div>
              {/*<div className={"col-md-2"}>{shift.shift_id}</div>*/}
              <div className={"col-md-2"}>{shift.shift_from_time}</div>
              <div className={"col-md-2"}>{shift.shift_to_time}</div>
              <div className={"col-md-3"}>{shift.shift_from_date}</div>
              <div className={"col-md-3"}>{shift.shift_to_date}</div>
            </>
          ))}
          </div>
        </>
      )}

      </div>
      </div>
      </React.Fragment>
  );
};

export default TeamRow;
