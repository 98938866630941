import React, { useState } from "react"
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import ReactDatePicker from "react-datepicker";
import { formToObject } from "../../../Utils/Helpers";
import moment from "moment";
import { connect } from "react-redux";
import Select from "react-select";
import Restricted from "../../../Utils/Restricted";


const mapState = state => ({session: state.session, task_types: state.filters.task_types});

class Planning extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            planning: [],
            selected_type: props.task_types[0].value
        }
    }
    componentDidMount() {
        this.loadList();
    }

    loadList = () => {
        this.setState({loading: true});
        api.get("/api/sites/planning/"+this.props.match.params.id+"/"+this.state.selected_type)
        .then(res => {
            if(res.data.success)
                this.setState({ planning: res.data.data });

            this.setState({loading: false});
        })
    }

    /*deletePlan = id => {
        api.post(`/api/${this.state.selected_type}/plan/delete/${id}`)
        .then(res => {
            if(res.data.success)
                this.loadList();

            this.setState({loading: false});
        })
    }*/

    deletePlan = id => {
        api.get(`/api/${this.state.selected_type}/plan/delete/${id}`)
        .then(res => {
            if(res.data.success)
                this.loadList();

            this.setState({loading: false});
        })
    }

    changeTaskType = e => {
        this.setState(state => ({
            ...state,
            selected_type: e.value
        }), this.loadList)
    }

    render() {
        return (<>
            <h4>
                Planning
                <span className={"pl-5 "}>
                    {this.state.loading && <FontAwesomeIcon icon={faSpinner} spin /> }
                </span>
            </h4>

            <Select
                classNamePrefix={"custom-select"}
                options={this.props.task_types}
                defaultValue={this.props.task_types.filter(item => item.value === this.state.selected_type)}
                onChange={this.changeTaskType}
            />

            <div className={"ctable col-md-12"}>
            {
              this.state.selected_type == 'cm'
              ?
              <div className={"row head-titles ctable-row"}>
                  <div className={"col-md-2"}>
                          Date
                  </div>
                  <div className={"col-md-2"}>
                          Task
                  </div>
                  <div className={"col-md-3"}>
                          Problem type
                  </div>
                  <div className={"col-md-3"}>
                          Alarm type
                  </div>
                  <div className={"col-md-2"}>
                          Action
                  </div>
              </div>
              :
              <div className={"row head-titles ctable-row"}>
                  <div className={"col-md-5"}>
                          Date
                  </div>
                  <div className={"col-md-3"}>
                          Task
                  </div>
                  <div className={"col-md-4"}>
                          Action
                  </div>
              </div>
                }
                {
                  this.state.selected_type == 'cm'
                  ?
                  this.state.planning.map((plan, i) => (
                    <PlanRowCM key={plan.id} reload={this.loadList} session={this.props.session} deletePlan={this.deletePlan} task_type={this.state.selected_type} key={'site_plan_'+i} plan={plan} />
                  ))
                  :
                  this.state.planning.map((plan, i) => (
                    <PlanRow key={plan.id} reload={this.loadList} session={this.props.session} deletePlan={this.deletePlan} task_type={this.state.selected_type} key={'site_plan_'+i} plan={plan} />
                  ))
              }
            </div>
        </>)
    }
}


const PlanRow = ({plan, deletePlan, reload, session, task_type, ...props}) => {

    const [editable, setEditable] = useState(false);
    const [planDate, setPlanDate] = useState(null);


    let allowed = false;
    if(session.user.role === "manager" || session.user.role === "service") {
        if(session.user.owner === "O&M") {
            allowed = true;
        }
    } else {
        allowed = true;
    }


    const editPlan = (e) => {
        e.preventDefault();

        const data = formToObject(e.target);

        api.post(`/api/${task_type}/plan/edit/${plan.id}`, data)
        .then(res => {
            setEditable(false);
            reload()
        })

        return false;
    }

    return (
        <div className={"row ctable-row hoverable"}>
            <div className={"col-md-5"}>
                    {editable ? <>
                    <form onSubmit={editPlan}>
                        <ReactDatePicker name={"date"} dateFormat={"yyyy-MM-dd"} showTimeSelect={false}  selected={new Date(planDate || plan.date)} onChange={setPlanDate} />
                        <button type="submit" className={"btn btn-sm btn-primary ml-2"}> Valider </button>
                    </form>
                    </> : moment(plan.date).format("YYYY-MM-DD")}
            </div>
            <div className={"col-md-3 site-plan-col-date"}>
                    {plan.task ? plan.task : "NOT DONE"}
                    {plan.instruction && <p>{plan.instruction}</p>}
            </div>
            <Restricted scope={task_type} permission={"edit_plan_site"} >
                <div className={"col-md-4 showOnHover"}>
                    <button type="button" onClick={() => deletePlan(plan.id)} className={"task-action-btn"}>Supprimer <FontAwesomeIcon icon={faTrash} /> </button>
                    <button type="button" onClick={() => setEditable(!editable)} className={"task-action-btn"}>Modifier <FontAwesomeIcon icon={faEdit} /> </button>
                </div>
            </Restricted>
        </div>
    )
}

const PlanRowCM = ({plan, deletePlan, reload, session, task_type, ...props}) => {

    const [editable, setEditable] = useState(false);
    const [planDate, setPlanDate] = useState(null);


    let allowed = false;
    if(session.user.role === "manager" || session.user.role === "service") {
        if(session.user.owner === "O&M") {
            allowed = true;
        }
    } else {
        allowed = true;
    }


    const editPlan = (e) => {
        e.preventDefault();

        const data = formToObject(e.target);

        api.post(`/api/${task_type}/plan/edit/${plan.id}`, data)
        .then(res => {
            setEditable(false);
            reload()
        })

        return false;
    }

    return (
        <div className={"row ctable-row hoverable"}>
            <div className={"col-md-2"}>
                    {editable ? <>
                    <form onSubmit={editPlan}>
                        <ReactDatePicker name={"date"} dateFormat={"yyyy-MM-dd"} showTimeSelect={false}  selected={new Date(planDate || plan.date)} onChange={setPlanDate} />
                        <button type="submit" className={"btn btn-sm btn-primary ml-2"}> Valider </button>
                    </form>
                    </> : moment(plan.date).format("YYYY-MM-DD")}
            </div>
            <div className={"col-md-2 site-plan-col-date"}>
                    {plan.task ? plan.task : "NOT DONE"}
                    {plan.instruction && <p>{plan.instruction}</p>}
            </div>
            <div className={"col-md-3 site-plan-col-date"}>
                    {plan.cm_problem_type && <p>{plan.cm_problem_type}</p>}
            </div>
            <div className={"col-md-3 site-plan-col-date"}>
                    {plan.cm_alarm_type && <p>{plan.cm_alarm_type}</p>}
            </div>
            <Restricted scope={task_type} permission={"edit_plan_site"} >
                <div className={"col-md-2 showOnHover"}>
                    <button type="button" onClick={() => deletePlan(plan.id)} className={"task-action-btn"}>Supprimer <FontAwesomeIcon icon={faTrash} /> </button>
                    <button type="button" onClick={() => setEditable(!editable)} className={"task-action-btn"}>Modifier <FontAwesomeIcon icon={faEdit} /> </button>
                </div>
            </Restricted>
        </div>
    )
}

export default connect(mapState)(Planning);
