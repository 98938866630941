import React, { Component } from "react";
import set from "lodash/set";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  setAuthAction,
  startLoading,
  doneLoading,
  setFlash,
} from "../../Redux/actions/index";
import { Trans } from "react-i18next";
import Select from "react-select";
import SocketContext from "../../Utils/socket-context";
import api from "../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../Utils/Pagination";
import Constants from "../../Utils/Constants";
import { buildFilters } from "../../Utils/Helpers";
import GPMTaskList from "../../Components/Task/GPM/GPMTaskList";
import Restricted from "../../Utils/Restricted";

const mapStateToProps = (state) => {
  return {
    session: state.session,
    filters: state.filters,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    isLoading: (e) => dispatch(startLoading(e)),
    doneLoading: (e) => dispatch(doneLoading()),
    setAuth: (e) => dispatch(setAuthAction(e)),
    setFlash: (f) => dispatch(setFlash(f)),
  };
}

class TaskListContainer extends Component {
  constructor(props) {
    super(props);

    this.props.isLoading({
      message: "Loading tasks",
      animate: true,
    });

    this.state = {
      taskid: null,
      total: 0,
      managers: [],
      tasks: [],
      agents: [],
      status: [],
      types: [],
      rows: [],
      current: 1,
      pages: 1,
      limit: 15,
      filters: {
        agent: "",
        site: "",
        pm: "",
        site_owner: "",
        site_manager: "",
        date_from: "",
        date_to: "",
        site_cmanager: "",
        site_cluster: "",
        site_wilaya: "",
        cycle: "cycle",
      },
    };

    this.handlers = {
      selectPage: this.selectPage.bind(this),
      loadTasks: this.loadTasks.bind(this),
      updateFilter: this.updateFilter.bind(this),
    };
  }

  changeLimit = (e) => {
    if (window.ga) window.ga("send", "event", "TaskList", "changeLimit");

    this.props.isLoading();
    this.setState(
      {
        limit: e.value,
        current: 1,
      },
      () => this.loadTasks(),
    );
  };

  updateFilter = (event, name) => {
    if (window.ga) window.ga("send", "event", "TaskList", "toggleFilters");

    let that = this;
    const value = event.target ? event.target.value : event.value;

    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
        },
      },
      function() {
        console.log(name, event);
        that.props.isLoading();
        that.loadTasks(1);
      },
    );
  };

  toggleHidden = (e) => {
    if (window.ga) window.ga("send", "event", "TaskList", "toggleHidden");

    const i = e.target.getAttribute("data-row-index");
    let nstate = this.state;

    nstate.rows[i] &&
      set(nstate, "rows[" + i + "].hidden", !nstate.rows[i].hidden);

    this.setState(nstate);
  };

  componentDidMount() {
    if (this.props.match.params.taskid && this.props.match.params.taskid) {
      this.updateFilter({ value: this.props.match.params.taskid }, "pm");
    } else if (this.props.match.params.agentid) {
      this.updateFilter({ value: this.props.match.params.agentid }, "agent");
    } else if (this.props.match.params.site) {
      this.updateFilter({ value: this.props.match.params.site }, "site");
    } else this.loadTasks();
  }

  componentDidUpdate(prev) {
    // if(this.props.match.params.taskid && this.props.match.params.taskid) {
    // 	this.setState({
    // 		taskid: this.props.match.params.taskid
    // 	})
    // }
  }

  loadTasks(p = this.state.current) {
    let nstate = this.state;
    let that = this;

    let crits = buildFilters(this.state.filters);

    api
      .get(`/api/gpm/task/fetch?${crits}&page=${p}&limit=${this.state.limit}`)
      .then(function(response) {
        if (response.data.success) {
          response.data.tasks.map((r) => {
            nstate.rows.push({ hidden: true });
          });

          that.setState({
            tasks: response.data.tasks,
            pages: response.data.pages,
            rows: nstate.rows,
            total: response.data.total,
          });
        } else {
          if (response.data.errcode === 101) {
            that.props.setAuth(false);
          }
          that.props.setFlash({
            active: true,
            message: response.data.error,
            classes: "warning",
            style: {},
          });
        }
        that.props.doneLoading();
      })
      .catch((err) => {
        this.props.setFlash({
          active: true,
          message: `Une erreur est survenue: ${err.message}`,
          classes: "warning",
          style: {},
        });
        // console.log(`error:  ${error}`);
        this.props.doneLoading();
      });
  }

  exportAnomalies = (e) => {
    if (window.ga) window.ga("send", "event", "TaskList", "exportAnomalies");

    e.preventDefault();

    let crits = buildFilters(this.state.filters);

    let remote = Constants.apiURL;

    const url = remote + `/api/task/fetch?${crits}&limit=-1&anomalies`;

    // window.location.href = url;
    // return;

    e.target.action = url;
    e.target.submit();
  };

  exportPMS = (e) => {
    e.preventDefault();

    let crits = buildFilters(this.state.filters);

    let remote = Constants.apiURL;

    const url = remote + `/api/task/fetch?${crits}&limit=-1&export=true`;

    // window.location.href = url;
    // return;

    e.target.action = url;
    e.target.submit();
  };

  exportGPMS = (e) => {
    e.preventDefault();

    let crits = buildFilters(this.state.filters);

    let remote = Constants.apiURL;

    const url = remote + `/api/gpm/task/fetch?${crits}&limit=-1&export=true`;

    // window.location.href = url;
    // return;

    e.target.action = url;
    e.target.submit();
  };

  selectPage = (p) => {
    if (window.ga) window.ga("send", "event", "TaskList", "selectPage");

    this.props.isLoading();

    this.setState({ current: p });

    this.loadTasks(p);
  };

  render() {
    return (
      <React.Fragment>
        <div className={"container-header"}>
          PM Tasks
          {!this.props.session.auth ? <Redirect to='/login' /> : ""}
        </div>

        <div className={"row"}>
          <div className={"col-md-2"}>
            <div className={"side-filters"}>
              {/* <form action="" method="GET"  onSubmit={e => this.exportAnomalies(e)} >
						<button type="submit" className={"btn btn-primary btn-block mb-2"}>Exporter les anomalies <FontAwesomeIcon className={"ml-2"} icon={faFileExport} /></button>
						</form> */}

              <form
                action=''
                method='POST'
                onSubmit={(e) => this.exportGPMS(e)}
              >
                <input
                  type='hidden'
                  name='token'
                  value={this.props.session.user.token}
                />
                <button
                  type='submit'
                  className={"btn btn-primary btn-block mb-2"}
                >
                  Exporter la liste
                </button>
              </form>

              <h4>Recherche</h4>

              <div className={"form-group"}>
                <label>Cycle</label>
                <Select
                  classNamePrefix={"custom-select"}
                  options={[
                    { label: "Tous", value: "" },
                    ...this.props.filters.cycle,
                  ]}
                  onChange={(e) => this.updateFilter(e, "cycle")}
                  defaultValue={[{ label: "Cycle actuel", value: "cycle" }]}
                />
              </div>

              <Restricted scope={"gpm"} permission={"all_owners"}>
                <>
                  <div className={"form-group"}>
                    <label>Site owner</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={[
                        { label: "Tous", value: "" },
                        ...this.props.filters.site_owners,
                      ]}
                      placeholder='O&M, Sous-traitant...'
                      onChange={(e) => this.updateFilter(e, "site_owner")}
                    />
                  </div>
                </>
              </Restricted>

              {/* <div className={"form-group"}>
							<label>Cluster Manager</label>
							<Select
							classNamePrefix={"custom-select"}
							options={[
								{label: "Tous", value: ""},
								...this.props.filters.cluster_managers
							]}
							placeholder="Séléctionnez..."
							onChange={e => this.updateFilter(e, 'site_cmanager')}
							/>
						</div> */}

              <div className={"form-group"}>
                <label>Cluster</label>
                <Select
                  classNamePrefix={"custom-select"}
                  options={[
                    { label: "Tous", value: "" },
                    ...this.props.filters.clusters,
                  ]}
                  placeholder='Séléctionnez...'
                  onChange={(e) => this.updateFilter(e, "site_cluster")}
                />
              </div>

              <div className={"form-group"}>
                <label>Wilaya</label>
                <Select
                  classNamePrefix={"custom-select"}
                  options={[
                    { label: "Tous", value: "" },
                    ...this.props.filters.wilayas,
                  ]}
                  placeholder='Séléctionnez...'
                  onChange={(e) => this.updateFilter(e, "site_wilaya")}
                />
              </div>

              {this.props.session.user.role !== "agent" && (
                <div className={"form-group"}>
                  <label>Agent</label>
                  <Select
                    classNamePrefix={"custom-select"}
                    options={[
                      { label: "Tous", value: "" },
                      ...this.props.filters.agents,
                    ]}
                    placeholder='Agent'
                    onChange={(e) => this.updateFilter(e, "agent")}
                  />
                </div>
              )}

              <div className={"form-group"}>
                <label>GPM mode</label>
                <Select
                  classNamePrefix={"custom-select"}
                  options={[
                    { label: "Tous", value: "" },
                    ...this.props.filters.gpm_modes,
                  ]}
                  placeholder='Séléctionnez...'
                  onChange={(e) => this.updateFilter(e, "gpm_mode")}
                />
              </div>

              <div className={"form-group"}>
                <label>Date début</label>
                <input
                  type='date'
                  className={"form-control"}
                  onChange={(e) => this.updateFilter(e, "date_from")}
                />
              </div>

              <div className={"form-group"}>
                <label>Date fin</label>
                <input
                  type='date'
                  className={"form-control"}
                  onChange={(e) => this.updateFilter(e, "date_to")}
                />
              </div>

              <div className={"form-group"}>
                <label>Sites</label>
                <input
                  name='site'
                  className={"form-control"}
                  placeholder='Nom ou code site'
                  value={this.state.filters.site}
                  onChange={(e) => this.updateFilter(e, "site")}
                />
              </div>

              <div className={"form-group"}>
                <label>ID PM</label>
                <input
                  type={"text"}
                  name='pm'
                  className={"form-control"}
                  onKeyUp={(e) => this.updateFilter(e, "pm")}
                  defaultValue={this.state.filters.pm}
                />
              </div>
            </div>
          </div>

          <div className={"col-md-10"}>
            <div className={"row mb-3"}>
              <div className={"col-md-6 items-found"}>
                <p>
                  <b>{this.state.total}</b> PM trouvés
                </p>
              </div>
              <div className={"col-md-6 per-page-wrap"}>
                <div className={"per-page-content"}>
                  <p>Résultat par page</p>
                  <Select
                    classNamePrefix={"custom-select"}
                    onChange={this.changeLimit}
                    className={"per-page nm"}
                    options={this.props.filters.result_per_page}
                    value={{ label: this.state.limit, value: this.state.limit }}
                  />
                </div>
                <Pagination data={this.state} handleClick={this.selectPage} />
              </div>
            </div>

            <GPMTaskList
              data={this.state}
              rows={this.state.rows}
              toggleHidden={this.toggleHidden}
              handlers={this.handlers}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const TaskListConsumer = (props) => (
  <SocketContext.Consumer>
    {(socket) => <TaskListContainer {...props} socket={socket} />}
  </SocketContext.Consumer>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskListConsumer);
