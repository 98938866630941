import React, { Component } from "react";
import set from "lodash/set";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  setAuthAction,
  startLoading,
  doneLoading,
  setFlash,
} from "../../Redux/actions/index";
import { Trans } from "react-i18next";
import Select from "react-select";
import SocketContext from "../../Utils/socket-context";
import api from "../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExport,
  faPlus,
  faAngleRight,
  faAngleLeft,
  faChevronDown,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../Utils/Pagination";
import Constants from "../../Utils/Constants";
import { buildFilters } from "../../Utils/Helpers";
import GPMTaskList from "../../Components/Task/GPM/GPMTaskList";
import CMTaskList from "../../Components/Task/CM/CMTaskList";
import Restricted from "../../Utils/Restricted";
import CMImportOverlay from "../../Components/Addresses/SiteList/CMImportOverlay";
import FilterElement from "../../Utils/Filter/FilterElement";
import FiltersWrap from "../../Utils/Filter/FiltersWrap";
import notificationSound from "./../../../data/notification.mp3";

const mapStateToProps = (state) => {
  return {
    session: state.session,
    filters: state.filters,
    user: state.session.user,
    auth: state.session.auth,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    isLoading: (e) => dispatch(startLoading(e)),
    doneLoading: (e) => dispatch(doneLoading()),
    setAuth: (e) => dispatch(setAuthAction(e)),
    setFlash: (f) => dispatch(setFlash(f)),
  };
}

class TaskListContainer extends Component {
  constructor(props) {
    super(props);

    this.props.isLoading({
      message: "Loading tasks",
      animate: true,
    });

    this.state = {
      taskid: null,
      total: 0,
      managers: [],
      tasks: [],
      agents: [],
      status: [],
      types: [],
      rows: [],
      current: 1,
      pages: 1,
      limit: 15,
      filters: {
        agent: "",
        site: "",
        pm: "",
        site_owner: "",
        site_manager: "",
        date_from: "",
        date_to: "",
        site_cmanager: "",
        site_cluster: "",
        site_wilaya: "",
        mine: "mine",
        //cm_category: "",
        cm_code: "",
        ongoing: true,
        today: false,
      },
      selected_tab: "mine",
      showFilters: false,
      cmCode: this.props.filters.cm_code,
      activeOverlay: null,
    };

    this.handlers = {
      selectPage: this.selectPage.bind(this),
      loadTasks: this.loadTasks.bind(this),
      updateFilter: this.updateFilter.bind(this),
      updateTab: this.updateTab.bind(this),
    };
  }

  showFilters = () => {
    this.setState({ showFilters: !this.state.showFilters });
  };

  changeLimit = (e) => {
    if (window.ga) window.ga("send", "event", "TaskList", "changeLimit");

    this.props.isLoading();
    this.setState(
      {
        limit: e.value,
        current: 1,
      },
      () => this.loadTasks(),
    );
  };

  changeCMCode = (e) => {
    var parent = e.label;

    //take the matching code
    var code = this.props.filters.cm_code.map((obj) => {
      return String(obj.parent_label).toUpperCase() ===
        String(parent).toUpperCase()
        ? { label: obj.label, value: obj.value }
        : { label: null, value: null };
    });

    //remove empty ones
    var filtered = code.filter(function(el) {
      return el.label != null;
    });

    this.setState({ cmCode: filtered }, () => {
      /*console.log("hello state", this.state.cmCode);*/
    });
  };

  updateFilter = (event, name) => {
    if (name === "cm_category") {
      this.changeCMCode(event);
    }

    let that = this;
    const value = event.target ? event.target.value : event.value;

    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
        },
      },
      function() {
        if (name === "site" && value.length < 3) {
          //console.log("hello", value.length);
          return;
        } else {
          that.props.isLoading();
          that.loadTasks(1);
        }
      },
    );
  };

  toggleHidden = (e) => {
    if (window.ga) window.ga("send", "event", "TaskList", "toggleHidden");

    const i = e.target.getAttribute("data-row-index");
    let nstate = this.state;

    nstate.rows[i] &&
      set(nstate, "rows[" + i + "].hidden", !nstate.rows[i].hidden);

    this.setState(nstate);
  };

  componentDidMount() {
    if (this.props.match.params.taskid && this.props.match.params.taskid) {
      this.updateFilter({ value: this.props.match.params.taskid }, "pm");
    } else if (this.props.match.params.agentid) {
      this.updateFilter({ value: this.props.match.params.agentid }, "agent");
    } else if (this.props.match.params.site) {
      this.updateFilter({ value: this.props.match.params.site }, "site");
    } else this.loadTasks();

    //this.updateTab("mine", "mine");
  }

  componentDidUpdate(prev) {
    // if(this.props.match.params.taskid && this.props.match.params.taskid) {
    // 	this.setState({
    // 		taskid: this.props.match.params.taskid
    // 	})
    // }
  }

  loadTasks(p = this.state.current) {
    let nstate = this.state;
    let that = this;

    let crits = buildFilters(this.state.filters);

    api
      .get(
        `/api/cm/task/fetch?${crits}&page=${p}&limit=${
          this.state.limit
        }&service_name=${this.props.user.service_name}&external=${
          this.props.user.external
        }`,
      )
      .then(function(response) {
        if (response.data.success) {
          response.data.tasks.map((r) => {
            nstate.rows.push({ hidden: true });
          });

          that.setState({
            tasks: response.data.tasks,
            pages: response.data.pages,
            rows: nstate.rows,
            total: response.data.total,
          });
          //that.notifyCMTransfer();
          that.notifyCMPending();
        } else {
          if (response.data.errcode === 101) {
            that.props.setAuth(false);
          }
          that.props.setFlash({
            active: true,
            message: response.data.error,
            classes: "warning",
            style: {},
          });
        }
        // that.props.doneLoading();
      })
      .catch((err) => {
        // to do revert to initial state when there is error
        this.props.setFlash({
          active: true,
          message: `Une erreur est survenue: ${err.message}`,
          classes: "warning",
          style: {},
        });
        // console.log(`error:  ${error}`);
        // this.props.doneLoading();
      })
      .finally(() => this.props.doneLoading());
  }

  exportAnomalies = (e) => {
    e.preventDefault();
    let crits = buildFilters(this.state.filters);
    let remote = Constants.apiURL;
    const url =
      remote +
      `/api/cm/task/fetch?${crits}&limit=-1&anomalies&service_name=${
        this.props.user.service_name
      }&external=${this.props.user.external}`;

    // window.location.href = url;
    // return;

    e.target.action = url;
    e.target.submit();
  };

  exportPMS = (e) => {
    e.preventDefault();

    let crits = buildFilters(this.state.filters);

    let remote = Constants.apiURL;

    const url =
      remote +
      `/api/cm/task/fetch?${crits}&limit=-1&export=true&service_name=${
        this.props.user.service_name
      }&external=${this.props.user.external}`;

    e.target.action = url;
    e.target.submit();
  };

  exportICT = (e) => {
    e.preventDefault();

    let crits = buildFilters(this.state.filters);
    let remote = Constants.apiURL;

    const url =
      remote +
      `/api/cm/task/fetch?${crits}&limit=-1&ict=true&service_name=${
        this.props.user.service_name
      }&external=${this.props.user.external}`;

    e.target.action = url;
    e.target.submit();
  };

  exportSV = (e) => {
    e.preventDefault();

    let crits = buildFilters(this.state.filters);
    let remote = Constants.apiURL;

    const url =
      remote +
      `/api/cm/task/fetch?${crits}&limit=-1&sv=true&service_name=${
        this.props.user.service_name
      }&external=${this.props.user.external}`;

    e.target.action = url;
    e.target.submit();
  };

  selectPage = (p) => {
    this.props.isLoading();
    this.setState({ current: p });
    this.loadTasks(p);
  };

  notifyCMTransfer = () => {
    const tasks = this.state.tasks;
    //console.log("hello ", tasks);

    tasks.map((task, i) => {
      if (
        task["cm_history_status"] &&
        (task["cm_history_status"].includes("transfered") ||
          task["cm_history_status"].includes("escalated"))
      ) {
        this.props.setFlash({
          active: true,
          message: "Une CM est transférée/escaladée par l'agent.",
          classes: "warning",
          style: {},
        });
      }
    });
  };

  notifyCMPending = () => {
    const audio = new Audio(notificationSound);
    const tasks = this.state.tasks;

    //console.log("hello ", tasks);

    tasks.map((task, i) => {
      if (
        task.assigned_to_me == "1" &&
        (task.cm_last_status == "Wait Accept" ||
          task.cm_last_status == "Wait Restart")
      ) {
        audio.play();
        this.props.setFlash({
          active: true,
          message: "Une CM n'est pas encore acceptée !",
          classes: "warning",
          style: {},
        });
      }
    });
  };

  updateTab = (event, name) => {
    let that = this;
    const value = event;

    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
        },
      },
      function() {
        that.props.isLoading();
        that.loadTasks(1);
      },
    );
  };

  changeTab = (tab) => {
    var t = "" + tab;
    this.setState({ selected_tab: tab });
    if (tab == "mine") {
      this.updateTab("mine", "mine");
    } else if (tab == "other") {
      this.updateTab("other", "mine");
    } else if (tab == "all") {
      this.updateTab("all", "mine");
    } else if (tab == "trans") {
      this.updateTab("trans", "mine");
    } else if (tab == "wo") {
      this.updateTab("wo", "mine");
    } /*else if(tab == "prf") {
      this.updateTab("prf", "mine");
    }*/
  };

  render() {
    return (
      <React.Fragment>
        <div className={"container-header"}>
          CM Tasks
          {!this.props.session.auth ? <Redirect to='/login' /> : ""}
          {this.state.activeOverlay === "import" && (
            <CMImportOverlay
              close={() => this.setState({ activeOverlay: null })}
              open={true}
            />
          )}
          <div className={"float-right"}>
            <Restricted scope={"cm"} permission={"import_task"}>
              <button
                type='button'
                onClick={() => this.setState({ activeOverlay: "import" })}
                className={"btn btn-sm ml-2 btn-primary"}
              >
                <FontAwesomeIcon icon={faFileExcel} className={"mr-2"} />
                Importer
              </button>
            </Restricted>
          </div>
        </div>

        <div className={"row"}>
          {this.state.showFilters ? (
            <>
              <div className={"col-md-2"}>
                <div className={"side-filters"}>
                  {/*this.props.user &&
                (this.props.user.role === "cluster_manager" ||
                  this.props.user.role === "admin") && (
                  <>
                  <Restricted scope={"cm"} permission={"add_site"}>
                    <Link
                      to={"/cm/new"}
                      className={"btn btn-primary btn-block mb-2"}
                    >
                      <FontAwesomeIcon icon={faPlus} className={"mr-2"} />
                      <Trans i18nKey="label_new_cm" />
                    </Link>
                  </Restricted>
                  </>
                  )********/}

                  <h4>Recherche</h4>

                  <div className={"form-group"}>
                    <label>Etat</label>
                    <FiltersWrap
                      update={this.updateFilter}
                      session={this.props.user}
                    >
                      <FilterElement
                        type={"radio"}
                        name={"ongoing"}
                        options={[
                          { label: "All", value: "all" },
                          { label: "Pending", value: true, default: true },
                          { label: "Closed", value: false },
                        ]}
                        onChange={(e) => this.updateFilter(e, "ongoing")}
                      />
                    </FiltersWrap>
                  </div>

                  <div className={"form-group"}>
                    <label>Période</label>
                    <FiltersWrap
                      update={this.updateFilter}
                      session={this.props.user}
                    >
                      <FilterElement
                        type={"radio"}
                        name={"today"}
                        options={[
                          { label: "All", value: false, default: true },
                          { label: "Today", value: true },
                        ]}
                        onChange={(e) => this.updateFilter(e, "today")}
                      />
                    </FiltersWrap>
                  </div>

                  <div className={"form-group"}>
                    <label>Période</label>
                    <br />

                    <label>Du</label>
                    <input
                      type='date'
                      className={"form-control"}
                      onChange={(e) => this.updateFilter(e, "date_from")}
                    />

                    <label>Au</label>
                    <input
                      type='date'
                      className={"form-control"}
                      onChange={(e) => this.updateFilter(e, "date_to")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Assignement</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={[
                        { label: "Tous", value: "" },
                        ...this.props.filters.services,
                      ]}
                      placeholder='Assignement'
                      onChange={(e) => this.updateFilter(e, "assignment")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Etat</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={[
                        { label: "Tous", value: "" },
                        ...this.props.filters.cm_state,
                      ]}
                      placeholder='Etat'
                      onChange={(e) => this.updateFilter(e, "cm_state")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>CM site owner</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={[
                        { label: "Tous", value: "" },
                        ...this.props.filters.cm_site_owners,
                      ]}
                      placeholder='M_OTT, O&M_ENV...'
                      onChange={(e) => this.updateFilter(e, "cm_site_owner")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>BSS Vendor</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={[
                        { label: "Tous", value: "" },
                        ...this.props.filters.cm_site_vendors,
                      ]}
                      placeholder='M_ALCATEL, M_HUAWEI...'
                      onChange={(e) => this.updateFilter(e, "cm_site_vendors")}
                    />
                  </div>

                  {this.props.session.user.role !== "agent" &&
                    this.props.session.user.role !== "service" &&
                    this.props.session.user.role !== "manager" && (
                      <>
                        {/*<div className={"form-group"}>
                      <label>Physic site owner</label>
                      <Select
                        classNamePrefix={"custom-select"}
                        options={[
                          ...this.props.filters.site_owners,
                        ]}
                        placeholder="O&M, Sous-traitant..."
                        onChange={(e) => this.updateFilter(e, "site_owner")}
                      />
                    </div>*/}

                        <div className={"form-group"}>
                          <label>Manager </label>
                          <Select
                            classNamePrefix={"custom-select"}
                            options={[
                              { label: "Tous", value: "" },
                              ...this.props.filters.managers,
                            ]}
                            placeholder='Manager'
                            onChange={(e) =>
                              this.updateFilter(e, "site_manager")
                            }
                          />
                        </div>
                      </>
                    )}

                  <div className={"form-group"}>
                    <label>Catégorie Alarme</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={[
                        { label: "Tous", value: "" },
                        ...this.props.filters.cm_category,
                      ]}
                      placeholder='Séléctionnez...'
                      onChange={(e) => this.updateFilter(e, "cm_category")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Alarme</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={[
                        { label: "Tous", value: "" },
                        ...this.state.cmCode,
                      ]}
                      placeholder='Séléctionnez...'
                      onChange={(e) => this.updateFilter(e, "cm_code")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Cluster</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={[
                        { label: "Tous", value: "" },
                        ...this.props.filters.clusters,
                      ]}
                      placeholder='Séléctionnez...'
                      onChange={(e) => this.updateFilter(e, "site_cluster")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Wilaya</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={[
                        { label: "Tous", value: "" },
                        ...this.props.filters.wilayas,
                      ]}
                      placeholder='Séléctionnez...'
                      onChange={(e) => this.updateFilter(e, "site_wilaya")}
                    />
                  </div>

                  {this.props.session.user.role !== "agent" && (
                    <>
                      <div className={"form-group"}>
                        <label>Agent</label>
                        <Select
                          classNamePrefix={"custom-select"}
                          options={[
                            { label: "Tous", value: "" },
                            ...this.props.filters.agents,
                          ]}
                          placeholder='Agent'
                          onChange={(e) => this.updateFilter(e, "agent")}
                        />
                      </div>
                      <div className={"form-group"}>
                        <label>Groupe</label>
                        <Select
                          classNamePrefix={"custom-select"}
                          options={[
                            { label: "Tous", value: "" },
                            ...this.props.filters.teams,
                          ]}
                          placeholder='Groupe'
                          onChange={(e) => this.updateFilter(e, "team")}
                        />
                      </div>
                      <div className={"form-group"}>
                        <label>Service</label>
                        <Select
                          classNamePrefix={"custom-select"}
                          options={[
                            { label: "Tous", value: "" },
                            ...this.props.filters.services,
                          ]}
                          placeholder='Service'
                          onChange={(e) => this.updateFilter(e, "service")}
                        />
                      </div>
                    </>
                  )}

                  {this.props.session.user.role !== "agent" && (
                    <div className={"form-group"}>
                      <label>CM Agent Owner</label>
                      <Select
                        classNamePrefix={"custom-select"}
                        options={[
                          { label: "Tous", value: "" },
                          ...this.props.filters.agents,
                        ]}
                        placeholder='CM Agent'
                        onChange={(e) =>
                          this.updateFilter(e, "cm_owner_agent_id")
                        }
                      />
                    </div>
                  )}

                  {this.props.session.user.role !== "agent" &&
                    this.props.session.user.role !== "service" &&
                    this.props.session.user.role !== "manager" && (
                      <div>
                        <div className={"form-group"}>
                          <label>Créé par (Service)</label>
                          <Select
                            classNamePrefix={"custom-select"}
                            options={[
                              { label: "Tous", value: "" },
                              ...this.props.filters.services,
                            ]}
                            placeholder='Service'
                            onChange={(e) => this.updateFilter(e, "created_by")}
                          />
                        </div>

                        <div className={"form-group"}>
                          <label>Créé par (Ingénieur)</label>
                          <Select
                            classNamePrefix={"custom-select"}
                            options={[
                              { label: "Tous", value: "" },
                              ...this.props.filters.managers,
                            ]}
                            placeholder='Manager'
                            onChange={(e) =>
                              this.updateFilter(e, "created_by_manager")
                            }
                          />
                        </div>
                      </div>
                    )}

                  <div className={"form-group"}>
                    <label>Sites</label>
                    <input
                      name='site'
                      className={"form-control"}
                      placeholder='Nom ou code site'
                      value={this.state.filters.site}
                      onChange={(e) => this.updateFilter(e, "site")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>N° ticket</label>
                    <input
                      type={"text"}
                      name='pm'
                      className={"form-control"}
                      onKeyUp={(e) => this.updateFilter(e, "pm")}
                      defaultValue={this.state.filters.pm}
                    />
                  </div>
                </div>
              </div>
              <div className={"col-md-10"}>
                <div className={"row mb-3"}>
                  <div className={"col-md-6 items-found"}>
                    <div className={"col-md-3"}>
                      {this.state.showFilters ? (
                        <button
                          onClick={(e) => this.showFilters()}
                          className={"btn btn-primary btn-block mb-2"}
                        >
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            className={"mr-2"}
                          />
                          <Trans i18nKey='label_hide_filters' />
                        </button>
                      ) : (
                        <button
                          onClick={(e) => this.showFilters()}
                          className={"btn btn-primary btn-block mb-2"}
                        >
                          <FontAwesomeIcon
                            icon={faAngleLeft}
                            className={"mr-2"}
                          />
                          <Trans i18nKey='label_show_filters' />
                        </button>
                      )}
                    </div>
                    <div className={"col-md-3"}>
                      <Restricted scope={"cm"} permission={"export_task"}>
                        <form
                          action=''
                          method='POST'
                          onSubmit={(e) => this.exportPMS(e)}
                        >
                          <input
                            type='hidden'
                            name='token'
                            value={this.props.session.user.token}
                          />
                          <button
                            type='submit'
                            className={"btn btn-primary btn-block mb-2"}
                          >
                            Exporter le rapport
                          </button>
                        </form>
                      </Restricted>
                      <Restricted scope={"cm"} permission={"export_task"}>
                        <form
                          action=''
                          method='POST'
                          onSubmit={(e) => this.exportICT(e)}
                        >
                          <input
                            type='hidden'
                            name='token'
                            value={this.props.session.user.token}
                          />
                          <button
                            type='submit'
                            className={"btn btn-primary btn-block mb-2"}
                          >
                            Daily ICT
                          </button>
                        </form>
                      </Restricted>
                      <Restricted scope={"cm"} permission={"export_task"}>
                        <form
                          action=''
                          method='POST'
                          onSubmit={(e) => this.exportSV(e)}
                        >
                          <input
                            type='hidden'
                            name='token'
                            value={this.props.session.user.token}
                          />
                          <button
                            type='submit'
                            className={"btn btn-primary btn-block mb-2"}
                          >
                            Daily Sans visibilité
                          </button>
                        </form>
                      </Restricted>
                    </div>
                    <p>
                      <b>{this.state.total}</b> CM trouvés
                    </p>
                  </div>
                  <div className={"col-md-6 per-page-wrap"}>
                    <div className={"col-md-4"}>
                      {
                        /*
                  this.props.user && this.props.user.role === "admin" 
                  ?
                  <Link
                      to={"/cm/new"}
                      className={"btn btn-primary btn-block mb-2"}
                    >
                      <FontAwesomeIcon icon={faPlus} className={"mr-2"} />
                      <Trans i18nKey="label_new_cm" />
                  </Link>
                  :*/
                        <Restricted scope={"cm"} permission={"plan_task"}>
                          <Link
                            to={"/cm/new"}
                            className={"btn btn-primary btn-block mb-2"}
                          >
                            <FontAwesomeIcon icon={faPlus} className={"mr-2"} />
                            <Trans i18nKey='label_new_cm' />
                          </Link>
                        </Restricted>
                      }
                    </div>
                    <div className={"per-page-content"}>
                      <p>Résultat par page</p>
                      <Select
                        classNamePrefix={"custom-select"}
                        onChange={this.changeLimit}
                        className={"per-page nm"}
                        options={this.props.filters.result_per_page}
                        value={{
                          label: this.state.limit,
                          value: this.state.limit,
                        }}
                      />
                    </div>
                    <Pagination
                      data={this.state}
                      handleClick={this.selectPage}
                    />
                  </div>
                </div>

                <div className={"page-top-menu"}>
                  <ul>
                    <li
                      onClick={() => this.changeTab("mine")}
                      className={
                        this.state.selected_tab === "mine" && "selected"
                      }
                    >
                      Mes CMs
                    </li>
                    {/*<li onClick={() => this.changeTab('other')} className={this.state.selected_tab === "other" && "selected"}>Autre CMs</li>*/}
                    <li
                      onClick={() => this.changeTab("all")}
                      className={
                        this.state.selected_tab === "all" && "selected"
                      }
                    >
                      Tous
                    </li>
                    {/*<li onClick={() => this.changeTab('trans')} className={this.state.selected_tab === "trans" && "selected"}>Transféré/Escaladé</li>*/}
                    {this.props.session.user.external &&
                    this.props.session.user.external == "true" ? null : (
                      <li
                        onClick={() => this.changeTab("wo")}
                        className={
                          this.state.selected_tab === "wo" && "selected"
                        }
                      >
                        WO
                      </li>
                    )}
                    {/*<li onClick={() => this.changeTab('prf')} className={this.state.selected_tab === "prf" && "selected"}>PRF</li>*/}
                  </ul>
                </div>

                <CMTaskList
                  data={this.state}
                  rows={this.state.rows}
                  toggleHidden={this.toggleHidden}
                  handlers={this.handlers}
                  mine={this.state.filters["mine"]}
                />
              </div>
            </>
          ) : (
            <>
              <div className={"col-md-12"}>
                <div className={"row mb-3"}>
                  <div className={"col-md-6 items-found"}>
                    <div className={"col-md-4"}>
                      {this.state.showFilters ? (
                        <button
                          onClick={(e) => this.showFilters()}
                          className={"btn btn-primary btn-block mb-2"}
                        >
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            className={"mr-2"}
                          />
                          <Trans i18nKey='label_hide_filters' />
                        </button>
                      ) : (
                        <button
                          onClick={(e) => this.showFilters()}
                          className={"btn btn-primary btn-block mb-2"}
                        >
                          <FontAwesomeIcon
                            icon={faAngleLeft}
                            className={"mr-2"}
                          />
                          <Trans i18nKey='label_show_filters' />
                        </button>
                      )}
                    </div>
                    <div className={"col-md-4"}>
                      <Restricted scope={"cm"} permission={"export_task"}>
                        <form
                          action=''
                          method='POST'
                          onSubmit={(e) => this.exportPMS(e)}
                        >
                          <input
                            type='hidden'
                            name='token'
                            value={this.props.session.user.token}
                          />
                          <button
                            type='submit'
                            className={"btn btn-primary btn-block mb-2"}
                          >
                            Exporter le rapport
                          </button>
                        </form>
                      </Restricted>
                      <Restricted scope={"cm"} permission={"export_task"}>
                        <form
                          action=''
                          method='POST'
                          onSubmit={(e) => this.exportICT(e)}
                        >
                          <input
                            type='hidden'
                            name='token'
                            value={this.props.session.user.token}
                          />
                          <button
                            type='submit'
                            className={"btn btn-primary btn-block mb-2"}
                          >
                            Daily ICT
                          </button>
                        </form>
                      </Restricted>
                      <Restricted scope={"cm"} permission={"export_task"}>
                        <form
                          action=''
                          method='POST'
                          onSubmit={(e) => this.exportSV(e)}
                        >
                          <input
                            type='hidden'
                            name='token'
                            value={this.props.session.user.token}
                          />
                          <button
                            type='submit'
                            className={"btn btn-primary btn-block mb-2"}
                          >
                            Daily Sans visibilité
                          </button>
                        </form>
                      </Restricted>
                    </div>
                    <p>
                      <b>{this.state.total}</b> CM trouvés
                    </p>
                  </div>
                  <div className={"col-md-6 per-page-wrap"}>
                    <div className={"col-md-4"}>
                      {
                        /*
                  this.props.user && this.props.user.role === "admin" 
                  ?
                  <Link
                      to={"/cm/new"}
                      className={"btn btn-primary btn-block mb-2"}
                    >
                      <FontAwesomeIcon icon={faPlus} className={"mr-2"} />
                      <Trans i18nKey="label_new_cm" />
                  </Link>
                  :*/
                        <Restricted scope={"cm"} permission={"plan_task"}>
                          <Link
                            to={"/cm/new"}
                            className={"btn btn-primary btn-block mb-2"}
                          >
                            <FontAwesomeIcon icon={faPlus} className={"mr-2"} />
                            <Trans i18nKey='label_new_cm' />
                          </Link>
                        </Restricted>
                      }
                    </div>
                    <div className={"per-page-content"}>
                      <p>Résultat par page</p>
                      <Select
                        classNamePrefix={"custom-select"}
                        onChange={this.changeLimit}
                        className={"per-page nm"}
                        options={this.props.filters.result_per_page}
                        value={{
                          label: this.state.limit,
                          value: this.state.limit,
                        }}
                      />
                    </div>
                    <Pagination
                      data={this.state}
                      handleClick={this.selectPage}
                    />
                  </div>
                </div>

                <div className={"page-top-menu"}>
                  <ul>
                    <li
                      onClick={() => this.changeTab("mine")}
                      className={
                        this.state.selected_tab === "mine" && "selected"
                      }
                    >
                      Mes CMs
                    </li>
                    {/*<li onClick={() => this.changeTab('other')} className={this.state.selected_tab === "other" && "selected"}>Autre CMs</li>*/}
                    <li
                      onClick={() => this.changeTab("all")}
                      className={
                        this.state.selected_tab === "all" && "selected"
                      }
                    >
                      Tous
                    </li>
                    {/*<li onClick={() => this.changeTab('trans')} className={this.state.selected_tab === "trans" && "selected"}>Transféré/Escaladé</li>*/}
                    {this.props.session.user.external &&
                    this.props.session.user.external == "true" ? null : (
                      <li
                        onClick={() => this.changeTab("wo")}
                        className={
                          this.state.selected_tab === "wo" && "selected"
                        }
                      >
                        WO
                      </li>
                    )}
                    {/*<li onClick={() => this.changeTab('prf')} className={this.state.selected_tab === "prf" && "selected"}>PRF</li>*/}
                  </ul>
                </div>

                <CMTaskList
                  data={this.state}
                  rows={this.state.rows}
                  toggleHidden={this.toggleHidden}
                  handlers={this.handlers}
                  mine={this.state.filters["mine"]}
                />
              </div>
            </>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskListContainer);
