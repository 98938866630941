import React from "react";
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

const EscalateCMOverlay = ({ open, task, toggle, reload, ...props }) => {
  const submitSiteForm = (e) => {
    e.preventDefault();

    let that = this;

    const formdata = new FormData(e.target);

    let data = {};

    for (var [key, value] of formdata.entries()) data[key] = value;

    data["user"] = props.user;
    data["manager_id"] = "";

    formdata["user"] = props.session.user;

    formdata.append("site_plan_id", task.site_plan_id);

    /*var data = {
			site_plan_id : siteplanid,
			//agent_id : agentid,
		};*/

    if (data["service_id"] != "") {
      props.isLoading({
        message: "Escalade en cours",
        animate: true,
      });

      //api.post('/api/cm/escalate/service', data)
      api
        .post("/api/cm/escalate/service", formdata, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data.success) {
            props.doneLoading();
            props.setFlash({
              active: true,
              message: "CM escaladé",
              classes: "success",
              style: {},
            });
          } else {
            props.setFlash({
              active: true,
              message: "Une erreur est survenue, Envoi echoué.",
              classes: "warning",
              style: {},
            });
          }
          toggle();
          props.doneLoading();
          reload();
        })
        .catch((err) => {
          props.setFlash({
            active: true,
            message: "Une erreur est survenue, Envoi echoué.",
            classes: "warning",
            style: {},
          });
          props.doneLoading();
        });
    } else {
      props.setFlash({
        active: true,
        message: "Veuillez d'abord choisir un service !",
        classes: "warning",
        style: {},
      });
    }
  };

  return (
    <div className={"ui-overlay " + (open ? " open" : "")}>
      <div className={"ui-overlay-content site-overlay col-md-5"}>
        <button
          data-package-id='0'
          onClick={(e) => {
            toggle(e);
          }}
          className={"ui-overlay-close btn btn-danger btn-sm"}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <h5 className={"mb-3"}>Eescalader</h5>

        <form onSubmit={submitSiteForm}>
          {open && (
            <>
              <input
                type='hidden'
                name='site_plan_id'
                value={task.site_plan_id}
              />

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>Service</label>
                <Select
                  name='service_id'
                  classNamePrefix={"custom-select"}
                  options={props.filters.servicesToEscalate}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>Type de problème</label>
                <Select
                  name='problem_type'
                  classNamePrefix={"custom-select"}
                  defaultValue={{
                    value: task.cm_last_problem_type,
                    label: task.cm_last_problem_type,
                  }}
                  options={props.filters.problem_types}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>Pièce jointe</label>
                <input
                  type='file'
                  name='attachment'
                  className={"form-control col-md-8"}
                  //accept={"application/pdf"}
                />
              </div>

              <div className='form-group custom-inline-form mt-3 no-gutters'>
                <label className={"font-exp"}>Commentaire</label>
                <textarea rows={5} name='comment' className={"form-control"} />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3 no-gutters'>
                <input
                  type='submit'
                  className={"btn btn-primary col-md-12"}
                  value='Escalader'
                />
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default EscalateCMOverlay;
