import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'


const SingleCheck = ({test_content, test_status, ...props}) => {


    return (
        <div className={"singleCheck"}>
            <div className={"singleCheck-status"}>
                {test_status === 'ok' && (<FontAwesomeIcon color={"rgb(48, 209, 88)"} icon={faCheck} />)}
                {test_status === 'nok' && (<FontAwesomeIcon color={"#e71722"} icon={faTimes} />)}
                {test_status === 'na' && (<p>NA</p>)}
            </div>

            <div className={"singleCheck-content"}>
                <p>{test_content}</p>
                {props.test_comment && (
                    <p><i><b>{props.test_comment}</b></i></p>
                )}
            </div>

                
        </div>
    )

}

export default SingleCheck;