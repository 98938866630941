import React from "react";
import Constants from "./Constants";


const PhotoItem = ({photo, active, ...props}) => {
    let url;
    if(typeof photo.cloud_asset_url !== 'undefined') {
        url = photo.cloud_asset_url;
    } else {
        url = Constants.apiURL+"/"+photo.url;
    }


    if(true) {

        return (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a href={url} target="_blank">

                <img src={url} alt="" />
        
                {photo.comment ? (
                    <div className={"photo-comment"}>
                        <p>{photo.comment}</p>
                    </div>
                ) : <></>}
            </a>
        )
    } else {
        return <></>
    }
}

export default PhotoItem;