import React from 'react';
import api from '../../../Utils/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';

import Select from "react-select";
import { connect } from 'react-redux';
import Constants from '../../../Utils/Constants';


const mapState = state => ({filters: state.filters});
class SMS extends React.Component {
  constructor() {
    super();
    this.state = {
      someKey: 'someValue',
      sticky: "",
      saving: false,
      sending: false,
      tab: "users"
    };
  }


  componentDidMount() {
      /*api.get('/api/settings/send/sms')
      .then(res => {
          if(res.data.success)
            this.setState({sticky: res.data.sticky})


      })*/
  }

  submit = e => {
    this.setState({sending: true});
    e.preventDefault();
    const data = new FormData(e.target);
    api.post(e.target.action, data)
    .then(res => {
        this.setState({sending: false});
    })

  }

  submitSticky = e => {
    this.setState({saving: true});
    e.preventDefault();
    const data = new FormData(e.target);
    api.post(e.target.action, data)
    .then(res => {
        this.setState({saving: false});
    })
  }

  render() {
    return (
        <>
            <h3>SMS</h3>
            <div className={"row mt-5"}>
                <div className={"form-group col-md-6"}>
                    <h4>Notification par SMS</h4>

                    <form onSubmit={this.submit} action={Constants.apiURL+"/api/settings/sms/send/agent"}>

                        <div className={"tab-container"}>

                            <div>
                                <div className={"form-group"}>
                                    <label>Agent</label>
                                    <Select
                                        name={"users[]"}
                                        classNamePrefix={"custom-select"}
                                        //options={this.props.filters.agents}
                                        options={[{ label: "Tous", value: "" }, ...this.props.filters.agents]}
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={"form-group"}>
                            <label>Message</label>
                            <textarea name={"message"} className={"form-control"}></textarea>
                        </div>

                        <button className={"btn btn-primary btn-sm mt-1 float-right"}>
                            Envoyer
                            <FontAwesomeIcon className={"ml-2"} spin={this.state.sending} icon={this.state.sending ? faSpinner : faCheck} />
                        </button>
                    </form>
                </div>

                <div className={"form-group col-md-6"}>
                    <h4>&nbsp;</h4>

                    <form onSubmit={this.submit} action={Constants.apiURL+"/api/settings/sms/send/manager"}>

                        <div className={"tab-container"}>

                            <div>
                                <div className={"form-group"}>
                                    <label>Manager</label>
                                    <Select
                                        name={"users[]"}
                                        classNamePrefix={"custom-select"}
                                        //options={this.props.filters.agents}
                                        options={[{ label: "Tous", value: "" }, ...this.props.filters.managers]}
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className={"form-group"}>
                            <label>Message</label>
                            <textarea name={"message"} className={"form-control"}></textarea>
                        </div>

                        <button className={"btn btn-primary btn-sm mt-1 float-right"}>
                            Envoyer
                            <FontAwesomeIcon className={"ml-2"} spin={this.state.sending} icon={this.state.sending ? faSpinner : faCheck} />
                        </button>
                    </form>

                </div>
            </div>

        </>
    );
  }


}

export default connect(mapState)(SMS);
