import React, { Suspense } from 'react';
import { NavLink, Switch, Route } from 'react-router-dom';
import LoadingOverlay from '../../Common/LoadingOverlay';
//import Agents from './CC/Agents';
import Received from './CC/Received';
import Open from './CC/Open';
//import HsSites from './CC/HsSites';
//import PendingCC from './CC/PendingCC';
//import StateCC from './CC/StateCC';
import { connect } from "react-redux";
import { setAuthAction, } from "../../../Redux/actions/index";


const mapStateToProps = (state) => {
    return {
      user: state.session.user,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setAuth: (user) => dispatch(setAuthAction(user)),
});

//const AdminComp = React.lazy(() => import(/* webpackChunkName: "adminDashbaord" */"../CCAdminDashboard"));

class CCDashboardWrapper extends React.Component {


  render() {


    return (

        <>
        <div className={"page-menu dashboard"}>
            <ul>
                {/*<li><NavLink to={'/cc/dashboard/tasks'}>Plan & Execution</NavLink></li>
                <li><NavLink to={'/cc/dashboard/equip'}>Equipement & matériel</NavLink></li>
                <li><NavLink to={'/cc/dashboard/agents'}>Agents</NavLink></li>*/}
                <li><NavLink to={'/cc/dashboard/open'}>Open</NavLink></li>
                <li><NavLink to={'/cc/dashboard/received'}>Received</NavLink></li>
            </ul>
        </div>

        <div>
            <Switch>

                <Suspense  fallback={<LoadingOverlay active={true} />}>
                    {/*<Route exact path="/cc/dashboard" component={Sites} />
                    <Route exact path="/cc/dashboard/tasks" component={Tasks} />
                    <Route exact path="/cc/dashboard/equip" component={AdminComp} />
                    <Route exact path="/cc/dashboard/agents" component={Agents} />*/}
                    <Route exact path="/cc/dashboard" component={Open} />
                    <Route exact path="/cc/dashboard/open" component={Open} />
                    <Route exact path="/cc/dashboard/received" component={Received} />
                </Suspense>

            </Switch>
        </div>
        </>
      );
  }


}

//export default CCDashboardWrapper;
export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(CCDashboardWrapper);