import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import { connect } from "react-redux";
import {
  setAuthAction,
  startLoading,
  doneLoading,
  closeFlash,
  setFlash,
  setOverlay,
} from "../../Redux/actions/index";
import { Trans } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../Utils/Api";
import Pagination from "../../Utils/Pagination";
import SitesExport from "../../Utils/SitesExport";
import SiteRow from "./SiteList/SiteRowCM";
import EditCMSiteOverlay from "./SiteList/EditCMSiteOverlay";
import AssignSiteOverlay from "./SiteList/AssignSiteOverlay";
import AssignSiteOverlayCM from "./SiteList/AssignSiteOverlayCM";
import PlanPmOverlay from "./SiteList/PlanPmOverlay";
import ChangeOwnerOverlay from "./SiteList/ChangeOwnerOverlay";

const moment = require("moment");

const mapStateToProps = (state) => {
  return {
    auth: state.session.auth,
    user: state.session.user,
    filters: state.filters,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setAuth: (user) => dispatch(setAuthAction(user)),
  setOverlay: (e) => dispatch(setOverlay(e)),
  doneLoading: () => dispatch(doneLoading()),
  isLoading: (p) => dispatch(startLoading(p)),
  setFlash: (f) => dispatch(setFlash(f)),
  closeFlash: (f) => dispatch(closeFlash(f)),
});

class TaskList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      showSiteOverlay: false,
      showOverlay: false,
      siteid: "",
      agentid: "",
      date: "",
      selected_site: {},
      physicSites: [],
    };

    //load physic sites
    this.props.isLoading({
      message: "Chargement",
      animate: true,
    });

    this.loadPhysicSites();

    this.toggleOverlay = this.toggleOverlay.bind(this);
  }

  loadPhysicSites() {
    this.props.isLoading();

    api
      .get(`/api/physicsites/fetch?limit=-1`)
      .then((response) => {
        if (response.data.success) {
          //console.log("hello response", response.data.sites);
          this.setState({
            physicSites: response.data.sites.map((site) => ({
              label: site.site_code,
              value: site.site_id,
            })),
          });
          //this.props.doneLoading();
        } else {
          if (response.data.errcode === 101) {
            this.props.setAuth(false);
            //this.props.doneLoading();
          }
        }
      })
      .catch((error) => {
        //this.props.doneLoading();
      });
  }

  handleEscape = (e) => {
    if (e.keyCode === 27) {
      this.setState({ showOverlay: false });
    }
  };

  toggleOverlay(e) {
    this.setState({ showOverlay: !this.state.showOverlay });
  }

  componentDidMount() {
    document.addEventListener("keyup", this.handleEscape);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleEscape);
  }

  planSite = (site, e) => {
    this.setState({
      taskType: "pm",
      date: site.site_next_pm ? site.site_next_pm : "",
      agentid: site.site_next_pm_agent_id ? site.site_next_pm_agent_id : "",
      agent_username: site.site_next_pm_agent ? site.site_next_pm_agent : "",
      siteid: site.site_id,
      site: site,
      showPlanOverlay: true,
    });
  };

  assignSite = (site, e) => {
    this.setState({
      agentid: site.site_next_pm_agent_id ? site.site_next_pm_agent_id : "",
      agent_username: site.site_next_pm_agent ? site.site_next_pm_agent : "",
      siteid: site.site_id,
      site: site,
      showAssignOverlay: true,
    });
  };

  assignSiteCM = (site, e) => {
    this.setState({
      agentid: site.site_next_pm_agent_id ? site.site_next_pm_agent_id : "",
      agent_username: site.site_next_pm_agent ? site.site_next_pm_agent : "",
      siteid: site.site_id,
      site: site,
      showAssignOverlayCM: true,
    });
  };

  ownerSite = (site, e) => {
    this.setState({
      defaultOwner: site.owner ? site.owner : "",
      site: site,
      showOwnerOverlay: true,
    });
  };

  editSite = (site) => {
    this.setState({
      showSiteOverlay: true,
      selected_site: site,
    });
  };

  render() {
    //const { t, i18n } = useTranslation();

    const data = this.props.data;
    const {
      showAssignOverlay,
      showAssignOverlayCM,
      showPlanOverlay,
      showOwnerOverlay,
    } = this.state;

    return (
      <React.Fragment>
        {/*<div className="col-md-12">

          <div className={"table-responsive"}>
            <table className={"table table-striped"}>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Site name</th>
                  <th>Wilaya</th>
                  <th> {this.props.user.role === "admin" ||
                    this.props.user.role === "cluster_manager"
                      ? "Owner"
                      : "Derniere PM"}</th>

                  <th>
                    PM Plan
                  </th>
                  <th>Ingénieur</th>
                  <th></th>
                </tr>
              </thead>*/}

        <div className='col-md-12 ctable mb-3'>
          <div className={"d-none d-sm-block"}>
            <div className={"row head-titles ctable-row"}>
              <div className={"col-md-1"}>Code</div>
              <div className={"col-md-2"}>Site name</div>
              <div className={"col-md-1"}>Wilaya</div>
              <div className={"col-md-1"}>Région O&M</div>
              <div className={"col-md-1"}>Catégorie</div>
              <div className={"col-md-2"}>
                {this.props.user.role === "admin" ||
                this.props.user.role === "cluster_manager"
                  ? "Owner"
                  : "Derniere PM"}
              </div>
              <div className={"col-md-1"}>Location owner</div>
              <div className={"col-md-1"}>Nombre CM</div>
              {/*<div className={"col-md-1"}>Ingénieur PM</div>*/}
              <div className={"col-md-1"}>Site keeper</div>
              <div className={"col-md-1 task-details-col"}>Action</div>
            </div>
          </div>
          {/*  <tbody>*/}
          {data.sites.map((site, i) => (
            <SiteRow
              role={this.props.user.role}
              key={i}
              site={site}
              selectSite={this.props.handlers.selectSite}
              editSite={this.editSite}
              reassignSite={this.assignSite}
              reassignSiteCM={this.assignSiteCM}
              planSite={this.planSite}
              ownerSite={this.ownerSite}
              session={this.props.user}
            />
          ))}
          {/*    </tbody>*/}
          {/*  </table>
          </div>*/}
        </div>

        <div className='col-md-12 ctable mb-3'>
          {/* <div className={"d-none d-sm-block"}>
            <div className={"row head-titles ctable-row"}>

              <div className={"col-md-2"}>Code (physique)</div>

              <div className={"col-md-2"}>Site name</div>

              <div className={"col-md-2"}>Wilaya</div>

              <div className={"col-md-1"}>
                {this.props.user.role === "admin" ||
                this.props.user.role === "cluster_manager"
                  ? "Owner"
                  : "Derniere PM"}
              </div>

              <div className={"col-md-2"}>PM Plan</div>
              <div className={"col-md-1"}>Ingénieur</div>

              <div className={"col-md-2 task-details-col"}>Action</div>
            </div>
          </div> */}

          {/* {data.sites.map((site, i) => (
            <SiteRow
              role={this.props.user.role}
              key={i}
              site={site}
              selectSite={this.props.handlers.selectSite}
              editSite={this.editSite}
              reassignSite={this.assignSite}
              planSite={this.planSite}
              ownerSite={this.ownerSite}
              session={this.props.user}
            />
          ))} */}

          {data.sites.length === 0 && (
            <div className={"col-md-12 text-center my-3"}>
              Aucun site trouvé
            </div>
          )}

          {showAssignOverlay && (
            <AssignSiteOverlay
              close={() => this.setState({ showAssignOverlay: false })}
              loadSites={this.props.handlers.loadSites}
              open={true}
              site={this.state.site}
              {...this.props}
            />
          )}

          {showAssignOverlayCM && (
            <AssignSiteOverlayCM
              close={() => this.setState({ showAssignOverlayCM: false })}
              loadSites={this.props.handlers.loadSites}
              open={true}
              site={this.state.site}
              {...this.props}
            />
          )}

          {showPlanOverlay && (
            <PlanPmOverlay
              close={() => this.setState({ showPlanOverlay: false })}
              loadSites={this.props.handlers.loadSites}
              open={true}
              site={this.state.site}
              taskType={this.state.taskType}
              {...this.props}
            />
          )}

          {showOwnerOverlay && (
            <ChangeOwnerOverlay
              loadSites={this.props.handlers.loadSites}
              open={true}
              site={this.state.site}
              close={() => this.setState({ showOwnerOverlay: false })}
            />
          )}
          <EditCMSiteOverlay
            loadSites={this.props.handlers.loadSites}
            open={this.state.showSiteOverlay}
            site={this.state.selected_site}
            physicSites={this.state.physicSites}
            close={() =>
              this.setState({ showSiteOverlay: false, selected_site: {} })
            }
            {...this.props}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskList);
