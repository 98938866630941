import React, { useState, useRef } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../../Utils/Api";
import ApiService from "../../../Utils/ApiService";

const CancelCMOverlay = ({
  toggle,
  open,
  task,
  reload,
  scope = "cm",
  ...props
}) => {
  const messageRef = useRef(null);

  const returnTask = () => {
    props.isLoading({
      message: "Annulation de la tâche CM en cours",
      animated: true,
    });

    /* const data = {
            pmid : task,
            message: messageRef.current.value,
            scope
        }
        ApiService.returnTask(data)
        .then(response => {
            toggle();
            reload();
            props.doneLoading()
        })*/

    var data = {
      //site_plan_id : planid,
      site_plan_id: task,
      comment: messageRef.current.value,
    };

    api
      .post("/api/cm/cancel", data)
      .then((response) => {
        if (!response.data.success) {
          alert("Envoi echoué");
        }
        toggle();
        reload();
        props.doneLoading();
      })
      .catch((err) => {
        props.doneLoading();
        alert(`Envoi echoué: ${err.message}`);
      });
  };

  return (
    <>
      <div
        className={
          open ? "ui-overlay delete-task open" : "ui-overlay delete-task"
        }
      >
        <div
          className={
            "ui-overlay-content status-overlay-text col-md-4 offset-md-4"
          }
        >
          <button
            onClick={(e) => toggle()}
            className={"ui-overlay-close btn btn-danger btn-sm"}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <h5 className={"mb-4"}>Vous pouvez inclure un commentaire : </h5>
          <textarea ref={messageRef} className={"form-control"} />
          <button onClick={returnTask} className={"btn btn-primary mt-3"}>
            Valider
          </button>
        </div>
      </div>
    </>
  );
};

export default CancelCMOverlay;
