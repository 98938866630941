import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setAuthAction, doneLoading, startLoading, setOverlay } from '../../Redux/actions/index';
import AddressNew from '../../Components/Addresses/AddressNew';
import api from '../../Utils/Api';
import { formToObject } from '../../Utils/Helpers';



const mapStateToProps = state => {
	return {
		user: state.session.user,
		loading: state.ui.loading
	}
}

function mapDispatchToProps(dispatch) {
  return {
    setAuth: user => dispatch(setAuthAction(user)),
    doneLoading: () => dispatch(doneLoading()),
    setOverlay: (x) => dispatch(setOverlay(x)),
    isLoading: (e) => dispatch(startLoading(e))
  };
}


class AddressNewContainer extends Component {

	submitTask = e => {
		e.preventDefault();

		this.props.isLoading({
			message:"Adding site",
			animate:true
		});


		let data = formToObject(e.target);

		api.post('/api/sites/new', (data))
		.then((response) => {

			if(response.data.success) {
				this.props.history.push('/flm/sites');

				this.props.setOverlay({
					message: 'Site successfully added !',
					label: "success",
					type: "success",
					button: {
						type: "close",
						label: "OK",
						link: "javascript:void(0)"
					}
				});
			} else {

				this.props.setOverlay({
					message: response.data.error,
					label: "warning",
					type: "fail",
					button: {
						type: "close",
						label: "OK",
						link: "javascript:void(0)"
					}
				});


				if(response.data.errcode === 101) {
					this.props.setAuth(response.data)
				}
			}

			this.props.doneLoading();

		}).catch(err => this.props.doneLoading());
		
	}


	render() {
		

		return(

			<AddressNew
				submitTask={this.submitTask}
			/>
		)
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(AddressNewContainer)