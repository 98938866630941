import React from "react";
import GPMAnomalies from "./../../GPM/Tests/GPMAnomalies";


import SpareParts from "./../../PM/Tests/SpareParts";
import GPMPhotos from "./../../GPM/Tests/GPMPhotos";
import ClockIn from "../../PM/Tests/ClockIn";
import CMActions from "./CMActions";
import Sabotage from "./../../PM/Tests/Sabotage";
import SiteTransfer from "./SiteTransfer";

const CMTests = [
    {
        name: "Clockin",
        key: "clockin",
        component: ClockIn
    },
    {
        name: "Actions",
        key: "cm_actions",
        component: CMActions
    },
    {
        name: "Spare Parts",
        key: "spareparts",
        component: SpareParts
    },
    {
        name: "Anomalies",
        key: "anomalies",
        component: GPMAnomalies,
    },
    {
        name: "Sabotage",
        key: "sabotage",
        component: Sabotage,
    },
    {
        name: "Photos",
        key: "photos",
        component: GPMPhotos
    },
    {
        name: "SiteTransfer",
        key: "site_transfer",
        component: SiteTransfer
    }
];


export default CMTests