import React, { useState } from "react";
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import WindowedSelect from "react-windowed-select";

const AttachFileOverlay = ({ open, task, toggle, reload, ...props }) => {
  //console.log("hello task", task);

  const submitSiteForm = (e) => {
    e.preventDefault();

    let that = this;

    const formdata = new FormData(e.target);

    let data = {};

    for (var [key, value] of formdata.entries()) data[key] = value;

    formdata["user"] = props.session.user;

    props.isLoading({
      message: "Traitement en cours",
      animate: true,
    });

    //api.post('/api/cm/file/attach', data)
    api
      .post("/api/cm/file/attach", formdata, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        if (response.data.success) {
          props.doneLoading();
          props.setFlash({
            active: true,
            message: "Fichier attaché",
            classes: "success",
            style: {},
          });
        } else if (response.data.errcode == 102) {
          props.doneLoading();
          props.setFlash({
            active: true,
            message: "Extension de fichier non compatible!",
            classes: "warning",
            style: {},
          });
        } else {
          props.setFlash({
            active: true,
            message: "Une erreur est survenue, Envoi echoué.",
            classes: "warning",
            style: {},
          });
        }
        toggle();
        props.doneLoading();
        reload();
      })
      .catch((err) => {
        props.doneLoading();
        props.setFlash({
          active: true,
          message: `Envoi echoué: ${err.message}`,
          classes: "warning",
          style: {},
        });
      });
  };

  return (
    <div className={"ui-overlay " + (open ? " open" : "")}>
      <div className={"ui-overlay-content site-overlay col-md-5"}>
        <button
          data-package-id='0'
          onClick={(e) => {
            toggle(e);
          }}
          className={"ui-overlay-close btn btn-danger btn-sm"}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <h5 className={"mb-3"}>Attacher fichier</h5>

        <form onSubmit={submitSiteForm}>
          {open && (
            <>
              <input type='hidden' name='task_id' value={task.key} />

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>Ticket ref</label>
                <input
                  type='text'
                  name='ticket_ref'
                  className={"form-control-read col-md-8"}
                  value={task.ticket_ref}
                  disabled
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>Pièce jointe</label>
                <input
                  type='file'
                  name='attachment'
                  className={"form-control col-md-8"}
                  //accept={"application/pdf"}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3 no-gutters'>
                <input
                  type='submit'
                  className={"btn btn-primary col-md-12"}
                  value='Valider'
                />
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default AttachFileOverlay;
