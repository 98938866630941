import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSpinner } from "@fortawesome/free-solid-svg-icons";
import api from "../../../Utils/Api";

class SiteHistoryOverlay extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        history: [],
        loading: true,
        history_data: []
      };
    }
  

    componentDidMount() {
        api.get("/api/sites/history/" + this.props.site.site_id).then((response) => {
            console.log(response.data)
            this.setState({
                loading: false,
                history_data: response.data.data,
            });
        });

        
    }
    render() {
      return (
        <div className={"ui-overlay" + (this.props.open ? " open" : "")}>
          <div className={"ui-overlay-content anomalie-history col-md-7"}>
            <button
              onClick={this.props.toggleOverlay}
              className={"ui-overlay-close btn btn-danger btn-sm"}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
  
            <h5>
              Historique du site :{" "}
              {this.props.site.site_code + " - " + this.props.site.site_name}
            </h5>
  
            <div className="col-md-12 ctable my-3">
              <div className={"row head-titles ctable-row"}>
                <div className={"col-md-3"}>Date & heure</div>
  
                <div className={"col-md-5"}>Action</div>
  
                <div className={"col-md-2"}>Utilisateur</div>
  
                <div className={"col-md-2"}>Role</div>
              </div>
  
              {this.state.loading && (
                <FontAwesomeIcon
                  style={{ fontSize: 24 }}
                  className={"mt-2"}
                  spin={true}
                  icon={faSpinner}
                />
              )}

              {this.state.history_data.map((item) => (
                <div className={"row ctable-row"}>
                  <div className={"col-md-3"}>{item.created_at}</div>
  
                  <div className={"col-md-5 text-left"}>{item.action}</div>
  
                  <div className={"col-md-2"}>{item.username}</div>
  
                  <div className={"col-md-2"}>{item.role}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
  }
  

export default SiteHistoryOverlay