import React from "react";
import SingleCheck from "./SingleCheck";


const RenderChecks = ({ data, isAc }) => {
  
  if(isAc) {
    return (<>
      {Array.isArray(data) && data.map((section) => (
        <>
          {section.section_name && <b>{section.section_name}</b>}


          {section.checks && section.checks.map((item) => (
              <SingleCheck
                test_content={item.test_content}
                test_status={item.test_status}
                test_comment={item.test_comment}
              />
            ))}

          {section.data && section.data.map((item) => (
              <SingleCheck
                test_content={item.test_content}
                test_status={item.test_status}
                test_comment={item.test_comment}
              />
            ))}
        </>
      ))}
    </>);
  } else {

    return (<>
      {data.checks && data.checks.map((section) => (
        <>
          {section.section_name && <b>{section.section_name}</b>}


          {section.checks && section.checks.map((item) => (
              <SingleCheck
                test_content={item.test_content}
                test_status={item.test_status}
                test_comment={item.test_comment}
              />
            ))}

          {section.data && section.data.map((item) => (
              <SingleCheck
                test_content={item.test_content}
                test_status={item.test_status}
                test_comment={item.test_comment}
              />
            ))}
        </>
      ))}
    </>);
  }
};

export default RenderChecks;