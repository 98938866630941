const filters = {
  permissions: {
    task: {},
    site: {},
    other: {}
  },
  task_types: [
    {label: 'PM', value: 'pm'},
    {label: 'GPM', value: 'gpm'},
    {label: 'CM', value: 'cm'}
  ],
  cm_task_types: [
    {label: 'CM', value: 'cm'},
    {label: 'WO', value: 'wo'},
    //{label: 'PRF', value: 'prf'},
  ],
  account_status: [
    { label: "Tous", value: "all" },
    { label: "Désactivé", value: "disabled" },
    { label: "Activé", value: "" },
    { label: "Supprimé", value: "deleted" },
  ],
  result_per_page: [
    { label: "15", value: 15 },
    { label: "40", value: 40 },
    { label: "100", value: 100 },
  ],
  anomalies_status: [
    { label: "Resolved", value: "resolved" },
    { label: "Unresolved", value: "unresolved" },
    { label: "Canceled", value: "canceled" },
    { label: "Repeated", value: "repeated" },
  ],
  /*anomalies_types: [
    { label: "AC", value: "AC" },
    { label: "Batteries", value: "Batteries" },
    { label: "HSE", value: "HSE" },
    { label: "Electricity", value: "Electricity" },
    { label: "Power Supply", value: "Power Supply" },
    { label: "Shelter", value: "Shelter" },
    { label: "Autre", value: "Autre" },
  ],*/
  anomalies_types: [],
  anomalies_names: [],
  task_steps: [
    { label: "Electricité", value: "electricity" },
    { label: "Power Supply", value: "power" },
    { label: "Batteries", value: "batteries" },
    { label: "Shelter", value: "shelter" },
    { label: "AC", value: "ac" },
    { label: "BSS", value: "bss" },
    { label: "PDH-PAV-Redline", value: "pdh" },
    { label: "Alarms", value: "alarms" },
    { label: "GE", value: "ge" },
    { label: "Exterieur", value: "exterieur" },
  ],
  connectionInterval: [
    { label: "Tous", value: "" },
    { label: "Aujourd'hui", value: 24 },
    { label: "7 derniers jours", value: 24 * 7 },
    { label: "15 derniers jours", value: 24 * 15 },
    { label: "30 derniers jours", value: 24 * 30 },
    { label: "Jamais", value: 0 },
  ],
  site_classification: [
    { label: "Résidentiel", value: "Résidentiel" },
    { label: "Bureau", value: "Bureau" },
    { label: "Administratif", value: "Administratif" },
    { label: "Commercial", value: "Commercial" },
    { label: "Terrain", value: "Terrain" },
    { label: "Château d'eau", value: "Château d'eau" },
    { label: "Autre", value: "Autre" },
  ],
  site_status: [
    { label: "Tous", value: "all" },
    { label: "Active", value: "active" },
    { label: "Désactivé", value: "disabled" },
  ],
  cm_site_status: [
    { label: "Tous", value: "all" },
    { label: "Active", value: null },
    { label: "Désactivé", value: "disabled" },
  ],
  cosite_types: [
    { label: "RADIO", value: "RADIO" },
    { label: "MSC", value: "MSC" },
    { label: "FO", value: "FO" },
    { label: "BSC", value: "BSC" },
    { label: "SDH", value: "SDH" },
    { label: "TRANS", value: "TRANS" },
    { label: "REPETEUR", value: "REPETEUR" },
    { label: "3G", value: "3G" },
    { label: "4G", value: "4G" },
  ],
  gpm_modes: [
    { label: 'All', value: ''},
    { label: 'Permanent', value: 'permanent'},
    { label: 'Hybride', value: 'hybride'},
    { label: 'Stand-by', value: 'stand-by'},
  ],
  gpm_zones: [
    { label: 'All', value: ''},
    { label: 'Environnement', value: 'Environnement'},
    { label: 'Nord', value: 'nord'},
    { label: 'Sud', value: 'Sud'},
    { label: 'Border', value: 'Border'},
  ],
  clusters: [],
  wilayas: [],
  agents: [],
  cmManagerAgents: [],
  teams: [],
  shifts: [
    { label: "Jour", value: "1" },
    { label: "Soir", value: "2" },
    { label: "Nuit", value: "3" },
  ],
  managers: [],
  cluster_managers: [],
  pm_site_owners: [
    { label: "All", value: "" },
    { label: "Sous-traitant", value: "outsource" },
    { label: "O&M", value: "inhouse" },
    { label: "Production", value: "production" },
    { label: "MBS", value: "MBS" },
    { label: "SASCC", value: "SASCC" },
    { label: "ACTIC", value: "ACTIC" },
    { label: "Environnement", value: "Environnement" },
    { label: "PROTECH", value: "PROTECH" },
    { label: "AFRONET", value: "AFRONET" },
  ],
  //add new type compte
  site_owners: [],
  /*site_owners: [
    { label: "All", value: "" },
    { label: "Sous-traitant", value: "outsource" },
    { label: "O&M", value: "inhouse" },
    { label: "Production", value: "production" },
    { label: "MBS", value: "MBS" },
    { label: "SASCC", value: "SASCC" },
    { label: "ACTIC", value: "ACTIC" },
    { label: "PROTECH", value: "PROTECH" },
    { label: "AFRONET", value: "AFRONET" },
    { label: "Environnement", value: "Environnement" },
    { label: "NSOC_BSS", value: "NSOC_BSS" },
    { label: "NSOC_NSS", value: "NSOC_NSS" },
    { label: "NSOC_SDH", value: "NSOC_SDH" },
    { label: "NSOC_MW", value: "NSOC_MW" },
    { label: "NSOC_FO & VSAT", value: "NSOC_FO & VSAT" },
    { label: "SPMS", value: "SPMS" },
    { label: "SUPERVISION", value: "SUPERVISION" },
    { label: "NSOC_VAS", value: "NSOC_VAS" },
    { label: "ROLLOUT", value: "ROLLOUT" },
    { label: "SYS_ADMIN", value: "SYS_ADMIN" },
    { label: "M_ALGER", value: "M_ALGER" },
    { label: "M_CHLEF", value: "M_CHLEF" },
    { label: "M_ORAN", value: "M_ORAN" },
    { label: "M_SETIF", value: "M_SETIF" },
    { label: "M_ANNABA", value: "M_ANNABA" },
    { label: "M_TLEMCN", value: "M_TLEMCN" },
    { label: "M_BATNA", value: "M_BATNA" },
    { label: "M_BLIDA", value: "M_BLIDA" },
    { label: "M_CONSTANTINE", value: "M_CONSTANTINE" },
    { label: "PATRIMONY", value: "PATRIMONY" },
    { label: "ENG_BSS", value: "ENG_BSS" },
    { label: "ENG_SDH", value: "ENG_SDH" },
    { label: "ENG_NSS", value: "ENG_NSS" },
    { label: "ENG_MW", value: "ENG_MW" },
    { label: "OPTIM", value: "OPTIM" },
    { label: "RADIO", value: "RADIO" },
    { label: "ENG_TRANS", value: "ENG_TRANS" },
    { label: "IT_VOICE", value: "IT_VOICE" },
    { label: "DATA_SECURITE", value: "DATA_SECURITE" },
    { label: "JURIDIQUE", value: "JURIDIQUE" },
    { label: "LOGISTIC", value: "LOGISTIC" },
    { label: "ENG_DATA", value: "ENG_DATA" },
    { label: "TCM", value: "TCM" },
    { label: "NETWORK PERFORMANCE", value: "NETWORK PERFORMANCE" },
    { label: "TRAFFIC", value: "TRAFFIC" },
    { label: "NSOC_DCN", value: "NSOC_DCN" },
    { label: "Drive Test", value: "Drive Test" },
    { label: "Traces Team", value: "Traces Team" },
    { label: "Geomarketing", value: "Geomarketing" },
    { label: "NOC_ZTE_DWDM", value: "NOC_ZTE_DWDM" },
    { label: "FLM_ZTE_DWDM", value: "FLM_ZTE_DWDM" },
    { label: "MD_OTA", value: "MD_OTA" },
    { label: "MD_1", value: "MD_1" },
    { label: "MD_2", value: "MD_2" },
  ],*/
  problem_manager_types: [
    { label: "Access problem", value: "Access problem" },
    { label: "Spare part problem", value: "Spare part problem" },
  ],
  type_site: [
    { label: "All", value: "" },
    { label: "Indoor", value: "indoor" },
    { label: "Outdoor", value: "outdoor" },
    { label: "Local Technique", value: "localtechnique" },
    { label: "Station Mobile", value: "stationmobile"},
  ],
  typology_site: [
    { label: "All", value: "" },
    { label: "Green Field", value: "greenfield" },
    { label: "Rooftop", value: "rooftop" },
  ],
  support_antenne: [
    { label: "All", value: "" },
    { label: "Mat", value: "mat" },
    { label: "Pylone", value: "pylone" },
  ],
  type_ac: [
    { label: "All", value: "" },
    { label: "Monobloc", value: "monobloc" },
    { label: "Split", value: "split" },
  ],
  marques_ac: [],
  capacity_ac: [],
  marques_compteur: [],
  marques_batteries: [],
  marques_power: [],
  types_bss: [],
  problem_types: [],
  alarm_types: [],
  responsibility: [
    { label: "Oui", value: "Oui" },
    { label: "Non", value: "Non" },
  ],
  cm_category: [],
  cm_code: [],
  cm_priority: [],
  //logic_sites: [],
  cm_state: [
    //{ label: "All", value: "" },
    { label: "Wait Accept", value: "Wait Accept" },
    { label: "Wait Arrival", value: "Wait Arrival" },
    { label: "Wait Process", value: "Wait Process" },
    { label: "Wait Confirm", value: "Wait Confirm" },
    { label: "Wait Reprocess", value: "Wait Reprocess" },
    { label: "Wait Restart", value: "Wait Restart" },
    { label: "Canceled", value: "Canceled" },
    { label: "Closed", value: "Closed" },
    { label: "Force Closed", value: "Force Closed" },
  ],
  cm_creator_service: [
    //{ label: "Tout", value: "" },
    { label: "Supervision", value: "Supervision" },
    { label: "NOC BSS", value: "NOC BSS" },
    { label: "NOC MW", value: "NOC MW" },
  ],
  cm_site_categories: [
    { label: "Bronze", value: "Bronze" },
    { label: "Gold", value: "Gold" },
    { label: "Platinium", value: "Platinium" },
    { label: "Silver", value: "Silver" },
  ],
  cm_rt_gf: [
    { label: "RT", value: "RT" },
    { label: "GF", value: "GF" },
    { label: "Chateau d eau RT", value: "Chateau d eau RT"},
  ],
  cm_site_cabinets: [
    { label: "Indoor", value: "Indoor" },
    { label: "Outdoor", value: "Outdoor" },
  ],
  cm_site_natures: [
    { label: "COMMUNE", value: "COMMUNE" },
    { label: "CHEF-LIEU-WILAYA", value: "CHEF-LIEU-WILAYA" },
  ],
  cm_site_cell_numbers: [
    { label: "0", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
    { label: "15", value: "15" },
    { label: "16", value: "16" },
    { label: "17", value: "17" },
    { label: "18", value: "18" },
    { label: "19", value: "19" },
    { label: "20", value: "20" },
    { label: "21", value: "21" },
    { label: "22", value: "22" },
    { label: "23", value: "23" },
    { label: "24", value: "24" },
    { label: "25", value: "25" },
  ],
  cm_site_vendors: [
    { label: "M_ALCATEL", value: "M_ALCATEL" },
    { label: "M_HUAWEI", value: "M_HUAWEI" },
    { label: "M_NOKIA", value: "M_NOKIA" },
    { label: "VSAT", value: "VSAT" },
    { label: "M_ZTE", value: "M_ZTE" },
  ],
  cm_site_technology: [
    { label: "2G", value: "2G" },
    { label: "3G", value: "3G" },
    { label: "4G", value: "4G" },
    { label: "SDH", value: "SDH" },
    { label: "TSS", value: "TSS" },
    { label: "ADMIN", value: "ADMIN" },
    { label: "BOUTIQUE", value: "BOUTIQUE" },
    { label: "CS", value: "CS" },
    { label: "DCN", value: "DCN" },
    { label: "ENV", value: "ENV" },
    { label: "FO", value: "FO" },
    { label: "MEDCABLE", value: "MEDCABLE" },
    { label: "MSC", value: "MSC" },
    { label: "PS", value: "PS" },
    { label: "PSS", value: "PSS" },
    { label: "REPETEUR", value: "REPETEUR" },
    { label: "STATION MOBILE", value: "STATION MOBILE" },
    { label: "TRANS", value: "TRANS" },
    { label: "VAS", value: "VAS" },
    { label: "VSAT", value: "VSAT" },
    { label: "IT_BTO", value: "IT_BTO" },
  ],
  /*cm_site_owners: [
    //{ label: "All", value: "" },
    //{ label: "M_OTT", value: "M_OTT" },
    { label: "M_SETIF", value: "M_SETIF" },
    { label: "M_BLIDA", value: "M_BLIDA" },
    { label: "M_MOBISERV", value: "M_MOBISERV" },
    { label: "M_ALGER", value: "M_ALGER" },
    { label: "M_CHLEF", value: "M_CHLEF" },
    { label: "M_TLEMCN", value: "M_TLEMCN" },
    { label: "M_ORAN", value: "M_ORAN" },
    //{ label: "M_CONST", value: "M_CONST" },
    { label: "M_BATNA", value: "M_BATNA" },
    { label: "M_ANNABA", value: "M_ANNABA" },
    //{ label: "NOC_FO", value: "NOC_FO" },
    //{ label: "NOC_PS", value: "NOC_PS" },
    { label: "NSOC_DCN", value: "NSOC_DCN" },
    //{ label: "NOC_CS", value: "NOC_CS" },
    //{ label: "Sys_ADMIN", value: "Sys_ADMIN" },
    { label: "O&M_ENV", value: "O&M_ENV" },
    //{ label: "NOC_VAS", value: "NOC_VAS" },
    //{ label: "OOREDOO", value: "OOREDOO" },
    { label: "M_PROTECH", value: "M_PROTECH" },
    { label: "M_AFRONET", value: "M_AFRONET" },
    { label: "NSOC_NSS", value: "NSOC_NSS" },
    { label: "NSOC_FO & VSAT", value: "NSOC_FO & VSAT" },
    { label: "M_CONSTANTINE", value: "M_CONSTANTINE" },
    { label: "SYS_ADMIN", value: "SYS_ADMIN" },
    { label: "NSOC_VAS", value: "NSOC_VAS" },
    { label: "IT-BTOSUPPORT", value: "IT-BTOSUPPORT" },
  ],*/
  cm_site_owners: [],
  services: [],
  servicesToEscalate: [],
  externalServicesToEscalate: [],
  agentsToTransfer: [],
  teamsToTransfer: [],
  pm_state: [
    { label: "En cours", value: "ongoing" },
    { label: "Terminé", value: "done" },
    { label: "Retourné", value: "return" },
  ],
  cycle: [
    { label: "Cycle actuel", value: "cycle" },
    { label: "Année actuelle", value: "year" },
    { label: "2024 2eme cycle", value: "second2024" },
    { label: "2024 1er cycle", value: "first2024" },
    { label: "2023 2eme cycle", value: "second2023" },
    { label: "2023 1er cycle", value: "first2023" },
    { label: "2022 2eme cycle", value: "second2022" },
    { label: "2022 1er cycle", value: "first2022" },
    { label: "2021 2eme cycle", value: "second2021" },
    { label: "2021 1er cycle", value: "first2021" },
    { label: "2020 2eme cycle", value: "second2020" },
    { label: "2020 1er cycle", value: "first2020" },
  ],
  new_wilayas: [],
  communes: [],
  regions: [],
  cc_complaint_types: [
    { label: "Coverage", value: "Coverage" },
    { label: "Quality", value: "Quality" },
    { label: "Data", value: "Data" },
    { label: "Core", value: "Core" },
  ],
  cc_problems: [],
  cc_sub_problems: [],
  cc_in_out: [
    { label: "Indoor", value: "Indoor" },
    { label: "Outdoor", value: "Outdoor" },
    { label: "Indoor/Outdoor", value: "Indoor/Outdoor" },
  ],
  cc_sources: [
    { label: "Mail", value: "Mail" },
    { label: "SO", value: "SO" },
    { label: "Otaportail", value: "Otaportail" },
  ],
  cc_classifications: [
    { label: "Corporate", value: "Corporate" },
    { label: "Employee", value: "Employee" },
    { label: "Mass Market", value: "Mass Market" },
    { label: "VIP", value: "VIP" },
    { label: "ARPCE", value: "ARPCE" },
  ],
  cc_actions: [],
  cc_action_details: [],
  cc_dst_services: [],
  cc_status: [],
  cc_status_cause: [],
  cc_delete_cause: [
    { label: "Plainte dupliquée", value: "Plainte dupliquée" },
    { label: "Erreur d’insertion", value: " Erreur d’insertion" },
  ],
  yes_no: [
    { label: "Oui", value: "Oui" },
    { label: "Non", value: "Non" },
  ],
  location_owners: [],
  cc_enabled: '',
  cc_reports: [
    { label: "General", value: "general" },
    { label: "Open By Service", value: "open_by_service" },
    { label: "Complaint SLA", value: "complaint_sla" },
    { label: "Access Network", value: "access_network" },
    { label: "Complaint Status", value: "complaint_status" },
  ],
};

export default (state = filters, action) => {
  if (action.type === "SET_FILTERS") {
    // let newState = {
    // 	...state,
    // 	filters: {
    // 		...state.filters,
    // 		...action.payload
    // 	}
    // }

    // console.log("Filters updated")

    return { ...state, ...action.payload };
  }

  return state;
};
