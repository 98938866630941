import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import debounce from "lodash/debounce";
import { startLoading, doneLoading, setFlash } from "../../Redux/actions/index";
import * as helpers from "../../Utils/Helpers";
import api from "../../Utils/Api";
import { Trans } from "react-i18next";
import { Multiselect } from "multiselect-react-dropdown";

const mapStateToProps = (state) => {
  return {
    user: state.session.user,
    filters: state.filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoading: (e) => dispatch(startLoading(e)),
    doneLoading: () => dispatch(doneLoading()),
    setFlash: (d) => dispatch(setFlash(d)),
  };
};

class ServiceNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validEmail: true,
      validUsername: true,
      info: {
        subscribe: 0,
        userid: 0,
        username: "",
        password: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        accountType: "inhouse",
      },
      sites: [],
      shifts: [],
    };

    // this.handleInput = this.handleInput.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
    // this.checkUsername = debounce(this.checkUsername.bind(this), 400);
    // this.checkEmail = debounce(this.checkEmail.bind(this), 400);
    this.changeSub = this.changeSub.bind(this);

    this.onSelectAgent = this.onSelectAgent.bind(this);
    this.onRemoveAgent = this.onRemoveAgent.bind(this);
    this.onSelectProblem = this.onSelectProblem.bind(this);
    this.onRemoveProblem = this.onRemoveProblem.bind(this);
    this.onSelectSite = this.onSelectSite.bind(this);
    this.onRemoveSite = this.onRemoveSite.bind(this);
    this.onSelectShift = this.onSelectShift.bind(this);
    this.onRemoveShift = this.onRemoveShift.bind(this);
    //this.loadLogicSites = this.loadLogicSites.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFromDateChange = this.handleFromDateChange.bind(this);
    this.handleToDateChange = this.handleToDateChange.bind(this);

    //add selected values arrays
    this.agentsArray = [];
    this.sitesArray = [];
    this.shiftsArray = [];
    this.problemsArray = [];
    //this.shifts = [];

    //load logic sites
    /*this.props.isLoading({
			message:"Chargement",
			animate:true
		});

    this.loadLogicSites();*/
  }

  /*loadLogicSites() {

    this.props.isLoading();

    //const crits = buildFilters(this.state.filters);
    api.get(`/api/logicsites/fetch?limit=-1`)
      .then((response) => {
        if (response.data.success) {
          this.setState({ sites: response.data.sites,});
          this.props.doneLoading();
        } else {
          if (response.data.errcode === 101) {
            this.props.setAuth(false);
            this.props.doneLoading();
          }
        }
      })
			.catch((error) => {
				this.props.doneLoading();
			});
  }*/

  /*checkUsername() {

		const that = this;
		api.post('/api/user/availability', { username: this.state.info.username})
		.then(function(response) {
			if(response.data.success) {
				that.setState({validUsername: true})
			} else {
				that.setState({validUsername: false})
			}
		})
	}

	checkEmail() {

		const that = this;
		const email = this.state.info.email
		if(!helpers.validateEmail(email))
			return that.setState({validEmail: false})

		api.post('/api/user/availability', {email: email})
		.then(function(response) {
			if(response.data.success) {
				that.setState({validEmail: true})
			} else {
				that.setState({validEmail: false})
			}
		})
	}*/

  changeSub(e) {
    this.setState({
      info: {
        ...this.state.info,
        subscribe: e.value,
      },
    });
  }

  /*handleInput(e) {

		let s = this.state;
		const event = Object.assign({}, e);
		const that = this;

		s.info[event.target.name] = event.target.value;
		this.setState({...s}, function() {
			if(event.target.name === 'username') {
				that.checkUsername()
			} else if(event.target.name === 'email') {
				that.checkEmail()
			}
		});
	}*/

  formSubmit(e) {
    e.preventDefault();
    this.props.isLoading();

    let that = this;
    const data = helpers.formToObject(e.target);
    data["managers"] = this.agentsArray;
    data["problems"] = this.problemsArray;
    //data['shifts'] = this.state.shifts;

    //console.log("hello data", data);

    api
      .post("/api/service/register", data)
      .then(function(response) {
        if (response.data.success) {
          that.props.history.push("/services");
          that.props.setFlash({
            active: true,
            message: "Service créé avec succès.",
            classes: "success",
            style: {},
          });
        } else {
          that.props.setFlash({
            active: true,
            message: response.data.error,
            classes: "warning",
            style: {},
          });
        }
        //that.props.doneLoading();
      })
      .catch((err) => {
        this.props.setFlash({
          active: true,
          message: `Une erreur est survenue: ${err.message}`,
          classes: "warning",
          style: {},
        });
        // console.log(`error:  ${error}`);
        //this.props.doneLoading();
      })
      .finally(() => this.props.doneLoading());
  }

  componentDidMount() {
    //add checked field to shifts array
    /*let shifts = this.props.filters.shifts.map(obj=> ({ ...obj, checked: false, from_date: '', to_date: '' }));
		this.setState({ shifts: shifts,});*/
  }

  onSelectAgent(selectedList, selectedItem) {
    //this.agentsArray.push(selectedItem);
    this.agentsArray = selectedList;
  }

  onRemoveAgent(selectedList, removedItem) {
    //this.agentsArray.pop(removedItem);
    this.agentsArray = selectedList;
  }

  onSelectProblem(selectedList, selectedItem) {
    //this.problemsArray.push(selectedItem);
    this.problemsArray = selectedList;
  }

  onRemoveProblem(selectedList, removedItem) {
    //this.problemsArray.pop(removedItem);
    this.problemsArray = selectedList;
  }

  onSelectSite(selectedList, selectedItem) {
    this.sitesArray.push(selectedItem);
  }

  onRemoveSite(selectedList, removedItem) {
    this.sitesArray.pop(removedItem);
  }

  onSelectShift(selectedList, selectedItem) {
    this.shiftsArray.push(selectedItem);
  }

  onRemoveShift(selectedList, removedItem) {
    this.shiftsArray.pop(removedItem);
  }

  handleChange(e) {
    let shifts = this.state.shifts;

    shifts.map((shift) => {
      if (shift.value === e.target.value) shift.checked = e.target.checked;
    });

    this.setState({ shifts: shifts });
  }

  handleFromDateChange(e) {
    let shifts = this.state.shifts;

    shifts.map((shift) => {
      if (shift.value === e.target.id) shift.from_date = e.target.value;
    });

    this.setState({ shifts: shifts });
  }

  handleToDateChange(e) {
    let shifts = this.state.shifts;

    shifts.map((shift) => {
      if (shift.value === e.target.id) shift.to_date = e.target.value;
    });

    this.setState({ shifts: shifts });
  }

  render() {
    return (
      <div>
        <div className={"container-header"}>
          <Trans i18nKey='label_new_service' />
        </div>

        <form method='#' onSubmit={this.formSubmit}>
          <div className={"row"}>
            <div className={"col-md-5"}>
              <h4 className={"mb-4"}>
                <Trans i18nKey='service_information' />
              </h4>

              {/*<div className={"form-group"}>
						<label><Trans i18nKey="user_username" /></label>
						<input
						required
						type="text"
						className={"form-control" + (!this.state.validUsername ? ' invalid' : '')}
						name="username"
						onChange={this.handleInput} />
					</div>

					<div className={"form-group"}>
						<label><Trans i18nKey="user_password" /></label>
						<input required type="password" className={"form-control"} name="password" />
					</div>*/}

              {this.props.user.role !== "cluster_manager" && (
                <div className={"form-group"}>
                  <label>Cluster Manager</label>
                  <Select
                    name={"cluster_manager"}
                    options={this.props.filters.cluster_managers}
                    classNamePrefix={"custom-select"}
                    //required
                  />
                </div>
              )}

              {/*this.props.user.role !== "manager" && (
						<div className={"form-group"}>
							<label>Manager</label>
							<Select
								name={"manager"}
								options={this.props.filters.managers}
								classNamePrefix={"custom-select"}
								//required
							/>
						</div>
					)*/}

              <div className={"form-group"}>
                <label>Nom</label>
                <input
                  type='text'
                  className={"form-control"}
                  name='name'
                  required
                />
              </div>

              <div className={"form-group"}>
                <label>Email</label>
                <input
                  type='text'
                  className={"form-control"}
                  name='email'
                  required
                />
              </div>

              {/*<div className={"form-group"}>
						<label>Task type</label>
						<Select
							name={"task_type"}
							options={this.props.filters.task_types}
							classNamePrefix={"custom-select"}
							required
							value={{label: 'CM', value: 'cm'}}
							disabled
                    		isDisabled={true}
						/>
					</div>*/}

              {/*<div className={"form-group"}>
						<label>Type de problème</label>
						<Select
							name={"alarm"}
							options={this.props.filters.problem_types}
							classNamePrefix={"custom-select"}
						/>
				</div>*/}

              <div className={"form-group"}>
                <label>Catégories d'alarmes</label>
                <Multiselect
                  options={this.props.filters.cm_category} // Options to display in the dropdown
                  //selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                  onSelect={this.onSelectProblem} // Function will trigger on select event
                  onRemove={this.onRemoveProblem} // Function will trigger on remove event
                  displayValue='label' // Property name to display in the dropdown options
                  required
                />
              </div>

              {/*<div className={"form-group"}>
						<label>Anomalie type</label>
						<Select
							name={"anomalie_type"}
							options={this.props.filters.anomalies_types}
							classNamePrefix={"custom-select"}
						/>
					</div>*/}

              {/*<h4 className={"mb-4"}><Trans i18nKey="members" /></h4>
					<div className={"form-group"}>
						<label>Agents</label>
						<Select
							name={"alarm_type"}
							options={this.props.filters.agents}
							classNamePrefix={"custom-select"}
						/>
					</div>*/}

              <div className={"form-group"}>
                <label>Cluster</label>
                <Select
                  name={"cluster"}
                  options={this.props.filters.clusters}
                  classNamePrefix={"custom-select"}
                  required
                />
              </div>

              <h4 className={"mb-4"}>
                <Trans i18nKey='service_members' />
              </h4>

              <div className={"form-group"}>
                <label>Membres</label>
                <Multiselect
                  options={this.props.filters.managers} // Options to display in the dropdown
                  //selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                  onSelect={this.onSelectAgent} // Function will trigger on select event
                  onRemove={this.onRemoveAgent} // Function will trigger on remove event
                  displayValue='label' // Property name to display in the dropdown options
                  required
                />
              </div>

              <div className={"form-group"}>
                <label>Escalade Web</label>
                <Select
                  name={"web_escalation"}
                  options={[
                    { label: "Oui", value: "true" },
                    { label: "Non", value: "false" },
                  ]}
                  classNamePrefix={"custom-select"}
                  required
                  defaultValue={{ label: "Non", value: "false" }}
                />
              </div>

              <div className={"form-group"}>
                <label>Escalade Mobile</label>
                <Select
                  name={"mobile_escalation"}
                  options={[
                    { label: "Oui", value: "true" },
                    { label: "Non", value: "false" },
                  ]}
                  classNamePrefix={"custom-select"}
                  required
                  defaultValue={{ label: "Non", value: "false" }}
                />
              </div>

              <div className={"form-group"}>
                <label>Externe</label>
                <Select
                  name={"external"}
                  options={[
                    { label: "Oui", value: "true" },
                    { label: "Non", value: "false" },
                  ]}
                  classNamePrefix={"custom-select"}
                  required
                  defaultValue={{ label: "Non", value: "false" }}
                />
              </div>

              {/*<h4 className={"mb-4"}><Trans i18nKey="service_sites" /></h4>

					<div className={"form-group"}>
						<label>Sites logiques</label>
						<Multiselect
							options={this.state.sites}
							//selectedValues={this.state.selectedValue}
							onSelect={this.onSelectSite}
							onRemove={this.onRemoveSite}
							displayValue="site_code"
							required
						/>
					</div>*/}

              {/*<h4 className={"mb-4"}><Trans i18nKey="service_shifts" /></h4>

					<div className={"form-group"}>
						<label>Shifts</label>
						<Multiselect
							options={this.props.filters.shifts}
							//selectedValues={this.state.selectedValue}
							onSelect={this.onSelectShift}
							onRemove={this.onRemoveShift}
							displayValue="label"
						/>
					</div>

					<div className={"form-group"}>
						<label>Shifts</label>

					{this.state.shifts.map(shift => (
						<li>
								<div className="custom-control custom-checkbox" key={shift.value}>
									<input
										id={shift.value}
										key={shift.value}
										onClick={this.handleChange}
										type="checkbox"
										checked={shift.checked}
										value={shift.value}
										//defaultChecked={false}
									/> {shift.label}

									<div className={"form-group"}>
										<label>De</label>
										<input
											required={shift.checked}
											name="from_date"
											type={"date"}
											className={"form-control"}
											onChange={this.handleFromDateChange}
											value={shift.from_date}
											id={shift.value}
										/>
									</div>

									<div className={"form-group"}>
										<label>A</label>
										<input
											required={shift.checked}
											name="to_date"
											type={"date"}
											className={"form-control"}
											onChange={this.handleToDateChange}
											value={shift.to_date}
											id={shift.value}
										/>
									</div>

								</div>
						</li>
          ))}
					</div>*/}

              <div className={"mt-5"}>
                <button
                  //disabled={! (this.state.validEmail && this.state.validUsername)}
                  className={"btn btn-success float-right col-md-6"}
                >
                  <Trans i18nKey='button_create' />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceNew);
