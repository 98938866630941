import React, { useState } from "react";
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import WindowedSelect from "react-windowed-select";


const ChangeStatusCCOverlay = ({open, task, toggle, reload, selected, selectedList, ...props}) => {

  const [statusCause, setStatusCause] = useState([]);

  const handleStatusChange = (e) => {

		var parent = e.label;

    setStatusCause(props.filters.cc_status_cause
        .filter(obj => String(obj.parent_label).toUpperCase() === String(parent).toUpperCase())
        .map(obj => ({ label: obj.label, value: obj.value }))
        .filter(el => el.label != null)
    );
	};

  const submitSiteForm = (e) => {
    
    e.preventDefault();

    let that = this;

    const formdata = new FormData(e.target);

    let data = {};

    for (var [key, value] of formdata.entries()) data[key] = value;

    if(data["status"] != "") {

      props.isLoading({
        message: "Changement de status en cours",
        animate: true,
      });
      
		api.post('/api/cc/change/status', data)
      	.then(response => {
				if (response.data.success) {
				  props.doneLoading();
					props.setFlash({
						active: true,
						message: "CC status changé",
						classes: "success",
						style: {},
					});
				} else {
          props.setFlash({
						active: true,
						message: "Une erreur est survenue, Envoi echoué.",
						classes: "warning",
						style: {},
					});
				}
        toggle();
        props.doneLoading();
        reload();
			}).catch((err) => props.doneLoading());
    
    } else {
      props.setFlash({
        active: true,
        message: "Veuillez d'abord choisir un service !",
        classes: "warning",
        style: {},
      });
    } 
  };

    return (
      <div className={"ui-overlay " + (open ? " open" : "")}>
        <div className={"ui-overlay-content site-overlay col-md-5"}>
          <button
            data-package-id="0"
            onClick={(e) => {
                toggle(e);
            }}
            className={"ui-overlay-close btn btn-danger btn-sm"}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <h5 className={"mb-3"}>Changer status</h5>

          <form onSubmit={submitSiteForm}>
            {open && (
              <>
                <input
                  type="hidden"
                  name="cc_task_id"
                  //value={task.id}
                  value={selectedList && selectedList.length > 0 ? selectedList : [task.id.toString()]}
                />

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>ID plainte</label>
                  <input
                    type="text"
                    name="id"
                    className={"form-control-read col-md-8"}
                    //value={task.id}
                    value={selectedList && selectedList.length > 0 ? selectedList.join(', ') : task.id}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Status</label>
                  <Select
                    name="status"
                    classNamePrefix={"custom-select"}
                    options={props.filters.cc_status}
                    className={"col-md-8"}
                    onChange={(e) => handleStatusChange(e)}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Cause</label>
                  <Select
                    name="status_cause"
                    classNamePrefix={"custom-select"}
                    //options={props.filters.cc_status_cause}
                    options={statusCause}
                    className={"col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Commentaire</label>
                  <textarea
                    rows={2}
                    name="status_comment"
                    className={"col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                  <input
                    type="submit"
                    className={"btn btn-primary col-md-12"}
                    value="Changer"
                  />
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    );
  
}

export default ChangeStatusCCOverlay;
