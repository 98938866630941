import React, { useState, useRef } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../../Utils/Api";
import ApiService from "../../../Utils/ApiService";

const StartCMOverlay = ({
  toggle,
  open,
  task,
  service,
  reload,
  flash,
  scope = "cm",
  ...props
}) => {
  const messageRef = useRef(null);

  const returnTask = () => {
    props.isLoading({
      message: "Début de la tâche CM en cours",
      animated: true,
    });

    var data = {
      //site_plan_id : planid,
      site_plan_id: task,
      service_id: service,
      comment: messageRef.current.value,
    };

    api
      .post("/api/cm/start/service", data)
      .then((response) => {
        if (response.data.success) {
        } else {
          alert("Envoi echoué");
        }
        toggle();
        reload();
        props.doneLoading();
      })
      .catch((err) => {
        props.doneLoading();
        flash({
          active: true,
          message: `Envoi echoué: ${err.message}`,
          classes: "warning",
          style: {},
        });
      });
  };

  return (
    <>
      <div
        className={
          open ? "ui-overlay delete-task open" : "ui-overlay delete-task"
        }
      >
        <div
          className={
            "ui-overlay-content status-overlay-text col-md-4 offset-md-4"
          }
        >
          <button
            onClick={(e) => toggle()}
            className={"ui-overlay-close btn btn-danger btn-sm"}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <h5 className={"mb-4"}>Commencer CM : </h5>
          <br />
          <h7 className={"mb-4"}>Vous pouvez inclure un commentaire : </h7>
          <textarea ref={messageRef} className={"form-control"} />
          <button onClick={returnTask} className={"btn btn-primary mt-3"}>
            Valider
          </button>
        </div>
      </div>
    </>
  );
};

export default StartCMOverlay;
