import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import debounce from "lodash/debounce";
import {
  startLoading,
  doneLoading,
  setFlash,
} from "../../../Redux/actions/index";
import * as helpers from "../../../Utils/Helpers";
import api from "../../../Utils/Api";
import { Trans } from "react-i18next";
import { Multiselect } from "multiselect-react-dropdown";
import WindowedSelect from "react-windowed-select";
import { components, createFilter } from "react-windowed-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faSpinner,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import Constants from "../../../Utils/Constants";
import Restricted from "../../../Utils/Restricted";
import FixRequiredSelect from "./FixRequiredSelect";

const moment = require("moment");

const RequiredSelect = (props) => (
  <FixRequiredSelect
    {...props}
    SelectComponent={Select}
    options={props.options}
  />
);

const mapStateToProps = (state) => {
  return {
    user: state.session.user,
    filters: state.filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoading: (e) => dispatch(startLoading(e)),
    doneLoading: () => dispatch(doneLoading()),
    flash: (d) => dispatch(setFlash(d)),
  };
};

const customFilter = createFilter({ ignoreAccents: false });

const customComponents = {
  ClearIndicator: (props) => (
    <components.ClearIndicator {...props}>clear</components.ClearIndicator>
  ),
};

class CMNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validEmail: true,
      validUsername: true,
      info: {
        subscribe: 0,
        userid: 0,
        username: "",
        password: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        accountType: "inhouse",
      },
      sites: [],
      sitesNames: [],
      shifts: [],
      siteCode: { label: "", value: "" },
      siteName: { label: "", value: "" },
      currentSite: { label: "", value: "" },
      //siteAddress: {label: '', value: ''},
      siteOwner: { label: "", value: "" },
      siteRegion: { label: "", value: "" },
      siteCategory: { label: "", value: "" },
      siteBSSVendor: { label: "", value: "" },
      siteMWVendor: { label: "", value: "" },
      sitePower: { label: "", value: "" },
      siteBSC: { label: "", value: "" },
      siteAiring: { label: "", value: "" },
      siteCMAgentOwner: { label: "", value: "" },
      siteType: { label: "", value: "" },
      cmStartDate: "", // moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      cmImpactedSite: false,
      cmImpactedSector: false,
      siteTeam: { label: "", value: "" },
      cmCode: [],
      siteService: { label: "", value: "" },
      physicSiteName: { label: "", value: "" },
      physicSiteCode: { label: "", value: "" },
      physicSiteOwner: { label: "", value: "" },
      agentAvailable: true,

      history: [],
      activeTab: "sites",
      error: "",
      success: "",
      loading: false,
      notImported: [],
      cmCategory: this.props.cm_category,

      parent_ref: this.props.location.state ? this.props.location.state : "",
      showInfo: false,
      teamMembers: "",

      taskType: { label: "", value: "" },
      //currentCode: {label: '', value: ''},
      currentCode: undefined,

      alarm: false,
      existingCM: "",
    };

    // this.handleInput = this.handleInput.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
    this.changeSub = this.changeSub.bind(this);

    this.loadLogicSites = this.loadLogicSites.bind(this);
    this.handleChangeSite = this.handleChangeSite.bind(this);
    this.handleChangeSector = this.handleChangeSector.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.setAgent = this.setAgent.bind(this);
    this.setTeam = this.setTeam.bind(this);
    this.changeCMCode = this.changeCMCode.bind(this);
    this.setService = this.setService.bind(this);
    this.onSelectSite = this.onSelectSite.bind(this);
    this.onRemoveSite = this.onRemoveSite.bind(this);
    this.loadAlarmCategories = this.loadAlarmCategories.bind(this);
    this.setCurrentCode = this.setCurrentCode.bind(this);

    //add selected values arrays
    //this.agentsArray = [];
    //this.sitesArray = [];
    //this.shiftsArray = [];
    //this.shifts = [];
    this.impactedSites = [];

    //load logic sites
    this.props.isLoading({
      message: "Chargement des sites logiques",
      animate: true,
    });

    this.loadLogicSites();

    //if(this.props.user.role == 'service') {

    //load the user's alarms
    this.props.isLoading({
      message: "Chargement des catégories d'alarmes",
      animate: true,
    });
    this.loadAlarmCategories();
    //}
  }

  setCurrentCode(e) {
    var currentCode = { label: e.label, value: e.value };
    this.setState({ currentCode: currentCode });

    var data = {
      site_id: "",
    };

    data["site_id"] = this.state.currentSite
      ? this.state.currentSite.value
      : null;
    data["cm_code"] = e ? e.value : null;
    data["site_code"] = this.state.currentSite
      ? this.state.currentSite.label
      : null;

    api
      .post(`/api/cm/site/alarm/details?`, data)
      .then((response) => {
        //console.log("hello", response);
        if (response.data.alarm_error) {
          this.setState({
            alarm: true,
            existingCM: response.data.sites[0].other_refs,
          });
          this.props.flash({
            active: true,
            message:
              "Attention, alarme existe déjà: " +
              response.data.sites[0].other_refs,
            classes: "warning",
            style: {},
          });
        } else {
          this.setState({ alarm: false, existingCM: "" });
          this.props.flash({
            active: false,
            message: "Attention, alarme existe déjà.",
            classes: "warning",
            style: {},
          });
        }
      })
      .catch((error) => {
        this.props.flash({
          active: false,
          message: error.message,
          classes: "warning",
          style: {},
        });
      });
  }

  onSelectSite(selectedList, selectedItem) {
    this.impactedSites.push(selectedItem);
  }

  onRemoveSite(selectedList, removedItem) {
    //this.impactedSites.pop(removedItem);
    this.impactedSites = selectedList;
  }

  loadLogicSites() {
    this.props.isLoading();

    //const crits = buildFilters(this.state.filters);
    api
      .get(
        `/api/logicsites/fetch?limit=-1&service_name=${
          this.props.user.service_name
        }&external=${this.props.user.external}`,
      )
      .then((response) => {
        if (response.data.success) {
          //console.log("hello response", response.data.sites);
          this.setState({
            sites: response.data.sites.map((site) => ({
              label: site.site,
              value: site.site_id,
            })),
          });
          this.setState({
            sitesNames: response.data.sites.map((site) => ({
              label: site.site_code,
              value: site.site_id,
            })),
          });
          //this.props.doneLoading();
        } else {
          if (response.data.errcode === 101) {
            this.props.setAuth(false);
            //this.props.doneLoading();
          }
        }
      })
      .catch((error) => {
        this.props.flash({
          active: false,
          message: error.message,
          classes: "warning",
          style: {},
        });
      })
      .finally(() => this.props.doneLoading());
  }

  loadAlarmCategories() {
    var data = { account_id: this.props.user.userid };

    //var account_id = this.props.user.userid;

    api
      .post(`/api/service/alarms/fetch?`, data)
      .then((response) => {
        //console.log("hello res", response.data);
        if (response.data.success && response.data.res[0]) {
          //remove empty ones
          var filtered = response.data.res.filter(function(el) {
            return el.value != null;
          });

          var taskType = { label: "CM", value: "cm" };

          var num = 0;
          var wo = filtered.map(function(el) {
            if (el.value.includes("Work Order")) {
              return (num = num + 1);
            }
          });

          if (num > 0) {
            var taskType = { label: "WO", value: "wo" };
          }

          /*var num2 = 0;
			var prf = filtered.map(function (el) {
				if(el.value.includes('QLT PERF')) {
					return num2 = num2 + 1;
				}
			});

			if(num2>0) {
				var taskType = {label: 'PRF', value: 'prf'};
			}*/

          this.setState({ taskType: taskType });

          this.setState({ cmCategory: filtered }, () => {
            /*console.log("hello state", this.state.cmCode);*/
          });
        } else {
          if (response.data.errcode === 101) {
            this.props.setAuth(false);
          }
        }

        this.props.doneLoading();
      })
      .catch((error) => {
        this.props.flash({
          active: false,
          message: error.message,
          classes: "warning",
          style: {},
        });
      })
      .finally(() => this.props.doneLoading());
  }

  changeSub(e) {
    this.setState({
      info: {
        ...this.state.info,
        subscribe: e.value,
      },
    });
  }

  formSubmit(e) {
    e.preventDefault();
    this.props.isLoading();

    var dateAlarm = new Date(this.state.cmStartDate);
    var dateAlerting = new Date();

    if (dateAlerting < dateAlarm) {
      this.props.flash({
        active: true,
        message: "Attention, la date d'alerting vient avant la date d'alarme!",
        classes: "warning",
        style: {},
      });

      this.props.doneLoading();
    } else {
      let that = this;
      const data = helpers.formToObject(e.target);
      //data['agents'] = this.agentsArray;
      data["cm_impacted_site_list"] = this.impactedSites
        .map((e) => e.label)
        .join(" , ");
      //data['shifts'] = this.state.shifts;
      //data['task_type'] = 'cm';
      data["task_type"] = this.state.taskType.value;

      //impacted sites file
      /*const fileData = new FormData(e.target);
			api.post(e.target.action, fileData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(res => {
				console.log("hello res", res.data);
			})
			.catch(err => {
				console.log("hello err", err);
			})*/

      const fileData = new FormData(e.target);

      fileData.append(
        "cm_impacted_site_list",
        this.impactedSites.map((e) => e.label).join(" , "),
      );
      //fileData.append('task_type', this.state.taskType.value);

      if (this.props.user.external && this.props.user.external == "true") {
        fileData.append("agent", null);
        fileData.append("team", null);
      }

      //console.log("hello data", fileData);

      /*api.post("/api/cm/attachement/new", fileData, {headers: {'Content-Type': 'multipart/form-data'}}).then((response) => {
				console.log("hello response", response);
				if (response.data.success) {
				
				} else {

				}
				//this.props.doneLoading();
				})
				.catch((err) => {console.log("hello err", err)})*/

      api
        .post("/api/cm/planning/assign", fileData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          //api.post("/api/cm/planning/assign", data).then((response) => {
          //console.log("hello response", response);
          if (response.data.success) {
            this.props.flash({
              active: true,
              message: "Tâche planifié avec succès.",
              classes: "success",
              style: {},
            });

            that.props.history.push("/cm");
            /*if(typeof loadSites === "function")
					loadSites();
				close();*******/
            if (response.data.alarm_error) {
              this.props.flash({
                active: true,
                message: "Attention, alarme existe déjà.",
                classes: "warning",
                style: {},
              });
            }
          } else {
            if (response.data.team_error) {
              this.props.flash({
                active: true,
                message:
                  "Une erreur est survenue, veuillez contacter l'administrateur.",
                classes: "warning",
                style: {},
              });
            } else if (response.data.alarm_error) {
              this.props.flash({
                active: true,
                message: "Une erreur est survenue, alarme existe déjà.",
                classes: "warning",
                style: {},
              });
            } /*else {
					this.props.flash({
					active: true,
					message: "Une erreur 3est survenue, veuillez contacter l'administrateur.",
					classes: "warning",
					style: {},
					});
				}*/
          }

          //this.props.doneLoading();
        })
        .catch((err) => {
          this.props.flash({
            active: true,
            message: `Error assigning the CM: ${err.message}`,
            classes: "warning",
            style: {},
          });
        })
        .finally(() => this.props.doneLoading());
    }
  }

  componentDidMount() {
    //add checked field to shifts array
    let shifts = this.props.filters.shifts.map((obj) => ({
      ...obj,
      checked: false,
      from_date: "",
      to_date: "",
    }));
    this.setState({ shifts: shifts });
  }

  handleChangeSite(e) {
    let cmImpactedSite = this.state.cmImpactedSite;
    this.setState({ cmImpactedSite: !cmImpactedSite });
  }

  handleChangeSector(e) {
    let cmImpactedSector = this.state.cmImpactedSector;
    this.setState({ cmImpactedSector: !cmImpactedSector });
  }

  handleDateChange(e) {
    this.setState({ cmStartDate: e.target.value });
  }

  getSiteData(site) {
    this.props.isLoading();

    var data = {
      site_id: "",
    };
    data["site_id"] = site.value;
    data["site_code"] = site.label;
    data["cm_code"] = this.state.currentCode
      ? this.state.currentCode.value
      : null;

    //console.log("hello code",this.state.currentCode);

    let nstate = this.state;
    let that = this;
    //let crits = buildFilters(this.state.filters);

    api
      .post(`/api/cm/site/details?`, data)
      .then((response) => {
        //console.log("hello", response);
        if (response.data.alarm_error) {
          this.setState({
            alarm: true,
            existingCM: response.data.sites[0].other_refs,
          });
          this.props.flash({
            active: true,
            message:
              "Attention, alarme existe déjà: " +
              response.data.sites[0].other_refs,
            classes: "warning",
            style: {},
          });
        } else {
          this.setState({ alarm: false, existingCM: "" });
          this.props.flash({
            active: false,
            message: "Attention, alarme existe déjà.",
            classes: "warning",
            style: {},
          });
        }
        if (response.data.success && response.data.sites[0]) {
          //console.log("hello result", response.data.sites[0].site_type);
          //console.log("hello result", response.data.sites[0].team_agents);

          /*response.data.tasks.map((r) => {
            nstate.rows.push({ hidden: true });
		  });*/

          var siteCode = {
            label: response.data.sites[0].site_code
              ? response.data.sites[0].site_code
              : "N/A",
            value: site.value ? site.value : "N/A",
          };
          var siteName = {
            label: response.data.sites[0].site_name
              ? response.data.sites[0].site_name
              : "N/A",
            value: site.value ? site.value : "N/A",
          };
          var currentSite = {
            label: response.data.sites[0].site
              ? response.data.sites[0].site
              : "N/A",
            value: site.value ? site.value : "N/A",
          };
          //var siteAddress = {label: response.data.sites[0].site_address_wilaya ? response.data.sites[0].site_address_wilaya : "N/A", value: response.data.sites[0].site_address_wilaya ? response.data.sites[0].site_address_wilaya : "N/A"};
          var siteOwner = {
            label: response.data.sites[0].site_owner
              ? response.data.sites[0].site_owner
              : "N/A",
            value: response.data.sites[0].site_owner
              ? response.data.sites[0].site_owner
              : "N/A",
          };
          var siteRegion = {
            label: response.data.sites[0].site_region
              ? response.data.sites[0].site_region
              : "N/A",
            value: response.data.sites[0].site_region
              ? response.data.sites[0].site_region
              : "N/A",
          };
          var siteCategory = {
            label: response.data.sites[0].site_category
              ? response.data.sites[0].site_category
              : "N/A",
            value: response.data.sites[0].site_category
              ? response.data.sites[0].site_category
              : "N/A",
          };
          var siteBSSVendor = {
            label: response.data.sites[0].site_bss_vendor
              ? response.data.sites[0].site_bss_vendor
              : "N/A",
            value: response.data.sites[0].site_bss_vendor
              ? response.data.sites[0].site_bss_vendor
              : "N/A",
          };
          var siteMWVendor = {
            label: response.data.sites[0].site_mw_vendor
              ? response.data.sites[0].site_mw_vendor
              : "N/A",
            value: response.data.sites[0].site_mw_vendor
              ? response.data.sites[0].site_mw_vendor
              : "N/A",
          };
          var sitePower = {
            label: response.data.sites[0].site_power
              ? response.data.sites[0].site_power
              : "N/A",
            value: response.data.sites[0].site_power
              ? response.data.sites[0].site_power
              : "N/A",
          };
          var siteBSC = {
            label: response.data.sites[0].site_bsc
              ? response.data.sites[0].site_bsc
              : "N/A",
            value: response.data.sites[0].site_bsc
              ? response.data.sites[0].site_bsc
              : "N/A",
          };
          var siteAiring = {
            label: response.data.sites[0].site_airing
              ? moment(response.data.sites[0].site_airing).format("YYYY-MM-DD")
              : "N/A",
            value: response.data.sites[0].site_airing
              ? moment(response.data.sites[0].site_airing).format("YYYY-MM-DD")
              : "N/A",
          };
          var siteCMAgentOwner = {
            label: response.data.sites[0].site_cm_agent_owner
              ? response.data.sites[0].site_cm_agent_owner
              : "N/A",
            value: response.data.sites[0].site_cm_agent_owner_id
              ? response.data.sites[0].site_cm_agent_owner_id
              : "N/A",
          };
          var siteType = {
            label: response.data.sites[0].site_type
              ? response.data.sites[0].site_type
              : "N/A",
            value: response.data.sites[0].site_type
              ? response.data.sites[0].site_type
              : "N/A",
          };
          var siteTeam = {
            label: response.data.sites[0].site_team_name
              ? response.data.sites[0].site_team_name
              : "N/A",
            value: response.data.sites[0].site_team_id
              ? response.data.sites[0].site_team_id
              : "N/A",
          };
          var physicSiteName = {
            label: response.data.sites[0].physic_site_name
              ? response.data.sites[0].physic_site_name
              : "N/A",
            value: response.data.sites[0].physic_site_name
              ? response.data.sites[0].physic_site_name
              : "N/A",
          };
          var physicSiteCode = {
            label: response.data.sites[0].physic_site_code
              ? response.data.sites[0].physic_site_code
              : "N/A",
            value: response.data.sites[0].physic_site_code
              ? response.data.sites[0].physic_site_code
              : "N/A",
          };
          var physicSiteOwner = {
            label: response.data.sites[0].physic_site_owner
              ? response.data.sites[0].physic_site_owner
              : "N/A",
            value: response.data.sites[0].physic_site_owner
              ? response.data.sites[0].physic_site_owner
              : "N/A",
          };
          var agentAvailable =
            response.data.sites[0].agent_available === "0"
              ? false
              : response.data.sites[0].agent_available === "1"
              ? true
              : true;
          var teamMembers = response.data.sites[0].team_agents
            .map((e) => e.username)
            .join(" , ");

          that.setState({
            siteCode: siteCode,
            siteName: siteName,
            currentSite: currentSite,
            //siteAddress: siteAddress,
            siteOwner: siteOwner,
            siteRegion: siteRegion,
            siteCategory: siteCategory,
            siteBSSVendor: siteBSSVendor,
            siteMWVendor: siteMWVendor,
            sitePower: sitePower,
            siteBSC: siteBSC,
            siteAiring: siteAiring,
            siteCMAgentOwner: siteCMAgentOwner,
            siteType: siteType,
            siteTeam: siteTeam,
            physicSiteName: physicSiteName,
            physicSiteCode: physicSiteCode,
            physicSiteOwner: physicSiteOwner,
            agentAvailable: agentAvailable,
            teamMembers: teamMembers,
          });

          //console.log("hello available", agentAvailable);
        } else {
          if (response.data.errcode === 101) {
            that.props.setAuth(false);
          }
        }

        that.props.doneLoading();
      })
      .catch((err) => {
        that.props.doneLoading();
        that.props.flash({
          active: true,
          message: `Error loading site details: ${err.message}`,
          classes: "warning",
          style: {},
        });
      });
  }

  setAgent(e) {
    var siteCMAgentOwner = { label: e.label, value: e.value };
    this.setState({ siteCMAgentOwner: siteCMAgentOwner });
    this.setState({
      siteTeam: { label: "", value: "" },
      siteService: { label: "", value: "" },
      teamMembers: "",
    });

    this.props.isLoading();

    var data = {
      agent: "",
    };
    data["agent"] = e.value;
    //console.log("hello data", data);

    api
      .post("/api/agent/availability", data)
      .then((response) => {
        //console.log("hello response", response);
        if (response.data.success) {
          var agentAvailable =
            response.data.available[0].available === "0"
              ? false
              : response.data.available[0].available === "1"
              ? true
              : true;
          this.setState({ agentAvailable: agentAvailable });
        } else {
          if (response.data.agent_error) {
            this.props.flash({
              active: true,
              message:
                "Une erreur est survenue, aucune équipe disponible à cette date à cette shift.",
              classes: "warning",
              style: {},
            });
          } else {
            this.props.flash({
              active: true,
              message:
                "Une erreur est survenue, veuillez contacter l'administrateur.",
              classes: "warning",
              style: {},
            });
          }
        }
        this.props.doneLoading();
      })
      .catch((err) => {
        this.props.doneLoading();
        this.props.flash({
          active: true,
          message: err.message,
          classes: "warning",
          style: {},
        });
      });
  }

  setTeam(e) {
    var siteTeam = { label: e.label, value: e.value };
    this.setState({ siteTeam: siteTeam });
    this.setState({
      siteCMAgentOwner: { label: "", value: "" },
      siteService: { label: "", value: "" },
    });

    this.props.isLoading();

    var data = {
      team: "",
    };
    data["team"] = e.value;

    api
      .post("/api/team/members", data)
      .then((response) => {
        //console.log("hello response", response);
        if (response.data.success) {
          var teamMembers = response.data.username
            .map((e) => e.username)
            .join(" , ");
          this.setState({ teamMembers: teamMembers });
        } else {
          this.props.flash({
            active: true,
            message: "Le groupe ne contient aucun membre!",
            classes: "warning",
            style: {},
          });
        }
        this.props.doneLoading();
      })
      .catch((err) => {
        this.props.doneLoading();
        this.props.flash({
          active: true,
          message: `Error loading the team: ${err.message}`,
          classes: "warning",
          style: {},
        });
      });
  }

  setService(e) {
    var siteService = { label: e.label, value: e.value };
    this.setState({ siteService: siteService });
    this.setState({
      siteTeam: { label: "", value: "" },
      siteCMAgentOwner: { label: "", value: "" },
      teamMembers: "",
    });
  }

  changeCMCode(e) {
    var parent = e.label;

    //take the matching code
    var code = this.props.filters.cm_code.map((obj) => {
      return String(obj.parent_label).toUpperCase() ===
        String(parent).toUpperCase()
        ? { label: obj.label, value: obj.value }
        : { label: null, value: null };
    });

    //remove empty ones
    var filtered = code.filter(function(el) {
      return el.label != null;
    });

    this.setState({ cmCode: filtered }, () => {
      /*console.log("hello state", this.state.cmCode);*/
    });
  }

  showInfo = () => {
    this.setState({ showInfo: !this.state.showInfo });
  };

  render() {
    //console.log("hello user", this.props.user);
    //console.log("hello", this.props.location);

    return (
      <div>
        <div className={"container-header"}>
          <Trans i18nKey='label_new_cm' />
        </div>

        <form method='#' onSubmit={this.formSubmit}>
          <div className={"row"}>
            <div className={"col-md-3"}>
              <h4 className={"mb-4"}>
                <Trans i18nKey='cm_information' />
              </h4>

              {this.state.parent_ref != "" ? (
                <div className={"form-group"}>
                  <label>Référence CM parent</label>
                  <input
                    name='cm_parent_ref'
                    type={"text"}
                    className={"form-control"}
                    value={this.state.parent_ref}
                    defaultValue={this.state.parent_ref}
                    isDisabled={true}
                  />
                </div>
              ) : (
                <div className={"form-group"}>
                  <label>Référence CM parent</label>
                  <input
                    name='cm_parent_ref'
                    type={"text"}
                    className={"form-control"}
                  />
                </div>
              )}

              <div className={"form-group"}>
                <label>Task type *</label>
                <Select
                  name={"task_type"}
                  options={this.props.filters.cm_task_types}
                  classNamePrefix={"disabled-custom-select"}
                  required
                  //value={{label: 'CM', value: 'cm'}}
                  value={this.state.taskType}
                  defaultValue={this.state.taskType}
                  //disabled
                  //isDisabled={true}
                  //defaultValue={{label: 'CM', value: 'cm'}}
                />
              </div>

              <div className={"form-group"}>
                <label>Catégorie *</label>
                <RequiredSelect
                  name={"cm_category"}
                  //options={this.props.filters.cm_category}
                  options={this.state.cmCategory}
                  classNamePrefix={"custom-select"}
                  required
                  onChange={(e) => this.changeCMCode(e)}
                  isSearchable
                />
              </div>

              <div className={"form-group"}>
                <label>Type d'alarm *</label>
                <RequiredSelect
                  name={"cm_code"}
                  //options={this.props.filters.cm_code}
                  options={this.state.cmCode}
                  classNamePrefix={"custom-select"}
                  required
                  isSearchable
                  value={this.state.currentCode}
                  onChange={(e) => this.setCurrentCode(e)}
                />
              </div>

              <div className={"form-group"}>
                <label>Type de problème *</label>
                <Select
                  name={"problem_type"}
                  options={this.props.filters.problem_types}
                  classNamePrefix={"custom-select"}
                  required
                  defaultValue={{
                    label: "Cause non determinee",
                    value: "Cause non determinee",
                  }}
                />
              </div>

              <div className={"form-group"}>
                <label>Priorité *</label>
                <RequiredSelect
                  name={"cm_priority"}
                  options={this.props.filters.cm_priority}
                  classNamePrefix={"custom-select"}
                  required
                  isSearchable
                />
              </div>
            </div>

            <div className={"col-md-3"}>
              <h4 className={"mb-4"}>
                <Trans i18nKey='site_information' />
              </h4>

              <div className={"form-group"}>
                <label>Site *</label>
                <WindowedSelect
                  name={"selected_site"}
                  //options={this.props.filters.logic_sites}
                  options={this.state.sites}
                  onChange={(e) => this.getSiteData(e)}
                  classNamePrefix={"custom-select"}
                  //components={customComponents}
                  //isClearable={false}
                  filterOption={customFilter}
                  required
                  value={this.state.currentSite}
                />
              </div>

              <div className={"form-group"}>
                <label>Code site</label>
                <WindowedSelect
                  //name={"selected_site"}
                  //options={this.props.filters.logic_sites}
                  //options={this.state.sites}
                  //onChange={(e) => this.getSiteData(e)}
                  classNamePrefix={"disabled-custom-select"}
                  //components={customComponents}
                  //isClearable={false}
                  filterOption={customFilter}
                  required
                  value={this.state.siteCode}
                  disabled
                  isDisabled={true}
                />
              </div>

              <div className={"form-group"}>
                <label>Nom site</label>
                <WindowedSelect
                  //name={"site_name"}
                  //options={this.props.filters.logic_sites}
                  //options={this.state.sitesNames}
                  //onChange={(e) => this.getSiteData(e)}
                  classNamePrefix={"disabled-custom-select"}
                  //components={customComponents}
                  //isClearable={false}
                  filterOption={customFilter}
                  required
                  value={this.state.siteName}
                  disabled
                  isDisabled={true}
                />
              </div>

              <div className={"form-group"}>
                <label>Owner</label>
                <Select
                  name={"site_owner"}
                  value={this.state.siteOwner}
                  classNamePrefix={"disabled-custom-select"}
                  required
                  disabled
                  isDisabled={true}
                />
              </div>

              {this.state.showInfo ? (
                <button
                  onClick={(e) => this.showInfo()}
                  className={"btn btn-sm btn-info"}
                >
                  <FontAwesomeIcon icon={faArrowUp} className={"mr-2"} />
                  <Trans i18nKey='label_hide_info' />
                </button>
              ) : (
                <button
                  onClick={(e) => this.showInfo()}
                  className={"btn btn-sm btn-info"}
                >
                  <FontAwesomeIcon icon={faArrowDown} className={"mr-2"} />
                  <Trans i18nKey='label_show_info' />
                </button>
              )}

              {this.state.showInfo ? (
                <div>
                  {/*<div className={"form-group"}>
											<label>Wilaya</label>
											<Select
												name={"site_address"}
												value={this.state.siteAddress}
												classNamePrefix={"disabled-custom-select"}
												required
												disabled
												isDisabled={true}
											/>
										</div>*/}

                  {/*<div className={"form-group"}>
											<label>Owner</label>
											<Select
												name={"site_owner"}
												value={this.state.siteOwner}
												classNamePrefix={"disabled-custom-select"}
												required
												disabled
												isDisabled={true}
											/>
										</div>*/}

                  <div className={"form-group"}>
                    <label>Région O&M</label>
                    <Select
                      name={"site_region"}
                      value={this.state.siteRegion}
                      classNamePrefix={"disabled-custom-select"}
                      required
                      disabled
                      isDisabled={true}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Catégorie</label>
                    <Select
                      name={"site_category"}
                      value={this.state.siteCategory}
                      classNamePrefix={"disabled-custom-select"}
                      required
                      disabled
                      isDisabled={true}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>BSS Vendor</label>
                    <Select
                      name={"site_bss_vendor"}
                      value={this.state.siteBSSVendor}
                      classNamePrefix={"disabled-custom-select"}
                      required
                      disabled
                      isDisabled={true}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>MW Vendor</label>
                    <Select
                      name={"site_mw_vendor"}
                      value={this.state.siteMWVendor}
                      classNamePrefix={"disabled-custom-select"}
                      required
                      disabled
                      isDisabled={true}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Power</label>
                    <Select
                      name={"site_power"}
                      value={this.state.sitePower}
                      classNamePrefix={"disabled-custom-select"}
                      required
                      disabled
                      isDisabled={true}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>BSC</label>
                    <Select
                      name={"site_bsc"}
                      value={this.state.siteBSC}
                      classNamePrefix={"disabled-custom-select"}
                      required
                      disabled
                      isDisabled={true}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Mise en air</label>
                    <Select
                      name={"site_airing"}
                      value={this.state.siteAiring}
                      classNamePrefix={"disabled-custom-select"}
                      required
                      disabled
                      isDisabled={true}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>CM agent Owner</label>
                    <Select
                      name={"site_cm_agent_owner"}
                      value={this.state.siteCMAgentOwner}
                      classNamePrefix={"disabled-custom-select"}
                      required
                      disabled
                      isDisabled={true}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Type</label>
                    <Select
                      name={"site_address"}
                      value={this.state.siteType}
                      classNamePrefix={"disabled-custom-select"}
                      required
                      disabled
                      isDisabled={true}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Nom site physique</label>
                    <Select
                      name={"site_name"}
                      value={this.state.physicSiteName}
                      classNamePrefix={"disabled-custom-select"}
                      required
                      disabled
                      isDisabled={true}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Code site physique</label>
                    <Select
                      name={"site_name"}
                      value={this.state.physicSiteCode}
                      classNamePrefix={"disabled-custom-select"}
                      required
                      disabled
                      isDisabled={true}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Owner site physique</label>
                    <Select
                      name={"site_name"}
                      value={this.state.physicSiteOwner}
                      classNamePrefix={"disabled-custom-select"}
                      required
                      disabled
                      isDisabled={true}
                    />
                  </div>
                </div>
              ) : null}

              <br />
              <br />
              <h4 className={"mb-4"}>
                <Trans i18nKey='alarm_information' />
              </h4>

              <div className={"form-group"}>
                <label>Date incident *</label>
                <input
                  name='cm_start_date'
                  type={"datetime-local"}
                  className={"form-control"}
                  onChange={this.handleDateChange}
                  value={this.state.cmStartDate}
                  //defaultValue={this.state.cmStartDate}
                  required
                />
              </div>

              {/*<div className={"form-group"}>
								<label>Solved by</label>
								<Select
									name={"cm_solved_by"}
									options={this.props.filters.agents}
									classNamePrefix={"custom-select"}
									required
								/>
							</div>*/}

              <div className={"form-group"}>
                <label>Détails problème</label>
                <textarea name='instruction' className={"form-control"} />
              </div>
            </div>

            <div className={"col-md-3"}>
              <h4 className={"mb-4"}>
                <Trans i18nKey='assign_to' /> *{" "}
              </h4>

              {this.props.user.external &&
              this.props.user.external == "true" ? (
                <div>
                  <div className={"form-group"}>
                    <label>Agent</label>
                    <Select
                      name={"agent"}
                      options={[
                        { label: "N/A", value: "" },
                        ...this.props.filters.agents,
                      ]}
                      value={{ label: "N/A", value: "" }}
                      classNamePrefix={"disabled-custom-select"}
                      disabled
                      isDisabled={true}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Groupe</label>
                    <Select
                      name={"team"}
                      options={[
                        { label: "N/A", value: "" },
                        ...this.props.filters.teams,
                      ]}
                      classNamePrefix={"disabled-custom-select"}
                      value={{ label: "N/A", value: "" }}
                      onChange={(e) => this.setTeam(e)}
                      disabled
                      isDisabled={true}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Service</label>
                    <Select
                      name={"service"}
                      //options={this.props.filters.services}
                      options={[
                        ...this.props.filters.externalServicesToEscalate,
                      ]}
                      classNamePrefix={"custom-select"}
                      value={this.state.siteService}
                      onChange={(e) => this.setService(e)}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <div className={"form-group"}>
                    <label>Agent</label>
                    <Select
                      name={"agent"}
                      //options={this.props.filters.agents}
                      options={[
                        { label: "N/A", value: "" },
                        ...this.props.filters.agents,
                      ]}
                      //classNamePrefix={"custom-select"}
                      //required
                      value={this.state.siteCMAgentOwner}
                      //defaultValue={this.state.siteCMAgentOwner}
                      onChange={(e) => this.setAgent(e)}
                      //isDisabled={!this.state.agentAvailable}
                      classNamePrefix={
                        this.state.agentAvailable
                          ? "available-custom-select"
                          : "unavailable-custom-select"
                      }
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Groupe</label>
                    <Select
                      name={"team"}
                      //options={this.props.filters.teams}
                      options={[
                        { label: "N/A", value: "" },
                        ...this.props.filters.teams,
                      ]}
                      classNamePrefix={"custom-select"}
                      //required
                      value={this.state.siteTeam}
                      onChange={(e) => this.setTeam(e)}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Membres : </label>{" "}
                    {this.state.teamMembers ? this.state.teamMembers : "-"}
                  </div>

                  <div className={"form-group"}>
                    <label>Service</label>
                    <Select
                      name={"service"}
                      //options={this.props.filters.services}
                      options={[
                        { label: "N/A", value: "" },
                        ...this.props.filters.servicesToEscalate,
                      ]}
                      classNamePrefix={"custom-select"}
                      value={this.state.siteService}
                      onChange={(e) => this.setService(e)}
                    />
                  </div>
                </div>
              )}

              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              {this.state.alarm ? (
                <div className={"form-group-alarm"}>
                  <label className={"form-group-alarm"}>
                    Attention! <br /> alarme existe déjà. <br />{" "}
                    {this.state.existingCM}{" "}
                  </label>
                </div>
              ) : null}
            </div>

            <div className={"col-md-3"}>
              <h4 className={"mb-4"}>
                <Trans i18nKey='loss_of_all_info' />
              </h4>

              {/*<div className="custom-control custom-checkbox" /*key={shift.value}****>
								<label>Sites impactés</label>
								{/*<input
									name={"cm_impacted_site"}
									//id={shift.value}
									//key={shift.value}
									onClick={this.handleChangeSite}
									type="checkbox"
									checked={this.state.cmImpactedSite}
									value={this.state.cmImpactedSite}
									//defaultChecked={false}
								/> Site
								<input
									name="cm_impacted_site_list"
									type={"text"}
									className={"form-control"}
								/>****}
								<Multiselect
									options={this.state.sites}
									//selectedValues={this.state.selectedValue}
									onSelect={this.onSelectSite}
									onRemove={this.onRemoveSite}
									displayValue="label"
									required
								/>
							</div>*/}

              <div className={"form-group"}>
                <label>Sites impactés</label>
                <Multiselect
                  options={this.state.sitesNames}
                  //selectedValues={this.state.selectedValue}
                  onSelect={this.onSelectSite}
                  onRemove={this.onRemoveSite}
                  displayValue='label'
                  required
                />
              </div>

              <div className={"form-group"}>
                <label>Importer une liste de sites impactés</label>
                <br />
                <a
                  href={Constants.apiURL + "/template_sites_impactes.xlsx"}
                  target={"_blank"}
                  className={"btn btn-sm btn-info"}
                >
                  Télécharger le fichier template
                </a>
              </div>

              <div className={"form-group"}>
                <label>Fichier des sites impactés</label>
                <input
                  type='file'
                  name='impacted_sites'
                  className={"form-control"}
                />
              </div>

              {/*<div className={"row"}>

								<div className={"col-md-8"}>
									{this.state.error && (<span className={"alert alert-warning d-block mb-2"}>{this.state.error}</span>)}

									{this.state.success && (<span className={"alert alert-success d-block mb-2"}>{this.state.success}</span>)}

									{this.state.notImported.length > 0 && (<span className={"alert alert-warning d-block"}>Certains élements n'ont pas été importés correctement.</span>)}

									<label>Importer une liste de nouveaux site logique à ajouter</label>
									<a href={Constants.apiURL+'/template_sites_logiques.xlsx'} target={"_blank"} className={"btn btn-sm btn-info"}>Télécharger le fichier template</a>

									<label>Fichier</label>
									<input type="file" name="sites_file" className={"form-control"} />

									this.state.activeTab === "sites" && this.props.type !== "gpm" && (
										<>
											<label>Importer une liste de nouveaux site logique à ajouter</label>
											<a href={Constants.apiURL+'/template_sites_logiques.xlsx'} target={"_blank"} className={"btn btn-sm btn-info"}>Télécharger le fichier template</a>

											<form onSubmit={this.submitForm} action={Constants.apiURL+"/api/cm/sites/bulk"} method="post">

												<div className={"form-group mt-5"}>
													<label>Fichier</label>
													<input type="file" name="sites_file" className={"form-control"} />
												</div>
												<div className={"form-group"}>
													<button disabled={this.state.loading} type="submit" className={"btn btn-success float-right"}>
														{this.state.loading ? <FontAwesomeIcon icon={faSpinner}  spin={true} /> : (<><FontAwesomeIcon icon={faArrowUp} className={"mr-2"} />Envoyer</>)}
													</button>
												</div>

											</form>
										</>
									)
								</div>
							</div>*/}

              {/*<div className="custom-control custom-checkbox" /*key={shift.value}*****>
								<label>Secteurs impactés</label>
								<input
									name={"cm_impacted_sector"}
									//id={shift.value}
									//key={shift.value}
									onClick={this.handleChangeSector}
									type="checkbox"
									checked={this.state.cmImpactedSector}
									value={this.state.cmImpactedSector}
									//defaultChecked={false}
								/> Secteur
								<input
									name="cm_impacted_sector_list"
									type={"text"}
									className={"form-control"}
								/>
							</div>

							<div className={"form-group"}>
								<label>Secteurs impactés</label>
								<input
									name="cm_impacted_sector_list"
									type={"text"}
									className={"form-control"}
								/>
							</div>*/}

              <div className={"form-group"}>
                <label>Nombre secteurs impactés</label>

                {/*<div className={"row"}>
									<div className={"col-md-4"}><label>2G</label></div>
									<div className={"col-md-4"}><label>3G</label></div>
									<div className={"col-md-4"}><label>4G</label></div>
								</div>*/}

                <div className={"row"}>
                  <div className={"col-md-12"}>
                    <input
                      name='cm_impacted_2g_sectors'
                      type={"text"}
                      className={"form-control"}
                    />
                  </div>
                  {/*<div className={"col-md-4"}>
										<input
											name="cm_impacted_3g_sectors"
											type={"text"}
											className={"form-control"}
										/>
									</div>
									<div className={"col-md-4"}>
										<input
											name="cm_impacted_4g_sectors"
											type={"text"}
											className={"form-control"}
										/>
									</div>*/}
                </div>
              </div>

              <br />
              <h4 className={"mb-4"}>
                <Trans i18nKey='attachement' />
              </h4>

              <div className={"form-group"}>
                <label>Fichier attachement</label>
                <input
                  type='file'
                  name='attached'
                  className={"form-control"} /*accept={"application/pdf"}*/
                />
              </div>

              <div className={"mt-5"}>
                <button
                  style={{ position: "absolute", bottom: "0px", right: "10px" }}
                  //disabled={! (this.state.validEmail && this.state.validUsername)}
                  className={"btn btn-success float-right col-md-6"}
                >
                  <Trans i18nKey='button_create' />
                </button>
              </div>

              {/*<br />
							<h4 className={"mb-4"}><Trans i18nKey="loss_of_tech_info"/></h4>

							<div className={"form-group"}>
								<label>Secteur 1</label>
								<textarea name="cm_impacted_sector_one" className={"form-control"} />
							</div>

							<div className={"form-group"}>
								<label>Secteur 2</label>
								<textarea name="cm_impacted_sector_two" className={"form-control"} />
							</div>

							<div className={"form-group"}>
								<label>Secteur 3</label>
								<textarea name="cm_impacted_sector_three" className={"form-control"} />
							</div>

							<div className={"form-group"}>
								<label>Secteur 4</label>
								<textarea name="cm_impacted_sector_four" className={"form-control"} />
							</div>

							<div className={"form-group"}>
								<label>Secteur 5</label>
								<textarea name="cm_impacted_sector_five" className={"form-control"} />
							</div>

							<div className={"form-group"}>
								<label>Secteur 6</label>
								<textarea name="cm_impacted_sector_six" className={"form-control"} />
							</div>*/}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CMNew);
