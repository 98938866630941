import React from "react";

import RenderChecks from "../../components/RenderChecks";
import PhotoItem from "../../../../Utils/PhotoItem";

const psTypes = [
  "SAFT MP54",
  "SAFT MP75",
  "SAFT MTP 69",
  "SAFT MTP 100",
  "Huawei",
  "Siemens 2008",
  "Siemens 4102",
  "ASCOM 1200",
  "ASCOM 2000",
  "AEG",
  "Benning 12(15)A",
  "Benning 34A",
  "Benning 50A",
  "Emerson Electric ",
  "Autres"
];

const Power = ({ data, ...props }) => {
  return (
    <>
      <b>Type de PS </b>
      <ul style={{ listStyle: "circle" }}>
        {data.types && data.types.map(type => (type.type ? <li>{type.type}</li> : <li>{psTypes[type.id]}</li>))}
      </ul>

      {data.redresseurs ? (
        <>
          <b>Redresseurs : </b>
          <ul style={{ listStyle: "circle" }}>{data.redresseurs.length && data.redresseurs.map(red => <li>{red}</li>)}</ul>
        </>
      ) : (
        <>
          <b>Redresseurs : </b>
          {data.redresseur}
        </>
      )}

      <br />
      <b>Consommation : </b>
      <ul style={{ listStyle: "circle" }}>{data.equipment && data.equipment.map(equip => <li>{equip.value}</li>)}</ul>

      <br />

      {data.groupe_elec && data.groupe_elec.length > 0 && (
        <>
          <b>Groupes (48v)</b>
          <div className={"table mt-1"}>
            <table className={"table-responsive"}>
              <tr>
                <td>Nombre de groupe</td>
                <td>Marque</td>
                <td>Réference</td>
                <td>Technologie</td>
              </tr>

              {data.groupe_elec.map((groupe, index) => (
                <tr>
                  <td>{groupe.nombre}</td>
                  <td>{groupe.marque}</td>
                  <td>{groupe.reference}</td>
                  <td>{groupe.tech}</td>
                </tr>
              ))}
            </table>

            <br />

            <table>
              <tr>
                <td />
                <td>Tension/Element</td>
                <td>Capacité</td>
                <td>Année de fabrication</td>
              </tr>
              {data.groupes_info.map((info, i) => (
                <tr>
                  <td>Groupe {i + 1}</td>
                  <td>{info.tension}</td>
                  <td>{info.capacite}</td>
                  <td>{info.annee}</td>
                </tr>
              ))}
            </table>
          </div>
        </>
      )}

      <RenderChecks data={data} />

      {data.photos && (
        <div className={"test-photos-wrapper"}>
          {data.photos.map((photo, key) => (
            <div className={"test-photos-single"}>
              <PhotoItem photo={photo} active={props.active} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Power;
