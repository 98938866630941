import React from "react";
import RenderChecks from "../../components/RenderChecks";

const GE = ({ data, ...props }) => {
  return (
    <>
      {data.groupes &&
        data.groupes.map((groupe, key) => (
          <>
            <div className={"row col-md-6"}>
              <label>Type GE : </label>
              {groupe.type}
            </div>

            <div className={"row col-md-6"}>
              <label>Numéro de série : </label>
              {groupe.num_serie}
            </div>

            <div className={"row col-md-6"}>
              <label>Type ATS : </label>
              {groupe.type_ats}
            </div>

            <div className={"row col-md-6"}>
              <label>Type Moteur : </label>
              {groupe.type_moteur}
            </div>

            <div className={"row col-md-6"}>
              <label>Type generatrice : </label>
              {groupe.type_gener}
            </div>

            <div className={"row col-md-6"}>
              <label>Voltage des phases : </label>
              {groupe.voltage}
            </div>

            <div className={"row col-md-6"}>
              <label>Fréquence : </label>
              {groupe.freq}
            </div>

            <div className={"row col-md-6"}>
              <label>Heures de marche : </label>
              {groupe.nb_heure}
            </div>

            <div className={"row col-md-6"}>
              <label>Gasoil restant : </label>
              {groupe.gasoil}
            </div>

            <hr />
          </>
        ))}

      <div className={"clear-fix mt-3"} />
      <RenderChecks data={data} />
    </>
  );
};

export default GE;
