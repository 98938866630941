import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import {
  setFlash,
  closeFlash,
  setAuthAction,
  startLoading,
  doneLoading,
} from "./../../Redux/actions/index";
import api from "../../Utils/Api";

function mapStateToProps(state) {
  return {
    auth: state.session.auth,
    user: state.session.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setAuth: (user) => dispatch(setAuthAction(user)),
    isLoading: (e) => dispatch(startLoading(e)),
    doneLoading: (e) => dispatch(doneLoading(e)),
    setFlash: (f) => dispatch(setFlash(f)),
    closeFlash: (f) => dispatch(closeFlash(f)),
  };
}

class AccountPrivacy extends Component {
  constructor(props) {
    super(props);
    this.formSubmit = this.formSubmit.bind(this);
    this.toggleCheck = this.toggleCheck.bind(this);

    this.state = {
      userid: 0,
      sms_notification: false,
      email_notification: false,
      subscribe_newsletter: false,
      email: "",
      phone: "",
    };
  }

  componentDidMount() {
    this.props.isLoading();
    let that = this;

    api
      .get(
        "/api/user/" +
          this.props.user.userid +
          "?tab=privacy&token=" +
          this.props.user.token,
      )
      .then(function(response) {
        that.setState(response.data);
        that.props.doneLoading();
      })
      .catch((err) => {
        that.props.setFlash({
          active: true,
          message: `Une erreur est survenue: ${err.message}`,
          classes: "warning",
          style: {},
        });
        that.props.doneLoading();
      });
  }

  toggleCheck(e) {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  }

  formSubmit(e) {
    e.preventDefault();

    this.props.isLoading();

    const data = {
      user: this.props.user,
      agent: this.state,
    };

    let that = this;

    api
      .post("/api/user/edit/" + this.props.user.userid, data)
      .then(function(response) {
        if (response.data.success) {
          that.props.setFlash({
            active: true,
            message: "Privacy settings have been succesfully updated.",
            classes: "success",
            style: {},
          });
        }

        that.props.doneLoading();
      });
  }

  render() {
    return (
      <form onSubmit={this.formSubmit}>
        <div className={"row"}>
          <div className={"col-md-6"}>
            <div class='custom-control custom-checkbox'>
              <input
                checked={this.state.sms_notification}
                value={this.state.sms_notification}
                name='sms_notification'
                onChange={this.toggleCheck}
                type='checkbox'
                class='custom-control-input'
                id={"sms-notification"}
              />

              <label class='custom-control-label' htmlFor={"sms-notification"}>
                <h6>SMS notifications</h6>
              </label>
            </div>

            <p>
              Enable SMS notifications to receive alerts whenever there's an
              update
            </p>
            <div className={"form-group"}>
              <label>Phone number :</label>
              <input
                value={this.state.phone}
                type='text'
                className={"form-control col-md-6"}
              />
            </div>

            <br />
            <div class='custom-control custom-checkbox'>
              <input
                checked={this.state.email_notification}
                value={this.state.email_notification}
                name='email_notification'
                onChange={this.toggleCheck}
                type='checkbox'
                class='custom-control-input'
                id={"email-notification"}
              />

              <label
                class='custom-control-label'
                htmlFor={"email-notification"}
              >
                <h6>Email notifications</h6>
              </label>
            </div>
            <p>
              Enable email notifications to receive an email whenever there's an
              update
            </p>

            <div className={"form-group"}>
              <label>Email address :</label>
              <input
                defaultValue={this.state.email}
                disabled={true}
                type='text'
                className={"form-control col-md-6"}
              />
            </div>

            <br />
            <div class='custom-control custom-checkbox'>
              <input
                checked={this.state.subscribe_newsletter}
                value={this.state.subscribe_newsletter}
                name='subscribe_newsletter'
                onChange={this.toggleCheck}
                type='checkbox'
                class='custom-control-input'
                id={"newsletter"}
              />

              <label class='custom-control-label' htmlFor={"newsletter"}>
                <h6>Subscribe to our newsletter</h6>
              </label>
            </div>

            <p>Receive product update notes & new products promotions</p>

            <br />
            <br />
            <input type='submit' className={"btn btn-primary "} value='Save' />
          </div>
        </div>
      </form>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountPrivacy);
