import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {setAuthAction, startLoading, doneLoading, setFlash} from '../../Redux/actions/index';
import Select from 'react-select';
import SocketContext from '../../Utils/socket-context';
import api from '../../Utils/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faCheck, faTimes, faChevronDown, faEdit, faLink, faHourglass, faBusinessTime, faCalendarTimes, faHistory, faSpinner, faCheckSquare, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../../Utils/Pagination';

import * as Helpers from '../../Utils/Helpers'
import HistoryOverlay from '../../Components/Anomalies/HistoryOverlay';
import AnomalieRow from '../../Components/Anomalies/AnomaliesRow';
import { Trans } from 'react-i18next';
import GPMAnomaliesList from '../../Components/Anomalies/GPMAnomaliesList';
import GPMNOKList from '../../Components/Anomalies/GPMNOKList';
import Constants from '../../Utils/Constants';
import TaskProblem from '../../Components/Anomalies/TaskProblem';
import Restricted from "../../Utils/Restricted";



const mapStateToProps = state => {
	return {
		auth: state.session.auth,
		user: state.session.user, 
		filters: state.filters
	}
}

function mapDispatchToProps(dispatch) {
	return {
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: e => dispatch(doneLoading()),
		setAuth: e => dispatch(setAuthAction(e)),
		setFlash: (f) => dispatch(setFlash(f)),
	}
}

class AnomaliesContainer extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			selected_tab: "anomalies",
			select_mode: false,
			selected_list: [],
			selected_list_nok: [],
			total: 0,
			anomalies: [],
			history_data: [],
			types: [],
			current: 1,
			pages: 1,
			limit: 15,
			showHistoryOverlay: false,
			selected_anomalie: 0,
			selected_anomalie_content: "",
			history_loading: true,
			filters: {
				anomalie_type: "",
				anomalie_status: "",
				agent: "",
				site: "",
				pmid: "",
				site_owner: "",
				site_manager: "",
				site_wilaya: "",
				site_cmanager: "",
				site_cluster: "",
				date_from: "",
				date_to: "",
				cycle: "cycle",
			}
		}

		
		
		this.buildFilters = () => Helpers.buildFilters.call(this, this.state.filters);

	}

	changeTab = (tab) => {
		this.setState({ selected_tab: tab });
	}

	toggleSelectMode = (p) => {
		if(this.state.select_mode) {
			this.setState({
				selected_list: [],
				selected_list_nok: [],
				select_mode: false
			})
		} else {
			this.setState({ 
				select_mode: true 
			});
		}
	}



	toggleSelectSingleAnomalie = (anomalie_id) => {

		let selected_list;
		if(this.state.selected_tab === 'anomalies') {
			selected_list = this.state.selected_list;
		} else {
			selected_list = this.state.selected_list_nok;
		}

		
		const idx = selected_list.indexOf(anomalie_id);
		if(idx > -1) {
			selected_list.splice(idx, 1);
		} else {
			selected_list.push(anomalie_id)
		}

		if(this.state.selected_tab === 'anomalies') {
			this.setState({ selected_list });

		} else {
			this.setState({ selected_list_nok: selected_list });
		}
		
	}



	updateFilter = (event, name) => {

		if(window.ga)
			window.ga('send', 'event', 'AnoamliesList', 'toggleFilters');

		let that = this;
		const value = event.target ? event.target.value : event.value;


		this.setState({
			filters: {
				...this.state.filters, 
				[name]: value
			}
		}, function() {
			//console.log(name, event)
			that.props.isLoading();
			if(this.state.selected_tab === 'anomalies' && that.anomaliesListRef) {

				that.anomaliesListRef.loadAnomalies(1)
			} else if(this.state.selected_tab === 'nok' && that.nokListRef) {
				that.nokListRef.loadAnomalies(1)
			} 
		}); 



	}



	enableAnomalieEditing = (key) => {
		const {anomalies} = this.state;

		anomalies[key].editing = true;
		
		this.setState({
			anomalies
		})
	}

		

	toggleHistoryOverlay = () => this.setState({ showHistoryOverlay: !this.state.showHistoryOverlay })

	selectAnomalie = (anomalie) => {
		//console.log("test");

		this.setState({
			history_data: [],
			history_loading: true,
			selected_anomalie_content: anomalie.anomalie,
			selected_anomalie: anomalie.id,
			showHistoryOverlay: true
		});

		api.get('/api/gpm/anomalies/history/'+anomalie.id)
		.then(response => {
			this.setState({
				history_loading: false,
				history_data: response.data.data
			});

		})

	}

	exportList = () => {

		if(this.state.selected_list.length === 0 && this.state.selected_list_nok.length === 0)
			return;

		if(!this.exportForm)
			return;


		this.exportForm.submit();
		

	}
	exportAll = () => {



		if(!this.exportForm)
			return;


		this.exportFormAll.submit();


	}

	render() {
		
		let remote = Constants.apiURL;

		return(
			<React.Fragment>

			<HistoryOverlay 
				anomalie={this.state.selected_anomalie_content}
				loading={this.state.history_loading}
				data={this.state.history_data}
				open={this.state.showHistoryOverlay}
				toggleOverlay={this.toggleHistoryOverlay}
				id={this.state.selected_anomalie}
			/>

			<form target={"_blank"} ref={ref => this.exportForm = ref} action={remote+"/api/gpm/anomalies/export/custom"} method="post">
				<input type="hidden" name="anomalies_list" value={JSON.stringify({anomalies: this.state.selected_list, nok: this.state.selected_list_nok})} />
				<input type="hidden" name="token" value={this.props.user.token} />
			</form>

				<form target={"_blank"} ref={ref => this.exportFormAll = ref} action={remote+"/api/gpm/anomalies/export/all?"+this.buildFilters()} method="post">
					<input type="hidden" name="filter" value={this.buildFilters()} />
					<input type="hidden" name="token" value={this.props.user.token} />
				</form>

			<div className={"container-header"}>
				Anomalies GPM
			</div>
			
			<div className={"row"}>
			
			<div className={"col-md-2"}>
				<div className={"side-filters"}>	

					{/* <button  className={"btn btn-primary btn-block mb-2"}>
						<FontAwesomeIcon icon={faFileExport} className={"mr-2"}  />
						Exporter la liste
					</button> */}

					{(this.props.user.role === "manager" || this.props.user.role === "service") 
					? 
                  	<Restricted scope={"gpm"} permission={"export_anomaly"}>
					<button className={"btn btn-primary btn-block mb-3"} onClick={this.toggleSelectMode}>{this.state.select_mode ? "Annuler" : "Mode séléction"}</button>
					{(this.state.selected_list.length > 0 || this.state.selected_list_nok.length > 0) && (
						<button className={"btn btn-primary btn-block mb-3"} onClick={this.exportList}>Exporter la séléction</button>
					)}

						<button className={"btn btn-primary btn-block mb-3"} onClick={this.exportAll}>Exporter tout</button>
					</Restricted>
					:
					<>
					<button className={"btn btn-primary btn-block mb-3"} onClick={this.toggleSelectMode}>{this.state.select_mode ? "Annuler" : "Mode séléction"}</button>
					{(this.state.selected_list.length > 0 || this.state.selected_list_nok.length > 0) && (
						<button className={"btn btn-primary btn-block mb-3"} onClick={this.exportList}>Exporter la séléction</button>
					)}

						<button className={"btn btn-primary btn-block mb-3"} onClick={this.exportAll}>Exporter tout</button>
					</>
					}

					<h4>Recherche</h4>

					<div className={"form-group"}>
						<label>Cycle</label>
						<Select
						classNamePrefix={"custom-select"}
						options={[{ label: "Tous", value: "" }, ...this.props.filters.cycle]}
						onChange={e => this.updateFilter(e, "cycle")}
						defaultValue={[{label: "Cycle actuel", value: "cycle"}]}
						/>
					</div>

					<div className={"form-group"}>
						<label>Site</label>
						<input
						name="site"
						className={"form-control"}
						placeholder="Nom ou code site"
						value={this.state.filters.site}
						onChange={e => this.updateFilter(e, 'site')}
						/>
					</div>
					
					{this.state.selected_tab === 'anomalies' ? (
						<div className={"form-group"}>
							<label>Type</label>
							<Select
							classNamePrefix={"custom-select"}
							options={[
								{label: "Tous", value: ""},
								...this.props.filters.anomalies_types
							]}
							placeholder="Type"
							onChange={e => this.updateFilter(e, 'anomalie_type')}
							/>
						</div>

					) : (
						<div className={"form-group"}>
							<label>Type</label>
							<Select
							classNamePrefix={"custom-select"}
							options={[
								{label: "Tous", value: ""},
								...this.props.filters.task_steps
							]}
							placeholder="Type"
							onChange={e => this.updateFilter(e, 'step')}
							/>
						</div>
					)}

					{this.state.selected_tab === 'anomalies' && (

						<div className={"form-group"}>
							<label>Status</label>
							<Select
							classNamePrefix={"custom-select"}
							options={[
								{label: "Tous", value: ""},
								...this.props.filters.anomalies_status
							]}
							placeholder="Status"
							onChange={e => this.updateFilter(e, 'anomalie_status')}
							/>
						</div>
					)}

					{this.state.selected_tab === 'anomalies' && (

						<div className={"form-group"}>
							<label>Responsabilité</label>
							<Select
							classNamePrefix={"custom-select"}
							options={[
								{label: "Tous", value: ""},
								...this.props.filters.responsibility
							]}
							placeholder="Responsabilité"
							onChange={e => this.updateFilter(e, 'anomalie_responsibility')}
							/>
						</div>
					)}

					<div className={"form-group"}>
						<label>Date début</label>
						<input type="date"
						className={"form-control"}
						onChange={e => this.updateFilter(e, 'date_from')}
						/>
					</div>

					<div className={"form-group"}>
						<label>Date fin</label>
						<input type="date"
						className={"form-control"}
						onChange={e => this.updateFilter(e, 'date_to')}
						/>
					</div>


					<hr />

					<div className={"form-group"}>
						<label>Site owner</label>
						<Select
						classNamePrefix={"custom-select"}
						options={this.props.filters.site_owners}
						placeholder="Séléctionnez..."
						onChange={e => this.updateFilter(e, 'site_owner')}
						/>
					</div>

					<div className={"form-group"}>
						<label>Cluster </label>
						<Select
						classNamePrefix={"custom-select"}
						options={[
							{label: "Tous", value: ""},
							...this.props.filters.clusters
						]}
						placeholder="Séléctionnez..."
						onChange={e => this.updateFilter(e, 'site_cluster')}
						/>
					</div>

					{/*<div className={"form-group"}>
						<label>Cluster manager </label>
						<Select
						classNamePrefix={"custom-select"}
						options={[
							{label: "Tous", value: ""},
							...this.props.filters.cluster_managers
						]}
						placeholder="Séléctionnez..."
						onChange={e => this.updateFilter(e, 'site_cluster_manager')}
						/>
					</div>
					
					<div className={"form-group"}>
						<label>Manager </label>
						<Select
						classNamePrefix={"custom-select"}
						options={[
							{label: "Tous", value: ""},
							...this.props.filters.managers
						]}
						placeholder="Séléctionnez..."
						onChange={e => this.updateFilter(e, 'site_manager')}
						/>
					</div>*/}

					<div className={"form-group"}>
						<label>Agent</label>
						<Select
						classNamePrefix={"custom-select"}
						options={[
							{label: "Tous", value: ""},
							...this.props.filters.agents
						]}
						placeholder="Séléctionnez..."
						onChange={e => this.updateFilter(e, 'agent')}
						/>
					</div>

					<hr />

					<div className={"form-group"}>
						<label>Wilaya</label>
							<Select
							classNamePrefix={"custom-select"}
							options={[
								{label: "Tous", value: ""},
								...this.props.filters.wilayas
							]}
							placeholder={"Séléctionnez..."}
							onChange={e => this.updateFilter(e, "site_wilaya")} 
							/>
					</div>


					<div className={"form-group"}>
						<label>ID PM</label>
						<input
						type={'text'}
						name="pmid"
						className={"form-control"}
						onKeyUp={e => this.updateFilter(e, 'pmid')}
						defaultValue={this.state.filters.pmid}
						/>
					</div>

				</div>
			</div>

			<div className={"col-md-10"}>
			{!this.props.auth ? <Redirect to="/login" /> :  ""}

				<div className={"page-top-menu"}>
					<ul>
						<li onClick={() => this.changeTab('anomalies')} className={this.state.selected_tab === "anomalies" && "selected"}>Anomalies</li>
						<li onClick={() => this.changeTab('nok')} className={this.state.selected_tab === "nok" && "selected"}>Checks Anomalies</li>
					</ul>
				</div>

				
				

				{this.state.selected_tab === "nok" && (
					<GPMNOKList
						ref={ref => this.nokListRef = ref}
						buildFilters={this.buildFilters}
						changeLimit={this.changeLimit}
						state={this.state}
						rowProps={{
							selected_list_nok: this.state.selected_list_nok,
							selectMode: this.state.select_mode,
							toggleSelectSingleAnomalie: this.toggleSelectSingleAnomalie,
							isLoading: this.props.isLoading,
							doneLoading: this.props.doneLoading,
							selectAnomalie: this.selectAnomalie,
							filters: this.props.filters,
							setFlash: this.props.setFlash,
						}}
						{...this.props}
					/>
				)}
				{this.state.selected_tab === "anomalies" && (
					<GPMAnomaliesList
						ref={ref => this.anomaliesListRef = ref}
						state={this.state}
						data={this.state.anomalies}
						filters={this.props.filters}
						buildFilters={this.buildFilters}
						rowProps={{
							selected_list: this.state.selected_list,
							selectMode: this.state.select_mode,
							toggleSelectSingleAnomalie: this.toggleSelectSingleAnomalie,
							isLoading: this.props.isLoading,
							doneLoading: this.props.doneLoading,
							selectAnomalie: this.selectAnomalie,
							filters: this.props.filters,
							setFlash: this.props.setFlash,
						}}
						{...this.props}
					/>
				)}

				

			</div>

				
                


			
			</div>
			
			</React.Fragment>)


	}
}


const AnomaliesConsumer = props => (
	<SocketContext.Consumer>
		{socket => <AnomaliesContainer {...props} socket={socket} />}
	</SocketContext.Consumer>
  )

export default connect(mapStateToProps, mapDispatchToProps)(AnomaliesConsumer)


