import React from "react";
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

const TransferCMOverlay = ({ open, task, toggle, reload, ...props }) => {
  const submitSiteForm = (e) => {
    e.preventDefault();

    let that = this;

    props.isLoading({
      message: "Transfer en cours",
      animate: true,
    });

    const formdata = new FormData(e.target);

    let data = {};

    for (var [key, value] of formdata.entries()) data[key] = value;

    data["user"] = props.user;
    data["manager_id"] = "";

    /*var data = {
			site_plan_id : siteplanid,
			//agent_id : agentid,
		};*/

    if (data["agent_id"] == "" && data["team_id"] == "") {
      props.setFlash({
        active: true,
        message: "Veuillez sélectionner une entité!",
        classes: "warning",
        style: {},
      });
      props.doneLoading();
    } else if (data["agent_id"] != "" && data["team_id"] != "") {
      props.setFlash({
        active: true,
        message:
          "Veuillez ne sélectionner qu'une seule entité, le double assignement n'est pas autorisée!",
        classes: "warning",
        style: {},
      });
      props.doneLoading();
    } else {
      api
        .post("/api/cm/web/transfer", data)
        .then((response) => {
          if (response.data.success) {
            props.doneLoading();
            props.setFlash({
              active: true,
              message: "CM transféré",
              classes: "success",
              style: {},
            });
          } else {
            props.setFlash({
              active: true,
              message: "Une erreur est survenue, Envoi echoué.",
              classes: "warning",
              style: {},
            });
          }
          toggle();
          props.doneLoading();
          reload();
        })
        .catch((err) => {
          alert(`Une erreur est survenue: ${err.message}`);
          props.doneLoading();
        });
    }
  };

  return (
    <div className={"ui-overlay " + (open ? " open" : "")}>
      <div className={"ui-overlay-content site-overlay col-md-5"}>
        <button
          data-package-id='0'
          onClick={(e) => {
            toggle(e);
          }}
          className={"ui-overlay-close btn btn-danger btn-sm"}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <h5 className={"mb-3"}>Transférer</h5>

        <form onSubmit={submitSiteForm}>
          {open && (
            <>
              <input
                type='hidden'
                name='site_plan_id'
                value={task.site_plan_id}
              />

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>Agent</label>
                <Select
                  name='agent_id'
                  classNamePrefix={"custom-select"}
                  options={props.filters.agentsToTransfer}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>Groupe</label>
                <Select
                  name='team_id'
                  classNamePrefix={"custom-select"}
                  options={props.filters.teamsToTransfer}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form mt-3 no-gutters'>
                <label className={"font-exp"}>Commentaire</label>
                <textarea rows={5} name='comment' className={"form-control"} />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3 no-gutters'>
                <input
                  type='submit'
                  className={"btn btn-primary col-md-12"}
                  value='Transférer'
                />
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default TransferCMOverlay;
