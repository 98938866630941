import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import debounce from "lodash/debounce";
import { startLoading, doneLoading, setFlash } from "../../Redux/actions/index";
import * as helpers from "../../Utils/Helpers";
import api from "../../Utils/Api";
import { Trans } from "react-i18next";

const mapStateToProps = (state) => {
  return {
    user: state.session.user,
    filters: state.filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoading: (e) => dispatch(startLoading(e)),
    doneLoading: () => dispatch(doneLoading()),
    setFlash: (f) => dispatch(setFlash(f)),
  };
};

class CManagerNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validEmail: true,

      validUsername: true,

      info: {
        subscribe: 0,
        userid: 0,
        username: "",
        password: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        cluster: "",
      },
      validPassword: true,
    };

    this.handleInput = this.handleInput.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
    this.checkUsername = debounce(this.checkUsername.bind(this), 400);
    this.checkEmail = debounce(this.checkEmail.bind(this), 400);
    this.changeSub = this.changeSub.bind(this);

    this.handlePassword = this.handlePassword.bind(this);
    this.checkPassword = debounce(this.checkPassword.bind(this), 100);
  }

  checkPassword(password) {
    //Regex to check if a string contains uppercase, lowercase special character & numeric value
    var pattern = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$",
    );

    if (!password || password.length < 10) {
      this.setState({ validUsername: false });
      return false;
    }

    if (pattern.test(password)) {
      this.setState({ validUsername: true });
      return true;
    } else {
      this.setState({ validUsername: false });
      return false;
    }
  }

  handlePassword(e) {
    let s = this.state;
    const event = Object.assign({}, e);
    const that = this;

    s.info[event.target.name] = event.target.value;
    this.setState({ ...s }, function() {
      if (event.target.name === "password") {
        if (that.checkPassword(event.target.value)) {
          that.setState({ validUsername: true });
        } else {
          that.setState({ validUsername: false });
        }
      }
    });
  }

  checkUsername() {
    const that = this;

    api
      .post("/api/user/availability", { username: this.state.info.username })
      .then(function(response) {
        if (response.data.success) {
          that.setState({ validUsername: true });
        } else {
          that.setState({ validUsername: false });
        }
      });
  }

  checkEmail() {
    const that = this;
    const email = this.state.info.email;

    if (!helpers.validateEmail(email))
      return that.setState({ validEmail: false });

    api
      .post("/api/user/availability", { email: email })
      .then(function(response) {
        if (response.data.success) {
          that.setState({ validEmail: true });
        } else {
          that.setState({ validEmail: false });
        }
      });
  }

  changeSub(e) {
    this.setState({
      info: {
        ...this.state.info,
        subscribe: e.value,
      },
    });
  }

  changeCluster = (e) => {
    const { info } = this.state;

    info.cluster = e.value;
    this.setState({
      info: info,
    });
  };

  handleInput(e) {
    let s = this.state;
    const event = Object.assign({}, e);
    const that = this;

    s.info[event.target.name] = event.target.value;
    this.setState({ ...s }, function() {
      if (event.target.name === "username") {
        that.checkUsername();
      } else if (event.target.name === "email") {
        that.checkEmail();
      }
    });
  }

  formSubmit(e) {
    e.preventDefault();

    this.props.isLoading();

    const data = helpers.formToObject(e.target);

    api
      .post("/api/clustermanager/register", data)
      .then((response) => {
        if (response.data.success) {
          this.props.history.push("/clustermanagers");

          this.props.setFlash({
            active: true,
            message: `User created`,
            classes: "success",
            style: {},
          });
        } else {
          this.props.setFlash({
            active: true,
            message: response.data.error,
            classes: "warning",
            style: {},
          });
        }

        //this.props.doneLoading();
      })
      .catch((err) => {
        this.props.setFlash({
          active: true,
          message: `Une erreur est survenue: ${err.message}`,
          classes: "warning",
          style: {},
        });
        // console.log(`error:  ${error}`);
        //this.props.doneLoading();
      })
      .finally(() => this.props.doneLoading());
  }

  render() {
    return (
      <div>
        <div className={"container-header"}>Nouveau compte cluster manager</div>

        <form method='#' onSubmit={this.formSubmit}>
          <div className={"row"}>
            <div className={"col-md-5"}>
              <h4 className={"mb-4"}>
                <Trans i18nKey='account_information' />
              </h4>

              <div className={"form-group"}>
                <label>Cluster</label>
                <Select
                  classNamePrefix={"custom-select"}
                  name={"cluster"}
                  options={this.props.filters.clusters}
                />
              </div>

              <div className={"form-group"}>
                <label>
                  <Trans i18nKey='user_username' />
                </label>
                <input
                  required
                  type='text'
                  className={
                    "form-control" +
                    (!this.state.validUsername ? " invalid" : "")
                  }
                  name='username'
                  onChange={this.handleInput}
                />
              </div>

              <div className={"form-group"}>
                <label>
                  <Trans i18nKey='user_password' />
                </label>
                <label className={"small-label"}>
                  Le mot de passe doit comporter plus de 10 caractères, contenir
                  des minuscules, des majuscules, au moins un chiffre et un
                  caractère spécial
                </label>
                <input
                  required
                  type='password'
                  className={
                    "form-control" +
                    (!this.state.validUsername ? " invalid" : "")
                  }
                  name='password'
                  onChange={this.handlePassword}
                />
              </div>

              <h4 className={"mb-4"}>
                <Trans i18nKey='personal_information' />
              </h4>

              <div className={"form-group"}>
                <label>Adresse email</label>
                <div class='input-group'>
                  <input type='text' className={"form-control"} name='email' />
                </div>
              </div>

              <div className={"form-group"}>
                <label>
                  <Trans i18nKey='user_phonenumber' />
                </label>

                <div class='input-group'>
                  <input type='text' className={"form-control"} name='phone' />
                </div>
              </div>

              <div className={"form-group"}>
                <label>
                  <Trans i18nKey='user_firstname' />
                </label>
                <input
                  type='text'
                  className={"form-control"}
                  name='firstName'
                />
              </div>

              <div className={"form-group"}>
                <label>
                  <Trans i18nKey='user_lastname' />
                </label>
                <input type='text' className={"form-control"} name='lastName' />
              </div>

              <div className={"mt-5"}>
                <button
                  disabled={
                    !(this.state.validEmail && this.state.validUsername)
                  }
                  className={"btn btn-success float-right col-md-6"}
                >
                  <Trans i18nKey='button_create' />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CManagerNew);
