import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import {
  setAuthAction,
  startLoading,
  doneLoading,
  /*setFlash,*/ closeFlash,
} from "../../../Redux/actions/index";
import { setFlash } from "../../../Redux/actions";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import api from "../../../Utils/Api";
import Constants from "../../../Utils/Constants";
import ApiService from "../../../Utils/ApiService";
import CMTaskRow from "./CMTaskRow";
import CancelCMOverlay from "./CancelCMOverlay";
import CloseCMOverlay from "./CloseCMOverlay";
import PlanCMOverlay from "../../Addresses/SiteList/PlanCMOverlay";
import EditCMOverlay from "./EditCMOverlay";
import ShowCMOverlay from "./ShowCMOverlay";
import SolveCMOverlay from "./SolveCMOverlay";
import StartCMOverlay from "./StartCMOverlay";
import RestartCMOverlay from "./RestartCMOverlay";
import FinishCMOverlay from "./FinishCMOverlay";
import RejectCMOverlay from "./RejectCMOverlay";
import EscalateCMOverlay from "./EscalateCMOverlay";
import { Link, Redirect } from "react-router-dom";
import TransferCMOverlay from "./TransferCMOverlay";
import AttachFileOverlay from "./AttachFileOverlay";

const mapStateToProps = (state) => {
  return {
    session: state.session,
    filters: state.filters,
  };
};

const mapDispatchToProps = (dispatch) => ({
  isLoading: (e) => dispatch(startLoading(e)),
  doneLoading: () => dispatch(doneLoading()),
  setAuth: (e) => dispatch(setAuthAction(e)),
  setFlash: (f) => dispatch(setFlash(f)),
  closeFlash: (f) => dispatch(closeFlash(f)),
});

class CMTaskList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      showOverlay: false,
      taskid: "",
      agentid: "",
      selected_pm: "",
      selected_plan: "",
      activeTest: "clockin",
      showDeleteOverlay: false,
      showDeleteOverlayConfirmation: false,
      showCancelCMOverlay: false,
      showCloseCMOverlay: false,
      showCmOverlay: false,
      site_plan_id: "",
      agent_id: "",
      selected_site: "",
      selectedSubSite: "",
      showCMTaskOverlay: false,
      showDetailsCMTaskOverlay: false,
      showSolveCMOverlay: false,
      lastToTime: "",
      userId: "",
      showStartCMOverlay: false,
      showRestartCMOverlay: false,
      showEscalateCMOverlay: false,
      service_id: "",
      showFinishCMOverlay: false,
      showRejectCMOverlay: false,
      showTransferCMOverlay: false,
      redirect: false,
      parent_ref: "",
      fromtime: "",
      owner: "",
      demanded_by: "",
      cm_sites: [],
      showAttachFileOverlay: false,
    };

    this.timerID = null;

    this.loadLogicSites();
  }

  toggleOverlay = () => this.setState({ showOverlay: !this.state.showOverlay });

  selectTest = (e, test) => {
    if (window.ga) window.ga("send", "event", "TaskList", "selectTest");

    e.preventDefault();

    this.setState({
      activeTest: test,
    });
  };

  editTask = (task) => {
    this.setState({
      selected_pm: task,
      showCMTaskOverlay: true,
      //selected_plan: planid,
    });
  };

  showTask = (task) => {
    this.setState({
      selected_pm: task,
      showDetailsCMTaskOverlay: true,
    });
  };

  attachFile = (task) => {
    this.setState({
      selected_pm: task,
      showAttachFileOverlay: true,
    });
  };

  cancelTask = (pmid, planid) => {
    this.setState({
      selected_pm: planid,
      showCancelCMOverlay: true,
    });

    /*var data = {
			site_plan_id : planid,
		};
		api.post('/api/cm/cancel', data)
		.then(response => {
			if (response.data.success) {
			} else {
			alert('Envoi echoué');
			}
		});*/
  };

  deleteTask = (pmid, planid) => {
    //const pmid = e.target.getAttribute('data-task-id');

    this.setState({
      selected_pm: pmid,
      showDeleteOverlay: true,
      deleteSteps: 1,
      selected_plan: planid,
    });
  };

  nextDeleteStep = () => {
    this.setState({
      showDeleteOverlay: false,
      showDeleteOverlayConfirmation: true,
      deleteSteps: 2,
    });
  };

  confirmDelete = () => {
    this.props.isLoading();

    this.setState({
      showDeleteOverlayConfirmation: false,
    });

    const data = {
      pmid: this.state.selected_pm,
      planid: this.state.selected_plan,
    };

    api
      .post("/api/cm/delete", data)
      .then((res) => {
        // console.log("delete: ", res);
        if (res.data.success) {
          this.props.setFlash({
            active: true,
            message: `Le ticket ${this.state.selected_plan} a été suprimé`,
            classes: "success",
            style: {},
          });
        } else {
          this.props.setFlash({
            active: true,
            message: res.data.error,
            classes: "warning",
            style: {},
          });
        }
        //this.props.handlers.loadTasks();
      })
      .catch((err) =>
        this.props.setFlash({
          active: true,
          message: `Supression echoué: ${err.message}`,
          classes: "warning",
          style: {},
        }),
      )
      .finally(() => {
        this.props.doneLoading();
        this.props.handlers.loadTasks();
      });
  };

  toggleCancelCMOverlay = () =>
    this.setState({ showCancelCMOverlay: !this.state.showCancelCMOverlay });

  toggleCloseCMOverlay = () =>
    this.setState({ showCloseCMOverlay: !this.state.showCloseCMOverlay });

  toggleCMTaskOverlay = () =>
    this.setState({ showCMTaskOverlay: !this.state.showCMTaskOverlay });

  toggleDetailtsCMTaskOverlay = () =>
    this.setState({
      showDetailsCMTaskOverlay: !this.state.showDetailsCMTaskOverlay,
    });

  toggleAttachFileOverlay = () =>
    this.setState({ showAttachFileOverlay: !this.state.showAttachFileOverlay });

  toggleSolveCMOverlay = () =>
    this.setState({ showSolveCMOverlay: !this.state.showSolveCMOverlay });

  toggleRejectCMOverlay = () =>
    this.setState({ showRejectCMOverlay: !this.state.showRejectCMOverlay });

  toggleTransferCMOverlay = () =>
    this.setState({ showTransferCMOverlay: !this.state.showTransferCMOverlay });

  returnTask = (task) => {
    this.setState({
      selected_pm: task,
      showCancelCMOverlay: true,
    });
  };

  closeTask = (
    taskid,
    planid,
    agentid,
    totime,
    fromtime,
    owner,
    demanded_by,
  ) => {
    /*this.props.isLoading({
			message: "Mise a jour en cours."
		});

		ApiService.closeTask(taskid)
		.then(res => {
			this.props.handlers.loadTasks();
			this.props.doneLoading();
		})*/

    this.setState({
      selected_pm: planid,
      showCloseCMOverlay: true,
      lastToTime: totime,
      userId: agentid,
      fromtime: fromtime,
      owner: owner,
      demanded_by: demanded_by,
    });

    //this.approveCM(planid, agentid);
  };

  //approve the CM by the owner
  approveCM = (planid, agentid) => {
    var data = {
      site_plan_id: planid,
      agent_id: agentid,
    };
    // couldn't test this
    api
      .post("/api/cm/approve", data)
      .then((response) => {
        if (!response.data.success) {
          alert("Envoi echoué");
        }
      })
      .catch((err) => {
        this.props.setFlash({
          active: true,
          message: `Envoi echoué: ${err.message}`,
          classes: "warning",
          style: {},
        });
      })
      .finally(() => this.props.doneLoading());
  };

  //solve the problem
  solveProblem = (taskid, siteplanid, task) => {
    this.setState({
      selected_pm: task,
      showSolveCMOverlay: true,
      //selected_plan: planid,
    });

    /*var data = {
			site_plan_id : siteplanid,
			//agent_id : agentid,
		};

		api.post('/api/cm/solve', data)
      		.then(response => {
				this.props.isLoading({
					message: "Envoi"
				});
				if (response.data.success) {
					this.props.doneLoading();
					setFlash({
						active: true,
						message: "L'agent est notifié.",
						classes: "success",
						style: {},
					});
					//reload
					//this.props.history.push('/cm');
					//this.props.reload();	
					window.location.reload();
				} else {
					//alert('Envoi echoué');
					setFlash({
						active: true,
						message: "Une erreur est survenue, Envoi echoué.",
						classes: "warning",
						style: {},
					});
				}
			});*/
  };

  //reassign the CM after being transfered
  reassignTask = (taskid, planid, agentid, siteid, sitelogiqueid) => {
    var data = {
      site_plan_id: planid,
      agent_id: agentid,
      selected_site: siteid,
    };

    this.setState({
      //agentid: site.site_next_pm_agent_id ? site.site_next_pm_agent_id : "",
      //agent_username: site.site_next_pm_agent ? site.site_next_pm_agent : "",
      //siteid: site.site_id,
      //site: site,
      showCmOverlay: true,
      site_plan_id: planid,
      agent_id: agentid,
      selected_site: sitelogiqueid,
      selectedSubSite: sitelogiqueid,
    });

    /*api.post('/api/cm/planning/reassign', data)
			.then(response => {
				//console.log("hello", response);
			  if (response.data.success) {
				setFlash({
					active: true,
					message: "Tâche réassignée avec succès.",
					classes: "success",
					style: {},
				  });
			  } else {
				if(response.data.team_error) {
					setFlash({
					  active: true,
					  message: "Une erreur est survenue, aucune équipe disponible à cette date à cette shift.",
					  classes: "warning",
					  style: {},
					});
				  } else {
					setFlash({
					  active: true,
					  message: "Une erreur est survenue, veuillez contacter l'administrateur.",
					  classes: "warning",
					  style: {},
					});
				  }
			  }
			});*/
    //.catch(() => ));
  };

  exportCM = (pm, anomalies) => {
    if (window.ga) window.ga("send", "event", "TaskList", "exportPM");

    // let remote = Constants.apiURL;

    if (anomalies) {
      this.pmExportForm.action =
        Constants.apiURL + "/api/cm/anomalies/checks_export/" + pm.task_id;
    } else {
      this.pmExportForm.action =
        Constants.apiURL + "/api/cm/export/test/igeneral/" + pm.task_id;
    }

    this.pmInput.value = JSON.stringify(pm);
    this.userInfo.value = JSON.stringify(this.props.session.user);
    this.props.isLoading();
    this.pmExportForm.submit();
    this.props.doneLoading();
  };

  startTask = (serviceid, planid) => {
    this.setState({
      selected_pm: planid,
      showStartCMOverlay: true,
      service_id: serviceid,
    });
  };

  restartTask = (serviceid, planid) => {
    this.setState({
      selected_pm: planid,
      showRestartCMOverlay: true,
      service_id: serviceid,
    });
  };

  finishTask = (serviceid, planid) => {
    this.setState({
      selected_pm: planid,
      showFinishCMOverlay: true,
      service_id: serviceid,
    });
  };

  //escalate CM
  escalateTask = (task) => {
    this.setState({
      selected_pm: task,
      showEscalateCMOverlay: true,
    });
  };

  //accept CM escalation
  acceptTask = (serviceid, planid) => {
    this.props.isLoading({
      message: "Acceptance de l'escalade en cours",
    });

    var data = {
      site_plan_id: planid,
      service_id: serviceid,
    };

    //Couldn't test this
    api
      .post("/api/cm/escalation/accept", data)
      .then((response) => {
        if (!response.data.success) {
          alert("Envoi echoué");
        }
      })
      .catch((err) => {
        this.props.setFlash({
          active: true,
          message: `Envoi echoué: ${err.message}`,
          classes: "warning",
          style: {},
        });
      })
      .finally(() => {
        this.props.handlers.loadTasks();
        this.props.doneLoading();
      });
  };

  //reject the CM escalation
  rejectTask = (task) => {
    this.setState({
      selected_pm: task,
      showRejectCMOverlay: true,
    });
  };

  //transfer CM
  transferTask = (task) => {
    this.setState({
      selected_pm: task,
      showTransferCMOverlay: true,
    });
  };

  createChild = (parent_ref) => {
    this.setState({ redirect: true, parent_ref: parent_ref });
  };

  updateFinal = (id, problem_type_final, service_id_final) => {
    if (service_id_final == "0") {
      this.props.setFlash({
        active: true,
        message: "Veuillez choisir le service!",
        classes: "warning",
        style: {},
      });
    } else {
      this.props.setFlash({
        active: false,
        message: "Veuillez choisir le service!",
        classes: "warning",
        style: {},
      });

      this.props.isLoading({
        message: "Mise à jour du problème type final",
      });

      var data = {
        id: id,
        problem_type_final: problem_type_final,
        service_id_final: service_id_final,
      };

      api
        .post("/api/cm/final/update", data)
        .then((response) => {
          if (!response.data.success) {
            alert("Envoi echoué");
          }
        })
        .catch((err) => {
          alert(`Envoi echoué: ${err.message}`);
          this.props.setFlash({
            active: true,
            message: `Envoi echoué: ${err.message}`,
            classes: "warning",
            style: {},
          });
        })
        .finally(() => {
          this.props.handlers.loadTasks();
          this.props.doneLoading();
        });
    }
  };

  toggleEscalateCMOverlay = () =>
    this.setState({ showEscalateCMOverlay: !this.state.showEscalateCMOverlay });

  toggleStartCMOverlay = () =>
    this.setState({ showStartCMOverlay: !this.state.showStartCMOverlay });

  toggleRestartCMOverlay = () =>
    this.setState({ showRestartCMOverlay: !this.state.showRestartCMOverlay });

  toggleFinishCMOverlay = () =>
    this.setState({ showFinishCMOverlay: !this.state.showFinishCMOverlay });

  componentDidMount() {
    this.timerID = setInterval(this.props.handlers.loadTasks, 10 * 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  loadLogicSites() {
    this.props.isLoading();

    api
      .get(`/api/logicsites/fetch?limit=-1`)
      .then((response) => {
        //console.log("hello sites", response.data);
        if (response.data.success) {
          this.setState({
            cm_sites: response.data.sites.map((site) => ({
              label: site.site_code,
              value: site.site_id,
            })),
          });
        } else {
          if (response.data.errcode === 101) {
            this.props.setAuth(false);
          }
          alert(`Error loading the sites ${response.data.error}`);
        }
      })
      .catch((error) => {
        //alert(`Error loading the sites: ${error.message}`);
        this.props.setFlash({
          active: true,
          message: `Error loading the sites: ${error.message}`,
          classes: "warning",
          style: {},
        });
      })
      .finally(() => this.props.doneLoading());
  }

  render() {
    const data = this.props.data;
    const { showCmOverlay } = this.state;

    return (
      <React.Fragment>
        <form method='post' ref={(ref) => (this.pmExportForm = ref)}>
          <input
            type='hidden'
            name={"pm"}
            ref={(ref) => (this.pmInput = ref)}
          />
          <input
            type='hidden'
            name={"token"}
            value={this.props.session.user.token}
          />
          <input
            type='hidden'
            name={"userInfo"}
            ref={(ref) => (this.userInfo = ref)}
          />
        </form>

        {this.state.redirect ? (
          <Redirect
            push
            to={{ pathname: "/cm/new", state: this.state.parent_ref }}
          />
        ) : null}

        {showCmOverlay && (
          <PlanCMOverlay
            close={() => this.setState({ showCmOverlay: false })}
            //loadSites={this.loadCoSites}
            loadSites={this.props.handlers.loadTasks}
            open={true}
            site={this.props.site}
            siteid={this.state.selectedSubSite}
            subsite={this.state.selectedSubSite}
            taskType={"cm"}
            accessProblem={true}
            siteplanid={this.state.site_plan_id}
            {...this.props}
          />
        )}

        <EscalateCMOverlay
          scope={"cm"}
          open={this.state.showEscalateCMOverlay}
          task={this.state.selected_pm}
          toggle={this.toggleEscalateCMOverlay}
          reload={this.props.handlers.loadTasks}
          {...this.props}
        />

        <StartCMOverlay
          scope={"cm"}
          open={this.state.showStartCMOverlay}
          task={this.state.selected_pm}
          service={this.state.service_id}
          toggle={this.toggleStartCMOverlay}
          reload={this.props.handlers.loadTasks}
          flash={this.props.setFlash}
          {...this.props}
        />

        <RestartCMOverlay
          scope={"cm"}
          open={this.state.showRestartCMOverlay}
          task={this.state.selected_pm}
          service={this.state.service_id}
          toggle={this.toggleRestartCMOverlay}
          reload={this.props.handlers.loadTasks}
          flash={this.props.setFlash}
          {...this.props}
        />

        <FinishCMOverlay
          scope={"cm"}
          open={this.state.showFinishCMOverlay}
          task={this.state.selected_pm}
          service={this.state.service_id}
          toggle={this.toggleFinishCMOverlay}
          reload={this.props.handlers.loadTasks}
          {...this.props}
        />

        <CancelCMOverlay
          scope={"cm"}
          open={this.state.showCancelCMOverlay}
          task={this.state.selected_pm}
          toggle={this.toggleCancelCMOverlay}
          reload={this.props.handlers.loadTasks}
          {...this.props}
        />

        <CloseCMOverlay
          scope={"cm"}
          open={this.state.showCloseCMOverlay}
          task={this.state.selected_pm}
          time={this.state.lastToTime}
          fromtime={this.state.fromtime}
          owner={this.state.owner}
          demanded_by={this.state.demanded_by}
          user={this.state.userId}
          toggle={this.toggleCloseCMOverlay}
          reload={this.props.handlers.loadTasks}
          {...this.props}
        />

        <EditCMOverlay
          open={this.state.showCMTaskOverlay}
          task={this.state.selected_pm}
          close={this.toggleCMTaskOverlay}
          reload={this.props.handlers.loadTasks}
          cm_sites={this.state.cm_sites}
          {...this.props}
        />

        <ShowCMOverlay
          open={this.state.showDetailsCMTaskOverlay}
          task={this.state.selected_pm}
          close={this.toggleDetailtsCMTaskOverlay}
          reload={this.props.handlers.loadTasks}
          {...this.props}
        />

        <AttachFileOverlay
          open={this.state.showAttachFileOverlay}
          task={this.state.selected_pm}
          toggle={this.toggleAttachFileOverlay}
          reload={this.props.handlers.loadTasks}
          {...this.props}
        />

        <SolveCMOverlay
          //loadSites={this.props.handlers.loadSites}
          open={this.state.showSolveCMOverlay}
          task={this.state.selected_pm}
          //close={() => this.setState({ showSolveCMOverlay: false, selected_pm: {} })}
          close={this.toggleSolveCMOverlay}
          reload={this.props.handlers.loadTasks}
          {...this.props}
        />

        <RejectCMOverlay
          //loadSites={this.props.handlers.loadSites}
          open={this.state.showRejectCMOverlay}
          task={this.state.selected_pm}
          //close={() => this.setState({ showRejectCMOverlay: false, selected_pm: {} })}
          close={this.toggleRejectCMOverlay}
          reload={this.props.handlers.loadTasks}
          {...this.props}
        />

        <TransferCMOverlay
          //loadSites={this.props.handlers.loadSites}
          open={this.state.showTransferCMOverlay}
          task={this.state.selected_pm}
          //close={() => this.setState({ showTransferCMOverlay: false, selected_pm: {} })}
          toggle={this.toggleTransferCMOverlay}
          reload={this.props.handlers.loadTasks}
          {...this.props}
        />

        <form method='post' ref={(ref) => (this.pmExportForm = ref)}>
          <input
            type='hidden'
            name={"pm"}
            ref={(ref) => (this.pmInput = ref)}
          />
          <input
            type='hidden'
            name={"token"}
            value={this.props.session.user.token}
          />
          <input
            type='hidden'
            name={"userInfo"}
            ref={(ref) => (this.userInfo = ref)}
          />
        </form>

        <div
          className={
            this.state.showDeleteOverlay
              ? "ui-overlay delete-task open"
              : "ui-overlay delete-task"
          }
        >
          <div
            className={
              "ui-overlay-content status-overlay-text col-md-4 offset-md-4"
            }
          >
            <button
              onClick={() => this.setState({ showDeleteOverlay: false })}
              className={"ui-overlay-close btn btn-danger btn-sm"}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>

            <h5 className={"mb-4"}>
              Voulez-vous vraiment supprimer cette CM ?
            </h5>

            <button type='submit' className={"btn btn-success btn-sm mt-2"}>
              ANNULER
            </button>
            <button
              onClick={this.nextDeleteStep}
              type='submit'
              className={"btn btn-danger btn-sm ml-2 mt-2"}
            >
              OUI, SUPPRIMER
            </button>
          </div>
        </div>

        <div
          className={
            this.state.showDeleteOverlayConfirmation
              ? "ui-overlay delete-task open"
              : "ui-overlay delete-task"
          }
        >
          <div
            className={
              "ui-overlay-content status-overlay-text col-md-4 offset-md-4"
            }
          >
            <button
              onClick={() =>
                this.setState({ showDeleteOverlayConfirmation: false })
              }
              className={"ui-overlay-close btn btn-danger btn-sm"}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>

            <h5 className={"mb-5"}>
              Êtes-vous sur de vouloir supprimer cette CM ?
            </h5>

            <button
              onClick={() =>
                this.setState({ showDeleteOverlayConfirmation: false })
              }
              type='submit'
              className={"btn btn-success btn-sm mt-2"}
            >
              Retour
            </button>
            <button
              onClick={this.confirmDelete}
              type='submit'
              className={"btn btn-danger btn-sm mt-2 ml-2"}
            >
              Confirmer la suppression
            </button>

            <div>
              <span className={"font-exp"}>
                Attention, cette action est irreversible.
              </span>
            </div>
          </div>
        </div>

        <div className='col-md-12 ctable mb-3'>
          <div className={"d-none d-sm-block"}>
            <div className={"row head-titles ctable-row"}>
              <div className={"col-md-1"}>N° ticket</div>

              <div className={"col-md-1"}>Ticket parent</div>

              <div className={"col-md-1"}>Site</div>

              <div className={"col-md-1"}>Alarme</div>

              <div className={"col-md-1"}>Type de problème</div>

              <div className={"col-md-1"}>Détails problème</div>

              <div className={"col-md-2"}>Date alarme & alerting</div>

              {/*<div className={"col-md-1"}>
						Groupe assginé
					</div>

					<div className={"col-md-1"}>
						Technicien assigné
					</div>

					<div className={"col-md-1"}>
						Service assigné
					</div>*/}

              <div className={"col-md-1"}>Assignement</div>

              <div className={"col-md-1"}>Opérateur</div>

              <div className={"col-md-1"}>Impact</div>

              {/*<div className={"col-md-1"}>
						Créé par
					</div>

					<div className={"col-md-1"}>
						approuvé par
					</div>

					<div className={"col-md-1"}>
						Catégorie
					</div>

					<div className={"col-md-1"}>
						Priorité
					</div>

					<div className={"col-md-1"}>
						Owner
					</div>*/}

              <div className={"col-md-1 task-details-col"}>
                <Trans i18nKey='label_details' />
              </div>
            </div>
          </div>

          {data.tasks.map((task, i) => (
            <CMTaskRow
              key={i}
              exportPM={this.exportPM}
              task={task}
              handlers={this.props.handlers}
              cancelTask={this.cancelTask}
              editTask={this.editTask}
              showTask={this.showTask}
              deleteTask={this.deleteTask}
              returnTask={this.returnTask}
              closeTask={this.closeTask}
              solveProblem={this.solveProblem}
              reassignTask={this.reassignTask}
              exportCM={this.exportCM}
              mine={this.props.mine}
              user={this.props.session.user}
              escalateTask={this.escalateTask}
              startTask={this.startTask}
              restartTask={this.restartTask}
              finishTask={this.finishTask}
              acceptTask={this.acceptTask}
              rejectTask={this.rejectTask}
              createChild={this.createChild}
              updateFinal={this.updateFinal}
              transferTask={this.transferTask}
              agentsToTransfer={this.props.filters.agentsToTransfer}
              teamsToTransfer={this.props.filters.teamsToTransfer}
              attachFile={this.attachFile}
              filters={this.props.filters}
            />
          ))}

          {data.tasks.length === 0 && (
            <div className={"col-md-12 text-center my-3"}>
              Aucune tâche CM trouvée
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

// export CheckOrTime;
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CMTaskList);
