import React from "react";
import { Chart } from "chart.js";
import * as ChartGeo from "chartjs-chart-geo";
import { Pie, HorizontalBar, Bar } from "react-chartjs-2";
import { chartColors, gradientColors, hsGradientColors, hsGradientColorsArray } from "../../../../Utils/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Select from "react-select";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import api from "../../../../Utils/Api";
import myImage from './Capture.JPG';



class DjezzyAppPage extends React.Component {

  constructor() {
  
    super();
  
    this.state = {
      loading: true,
      showAllWilayas: false,
      wilayas: [],
      clusters: [],
      site_owners: [],
      site_types: [],
      selectedCluster: "",
      selectedOwner: "",
      selectedCmCode: "LOSS-OF-ALL CHANNEL",
      hsSites: [],
      topTen: [],
      total: 0,
      totalNumber: 0,
      percentage: 0,
    };
  }

  render() {

    return (
      <div className={"hs-dashboard-page"}>
        <div className={"container-header header-spacing nmb"}>
          <img className="appimage" src={myImage} alt="My Image" />
        </div>
      </div>
    );
  }
}

const mapState = state => {
  return {
    filters: state.filters,
    session: state.session
  }
}
export default connect(mapState)(DjezzyAppPage);
