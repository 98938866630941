import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import api from "../../Utils/Api";
import UserAuthorizationsOverlay from "./UserAuthorizationsOverlay";
import Restricted from "../../Utils/Restricted";
import { Multiselect } from "multiselect-react-dropdown";

const ServiceEditOverlay = ({
  selected_user,
  toggleEditOverlay,
  open,
  role,
  ...props
}) => {
  const [permissionsOverlay, setPermissionsOverlay] = useState(false);

  //console.log("hello all", selected_user.managers);
  var managers = [];
  var problems = [];
  var web_escalation = selected_user.web_escalation == "true" ? true : false;
  var mobile_escalation =
    selected_user.mobile_escalation == "true" ? true : false;
  var external = selected_user.external == "true" ? true : false;

  if (selected_user.managers) {
    managers = selected_user.managers.map((obj) => {
      return { label: obj.manager_username, value: obj.manager_id };
    });
  }

  if (selected_user.problems) {
    problems = selected_user.problems.map((obj) => {
      return { label: obj.problem_type, value: obj.problem_type };
    });
  }

  const submitSiteForm = (e) => {
    props.isLoading({
      message: "Mise à jour en cours",
      animated: true,
    });

    e.preventDefault();

    const formdata = new FormData(e.target);

    let data = {};
    for (var [key, value] of formdata.entries()) data[key] = value;

    data["user"] = props.user;
    data["managers"] = managers;
    data["problems"] = problems;
    data["web_escalation"] = "" + web_escalation;
    data["mobile_escalation"] = "" + mobile_escalation;
    data["external"] = "" + external;

    //console.log("hello data", data);

    toggleEditOverlay();

    api
      .post("/api/services/edit/" + selected_user.service_id, data)
      .then((res) => {
        if (res.data.success) {
          props.setFlash({
            active: true,
            message: `Service updated`,
            classes: "success",
            style: {},
          });
        } else {
          props.setFlash({
            active: true,
            message: `Une erreur est survenue`,
            classes: "warning",
            style: {},
          });
        }
      })
      .catch((err) => {
        props.setFlash({
          active: true,
          message: `Une erreur est survenue: ${err.message}`,
          classes: "warning",
          style: {},
        });
        // console.log(`error:  ${error}`);
        //this.props.doneLoading();
      })
      .finally(() => {
        props.doneLoading();
        props.reload();
      });
  };

  const managePermissions = (show) => {
    toggleEditOverlay(show);
    if (typeof show !== "undefined") setPermissionsOverlay(show);
    else {
      setPermissionsOverlay(true);
    }
  };

  const onSelectManager = (selectedList, selectedItem) => {
    /*selected_user.managers.push(selectedItem);
    managers = selected_user.managers.map(obj => {
      return ({'label':obj.manager_username ? obj.manager_username : obj.label , 'value':obj.manager_id ? obj.manager_id : obj.value})
    });*/
    //console.log("hello add", selected_user.managers, managers);
    managers = selectedList;
    return managers;
  };

  const onRemoveManager = (selectedList, removedItem) => {
    /*selected_user.managers.pop(removedItem);
    managers = selected_user.managers.map(obj => {
      return ({'label':obj.manager_username ? obj.manager_username : obj.label , 'value':obj.manager_id ? obj.manager_id : obj.value})
    });*/
    //console.log("hello remove", selected_user.managers, managers);
    managers = selectedList;
    return managers;
  };

  const onSelectProblem = (selectedList, selectedItem) => {
    /*selected_user.problems.push(selectedItem);
    problems = selected_user.problems.map(obj => {
      return ({'label':obj.problem_type ? obj.problem_type : obj.label , 'value':obj.problem_type ? obj.problem_type : obj.value})
    });*/
    //console.log("hello add", selected_user.managers, managers);
    problems = selectedList;
    return problems;
  };

  const onRemoveProblem = (selectedList, removedItem) => {
    /*selected_user.problems.pop(removedItem);
    problems = selected_user.problems.map(obj => {
      return ({'label':obj.problem_type ? obj.problem_type : obj.label , 'value':obj.problem_type ? obj.problem_type : obj.value})
    });*/
    //console.log("hello remove", selected_user.managers, managers);
    problems = selectedList;
    return problems;
  };

  return (
    <>
      {permissionsOverlay && (
        <UserAuthorizationsOverlay
          {...props}
          selected_user={selected_user}
          toggleEditOverlay={() => managePermissions(false)}
        />
      )}

      <div className={"ui-overlay" + (open ? " open" : "")}>
        <div className={"ui-overlay-content anomalie-history col-md-6"}>
          <button
            onClick={toggleEditOverlay}
            className={"ui-overlay-close btn btn-danger btn-sm"}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <h5>Modifier le service</h5>

          <form onSubmit={submitSiteForm}>
            {open && (
              <>
                {/* <input type="hidden" name="userid" value={this.props.user.userid} /> */}

                {/*role === "manager" && props.user.role === "admin" && (
                  <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                    <label className={"font-exp"}>Cluster Manager</label>
                    <Select
                      name="cluster_manager"
                      classNamePrefix={"custom-select"}
                      options={props.filters.cluster_managers}
                      defaultValue={{
                        label: selected_user.cluster_manager_username,
                        value: selected_user.cluster_manager_id,
                      }}
                      className={"col-md-8"}
                    />
                  </div>
                )*/}

                {(props.user.role === "admin" ||
                  props.user.role === "cluster_manager") && (
                  <div className='form-group custom-inline-form form-inline mt-3 no-gutters'>
                    <label className={"font-exp"}>Cluster Manager</label>
                    <Select
                      name='cluster_manager'
                      classNamePrefix={"custom-select"}
                      options={props.filters.cluster_managers}
                      defaultValue={{
                        label: selected_user.cluster_manager_username,
                        value: selected_user.cluster_manager_id,
                      }}
                      className={"col-md-8"}
                    />
                  </div>
                )}

                {/*role === "manager" && (
                  <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                    <label className={"font-exp"}>Matricule</label>
                    <input
                      type="text"
                      name="matricule"
                      defaultValue={selected_user.matricule}
                      className={"col-md-8 form-control"}
                    />
                  </div>
                )*/}

                {/*role === "cluster_manager" && (
                  <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                    <label className={"font-exp"}>Cluster</label>
                    <Select
                      name="cluster"
                      classNamePrefix={"custom-select"}
                      options={props.filters.clusters}
                      defaultValue={{
                        label: selected_user.cluster,
                        value: selected_user.cluster,
                      }}
                      className={"col-md-8"}
                    />
                  </div>
                )*/}

                {/*role === "manager" && (
                  <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                    <label className={"font-exp"}>Type</label>
                    <Select
                      name="account_type"
                      classNamePrefix={"custom-select"}
                      options={props.filters.site_owners}
                      defaultValue={{
                        label: selected_user.account_type,
                        value: selected_user.account_type,
                      }}
                      className={"col-md-8"}
                    />
                  </div>
                )*/}

                {/*<Restricted role={"admin"}>
                  <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                    <label className={"font-exp"}>Access authorization</label>
                    <button
                      type="button"
                      onClick={e => managePermissions(e)}
                      className={"cbtn btn btn-sm btn-primary"}
                    >
                      Gérer
                    </button>
                  </div>
              </Restricted>*/}

                <div className='form-group custom-inline-form form-inline mt-3'>
                  <label className={"font-exp"}>Managers</label>
                  <Multiselect
                    options={props.filters.managers} // Options to display in the dropdown
                    selectedValues={managers} // Preselected value to persist in dropdown
                    onSelect={onSelectManager} // Function will trigger on select event
                    onRemove={onRemoveManager} // Function will trigger on remove event
                    displayValue='label' // Property name to display in the dropdown options
                    required
                    name='managers'
                  />
                </div>

                <div className='form-group custom-inline-form form-inline mt-3'>
                  <label className={"font-exp"}>Nom</label>
                  <input
                    type='text'
                    //disabled={true}
                    name='name'
                    className={"form-control col-md-8"}
                    defaultValue={selected_user.name}
                  />
                </div>

                <div className='form-group custom-inline-form form-inline mt-3'>
                  <label className={"font-exp"}>Email</label>
                  <input
                    type='text'
                    //disabled={true}
                    name='email'
                    className={"form-control col-md-8"}
                    defaultValue={selected_user.email}
                  />
                </div>

                {/*<div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Task type</label>
                  <Select
                    name="task_type"
                    classNamePrefix={"custom-select"}
                    options={props.filters.task_types}
                    defaultValue={{
                      label: selected_user.task_type,
                      value: selected_user.task_type,
                    }}
                    className={"col-md-8"}
                  />
                  </div>*/}

                {/* <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Type de problème</label>
                  <Select
                    name="alarm"
                    classNamePrefix={"custom-select"}
                    options={props.filters.problem_types}
                    defaultValue={{
                      label: selected_user.alarm,
                      value: selected_user.alarm,
                    }}
                    className={"col-md-8"}
                  />
                  </div> */}

                <div className='form-group custom-inline-form form-inline mt-3'>
                  <label className={"font-exp"}>Catégories d'alarmes</label>
                  <Multiselect
                    options={props.filters.cm_category} // Options to display in the dropdown
                    selectedValues={problems} // Preselected value to persist in dropdown
                    onSelect={onSelectProblem} // Function will trigger on select event
                    onRemove={onRemoveProblem} // Function will trigger on remove event
                    displayValue='label' // Property name to display in the dropdown options
                    required
                    name='problems'
                  />
                </div>

                {/*<div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Anomalie type</label>
                  <Select
                    name="anomalie_type"
                    classNamePrefix={"custom-select"}
                    options={props.filters.anomalies_types}
                    defaultValue={{
                      label: selected_user.anomalie_type,
                      value: selected_user.anomalie_type,
                    }}
                    className={"col-md-8"}
                  />
                  </div>*/}

                {/*<div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Membres</label>
                  <Multiselect
      							options={props.filters.managers} // Options to display in the dropdown
      							//selectedValues={selected_user.managers} // Preselected value to persist in dropdown
      							onSelect={e => onSelectManager(e)} // Function will trigger on select event
      							onRemove={e => onRemoveManager(e)} // Function will trigger on remove event
      							displayValue="label" // Property name to display in the dropdown options
      							required
                    className={"col-md-8"}
      						/>
                </div>*/}

                <div className='form-group custom-inline-form form-inline mt-3'>
                  <label className={"font-exp"}>Cluster</label>
                  <Select
                    name='cluster'
                    classNamePrefix={"custom-select"}
                    options={props.filters.clusters}
                    defaultValue={{
                      label: selected_user.cluster,
                      value: selected_user.cluster,
                    }}
                    className={"col-md-8"}
                  />
                </div>

                <div className='form-group custom-inline-form form-inline mt-3'>
                  <div className='custom-control custom-checkbox'>
                    <label className={"font-exp"}>Liste d'escalade Web</label>
                    <input
                      name='web_escalation'
                      type='checkbox'
                      value={web_escalation}
                      defaultChecked={web_escalation}
                      onChange={(e) => {
                        web_escalation = e.target.checked;
                      }}
                    />
                  </div>

                  <div className='custom-control custom-checkbox'>
                    <label className={"font-exp"}>
                      Liste d'escalade Mobile
                    </label>
                    <input
                      name='mobile_escalation'
                      type='checkbox'
                      value={mobile_escalation}
                      defaultChecked={mobile_escalation}
                      onChange={(e) => {
                        mobile_escalation = e.target.checked;
                      }}
                    />
                  </div>

                  <div className='custom-control custom-checkbox'>
                    <label className={"font-exp"}>Service Externe</label>
                    <input
                      name='external'
                      type='checkbox'
                      value={external}
                      defaultChecked={external}
                      onChange={(e) => {
                        external = e.target.checked;
                      }}
                    />
                  </div>
                </div>

                {/*<p>
                  <i>
                    Laissez le champ ci-dessus vide pour ne pas changer le mot
                    de passe
                  </i>
                </p>
                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Mot de passe</label>
                  <input
                    type="password"
                    name="password"
                    className={"form-control col-md-8"}
                  />
                </div>*/}

                <div className='form-group custom-inline-form form-inline mt-3 no-gutters'>
                  <input
                    type='submit'
                    className={"btn btn-primary col-md-12"}
                    value='Valider les modifications'
                  />
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default ServiceEditOverlay;
