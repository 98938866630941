import React from "react";
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

const ShowCMOverlay = ({open, task, close, reload, ...props}) => {
  
  //console.log("hello task", task, props.filters);

    return (
      <div className={"ui-overlay " + (open ? " open" : "")}>
        <div className={"ui-overlay-content site-overlay col-md-5"}>
          <button
            data-package-id="0"
            onClick={(e) => {
              close(e);
            }}
            className={"ui-overlay-close btn btn-danger btn-sm"}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <h5 className={"mb-3"}>Détails CM</h5>

          <form>
            {open && (
              <>
                <input
                  type="hidden"
                  name="site_plan_id"
                  value={task.key}
                />

                <input
                  type="hidden"
                  name="task_id"
                  value={task.task_id}
                />

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Numéro Ticket</label>
                  <input
                    type="text"
                    name="ticket_ref"
                    className={"form-control col-md-8"}
                    value={task.ticket_ref}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Tags</label>
                  <textarea
                    rows={2}
                    name="cm_tag"
                    className={"form-control col-md-8"}
                    value={task.cm_tag}
                    disabled
                  />
                </div>

                {/*<div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Dates d'ajout tag</label>
                  <textarea
                    rows={2}
                    name="cm_tag_created_at"
                    className={"form-control col-md-8"}
                    value={task.cm_tag_created_at}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Problème tag</label>
                  <textarea
                    rows={2}
                    name="cm_tag_problem"
                    className={"form-control col-md-8"}
                    value={task.cm_tag_problem}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Action tag</label>
                  <textarea
                    rows={2}
                    name="cm_tag_action"
                    className={"form-control col-md-8"}
                    value={task.cm_tag_action}
                    disabled
                  />
                </div>*/}

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Process Actuel</label>
                  <input
                    type="text"
                    name="cm_last_process"
                    className={"form-control col-md-8"}
                    value={task.cm_last_process}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Etat Actuel</label>
                  <input
                    type="text"
                    name="cm_last_status"
                    className={"form-control col-md-8"}
                    value={task.cm_last_status}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Ticket Parent</label>
                  <input
                    type="text"
                    name="cm_parent_ref"
                    className={"form-control col-md-8"}
                    value={task.cm_parent_ref}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Nom Site</label>
                  <input
                    type="text"
                    name="task_site_name"
                    className={"form-control col-md-8"}
                    value={task.task_site_name}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Code Site Physique</label>
                  <input
                    type="text"
                    name="task_physic_code"
                    className={"form-control col-md-8"}
                    value={task.task_physic_code}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Code Site Logique</label>
                  <input
                    type="text"
                    name="task_site_logique_code"
                    className={"form-control col-md-8"}
                    value={task.task_site_logique_code}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Type Site Logique</label>
                  <input
                    type="text"
                    name="task_site_logique_type"
                    className={"form-control col-md-8"}
                    value={task.task_site_logique_type}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Owner</label>
                  <input
                    type="text"
                    name="owner"
                    className={"form-control col-md-8"}
                    value={task.owner}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Catégorie</label>
                  <input
                    type="text"
                    name="cm_category"
                    className={"form-control col-md-8"}
                    value={task.cm_category}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Alarme</label>
                  <input
                    type="text"
                    name="cm_code"
                    className={"form-control col-md-8"}
                    value={task.cm_code}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Type de problème</label>
                  <input
                    type="text"
                    name="cm_problem_type"
                    className={"form-control col-md-8"}
                    value={task.cm_problem_type}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Priorité</label>
                  <input
                    type="text"
                    name="cm_priority"
                    className={"form-control col-md-8"}
                    value={task.cm_priority}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Détails Problème</label>
                  <textarea
                    rows={5}
                    name="comment"
                    className={"form-control col-md-8"}
                    value={task.comment}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Date d'alarme</label>
                  <input
                    type="text"
                    name="cm_start_date"
                    className={"form-control col-md-8"}
                    value={task.cm_start_date}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Date d'alerting</label>
                  <input
                    type="text"
                    name="task_created"
                    className={"form-control col-md-8"}
                    value={task.task_created}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Date fin</label>
                  <input
                    type="text"
                    name="cm_end_date"
                    className={"form-control col-md-8"}
                    value={task.cm_end_date}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Solved By</label>
                  <input
                    type="text"
                    name="solved_by"
                    className={"form-control col-md-8"}
                    value={task.solved_by}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Sites Impactés</label>
                  <input
                    type="text"
                    name="cm_impacted_site_list"
                    className={"form-control col-md-8"}
                    value={task.cm_impacted_site_list}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Secteurs Impactés</label>
                  <input
                    type="text"
                    name="cm_impacted_2g_sectors"
                    className={"form-control col-md-8"}
                    value={task.cm_impacted_2g_sectors}
                    disabled
                  />
                </div>

              </>
            )}
          </form>
        </div>
      </div>
    );
  
}

export default ShowCMOverlay;
