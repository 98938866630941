import React, { useState, useRef, Component, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faFileExport, faChevronDown, faTrash, faAngleRight, faCheck, faLock, faSpinner, faHourglass, faCalendarDay, faWindowClose, faPlus, faEdit, faCheckCircle, faArrowUp, faArrowDown, faEye} from '@fortawesome/free-solid-svg-icons';
import MoreInfoDialog, { Item } from '../../../Utils/MoreInfoDialog';
import moment from 'moment';
import CCTests from './Tests';
import CheckOrTime from '../components/CheckOrTime';
import Restricted from '../../../Utils/Restricted';
import RenderComponent from '../../../Utils/RenderComponent';
import Constants from '../../../Utils/Constants';
import ApiService from '../../../Utils/ApiService';
import { useSelector } from 'react-redux';
import Select from 'react-select';


const CCDeletedTaskRow = ({task, key, exportCC, editTask, showTask, startTask, restartTask, finishTask, closeTask, mine, user, filters, 
  treatTask, changeStatusTask, waitBudgetTask, deleteTask, ForceIdpTask, ...props}) => {

    var array = Array.from(CCTests);

    //console.log("hello", task);

    const {session} = useSelector(state => ({session: state.session}));

    const [activeTest, selectTest] = useState("cc_clockin")
    const [showDetail, setShowDetail] = useState(false);
    const [showStatus, setShowStatus] = useState(false);

    var cc_history_id = [];
    var cc_history_src_service_name = [];
    var cc_history_reception_date = [];
    var cc_history_dst_service_name = [];
    var cc_history_action_date = [];
    var cc_history_action = [];
    var cc_history_action_details = [];
    var cc_history_comment = [];
    var cc_history_src_username = [];
    var cc_history_cm_id = [];
    var cc_history_wo_id = [];
    var cc_history_site_code = [];
    var cc_history_cell = [];
    var cc_history_gps_x = [];
    var cc_history_gps_y = [];
    var cc_history_log_files = [];
    var cc_history_attachment = [];

    if(task.cc_history_id) {
      cc_history_id = task.cc_history_id.split(",");
    }
    if(task.cc_history_src_service_name) {
      cc_history_src_service_name = task.cc_history_src_service_name.split(",");
    }
    if(task.cc_history_reception_date) {
      cc_history_reception_date = task.cc_history_reception_date.split(",");
    }
    if(task.cc_history_dst_service_name) {
      cc_history_dst_service_name = task.cc_history_dst_service_name.split(",");
    }
    if(task.cc_history_action_date) {
      cc_history_action_date = task.cc_history_action_date.split(",");
    }
    if(task.cc_history_action) {
      cc_history_action = task.cc_history_action.split(",");
    }
    if(task.cc_history_action_details) {
      cc_history_action_details = task.cc_history_action_details.split(",");
    }
    if(task.cc_history_comment) {
      cc_history_comment = task.cc_history_comment.split(",");
    }
    if(task.cc_history_src_username) {
      cc_history_src_username = task.cc_history_src_username.split(",");
    }
    if(task.cc_history_cm_id) {
      cc_history_cm_id = task.cc_history_cm_id.split(",");
    }
    if(task.cc_history_wo_id) {
      cc_history_wo_id = task.cc_history_wo_id.split(",");
    }
    if(task.cc_history_site_code) {
      cc_history_site_code = task.cc_history_site_code.split(",");
    }
    if(task.cc_history_cell) {
      cc_history_cell = task.cc_history_cell.split(",");
    }
    if(task.cc_history_gps_x) {
      cc_history_gps_x = task.cc_history_gps_x.split(",");
    }
    if(task.cc_history_gps_y) {
      cc_history_gps_y = task.cc_history_gps_y.split(",");
    }
    if(task.cc_history_log_files) {
      cc_history_log_files = task.cc_history_log_files.split(",");
    }
    if(task.cc_history_attachment) {
      cc_history_attachment = task.cc_history_attachment.split(",");
    }

    var history = [];
    for(var i=0; i<cc_history_id.length; i++) {
      var obj = {
        cc_history_id: '-',
        cc_history_src_service_name: '-',
        cc_history_reception_date: '----/--/-- --:--:--',
        cc_history_dst_service_name: '-',
        cc_history_action_date: '----/--/-- --:--:--',
        cc_history_action: '-',
        cc_history_action_details: '-',
        cc_history_comment: '-',
        cc_history_src_username: '-',
        cc_history_cm_id: '-',
        cc_history_wo_id: '-',
        cc_history_site_code: '-',
        cc_history_cell: '-',
        cc_history_gps_x: '-',
        cc_history_gps_y: '-',
        cc_history_log_files: '-',
        cc_history_attachment: '-',
      }

      if(cc_history_id[i]){
        obj['cc_history_id'] = cc_history_id[i];
      }
      if(cc_history_src_service_name[i]){
        obj['cc_history_src_service_name'] = cc_history_src_service_name[i];
      }
      if(cc_history_reception_date[i]){
        obj['cc_history_reception_date'] = cc_history_reception_date[i];
      }
      if(cc_history_dst_service_name[i]){
        obj['cc_history_dst_service_name'] = cc_history_dst_service_name[i];
      }
      if(cc_history_action_date[i]){
        obj['cc_history_action_date'] = cc_history_action_date[i];
      }
      if(cc_history_action[i]){
        obj['cc_history_action'] = cc_history_action[i];
      }
      if(cc_history_action_details[i]){
        obj['cc_history_action_details'] = cc_history_action_details[i];
      }
      if(cc_history_comment[i]){
        obj['cc_history_comment'] = cc_history_comment[i];
      }
      if(cc_history_src_username[i]){
        obj['cc_history_src_username'] = cc_history_src_username[i];
      }
      if(cc_history_cm_id[i]){
        obj['cc_history_cm_id'] = cc_history_cm_id[i];
      }
      if(cc_history_wo_id[i]){
        obj['cc_history_wo_id'] = cc_history_wo_id[i];
      }
      if(cc_history_site_code[i]){
        obj['cc_history_site_code'] = cc_history_site_code[i];
      }
      if(cc_history_cell[i]){
        obj['cc_history_cell'] = cc_history_cell[i];
      }
      if(cc_history_gps_x[i]){
        obj['cc_history_gps_x'] = cc_history_gps_x[i];
      }
      if(cc_history_gps_y[i]){
        obj['cc_history_gps_y'] = cc_history_gps_y[i];
      }
      if(cc_history_log_files[i]){
        obj['cc_history_log_files'] = cc_history_log_files[i];
      }
      if(cc_history_attachment[i]){
        obj['cc_history_attachment'] = cc_history_attachment[i];
      }
      history[i] = obj;
    }

    var arr = [];
    for (var j = 0; j < history.length; j++) {
      arr.push(false);
    }

    const [editing, setEditing] = useState([]);
    useEffect(() => {
      setEditing(arr);
    }, []);

    var now = new Date();

    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);
    const exportForm = useRef(null);
    const detailInput = useRef(null);
  
    if(history.length > 0) {

      return (<React.Fragment>
        
        <form method="post" ref={exportForm} target={"_blank"} >
          <input type="hidden" name={"pm"} ref={detailInput} />
          <input type="hidden" name={"token"} value={session.user.token} />
        </form>
        
        <div className={"row "}>
          <div className={"col-md-12"}>
            <div className={"row ctable-row"}>

              <div className={"col-md-1 table-center"} >     
                <span className={"center"} >
                  <a title='Détails' onClick={() => showTask(task)} href={"javascript:void(0)"} style={{color: "inherit"}} className={"site-link"}>{task.key ? task.key : '-'}</a>
                </span> 
                <span className={"center"} >
                  <a title='Détails' onClick={() => showTask(task)} href={"javascript:void(0)"} style={{color: "inherit"}} className={"site-link"}>{task.cc_status ? task.cc_status : '-'}</a>
                </span>
                <span className={"center"} >
                  {
                    task.cc_status === "Open"
                    ?
                    <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faSpinner} />
                    :
                    task.cc_status === "Solved"
                    ?
                    <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faCheck} />
                    :
                    task.cc_status === "Closed"
                    ?
                    <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faCheck} />
                    :
                    task.cc_status === "Canceled"
                    ?
                    <FontAwesomeIcon color="red" icon={faCheck} />
                    :
                    task.cc_status === "Deleted"
                    ?
                    <FontAwesomeIcon color="red" icon={faTrash} />
                    :
                    <FontAwesomeIcon color="grey" icon={faHourglass} />
                  }
                </span>
              </div>

              <div className={"col-md-2 table-center"}>
                {task.created_at ? moment(task.created_at).format("YYYY-MM-DD HH:mm") : ''}
              </div>

              <div className={"col-md-2 table-center"}>
                {task.details ? task.details : '-'}
              </div>

              <div className={"col-md-2 table-center"}>
                {task.deleted_date ? moment(task.deleted_date).format("YYYY-MM-DD HH:mm") : ''}
              </div>

              <div className={"col-md-2 table-center"}>
                {task.deleted_cause ? task.deleted_cause : '-'}
              </div>

              <div className={"col-md-2 table-center"}>
                {task.deleted_by_username ? task.deleted_by_username : '-'}
              </div>
              
              <div className={"col-md-1 task-details-col"}>
                <span>
                  <button onClick={() => setShowStatus(!showStatus)} className="task-action-btn" ><FontAwesomeIcon icon={faChevronDown}/> Hist</button>
                  {/*<button onClick={() => setShowDetail(!showDetail)} className="task-action-btn" ><FontAwesomeIcon icon={faChevronDown}/> Info</button>*/}
                </span>

                <MoreInfoDialog title={"more"}>
                  {/*
                    history[history.length-1] 
                    && task.assigned_to_me == "1"
                    && user.role == "service"
                    && task.cc_status == "Open"
                    && task.idp_exist == "0"
                    && task.deleted == "0"
                    ?
                    <Item onClick={(e) => treatTask(task)}><FontAwesomeIcon className={"dialog-icon danger"} icon={faHourglass}/>Treater</Item>
                    :
                    null
                  */}
                  {/*
                    history[history.length-1] 
                    && task.is_tcm == "1"
                    && user.role == "service"
                    && task.cc_status == "Open"
                    && task.deleted == "0"
                    && task.idp_exist == "0"
                    && task.assigned_to_me == "1"
                    ?
                    <Item onClick={(e) => changeStatusTask(task)}><FontAwesomeIcon className={"dialog-icon danger"} icon={faCheck}/>Changer status</Item>
                    :
                    null
                  */}
                  {/*
                    history[history.length-1] 
                    && task.is_tcm == "1"
                    && user.role == "service"
                    && task.cc_status == "Open"
                    && task.wait_budget == "0"
                    && task.deleted == "0"
                    ?
                    <Item onClick={(e) => waitBudgetTask(task)}><FontAwesomeIcon className={"dialog-icon danger"} icon={faArrowUp}/>Déplacer vers wait budget</Item>
                    :
                    null
                  */}
                  {/*
                    history[history.length-1] 
                    && task.is_tcm == "1"
                    && user.role == "service"
                    && task.cc_status == "Open"
                    && task.wait_budget == "1"
                    && task.deleted == "0"
                    ?
                    <Item onClick={(e) => waitBudgetTask(task)}><FontAwesomeIcon className={"dialog-icon danger"} icon={faArrowDown}/>Retirer du wait budget</Item>
                    :
                    null
                  */}
                  {/*
                    history[history.length-1] 
                    && task.is_tcm == "1"
                    && user.role == "service"
                    && task.cc_status == "Open"
                    && task.deleted == "0"
                    && task.idp_exist == "0"
                    && task.assigned_to_me == "1"
                    ?
                    <Item onClick={(e) => ForceIdpTask(task)}><FontAwesomeIcon className={"dialog-icon danger"} icon={faUndo}/>Forcer ID parent</Item>
                    :
                    null
                  */}
                  {
                    <Item onClick={(e) => showTask(task)}><FontAwesomeIcon className={"dialog-icon danger"} icon={faPlus}/>Détails</Item>
                  }
                  {/*
                    history[history.length-1] 
                    && task.is_tcm == "1"
                    && user.role == "service"
                    && task.deleted == "0"
                    ?
                    <Item onClick={(e) => deleteTask(task)}><FontAwesomeIcon className={"dialog-icon danger"} icon={faTrash}/>Supprimer</Item>
                    :
                    null
                  */}
                </MoreInfoDialog>
              </div>
            </div>

          {
            !!showStatus && (
              <div className={"animateRowCM row"}>
                <div className={"pm-wrapper col-md-12 row no-gutters"}>

                  <div className={"col-md-12"}>
                    <div className={"row "}>
                      <div className={"col-md-12"}>
                        <div className={"row history-table-row"}>
                          <div className={"col-md-1"}>ID</div>
                          <div className={"col-md-1"}>ID ticket</div>
                          <div className={"col-md-1"}>Service source</div>
                          <div className={"col-md-1"}>Date réception</div>
                          <div className={"col-md-1"}>Service destination</div>
                          <div className={"col-md-1"}>Date action</div>
                          <div className={"col-md-1"}>Action / Détails action</div>
                          <div className={"col-md-1"}>Commentaire</div>
                          <div className={"col-md-1"}>Documents attachés / Fichiers logs</div>
                          <div className={"col-md-1"}>ID WO / ID CM</div>
                          <div className={"col-md-1"}>Code site / GPS</div>
                          <div className={"col-md-1"}>Traité par</div>
                        </div>
                      </div>
                    </div>

                    {
                      history.map((h, index) => ( 
                        <div className={"row "}>
                          <div className={"col-md-12"}>
                            <div className={"row history-table-row"}>
                            <div className={"col-md-1"}>
                                {h['cc_history_id'] ? index+1 : '-'}
                              </div>
                              <div className={"col-md-1"}>
                                {task.key}
                              </div>
                              <div className={"col-md-1"}>
                                {h['cc_history_src_service_name'] != 0 ? h['cc_history_src_service_name'] : '-'}
                              </div>
                              <div className={"col-md-1"}>
                                {h['cc_history_reception_date'] != 0 ? h['cc_history_reception_date'] : '----/--/-- --:--:--'}
                              </div>
                              <div className={"col-md-1"}>
                                {h['cc_history_dst_service_name'] != 0 ? h['cc_history_dst_service_name'] : '-'}
                              </div>
                              <div className={"col-md-1"}>
                                {h['cc_history_action_date'] != 0 ? h['cc_history_action_date'] : '----/--/-- --:--:--'} 
                              </div>
                              <div className={"col-md-1"}>
                                <span>{h['cc_history_action'] != 0 ? h['cc_history_action'] : '-'}</span>
                                <span>{h['cc_history_action_details'] != 0 ? h['cc_history_action_details'] : '-'}</span>
                              </div>
                              <div className={"col-md-1"}>
                                {h['cc_history_comment'] != 0 ? h['cc_history_comment'] : '-'} 
                              </div>
                              <div className={"col-md-1"}>
                                <span>
                                  {
                                    h['cc_history_attachment'] != 0
                                    ?
                                    <a target={"_blank"} href={Constants.apiURL+"/"+h['cc_history_attachment']} className={"task-action-btn"}>
                                      {h['cc_history_attachment'].replace("upload/", "")}
                                    </a>
                                    :
                                    '-'
                                  }
                                </span>
                                <span>
                                  {
                                    h['cc_history_log_files'] != 0
                                    ?
                                    <a target={"_blank"} href={Constants.apiURL+"/"+h['cc_history_log_files']} className={"task-action-btn"}>
                                      {h['cc_history_log_files'].replace("upload/", "")}
                                    </a>
                                    :
                                    '-'
                                  }
                                </span>
                              </div>
                              <div className={"col-md-1"}>
                                <span>{h['cc_history_cm_id'] != 0 ? h['cc_history_cm_id'] : '-'}</span>
                                <span>{h['cc_history_wo_id'] != 0 ? h['cc_history_wo_id'] : '-'}</span>
                              </div>
                              <div className={"col-md-1"}>
                                <span>{h['cc_history_site_code'] != 0 ? h['cc_history_site_code'] : '-'}</span>
                                <span>{h['cc_history_gps_x'] != 0 ? h['cc_history_gps_x'] : '-'}</span>
                                <span>{h['cc_history_gps_y'] != 0 ? h['cc_history_gps_y'] : '-'}</span>
                              </div>
                              <div className={"col-md-1"}>
                                {h['cc_history_src_username'] != 0 ? h['cc_history_src_username'] : '-'} 
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            )
          }

          {/*
            !!showDetail && (
              <div className={"animateRow row"}>
                <div className={"pm-wrapper col-md-12 row no-gutters"}>
                  <div className={"col-md-2 pm-sidebar"}>
                    <ul>
                      {
                        task.cc_code == 'Transfert de Sites'
                        ?
                        CCTests.map(test => (
                          <li className={activeTest === test.key ? "active" : ""}>
                            <a onClick={e => selectTest(test.key)}>
                              <CheckOrTime task={task.task_tests[test.key]} />
                              {test.name} 
                              <FontAwesomeIcon icon={faAngleRight} size={22} />
                            </a>
                          </li>
                        ))
                        :
                        array.map(test => (
                          <li className={activeTest === test.key ? "active" : ""}>
                              <a onClick={e => selectTest(test.key)}>
                                <CheckOrTime task={task.task_tests[test.key]} />
                                {test.name} 
                                <FontAwesomeIcon icon={faAngleRight} size={22} />
                              </a>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                  <div className={"col-md-10 pm-content"}>
                    {
                      CCTests.map(test => task.task_tests[test.key] && activeTest === test.key && (
                        <div className={"pm-test-single"}>
                          <h5>{test.name} {task.task_tests[test.key].data.finished_at ? <b>{moment(task.task_tests[test.key].data.finished_at).format('YYYY-MM-DD HH:mm').toString()} <br /></b> : ""}</h5>
                          <RenderComponent component={test.component} data={task.task_tests[test.key].data} />
                          {task.task_tests[test.key].data.otherAgents ? <>Autres agents: <b>{ task.task_tests[test.key].data.otherAgents} <br /></b> </>: ""}
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            )
          */}
        </div>
      </div>
    </React.Fragment>)

    } else {
      return null;
    }
}

export default CCDeletedTaskRow;
