import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import api from "../../Utils/Api";
import { useSelector } from "react-redux";

const UserAuthorizationsOverlay = ({
  selected_user,
  toggleEditOverlay,
  open = true,
  role,
  ...props
}) => {

  const [permissions, setPermissions] = useState({});

  //console.log("hello", props.filters.cc_enabled);


  useEffect(() => {
    api.get('/api/user/permissions/' + selected_user.userid)
    .then(res => {
      setPermissions(res.data.data);
    })
  }, [])

  const submitForm = (e) => {
    e.preventDefault();

    props.isLoading({
      message: "Mise à jour en cours",
      animated: true,
    });


    const formdata = new FormData(e.target);

    toggleEditOverlay();

    api.post("/api/user/permissions/" + selected_user.userid, formdata).then((res) => {
      props.reload();
      props.doneLoading();
    });
  };

  const {filters} = useSelector(store => store);

  const selectFields = exp => {
    
    const fields = document.querySelectorAll(`[id*='${exp}']`);
    const nextState = !fields[0].checked;
    Array.from(fields).forEach(item => item.checked = nextState);

  }

  return (
    <div className={"ui-overlay" + (open ? " open" : "")}>
      <div className={"ui-overlay-content custom-export col-md-8"}>
        <button
          onClick={toggleEditOverlay}
          className={"ui-overlay-close btn btn-danger btn-sm"}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <h5>Modifier les authorisations</h5>
        <form method="post" onSubmit={submitForm}>

        <div className={"table-responsive"}>
          <table className={"table"}>
            <thead>
              <tr>
                <th>Permission</th>
                <th className={"text-center"}>CM</th>
                <th className={"text-center"}>PM</th>
                <th className={"text-center"}>GPM</th>
                {props.filters.cc_enabled == 'true' ? <th className={"text-center"}>CC</th> : null}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className={"text-center"}>Site</th>
                <th className={"text-center"} colSpan={2}><span className={"export-fields-select-all text-center"} onClick={e => selectFields("permission-field-pm-site")}>Select</span></th>
                <th className={"text-center"}><span className={"export-fields-select-all text-center"} onClick={e => selectFields("permission-field-gpm-site")}>Select</span></th>
                {props.filters.cc_enabled == 'true' ? <th className={"text-center"}><span className={"export-fields-select-all text-center"} onClick={e => selectFields("permission-field-cc-site")}>Select</span></th> : null}
              </tr>

              {Object.entries(filters.permissions.site).map(([key, item]) => (
                  <tr>
                    <td>
                        <label>{item}</label>
                    </td>
                    <td className={"text-center"} colSpan={2} >
                      <div className="custom-control custom-checkbox">
                        <input defaultChecked={permissions.pm && permissions.pm[key]} name={`pm[${key}]`} type="checkbox" value={key} className="custom-control-input" id={`permission-field-pm-site-${key}`} />
                        <label className="custom-control-label" htmlFor={`permission-field-pm-site-${key}`}></label>
                      </div>
                    </td>

                    <td className={"text-center"}>
                      <div className="custom-control custom-checkbox">
                        <input defaultChecked={permissions.gpm && permissions.gpm[key]} name={`gpm[${key}]`} type="checkbox" value={key} className="custom-control-input" id={`permission-field-gpm-site-${key}`} />
                        <label className="custom-control-label" htmlFor={`permission-field-gpm-site-${key}`}></label>
                      </div>
                    </td>

                    {props.filters.cc_enabled == 'true'
                    ? 
                    <td className={"text-center"}>
                      <div className="custom-control custom-checkbox">
                        <input defaultChecked={permissions.cc && permissions.cc[key]} name={`cc[${key}]`} type="checkbox" value={key} className="custom-control-input" id={`permission-field-cc-site-${key}`} />
                        <label className="custom-control-label" htmlFor={`permission-field-cc-site-${key}`}></label>
                      </div>
                    </td>
                    : 
                    null}

                  </tr>
              ))} 
              <tr>
                <th className={"text-center"}>Task</th>
                <th className={"text-center"}><span className={"export-fields-select-all text-center"}  onClick={e => selectFields("permission-field-cm-task")}>Select</span></th>
                <th className={"text-center"}><span className={"export-fields-select-all text-center"}  onClick={e => selectFields("permission-field-pm-task")}>Select</span></th>
                <th className={"text-center"}><span className={"export-fields-select-all text-center"}  onClick={e => selectFields("permission-field-gpm-task")}>Select</span></th>
                {props.filters.cc_enabled == 'true' ? <th className={"text-center"}><span className={"export-fields-select-all text-center"}  onClick={e => selectFields("permission-field-cc-task")}>Select</span></th> : null}
              </tr>

              {Object.entries(filters.permissions.task).map(([key, item]) => (
                  <tr>
                    <td>
                        <label>{item}</label>
                    </td>
                    <td className={"text-center"}>
                      <div className="custom-control custom-checkbox">
                        <input defaultChecked={permissions.cm && permissions.cm[key]} name={`cm[${key}]`} type="checkbox" value={key} className="custom-control-input" id={`permission-field-cm-task-${key}`} />
                        <label className="custom-control-label" htmlFor={`permission-field-cm-task-${key}`}></label>
                      </div>
                    </td>
                    <td className={"text-center"}>
                      <div className="custom-control custom-checkbox">
                        <input defaultChecked={permissions.pm && permissions.pm[key]} name={`pm[${key}]`} type="checkbox" value={key} className="custom-control-input" id={`permission-field-pm-task-${key}`} />
                        <label className="custom-control-label" htmlFor={`permission-field-pm-task-${key}`}></label>
                      </div>
                    </td>

                    <td className={"text-center"}>
                      <div className="custom-control custom-checkbox">
                        <input defaultChecked={permissions.gpm && permissions.gpm[key]} name={`gpm[${key}]`} type="checkbox" value={key} className="custom-control-input" id={`permission-field-gpm-task-${key}`} />
                        <label className="custom-control-label" htmlFor={`permission-field-gpm-task-${key}`}></label>
                      </div>
                    </td>

                    {props.filters.cc_enabled == 'true' 
                    ? 
                    <td className={"text-center"}>
                      <div className="custom-control custom-checkbox">
                        <input defaultChecked={permissions.cc && permissions.cc[key]} name={`cc[${key}]`} type="checkbox" value={key} className="custom-control-input" id={`permission-field-cc-task-${key}`} />
                        <label className="custom-control-label" htmlFor={`permission-field-cc-task-${key}`}></label>
                      </div>
                    </td>
                    : 
                    null}

                  </tr>
              ))} 

              <tr>
                <th className={"text-center"}>Cluster</th>
                <th className={"text-center"}><span className={"export-fields-select-all text-center"}  onClick={e => selectFields("permission-field-cm-cluster")}>Select</span></th>
                <th className={"text-center"}><span className={"export-fields-select-all text-center"}  onClick={e => selectFields("permission-field-pm-cluster")}>Select</span></th>
                <th className={"text-center"}><span className={"export-fields-select-all text-center"}  onClick={e => selectFields("permission-field-gpm-cluster")}>Select</span></th>
                {props.filters.cc_enabled == 'true' ? <th className={"text-center"}><span className={"export-fields-select-all text-center"}  onClick={e => selectFields("permission-field-cc-cluster")}>Select</span></th> : null}
              </tr>

              {filters.clusters.map((item, key) => (
                  <tr>

                    <td>
                        <label>{item.label}</label>
                    </td>
                    <td className={"text-center"}>
                      <div className="custom-control custom-checkbox">
                        <input defaultChecked={permissions.cm && permissions.cm.clusters && permissions.cm.clusters.indexOf(item.value) > -1} name={`cm[clusters][]`} type="checkbox" value={`${item.value}`} className="custom-control-input" id={`permission-field-cm-cluster-${item.value}`} />
                        <label className="custom-control-label" htmlFor={`permission-field-cm-cluster-${item.value}`}></label>
                      </div>
                    </td>
                    <td className={"text-center"}>
                      <div className="custom-control custom-checkbox">
                        <input defaultChecked={permissions.pm && permissions.pm.clusters && permissions.pm.clusters.indexOf(item.value) > -1} name={`pm[clusters][]`} type="checkbox" value={`${item.value}`} className="custom-control-input" id={`permission-field-pm-cluster-${item.value}`} />
                        <label className="custom-control-label" htmlFor={`permission-field-pm-cluster-${item.value}`}></label>
                      </div>
                    </td>

                    <td className={"text-center"}>
                      <div className="custom-control custom-checkbox">
                        <input defaultChecked={permissions.gpm && permissions.gpm.clusters && permissions.gpm.clusters.indexOf(item.value) > -1} name={`gpm[clusters][]`} type="checkbox" value={`${item.value}`} className="custom-control-input" id={`permission-field-gpm-cluster-${item.value}`} />
                        <label className="custom-control-label" htmlFor={`permission-field-gpm-cluster-${item.value}`}></label>
                      </div>
                    </td>

                    {props.filters.cc_enabled == 'true' 
                    ? 
                    <td className={"text-center"}>
                      <div className="custom-control custom-checkbox">
                        <input defaultChecked={permissions.cc && permissions.cc.clusters && permissions.cc.clusters.indexOf(item.value) > -1} name={`cc[clusters][]`} type="checkbox" value={`${item.value}`} className="custom-control-input" id={`permission-field-cc-cluster-${item.value}`} />
                        <label className="custom-control-label" htmlFor={`permission-field-cc-cluster-${item.value}`}></label>
                      </div>
                    </td>
                    : 
                    null}

                  </tr>
              ))} 


            </tbody>
          </table>
        </div>
        
          <button type="submit" className={"float-right btn btn-primary"}  >Envoyer</button>

        
        </form>

        


      </div>
    </div>
  );
};
export default UserAuthorizationsOverlay;