import React from "react";

const CoSite = ({ data, ...props }) => {
  return (
    <>
      <b>Sites : </b>
      <br /> <br />
      <div className={"table-responsive"}>
        <table className={"table"}>
          {data.sites &&
            data.sites.map((site, id) => (
              <tr>
                <td>{id + 1}</td>
                <td>{site}</td>
              </tr>
            ))}
        </table>
      </div>
      <br />
      <div className={"clear-fix mt-3"} />
    </>
  );
};

export default CoSite;
