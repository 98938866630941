import React from "react";
import RenderChecks from "../../components/RenderChecks";
import PhotoItem from "../../../../Utils/PhotoItem";

const siteTransferTypes = ["BTS", "BSC/SDH", "Transmission", "Micro"];
const visitDecisions = ["Site Accepté avec réserves", "Site rejeté", "Site Accepté Sans réserves"];

const SiteTransfer = ({ data, ...props }) => {
  return (
    <>
      <div className={"row col-md-6"}>
        <label>Date : </label>
        {data.date}
      </div>

      <div className={"row col-md-6"}>
        <label>Code site : </label>
        {data.site_code}
      </div>

      <div className={"row col-md-6"}>
        <label>Nom site : </label>
        {data.site_name}
      </div>

      <div className={"row col-md-6"}>
        <label>Addresse site : </label>
        {data.site_address}
      </div>

      <div className={"row col-md-6"}>
        <label>Nom sous-traitant : </label>
        {data.subcontractor_name}
      </div>

      <div className={"row col-md-6"}>
        <label>Type du site : </label>
        {siteTransferTypes[data.site_type]}
      </div>

      <div className={"clear-fix mt-3"} />

      <div className={"row col-md-6"}>
        <label>Designations Genérales : </label>
        {'Description des Réserves'}
      </div>

      <div className={"row col-md-6"}>
        <label>Accès et conditions d’acces au site : </label>
        {data.designation_one}
      </div>
      
      <div className={"row col-md-6"}>
        <label>Energie AC (380 V) (3PH+1N+1PE) : </label>
        {data.designation_two}
      </div>

      <div className={"row col-md-6"}>
        <label>Energie DC (-48 V) : </label>
        {data.designation_three}
      </div>

      <div className={"row col-md-6"}>
        <label>Air conditioning system : </label>
        {data.designation_four}
      </div>

      <div className={"row col-md-6"}>
        <label>Feeders et chemin du cable : </label>
        {data.designation_five}
      </div>

      <div className={"row col-md-6"}>
        <label>Terres et foudres : </label>
        {data.designation_six}
      </div>

      <div className={"row col-md-6"}>
        <label>Eléments du shelter & Tests : </label>
        {data.designation_seven}
      </div>

      <div className={"row col-md-6"}>
        <label>VSWR chaine radio : </label>
        {data.designation_eight}
      </div>

      <div className={"row col-md-6"}>
        <label>Etancheité (terrasse, shelter et equipements) : </label>
        {data.designation_nine}
      </div>
      
      <div className={"clear-fix mt-3"} />

      <RenderChecks data={data} />

      <div className={"clear-fix mt-3"} />

      <div className={"row col-md-6"}>
        <label>Décision : </label>
      </div>

      <div className={"row col-md-6"}>
        <label>1° Visite : </label>
        {visitDecisions[data.first_visit_decision]}
      </div>

      <div className={"row col-md-6"}>
        <label>2° Visite : </label>
        {visitDecisions[data.second_visit_decision]}
      </div>

      {data.photos && (
        <div className={"test-photos-wrapper"}>
          {data.photos.map((photo, key) => (
            <div className={"test-photos-single"}>
              <PhotoItem photo={photo} active={props.active} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SiteTransfer;
