import React from "react";
import PhotoItem from "../../../../Utils/PhotoItem";

const Sabotage = ({ data, ...props }) => {
  return (
    <>
      <div className={"table-responsive"}>
        <table className={"table"}>
          <thead>
            <th>Type de sabotage</th>
            <th>Observation</th>
            <th>Action</th>
          </thead>
          <tbody>
            {data.items &&
              data.items.map(item => (
                <>
                  <tr>
                    <td>{item.type.toUpperCase()}</td>
                    <td>{item.observation}</td>
                    <td>{item.action}</td>
                  </tr>
                </>
              ))}
          </tbody>
        </table>

        {data.photos &&
          data.photos.length &&
          data.photos.map((photo) => (
            <div className={"test-photos-single"}>
              <PhotoItem photo={photo} active={props.active} />
            </div>
          ))}
      </div>
    </>
  );
};

export default Sabotage;
