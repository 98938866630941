import React, { Component } from 'react';
import { connect } from "react-redux";
import { setFlash, closeFlash, setAuthAction, startLoading, doneLoading } from './../../Redux/actions/index';
import api from '../../Utils/Api';

function mapStateToProps(state) {
  return { 
  	auth: state.session.auth,
  	user: state.session.user
  };
};


function mapDispatchToProps(dispatch) {
  return {
    setAuth: user => dispatch(setAuthAction(user)),
    isLoading: e => dispatch(startLoading(e)),
    doneLoading: e => dispatch(doneLoading(e)),
    setFlash: (f) => dispatch(setFlash(f)),
    closeFlash: (f) => dispatch(closeFlash(f))
  };
}


 class AccountSecurity extends Component {

 	constructor(props) {
 		super(props);
 		
 		this.formSubmit = this.formSubmit.bind(this);
 		this.handleInput = this.handleInput.bind(this);
		 this.handlePassword = this.handlePassword.bind(this);
 		

 		this.state = {
 			
 				
 				old_password: "",
 				new_password: "",
				validPassword: false,
 				
 			
 		}

 	}


 	componentDidMount() {

 	}

	checkPassword(password) {
        //Regex to check if a string contains uppercase, lowercase special character & numeric value
        var pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$");

        if (!password || password.length < 10) {
			this.setState({validPassword: false});
          return false;
        }
 
        if (pattern.test(password)) {
			this.setState({validPassword: true});
        	return true;

        } else {
			this.setState({validPassword: false});
          	return false;
        }
    }


 	handlePassword(e) {
		
		let s = this.state;
		const event = Object.assign({}, e);
		const that = this;

		this.setState({...s}, function() {
			if(event.target.name === 'new_password') {
				if(that.checkPassword(event.target.value)) {
					that.setState({validPassword: true, [event.target.name]: event.target.value})
				} else {
					that.setState({validPassword: false})
				}
			} 
		});
 	}

	
	handleInput(e) {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

 	formSubmit(e) {

		if(this.state.validPassword) {
 		e.preventDefault();

 		this.props.isLoading();
 		
 		const data = {
 			user: this.props.user,
			form: this.state
 		}

 		let that = this;

 		api.post('/api/user/changepassword', data)
		.then(function(response) {

			if(response.data.success)
				that.props.setFlash({
					active: true,
					message: "Your password has been successfully changed.",
					classes: "success",
					style: {}
				})

			that.props.doneLoading();

		})

		}
 	}

	render() {
		return(

			

				<form onSubmit={this.formSubmit}>

				<h6>Change your password</h6>
				<br />

				<div className={"form-group"}>
				<label>Old password</label>
				<input onChange={this.handleInput} name="old_password" type="password" className={"col-md-4 form-control"} />
				</div>

				<div className={"form-group"}>
				<label>New password</label>
				<br/>
				<label className={"small-label"}>Le mot de passe doit comporter plus de 10 caractères, contenir des minuscules, des majuscules, au moins un chiffre et un caractère spécial</label>
				<input onChange={this.handlePassword} name="new_password" type="password" className={"col-md-4 form-control" + (!this.state.validPassword ? ' invalid' : '')}   />
				</div>
				<input type="submit" className={"col-md-4 form-control btn-primary btn"} value="Submit" />



				{/* <h6 className={"mt-3"}>Enable Two-step authentication</h6>
				<p>Secure your account by enabling <u>2FA</u> </p>
				<br />

				<h6>Account activity log</h6>
				<p>2 Active sessions</p>
				<br />

				<h6>API Key?</h6>
				<p>Connect your data with your favorite services.<br /></p> */}

				{/* <div className={"row"}>

					<div className={"col-md-6"}>

					


						<div className={"row mb-4 "}>
							<div className={"col-md-3"}>
							</div>
							<div className={"col-md-7"}>
							<br /><br />
								<input type="submit" className={"form-control btn-primary btn"} />
							</div>
						</div>

					</div>
					
				</div> */}
				</form>


			
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSecurity);