import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import api from '../../Utils/Api';
import { startLoading, doneLoading, setFlash } from '../../Redux/actions/index';
import FixRequiredSelect from "./../Task/CM/FixRequiredSelect";


const RequiredSelect = props => (
	<FixRequiredSelect
	  {...props}
	  SelectComponent={Select}
	  options={props.options}
	/>
);

const mapStateToProps = state => {
	return {
		user: state.session.user,
		filters: state.filters
	}
}

const mapDispatchToProps = dispatch => {
	return {
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: () => dispatch(doneLoading()),
  		flash: d => dispatch(setFlash(d)),
	}
}

class CMTaskNew extends Component {

  constructor(props) {

		super(props);

		this.state = {
			sites: [],
		}

		//load physic sites
		this.props.isLoading({
			message:"Chargement",
			animate:true
		});

		this.loadPhysicSites();
	}

  
  submitTask = (e) => {
    e.preventDefault();
    this.props.submitTask(e);
  }

  loadPhysicSites() {

		this.props.isLoading();

		api.get(`/api/physicsites/fetch?limit=-1`)
		.then((response) => {
			if (response.data.success) {
				//console.log("hello response", response.data.sites);
				this.setState({ sites: response.data.sites.map(site => ({label: site.site_code, value: site.site_id }))});
				this.props.doneLoading();
			} else {
			if (response.data.errcode === 101) {
				this.props.setAuth(false);
				this.props.doneLoading();
			}
			}
		})
		.catch((error) => {
			this.props.doneLoading();
		});
  }

  render() {
    return (
      <React.Fragment>
        <div className={"container-header"}>Nouveau site CM</div>

        <form onSubmit={this.submitTask}>
          <div className={"row"}>
            <div className={"col-md-6 taskForm"}>

              <div className={"form-group"}>
                <label className={"font-exp"}>Code site *</label>
                <input
                  type="text"
                  name="site_code"
                  className={"form-control"}
                  required
                />
              </div>

              <div className={"form-group"}>
                <label className={"font-exp"}>Nom du site *</label>
                <input
                  type="text"
                  name="site_name"
                  className={"form-control"}
                  required
                />
              </div>

			        <div className={"form-group"}>
                <label>Site physique *</label>
                <RequiredSelect
                  classNamePrefix={"custom-select"}
                  name="site_parent"
                  options={this.state.sites}
                  required
                />
              </div>

              <div className={"form-group"}>
                <label>Technologie</label>
                <Select
                  classNamePrefix={"custom-select"}
                  name="site_technology"
                  options={this.props.filters.cm_site_technology}
                />
              </div>

              <div className={"form-group"}>
                <label>Owner *</label>
                <Select
                  classNamePrefix={"custom-select"}
                  name="site_owner"
                  options={this.props.filters.cm_site_owners}
                />
              </div>

              <div className={"form-group"}>
                <label>Location owner</label>
                <Select
                  classNamePrefix={"custom-select"}
                  name="location_owner"
                  options={this.props.filters.location_owners}
                />
              </div>

              <div className={"form-group"}>
                <label>Catégorie</label>
                <Select
                  classNamePrefix={"custom-select"}
                  name="site_category"
                  options={this.props.filters.cm_site_categories}
                />
              </div>

			        <div className={"form-group"}>
                <label>X</label>
                <input
                  type="text"
                  name="site_x"
                  className={"form-control"}
                />
              </div>

              <div className={"form-group"}>
                <label>Y</label>
                <input
                  type="text"
                  name="site_y"
                  className={"form-control"}
                />
              </div>

              <div className={"form-group"}>
                <label>Address</label>
                <input
                  type="text"
                  name="site_address"
                  className={"form-control"}
                />
              </div>
              
              <div className={"form-group"}>
                <label>RT/GF</label>
                <Select
                  classNamePrefix={"custom-select"}
                  name="site_rt_gf"
                  options={this.props.filters.cm_rt_gf}
                />
              </div>

              <div className={"form-group"}>
                <label>Cabinet</label>
                <Select
                  classNamePrefix={"custom-select"}
                  name="site_cabinet"
                  options={this.props.filters.cm_site_cabinets}
                />
              </div>

              <div className={"form-group"}>
                <label>Commune</label>
                <input
                  type="text"
                  name="site_commune"
                  className={"form-control"}
                />
              </div>

              <div className={"form-group"}>
                <label>Nature</label>
                <Select
                  classNamePrefix={"custom-select"}
                  name="site_nature"
                  options={this.props.filters.cm_site_natures}
                />
              </div>

              <div className={"form-group"}>
                <label>Région</label>
                <Select
                  name="site_region"
                  options={this.props.filters.wilayas}
                />
              </div>

              <div className={"form-group"}>
                <label>Wilaya *</label>
                <RequiredSelect
                  name="site_wilaya"
                  options={this.props.filters.wilayas}
                  required
                />
              </div>

              <div className={"form-group"}>
                <label>Nombre de cellules</label>
                <Select
                  classNamePrefix={"custom-select"}
                  name="site_cell_number"
                  options={this.props.filters.cm_site_cell_numbers}
                />
              </div>

              <div className={"form-group"}>
                <label>BSC</label>
                <input
                  type="text"
                  name="site_bsc"
                  className={"form-control"}
                />
              </div>

              <div className={"form-group"}>
                <label>BSS Vendor</label>
                <Select
                  name="site_bss_vendor"
                  options={this.props.filters.cm_site_vendors}
                />
              </div>

              <div className={"form-group"}>
                <label>MW Vendor</label>
                <Select
                  name="site_mw_vendor"
                  options={this.props.filters.cm_site_vendors}
                />
              </div>

              <div className={"form-group"}>
                <label>Power</label>
                <input
                  type="text"
                  name="site_power"
                  className={"form-control"}
                />
              </div>

              <div className="form-group">
                  <label>Mise en air</label>
                  <input
                    name="site_airing"
                    type={"date"}
                    className={"form-control"}
                  />
                </div>

              <div className={"form-group"}>
                <label>Site keeper *</label>
                <RequiredSelect
                  classNamePrefix={"custom-select"}
                  name="site_team"
                  options={this.props.filters.teams}
                  required
                />
              </div>

              <div className={"form-group"}>
                <button
                  type="submit"
                  className={"btn btn-success float-right"}
                >
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

//export default connect((state) => ({ filters: state.filters }))(CMTaskNew);
export default connect(mapStateToProps, mapDispatchToProps)(CMTaskNew)
