import React from "react";
import ClockIn from "./ClockIn";
import IGeneral from "./IGeneral";
import Electricity from "./Electricity";
import Power from "./Power";
import Batteries from "./Batteries";
import Shelter from "./Shelter";
import AC from "./AC";
import BSS from "./BSS";
import PDH from "./PDH";
import Alarms from "./Alarms";
import GE from "./GE";
import Exterieur from "./Exterieur";
import SpareParts from "./SpareParts";
import Anomalies from "./Anomalies";
import Photos from "./Photos";
import Security from "./Security";
import CoSite from "./CoSite";
import Sabotage from "./Sabotage";


const PMTests = [
    {
        name: "Clock-in",
        key: "clockin",
        component: ClockIn,
    },
    {
        name: "Informations générales",
        key: "igeneral",
        component: IGeneral,
    },
    {
        name: "Electricié",
        key: "electricity",
        component: Electricity,
    },
    {
        name: "Power Supply",
        key: "power",
        component: Power,
    },
    {
        name: "AC",
        key: "ac",
        component: AC,
    },
    {
        name: "Batteries",
        key: "batteries",
        component: Batteries,
    },
    {
        name: "Shelter",
        key: "shelter",
        component: Shelter,
    },
    {
        name: "BSS",
        key: "bss",
        component: BSS,
    },
    {
        name: "PDH-PAV-Redline",
        key: "pdh",
        component: PDH,
    },
    {
        name: "GE",
        key: "ge",
        component: GE,
    },
    {
        name: "Exterieure",
        key: "exterieur",
        component: Exterieur,
    },
    {
        name: "Alarms",
        key: "alarms",
        component: Alarms,
    },
    {
        name: "Spare Parts",
        key: "spareparts",
        component: SpareParts,
    },
    {
        name: "Anomalies",
        key: "anomalies",
        component: Anomalies,
    },
    {
        name: "Photos",
        key: "photos",
        component: Photos,
    },
    {
        name: "Securisation",
        key: "security",
        component: Security,
    },
    {
        name: "Sites colocalisé",
        key: "cosite",
        component: CoSite,
    },
    {
        name: "Sabotage",
        key: "sabotage",
        component: Sabotage,
    },
    
]

export default PMTests;