import React from "react";
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

const RejectCMOverlay = ({ open, task, close, reload, ...props }) => {
  const submitSiteForm = (e) => {
    e.preventDefault();

    let that = this;

    props.isLoading({
      message: "Rejet de l'escalade en cours",
      animate: true,
    });

    const formdata = new FormData(e.target);

    let data = {};

    for (var [key, value] of formdata.entries()) data[key] = value;

    data["user"] = props.user;

    /*var data = {
			site_plan_id : siteplanid,
			//agent_id : agentid,
		};*/

    if (data["comment"] == "") {
      props.setFlash({
        active: true,
        message:
          "Veuillez inserer un commentaire, le commentaire est obligatoire!",
        classes: "warning",
        style: {},
      });
      props.doneLoading();
    } else {
      api
        .post("/api/cm/escalation/reject", data)
        .then((response) => {
          /*this.props.isLoading({
            message: "Envoi"
          });*/
          if (response.data.success) {
            props.doneLoading();
            props.setFlash({
              active: true,
              message: "L'agent est notifié.",
              classes: "success",
              style: {},
            });
          } else {
            props.setFlash({
              active: true,
              message: "Une erreur est survenue, Envoi echoué.",
              classes: "warning",
              style: {},
            });
          }
          close();
          props.doneLoading();
          reload();
        })
        .catch((err) => {
          alert(`Une erreur est survenue: ${err.message}`);
          props.doneLoading();
        });
    }
  };

  return (
    <div className={"ui-overlay " + (open ? " open" : "")}>
      <div className={"ui-overlay-content site-overlay col-md-5"}>
        <button
          data-package-id='0'
          onClick={(e) => {
            close(e);
          }}
          className={"ui-overlay-close btn btn-danger btn-sm"}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <h5 className={"mb-3"}>Rejet de l'escalade</h5>

        <form onSubmit={submitSiteForm}>
          {open && (
            <>
              <input
                type='hidden'
                name='site_plan_id'
                value={task.site_plan_id}
              />

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>Type de problème</label>
                <Select
                  name='problem_type'
                  classNamePrefix={"custom-select"}
                  defaultValue={{
                    value: task.cm_last_problem_type,
                    label: task.cm_last_problem_type,
                  }}
                  options={props.filters.problem_types}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form mt-3 no-gutters'>
                <label className={"font-exp"}>Commentaire</label>
                <textarea rows={5} name='comment' className={"form-control"} />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3 no-gutters'>
                <input
                  type='submit'
                  className={"btn btn-primary col-md-12"}
                  value='Rejeter escalade'
                />
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default RejectCMOverlay;
