import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import api from "../../Utils/Api";
import UserAuthorizationsOverlay from "./UserAuthorizationsOverlay";
import Restricted from "../../Utils/Restricted";
import { Multiselect } from 'multiselect-react-dropdown';


const TeamEditOverlay = ({
  selected_user,
  toggleEditOverlay,
  open,
  role,
  ...props
}) => {

  const [permissionsOverlay, setPermissionsOverlay] = useState(false);

  //console.log("hello all", selected_user);
  var agents = [];
  if (selected_user.agents) {
    agents = selected_user.agents.map(obj => {
      return ({'label':obj.agent_username , 'value':obj.agent_id})
    });
  }


  const submitSiteForm = (e) => {
    props.isLoading({
      message: "Mise à jour en cours",
      animated: true,
    });

    e.preventDefault();

    const formdata = new FormData(e.target);

    let data = {};
    for (var [key, value] of formdata.entries()) data[key] = value;

    data["user"] = props.user;
    data["agents"] = agents;

    //console.log("hello data", data);

    toggleEditOverlay();

    api.post("/api/teams/edit/" + selected_user.team_id, data).then((res) => {
      props.reload();
      props.doneLoading();
    });
  };

  const managePermissions = (show) => {
    toggleEditOverlay(show);
    if (typeof show !== "undefined") setPermissionsOverlay(show);
    else {
      setPermissionsOverlay(true);
    }
  };

  const onSelectAgent = (selectedList, selectedItem) => {
   
    /*selected_user.agents.push(selectedItem);
    agents = selected_user.agents.map(obj => {
      return ({'label':obj.agent_username ? obj.agent_username : obj.label , 'value':obj.agent_id ? obj.agent_id : obj.value})
    });*/
    //console.log("hello add", selected_user.agents, agents);
    agents = selectedList;
    return agents;
	}

	const onRemoveAgent = (selectedList, removedItem) => {

    //selected_user.agents.pop(removedItem);
    /*const index = selected_user.agents.indexOf(removedItem);
    if (index > -1) {
      selected_user.agents.splice(index, 1); 
    }
    agents = selected_user.agents.map(obj => {
      return ({'label':obj.agent_username ? obj.agent_username : obj.label , 'value':obj.agent_id ? obj.agent_id : obj.value})
    });*/
    //console.log("hello remove", selected_user.agents, agents);
    agents = selectedList;
    return agents;
	}

  return (
    <>
      {permissionsOverlay && (
        <UserAuthorizationsOverlay
          {...props}
          selected_user={selected_user}
          toggleEditOverlay={() => managePermissions(false)}

        />
      )}

      <div className={"ui-overlay" + (open ? " open" : "")}>
        <div className={"ui-overlay-content anomalie-history col-md-6"}>
          <button
            onClick={toggleEditOverlay}
            className={"ui-overlay-close btn btn-danger btn-sm"}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <h5>Modifier le groupe</h5>

          <form onSubmit={submitSiteForm}>
            {open && (
              <>
                {/* <input type="hidden" name="userid" value={this.props.user.userid} /> */}

                {/*role === "manager" && props.user.role === "admin" && (
                  <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                    <label className={"font-exp"}>Cluster Manager</label>
                    <Select
                      name="cluster_manager"
                      classNamePrefix={"custom-select"}
                      options={props.filters.cluster_managers}
                      defaultValue={{
                        label: selected_user.cluster_manager_username,
                        value: selected_user.cluster_manager_id,
                      }}
                      className={"col-md-8"}
                    />
                  </div>
                )*/}

                {(props.user.role === "admin" || props.user.role === "cluster_manager") && (
                    <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                      <label className={"font-exp"}>Manager</label>
                      <Select
                        name="manager"
                        classNamePrefix={"custom-select"}
                        options={props.filters.managers}
                        defaultValue={{
                          label: selected_user.manager_username,
                          value: selected_user.manager_id,
                        }}
                        className={"col-md-8"}
                      />
                    </div>
                  )}

                {/*role === "agent" && (
                  <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                    <label className={"font-exp"}>Matricule</label>
                    <input
                      type="text"
                      name="matricule"
                      defaultValue={selected_user.matricule}
                      className={"col-md-8 form-control"}
                    />
                  </div>
                )*/}

                {/*role === "cluster_manager" && (
                  <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                    <label className={"font-exp"}>Cluster</label>
                    <Select
                      name="cluster"
                      classNamePrefix={"custom-select"}
                      options={props.filters.clusters}
                      defaultValue={{
                        label: selected_user.cluster,
                        value: selected_user.cluster,
                      }}
                      className={"col-md-8"}
                    />
                  </div>
                )*/}

                {/*role === "manager" && (
                  <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                    <label className={"font-exp"}>Type</label>
                    <Select
                      name="account_type"
                      classNamePrefix={"custom-select"}
                      options={props.filters.site_owners}
                      defaultValue={{
                        label: selected_user.account_type,
                        value: selected_user.account_type,
                      }}
                      className={"col-md-8"}
                    />
                  </div>
                )*/}

                {/*<Restricted role={"admin"}>
                  <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                    <label className={"font-exp"}>Access authorization</label>
                    <button
                      type="button"
                      onClick={e => managePermissions(e)}
                      className={"cbtn btn btn-sm btn-primary"}
                    >
                      Gérer
                    </button>
                  </div>
              </Restricted>*/}

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Agents</label>
                  <Multiselect
                    options={props.filters.agents} // Options to display in the dropdown
                    selectedValues={agents} // Preselected value to persist in dropdown
                    onSelect={onSelectAgent} // Function will trigger on select event
                    onRemove={onRemoveAgent} // Function will trigger on remove event
                    displayValue="label" // Property name to display in the dropdown options
                    required
                    name="agents"
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Nom</label>
                  <input
                    type="text"
                    //disabled={true}
                    name="name"
                    className={"form-control col-md-8"}
                    defaultValue={selected_user.name}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Email</label>
                  <input
                    type="text"
                    //disabled={true}
                    name="email"
                    className={"form-control col-md-8"}
                    defaultValue={selected_user.email}
                  />
                </div>

                {/*<div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Task type</label>
                  <Select
                    name="task_type"
                    classNamePrefix={"custom-select"}
                    options={props.filters.task_types}
                    defaultValue={{
                      label: selected_user.task_type,
                      value: selected_user.task_type,
                    }}
                    className={"col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Alarm type</label>
                  <Select
                    name="alarm_type"
                    classNamePrefix={"custom-select"}
                    options={props.filters.alarm_types}
                    defaultValue={{
                      label: selected_user.alarm_type,
                      value: selected_user.alarm_type,
                    }}
                    className={"col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Anomalie type</label>
                  <Select
                    name="anomalie_type"
                    classNamePrefix={"custom-select"}
                    options={props.filters.anomalies_types}
                    defaultValue={{
                      label: selected_user.anomalie_type,
                      value: selected_user.anomalie_type,
                    }}
                    className={"col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Membres</label>
                  <Multiselect
      							options={props.filters.agents} // Options to display in the dropdown
      							//selectedValues={selected_user.agents} // Preselected value to persist in dropdown
      							onSelect={e => onSelectAgent(e)} // Function will trigger on select event
      							onRemove={e => onRemoveAgent(e)} // Function will trigger on remove event
      							displayValue="label" // Property name to display in the dropdown options
      							required
                    className={"col-md-8"}
      						/>
                </div>*/}

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Wilaya</label>
                  <Select
                    name="wilaya"
                    classNamePrefix={"custom-select"}
                    options={props.filters.wilayas}
                    defaultValue={{
                      label: selected_user.wilaya,
                      value: selected_user.wilaya,
                    }}
                    className={"col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Owner</label>
                  <Select
                    name="owner"
                    classNamePrefix={"custom-select"}
                    options={props.filters.services}
                    defaultValue={{
                      label: selected_user.owner,
                      value: selected_user.owner_id,
                    }}
                    className={"col-md-8"}
                  />
                </div>

                {/*<p>
                  <i>
                    Laissez le champ ci-dessus vide pour ne pas changer le mot
                    de passe
                  </i>
                </p>
                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Mot de passe</label>
                  <input
                    type="password"
                    name="password"
                    className={"form-control col-md-8"}
                  />
                </div>*/}

                <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                  <input
                    type="submit"
                    className={"btn btn-primary col-md-12"}
                    value="Valider les modifications"
                  />
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default TeamEditOverlay;
