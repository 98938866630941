import React, { useState } from "react";
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

const EditCCOverlay = ({open, task, close, reload, ...props}) => {

  const [commune, setCommune] = useState([]);
  const [region, setRegion] = useState([]);
  const [subProblem, setSubProblem] = useState([]);

  const handleWilayaChange = (e) => {

		var parent = e.label;

    setCommune(props.filters.communes
      .filter(obj => String(obj.parent).toUpperCase() === String(parent).toUpperCase())
      .map(obj => ({ label: obj.label, value: obj.value }))
      .filter(el => el.label != null)
    );

    setRegion(props.filters.regions
      .filter(obj => String(obj.value).toUpperCase() === String(parent).toUpperCase())
      .map(obj => ({ label: obj.label, value: obj.value }))
      .filter(el => el.label != null)
    );
	};

  const handleProblemChange = (e) => {

		var parent = e.label;

    setSubProblem(props.filters.cc_sub_problems
      .filter(obj => String(obj.parent_label).toUpperCase() === String(parent).toUpperCase())
      .map(obj => ({ label: obj.label, value: obj.value }))
      .filter(el => el.label != null)
    );
	};

  const submitSiteForm = (e) => {
    e.preventDefault();

    let that = this;

    const formdata = new FormData(e.target);

    let data = {};

    for (var [key, value] of formdata.entries()) data[key] = value;

    data["user"] = props.session.user;

    if (data["problem_date"] == "aaaa-mm-jj h:m:s") {
      data["problem_date"] = null;
    }

    if (data["reception_date"] == "aaaa-mm-jj h:m:s") {
      data["reception_date"] = task.reception_date;
    }

    if (data["created_at"] == "aaaa-mm-jj h:m:s") {
      data["created_at"] = task.created_at;
    }

    //console.log("hello data", data);

    var reception_date = new Date(data["reception_date"]);
    var problem_date = new Date(data["problem_date"]);

    if(reception_date < problem_date) {

        alert('Attention, la date d\alerting est antérieure à la date d\'alarme!');

    } else {

      props.isLoading({
        message: "Mise à jour du CM",
        animate: true,
      });
	  
      api.post('/api/cc/task/edit', data)
      .then(response => {
        if (response.data.success) {
          props.setFlash({
            active: true,
            message: "Tache CC modifié avec succés",
            classes: "success",
            style: {},
          });
        } else {
          props.setFlash({
            active: true,
            message: "Une erreur est survenue, Envoi echoué.",
            classes: "warning",
            style: {},
          });
        }
        close();
        props.doneLoading();
        reload();
      }).catch((err) => props.doneLoading());
  }
  };

    return (
      <div className={"ui-overlay " + (open ? " open" : "")}>
        <div className={"ui-overlay-content site-overlay col-md-5"}>
          <button
            data-package-id="0"
            onClick={(e) => {
              close(e);
            }}
            className={"ui-overlay-close btn btn-danger btn-sm"}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <h5 className={"mb-3"}>Tâche CC</h5>

          <form onSubmit={submitSiteForm}>
            {open && (
              <>
                <input
                  type="hidden"
                  name="id"
                  value={task.id}
                />

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>ID</label>
                  <input
                    type="text"
                    name="id"
                    className={"form-control col-md-8"}
                    value={task.id}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Classification</label>
                  <Select
                    name="classification"
                    classNamePrefix={"custom-select"}
                    defaultValue={{
                      value: task.classification,
                      label: task.classification,
                    }}
                    options={props.filters.cc_classifications}
                    className={"col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Wilaya</label>
                  <Select
                    name="wilaya"
                    classNamePrefix={"custom-select"}
                    defaultValue={{
                      value: task.wilaya,
                      label: task.wilaya,
                    }}
                    options={props.filters.new_wilayas}
                    className={"col-md-8"}
                    onChange={(e) => handleWilayaChange(e)}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Commune</label>
                  <Select
                    name="commune"
                    classNamePrefix={"custom-select"}
                    defaultValue={{
                      value: task.commune,
                      label: task.commune,
                    }}
                    //options={props.filters.communes}
                    options={commune}
                    className={"col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Problème</label>
                  <Select
                    name="problem"
                    classNamePrefix={"custom-select"}
                    defaultValue={{
                      value: task.problem,
                      label: task.problem,
                    }}
                    options={props.filters.cc_problems}
                    className={"col-md-8"}
                    onChange={(e) => handleProblemChange(e)}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Sous problème</label>
                  <Select
                    name="sub_problem"
                    classNamePrefix={"custom-select"}
                    defaultValue={{
                      value: task.sub_problem,
                      label: task.sub_problem,
                    }}
                    //options={props.filters.cc_sub_problems}
                    options={subProblem}
                    className={"col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Déscription</label>
                  <textarea
                    rows={2}
                    name="details"
                    className={"form-control col-md-8"}
                    defaultValue={task.details}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Adresse</label>
                  <textarea
                    rows={2}
                    name="address"
                    className={"form-control col-md-8"}
                    defaultValue={task.address}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Type plainte</label>
                  <Select
                    name="complaint_type"
                    classNamePrefix={"custom-select"}
                    defaultValue={{
                      value: task.complaint_type,
                      label: task.complaint_type,
                    }}
                    options={props.filters.cc_complaint_types}
                    className={"col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Commentaire</label>
                  <textarea
                    rows={2}
                    name="comment"
                    className={"form-control col-md-8"}
                    defaultValue={task.comment}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Date réception</label>
                  <input
                    defaultValue={task.reception_date ? task.reception_date : "aaaa-mm-jj h:m:s"}
                    name="reception_date"
                    type={"datetime"}
                    className={"form-control col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Date inseertion</label>
                  <input
                    defaultValue={task.created_at ? task.created_at : "aaaa-mm-jj h:m:s"}
                    name="created_at"
                    type={"datetime"}
                    className={"form-control col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Date problème</label>
                  <input
                    defaultValue={task.problem_date ? task.problem_date : "aaaa-mm-jj h:m:s"}
                    name="problem_date"
                    type={"datetime"}
                    className={"form-control col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Num source</label>
                  <input
                    type="number"
                    name="source_num"
                    className={"form-control col-md-8"}
                    defaultValue={task.source_num}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Num contact</label>
                  <input
                    type="number"
                    name="contact_num"
                    className={"form-control col-md-8"}
                    defaultValue={task.contact_num}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Type</label>
                  <Select
                    name="in_out"
                    classNamePrefix={"custom-select"}
                    defaultValue={{
                      value: task.in_out,
                      label: task.in_out,
                    }}
                    options={props.filters.cc_in_out}
                    className={"col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>ID SO</label>
                  <input
                    type="number"
                    name="so_id"
                    className={"form-control col-md-8"}
                    defaultValue={task.so_id}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>ID OTAP</label>
                  <input
                    type="number"
                    name="otap_id"
                    className={"form-control col-md-8"}
                    defaultValue={task.otap_id}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>GPS X</label>
                  <input
                    type="text"
                    name="gps_x"
                    className={"form-control col-md-8"}
                    defaultValue={task.gps_x}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>GPS Y</label>
                  <input
                    type="text"
                    name="gps_y"
                    className={"form-control col-md-8"}
                    defaultValue={task.gps_y}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>ID ARPCE</label>
                  <input
                    type="number"
                    name="arpce_id"
                    className={"form-control col-md-8"}
                    defaultValue={task.arpce_id}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>IMSI</label>
                  <input
                    type="number"
                    name="imsi"
                    className={"form-control col-md-8"}
                    defaultValue={task.imsi}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Région</label>
                  <Select
                    name="region"
                    classNamePrefix={"custom-select"}
                    defaultValue={{
                      value: task.region,
                      label: task.region,
                    }}
                    //options={props.filters.regions}
                    options={region}
                    className={"col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                  <input
                    type="submit"
                    className={"btn btn-primary col-md-12"}
                    value="Valider les modifications"
                  />
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    );
  
}

export default EditCCOverlay;
