import React from "react";
import { Chart } from "chart.js";
import * as ChartGeo from "chartjs-chart-geo";
import { Pie, HorizontalBar, Bar } from "react-chartjs-2";
import { chartColors, gradientColors, hsGradientColors, hsGradientColorsArray } from "../../../../Utils/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Select from "react-select";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import api from "../../../../Utils/Api";


const statesData = require("../../../../../data/algeria.json");
const countryData = require("../../../../../data/africa.json");

Chart.plugins.unregister(ChartDataLabels);

class HsSitesPage extends React.Component {

  constructor() {
  
    super();
  
    this.state = {
      loading: true,
      showAllWilayas: false,
      wilayas: [],
      clusters: [],
      site_owners: [],
      site_types: [],
      selectedCluster: "",
      selectedOwner: "",
      //selectedCmCode: "LOSS-OF-ALL CHANNEL",
      selectedCmCode: ['"LOSS-OF-ALL CHANNEL"'],
      hsSites: [],
      topTen: [],
      total: 0,
      totalNumber: 0,
      percentage: 0,
    };

    this.colorsAr = {
      0: chartColors[0],
      1: chartColors[1],
      2: chartColors[2],
      3: chartColors[3],
      4: chartColors[4],
      5: chartColors[5],
      6: chartColors[6],
      7: chartColors[7],
      8: chartColors[8],
      9: chartColors[9],
      10: chartColors[10],
    };

    this.timerID = null;
  }

  loadData = async () => {

    const selectedCmCodes = this.state.selectedCmCode.join(',');
    //const sitesData = api.get(`/api/cm/dashboard/hs/sites?cluster=${this.state.selectedCluster}&owner=${this.state.selectedOwner}&cm_code=${this.state.selectedCmCode}`);
    const sitesData = api.get(`/api/cm/dashboard/hs/sites?cluster=${this.state.selectedCluster}&owner=${this.state.selectedOwner}&cm_code=${selectedCmCodes}`);
    const country = new Promise(resolve => resolve(countryData));
    const states = new Promise(resolve => resolve(statesData));
    return Promise.all([states, country, sitesData]);
  }

  /*componentDidMount() {
    this.drawGraphs();
  }*/

  componentDidMount() {
    this.drawGraphs();
		this.timerID = setInterval(this.drawGraphs, 60000 * 5); 
	}
	
	componentWillUnmount() {
		clearInterval(this.timerID); 
	}

  drawGraphs = () => {
    
    //this.setState({ loading: true });

    this.loadData().then(data => {

      this.setState({
        loading: false,
        hs_sites_by_wilaya: data[2].data.data.hs_sites_by_wilaya,
      });

      const regions = ChartGeo.topojson
        .feature(data[0], data[0].objects.DZA_adm1)
        .features.filter(item => item.properties.NAME_0 === "Algeria");

      const countries = ChartGeo.topojson.feature(
        data[1],
        data[1].objects.continent_Africa_subunits
      ).features;

      const Algeria = countries.find(d => d.properties.geounit === "Algeria");

      //HS
      const hsSitesByWilaya = data[2].data.data.hs_sites_by_wilaya;
      const sitesByWilaya = data[2].data.data.sites_by_wilaya;
      const totalSitesNumber = (data[2].data.data.total_sites)[0].total_sites;

      //console.log("hello", totalSitesNumber);

      var hsSites = [];
      hsSites = hsSitesByWilaya.map((item) => {
        return {
          address_wilaya: item.address_wilaya, 
    
          number: parseInt((sitesByWilaya.find((itmInner) => {if(itmInner.address_wilaya === item.address_wilaya) {return parseInt(itmInner.number)}})).number),
          number_2g: parseInt((sitesByWilaya.find((itmInner) => {if(itmInner.address_wilaya === item.address_wilaya) {return parseInt(itmInner.number_2g)}})).number_2g),
          number_3g: parseInt((sitesByWilaya.find((itmInner) => {if(itmInner.address_wilaya === item.address_wilaya) {return parseInt(itmInner.number_3g)}})).number_3g),
          number_4g: parseInt((sitesByWilaya.find((itmInner) => {if(itmInner.address_wilaya === item.address_wilaya) {return !isNaN(itmInner.number_4g) && parseInt(itmInner.number_4g) > 0 ? parseInt(itmInner.number_4g) : 0}}))),
          
          site_codes: parseInt(item.number_hs) != 0 ? this.getImpactedSites(item.site_codes) : [],
          impacted_sites: parseInt(item.number_hs) != 0 ? this.getImpactedSites(item.impacted_sites) : [],
          total_sites: parseInt(item.number_hs) != 0 ? this.getImpactedSites(item.impacted_sites + "," + item.site_codes) : [],
          
          impacted_sites_number: parseInt(item.number_hs) != 0 ? this.getImpactedSitesNumber(item.impacted_sites) : 0,
          number_hs: parseInt(item.number_hs),
          total_hs: this.getImpactedSitesNumber(item.impacted_sites + "," + item.site_codes),
          //total_hs_old: parseInt(item.number_hs) != 0 ? parseInt(item.number_hs) + this.getImpactedSitesNumber(item.impacted_sites) : 0,
          
          impacted_2g_sites: parseInt(item.number_hs) != 0 ? this.get2gImpactedSites(this.getImpactedSites(item.impacted_sites + "," + item.site_codes)): [],
          impacted_3g_sites: parseInt(item.number_hs) != 0 ? this.get3gImpactedSites(this.getImpactedSites(item.impacted_sites + "," + item.site_codes)): [],
          impacted_4g_sites: parseInt(item.number_hs) != 0 ? this.get4gImpactedSites(this.getImpactedSites(item.impacted_sites + "," + item.site_codes)): [],

          impacted_2g_sites_number: parseInt(item.number_hs) != 0 ? this.get2gImpactedSites(this.getImpactedSites(item.impacted_sites + "," + item.site_codes)).length : 0,
          impacted_3g_sites_number: parseInt(item.number_hs) != 0 ? this.get3gImpactedSites(this.getImpactedSites(item.impacted_sites + "," + item.site_codes)).length : 0,
          impacted_4g_sites_number: parseInt(item.number_hs) != 0 ? this.get4gImpactedSites(this.getImpactedSites(item.impacted_sites + "," + item.site_codes)).length : 0,
          
          pourcentage: parseInt(item.number_hs) != 0 ? (parseFloat((parseInt(item.number_hs) + this.getImpactedSitesNumber(item.impacted_sites)) / parseInt((sitesByWilaya.find((itmInner) => {if(itmInner.address_wilaya === item.address_wilaya) {return parseInt(itmInner.number)}})).number)) * 100).toFixed(2) : 0,
          pourcentage_2g: this.get2gImpactedSites(this.getImpactedSites(item.impacted_sites + "," + item.site_codes)).length != 0 ? Number(parseFloat(this.get2gImpactedSites(this.getImpactedSites(item.impacted_sites + "," + item.site_codes)).length / parseInt((sitesByWilaya.find((itmInner) => {if(itmInner.address_wilaya === item.address_wilaya) {return parseInt(itmInner.number_2g)}})).number_2g) * 100).toFixed(2)) : 0,
          pourcentage_3g: this.get3gImpactedSites(this.getImpactedSites(item.impacted_sites + "," + item.site_codes)).length != 0 ? Number(parseFloat(this.get3gImpactedSites(this.getImpactedSites(item.impacted_sites + "," + item.site_codes)).length / parseInt((sitesByWilaya.find((itmInner) => {if(itmInner.address_wilaya === item.address_wilaya) {return parseInt(itmInner.number_3g)}})).number_3g) * 100).toFixed(2)) : 0,
          pourcentage_4g: this.get4gImpactedSites(this.getImpactedSites(item.impacted_sites + "," + item.site_codes)).length != 0 ? Number(parseFloat(this.get4gImpactedSites(this.getImpactedSites(item.impacted_sites + "," + item.site_codes)).length / parseInt((sitesByWilaya.find((itmInner) => {if(itmInner.address_wilaya === item.address_wilaya) {return parseInt(itmInner.number_4g)}})).number_4g) * 100).toFixed(2)) : 0,
        }
      });

      var hsSitesUpdate = [];
      var total = 0;
      var totalNumber = 0;
      for(let i=0; i<sitesByWilaya.length; i++) {
        var found = false;
        for(let j=0; j<hsSites.length; j++) {
          if(sitesByWilaya[i].address_wilaya === hsSites[j].address_wilaya) {
            hsSitesUpdate.push({...hsSites[j]});
            found = true;
            total = total + hsSites[j].total_hs;
            totalNumber = totalNumber + hsSites[j].number;
          }
        }
        if(!found) {
          var item = {
            address_wilaya: sitesByWilaya[i].address_wilaya, 

            number: parseInt(sitesByWilaya[i].number),
            number_2g: parseInt(sitesByWilaya[i].number_2g),
            number_3g: parseInt(sitesByWilaya[i].number_3g),
            number_4g: parseInt(sitesByWilaya[i].number_4g),

            site_codes: [],
            impacted_sites: [],
            total_sites: [],

            impacted_sites_number: 0,
            number_hs: 0,
            total_hs: 0,
            
            impacted_2g_sites: [],
            impacted_3g_sites: [],
            impacted_4g_sites: [],

            impacted_2g_sites_number: 0,
            impacted_3g_sites_number: 0,
            impacted_4g_sites_number: 0,
            
            pourcentage: 0,
            pourcentage_2g: 0,
            pourcentage_3g: 0,
            pourcentage_4g: 0,
          }
          hsSitesUpdate.push({...item});
        }
      }

      var percentage = parseFloat((total/totalSitesNumber) * 100).toFixed(2);
      this.setState({ hsSites: hsSitesUpdate, total: total, totalNumber: totalNumber, percentage: percentage });

      //console.log("hello sites 1", hsSites);
      //console.log("hello sites 2", hsSitesUpdate);

      var topTen = this.state.hsSites.sort(function (a, b) {
        return parseInt(b.total_hs) - parseInt(a.total_hs);
      });

      topTen = topTen.slice(0, 15);

      this.setState({ topTen: topTen });

      //console.log("hello ten", topTen);

      let maxPerWilaya = 0;

      this.state.hsSites.forEach(item => {
        if (parseInt(item.number_hs) > maxPerWilaya) {
          maxPerWilaya = item.number_hs;
        }
      });

      const hsChart = new Chart(this.hsCanvas.getContext("2d"), {
        type: "choropleth",
        data: {
          labels: regions.map(d => {
            const wilayaVal = this.state.hsSites.filter(
              item => item.address_wilaya === d.properties.NAME_1
            );
            if (wilayaVal.length)
              /*return wilayaVal[0].address_wilaya + " \n Nombre sites : " + wilayaVal[0].number 
              + " \n Nombre sites HS : " + wilayaVal[0].total_hs 
              + " \n sites HS 2G : " + wilayaVal[0].impacted_2g_sites_number 
              + " \n sites HS 3G : " + wilayaVal[0].impacted_3g_sites_number 
              + " \n sites HS 4G : " + wilayaVal[0].impacted_4g_sites_number 
              ;*/
              return [[wilayaVal[0].address_wilaya], 
                ["nbr_site_hs : " + wilayaVal[0].total_hs], 
                ["2G : " + wilayaVal[0].impacted_2g_sites_number], 
                ["3G : " + wilayaVal[0].impacted_3g_sites_number], 
                ["4G : " + wilayaVal[0].impacted_4g_sites_number],
                ["Pourcentage : " + wilayaVal[0].pourcentage + "%"]];
            else return d.properties.NAME_1;
          }),
          datasets: [
            {
              label: "Countries",
              outline: Algeria,
              backgroundColor: context => {
                if (context.dataIndex == null) {
                  return null;
                }
                let value = parseInt(context.dataset.data[context.dataIndex].color);
                const color = hsGradientColorsArray[value];
                return color;
                /*let value = parseInt(context.dataset.data[context.dataIndex].color * 10) + 2;
                if (value > hsGradientColors.length - 1) { value = hsGradientColors.length - 1; }
                else if (value < 1) { value = 0; }
                const color = hsGradientColors[value];
                console.log("hello", parseInt(context.dataset.data[context.dataIndex].color), parseInt(context.dataset.data[context.dataIndex].color * 10) + 2);
                return color;*/
              },
              data: regions.map(d => {
                const wilayaVal = this.state.hsSites.filter(
                  item => item.address_wilaya === d.properties.NAME_1
                );

                let colorCoef = 0;
                var pourcentage = wilayaVal[0].pourcentage;

                /*if((wilayaVal[0].number_hs == maxPerWilaya) && maxPerWilaya != 0) {
                  colorCoef = 4;
                } else if (pourcentage == 0) {
                  colorCoef = 0;
                } else if (pourcentage < 5) {
                  colorCoef = 1;
                } else if (pourcentage < 10) {
                  colorCoef = 2;
                } else {
                  colorCoef = 3;
                }*/
                if (pourcentage < 5) {
                  colorCoef = 0;
                } else if (pourcentage < 10) {
                  colorCoef = 1;
                } else {
                  colorCoef = 2;
                }  
                return { feature: d, value: wilayaVal, color: colorCoef };
                /*if (wilayaVal.length) {
                  colorCoef = wilayaVal[0].number_hs / maxPerWilaya * 10;
                }
                return { feature: d, value: wilayaVal, color: colorCoef };*/
              }),
            }
          ]
        },
        options: {
          plugins: {
            zoom: {
              pan: {
                enabled: true,
                mode: "xy"
              },
              zoom: {
                enabled: true,
                mode: "xy"
              }
            }
          },
          showOutline: false,
          showGraticule: false,
          legend: {
            display: false
          },
          scale: {
            projection: "equalEarth"
          },
          geo: {
            colorScale: {
              display: true
            }
          }
        }
      });
    });
  }


  setStartDate = e => this.setState({ startDate: e });

  setEndDate = e => this.setState({ endDate: e });

  updateFilters = filters => {
  
    this.setState(filters);
  };

  getImpactedSitesNumber(string) {

    if(string != null) {
      var impacted_sites = string.split(/[ ,]+/).filter(Boolean);
      impacted_sites = impacted_sites.reduce(function(a,b){if(a.indexOf(b)<0)a.push(b);return a;},[]);
      impacted_sites = impacted_sites.filter(function(str){ return !str.includes('M') && !str.includes('m'); });
      return impacted_sites && impacted_sites != [] ? impacted_sites.length : 0;
    } else {
      return 0;
    }
  }

  getImpactedSites(string) {

    if(string != null) {
      var impacted_sites = string.split(/[ ,]+/).filter(Boolean);
      impacted_sites = impacted_sites.reduce(function(a,b){if(a.indexOf(b)<0)a.push(b);return a;},[]);
      impacted_sites = impacted_sites.filter(function(str){ return !str.includes('M') && !str.includes('m'); });
      return impacted_sites;
    } else {
      return [];
    }
  }

  get2gImpactedSites(array) {

    var impacted2gSites = array.filter(function(str){
      return !str.startsWith('3') && !str.startsWith('4'); 
    });
    return impacted2gSites;
  }

  get3gImpactedSites(array) {

    var impacted3gSites = array.filter(function(str){
      return str.startsWith('3'); 
    });
    return impacted3gSites;
  }

  get4gImpactedSites(array) {

    var impacted4gSites = array.filter(function(str){
      return str.startsWith('4'); 
    });
    return impacted4gSites;
  }

  getColor(pourcentage) {

    if (pourcentage < 5) {
      return hsGradientColors[0];
    } else if (pourcentage < 10) {
      return hsGradientColors[2];
    } else {
      return hsGradientColors[4];
    } 
  }

  render() {

    const totalSites =  this.state.site_owners.reduce((acc, curr) => acc + parseInt(curr.number),0);

    return (
      <>
        <div className={"page-loading " + (this.state.loading ? "active" : "")}/>

        <div className={"hs-dashboard-page"}>
          <div className={"container-header header-spacing nmb"}>
            
            <div></div>
            <div className={"date-filters"}>
              <>
                <b>Type d'alarm</b>
                <Select
                  classNamePrefix={"custom-select"}
                  //className={"select-menu"}
                  options={this.props.filters.cm_code}
                  placeholder={"cm_code"}
                  //defaultValue={{label: 'LOSS-OF-ALL CHANNEL', value: 'LOSS-OF-ALL CHANNEL'}}
                  //isDisabled={!!this.props.session.user.owner}
                  //onChange={e => this.updateFilters({ selectedCmCode: e.value })}
                  defaultValue={[{label: 'LOSS-OF-ALL CHANNEL', value: 'LOSS-OF-ALL CHANNEL'}]}
                  isMulti
                  onChange={selectedOptions => {
                    // Extract the values from the selected options and add single quotes to each CM code
                    const selectedCmCodes = selectedOptions.map(option => `"${option.value}"`);
                    this.updateFilters({ selectedCmCode: selectedCmCodes });
                  }}
                /> 

            {/*    <b>Owner</b>
                <Select
                  classNamePrefix={"custom-select"}
                  className={"select-menu"}
                  options={[{ label: "Tous", value: "" }, ...this.props.filters.cm_site_owners]}
                  placeholder={"Owner"}
                  value={this.props.session.user.owner ? {label: this.props.session.user.owner, value: this.props.session.user.owner === "O&M" ? "inhouse" : this.props.session.user.owner} : undefined}
                  isDisabled={!!this.props.session.user.owner}
                  onChange={e => this.updateFilters({ selectedOwner: e.value })}
                />

                <b>Cluster</b>
                <Select
                  classNamePrefix={"custom-select"}
                  className={"select-menu"}
                  options={[
                    { label: "Tous", value: "" },
                    ...this.props.filters.clusters
                  ]}
                  value={this.props.session.user.cluster ? {label: this.props.session.user.cluster, value: this.props.session.user.cluster} : undefined}
                  isDisabled={!!this.props.session.user.cluster}
                  placeholder={"Cluster"}
                  onChange={e => this.updateFilters({ selectedCluster: e.value })}
                /> */}

                <button
                  onClick={this.drawGraphs}
                  type={"button"}
                  className={"filter-apply"}
                >
                  <FontAwesomeIcon icon={faFilter} />
                </button>
              </>
            </div>
          </div>

          <div className={"row hs-dashboard-row "}>
            <div className={"col-md-6"}>
              <div className={"hs-dashboard-box"}>
                <span className={"hs-dashboard-box-title"}>HS Sites par wilaya</span>
                <span className={"hs-dashboard-box-value"}>Pourcentage sites HS : {this.state.percentage} %</span>
              </div>
              <div className={"hs-single-chart"}>
                <canvas height={"300"} ref={ref => (this.hsCanvas = ref)} />
              </div>
            </div>

            <div className={"col-md-6"}>
              <div className={"hs-dashboard-box"}>
                <span className={"hs-dashboard-box-title"}>HS Sites par technologie</span>
                <span className={"hs-dashboard-box-value"}>Top 15</span>
              </div>
              <div className={"hs-single-chart"}>
                <div className={"hs-table"}>
                  <table className={"hs-table-style"}>
                    <tr>
                      <th>Wilaya</th>
                      <th>&nbsp;</th> 
                      <th>2G</th>
                      <th>&nbsp;</th> 
                      <th>3G</th>
                      <th>&nbsp;</th> 
                      <th>4G</th>
                    </tr>
                    {this.state.topTen.map(item =>  <tr>
                      <td>{item.address_wilaya}</td> 
                      <td className={item.pourcentage_2g == 0 ? "no-hs-td" : item.pourcentage_2g < 5 ? "hs-td-5" : item.pourcentage_2g < 10 ? "hs-td-10" : "hs-td" }>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td> 
                      <td class="has-details">
                        {item.pourcentage_2g + "%"}
                        <span class="details">{item.impacted_2g_sites.join(', ')}</span>
                      </td> 
                      <td className={item.pourcentage_3g == 0 ? "no-hs-td" : item.pourcentage_3g < 5 ? "hs-td-5" : item.pourcentage_3g < 10 ? "hs-td-10" : "hs-td" }>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                      <td class="has-details">
                        {item.pourcentage_3g + "%"}
                        <span class="details">{item.impacted_3g_sites.join(', ')}</span>
                      </td> 
                      <td className={item.pourcentage_4g == 0 ? "no-hs-td" : item.pourcentage_4g < 5 ? "hs-td-5" : item.pourcentage_4g < 10 ? "hs-td-10" : "hs-td" }>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                      <td class="has-details">
                        {item.pourcentage_4g + "%"}
                        <span class="details">{item.impacted_4g_sites.join(', ')}</span>
                      </td> 
                    </tr>)}
                  </table>
                </div>
              </div>
            </div>

            {/*<div className={"col-md-6"}>
              <div className={"hs-dashboard-box"}>
                <span className={"hs-dashboard-box-title"}>HS Sites par technologie</span>
                <span className={"hs-dashboard-box-value"}>Top 10</span>
              </div>

              <div className={"hs-single-chart"}>
                <HorizontalBar
                  //redraw={true}
                  options={{
                    layout: {
                      padding: {
                        right: 50
                      }
                    },
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            fontColor:"#ccc",
                            fontFamily:"Open sans",
                            color: '#ccc'
                          },
                          gridLines: {
                            display: false,
                            color: '#ccc',
                            zeroLineColor: '#ccc'
                          },
                          //barThickness : 10,
                          categoryPercentage: 1.0,
                          barPercentage: 0.7,
                          scaleLineColor: "#ccc",
                        }
                      ],
                      xAxes: [
                        {
                          ticks: {
                            fontColor:"#ccc",
                            fontFamily:"Open sans",
                            color: '#ccc',
                          },
                          gridLines: {
                            display: false,
                            color: '#ccc',
                            zeroLineColor: '#ccc'
                          },
                          scaleLineColor: "#ccc",
                        }
                      ]
                    },
                    plugins: {
                      datalabels: {
                        anchor: "end",
                        color: "#ccc",
                        align: "end",
                        backgroundColor: "rgba(0,0,0,.1)",
                        padding: 3,
                        font: {
                          family: "Open sans",
                          weight: "bold"
                        }
                      }
                    },
                    legend: {
                      display: false
                    },
                    tooltips: {
                      enabled: true,
                      mode: 'single',
                      callbacks: {
                        label: function(tooltipItems, data) {
                          //console.log("hello inside", tooltipItems, data.datasets[tooltipItems.datasetIndex].label[tooltipItems.index]);
                          return data.datasets[tooltipItems.datasetIndex].label[tooltipItems.index].join(',');
                        }
                      }
                    },
                    animations: {
                      tension: {
                        duration: 1200,
                        easing: 'linear',
                        from: 1,
                        to: 0,
                        loop: true
                      }
                    },
                  }}
                  plugins={[ChartDataLabels]}
                  data={{
                    labels: this.state.topTen.map(item => item.address_wilaya + " " + item.total_hs + " ( " + item.pourcentage + "% )"),
                    datasets: [
                      {
                        label: this.state.topTen.map(item => item.impacted_2g_sites),
                        data: this.state.topTen.map(item => item.pourcentage_2g),
                        backgroundColor: this.state.topTen.map((item, index) => this.getColor(item.pourcentage_2g)),
                        hoverBackgroundColor: this.state.topTen.map((item, index) => this.getColor(item.pourcentage_2g)),
                      },
                      {
                        label: this.state.topTen.map(item => item.impacted_3g_sites),
                        data: this.state.topTen.map(item => item.pourcentage_3g),
                        backgroundColor: this.state.topTen.map((item, index) => this.getColor(item.pourcentage_3g)),
                        hoverBackgroundColor: this.state.topTen.map((item, index) => this.getColor(item.pourcentage_3g))
                      },
                      {
                        label: this.state.topTen.map(item => item.impacted_4g_sites),
                        data: this.state.topTen.map(item => item.pourcentage_4g),
                        backgroundColor: this.state.topTen.map((item, index) => this.getColor(item.pourcentage_4g)),
                        hoverBackgroundColor: this.state.topTen.map((item, index) => this.getColor(item.pourcentage_4g))
                      }
                    ]
                  }}
                />
              </div>
            </div>*/}
          </div>
        </div>           
      </>
    );
  }
}

const mapState = state => {
  return {
    filters: state.filters,
    session: state.session
  }
}
export default connect(mapState)(HsSitesPage);
