import React from "react";
import RenderChecks from "../../components/RenderChecks";
import PhotoItem from "../../../../Utils/PhotoItem";

//const Electricity = ({ data }) => <RenderChecks data={data} />;

const Electricity = ({ data, ...props }) => {
    return (
      <>
        <RenderChecks data={data} />
  
        {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              <div className={"test-photos-single"}>
                <PhotoItem photo={photo} active={props.active} />
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

export default Electricity;
