import React from "react";
import { useSelector, useDispatch } from "react-redux";


const Restricted = ({children, permission, role, owner, scope, condition}) => {

    if(typeof condition !== "undefined")
        if(!condition)
            return <React.Fragment />


    const roles = {
        agent: 1,
        manager: 3,
        cluster_manager: 4,
        admin: 5,
        problem_manager: 2,
        cm_creator: 2,
        service: 3,
    };

    const {session} = useSelector(state => ({session: state.session}));
    const currentRole = session.user.role;

    //console.log("hello", session.user.permissions, permission);

    if(!session.user.permissions) {
        return <React.Fragment />;
    }

    if(scope && !session.user.permissions[scope])
        return <React.Fragment />;

    if(permission && (!session.user.permissions[scope] || !(permission in session.user.permissions[scope]))) {
        return <React.Fragment />;
    }


    if(role && (roles[currentRole] < roles[role] ))
        return <React.Fragment />;

    return children;

}

export default Restricted;
