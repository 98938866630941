import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faSave, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import api from "../../../Utils/Api";
import { connect } from "react-redux";
import Select from "react-select";
import { formToObject } from "../../../Utils/Helpers";
import Restricted from "../../../Utils/Restricted";

class CoSiteRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handleEscape = e => {
    if (e.keyCode === 27) {
      this.setState({ editMode: false });
    }
  };

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleEscape);
  }

  componentDidMount() {
    document.addEventListener("keyup", this.handleEscape);
  }

  deleteCoSite = id => {
    this.props.setLoading(true);

    api
      .post("/api/sites/cosites/" + this.props.item.id + "/delete")
      .then(res => {
        if (res.data.success) this.props.loadCoSites();
      })
      .catch(err => {
        this.props.setLoading(false);
      });
  };

  editCoSite = id => {
    this.setState({ editMode: true });
  };

  submitEdiSite = e => {
    e.preventDefault();

    const data = formToObject(e.target);

    this.setState({ editMode: false });
    this.props.setLoading(true);

    api.post(`/api/sites/cosites/${this.props.item.id}/edit`, data).then(res => {
      this.props.loadCoSites();
    });
  };



  render() {
    const item = this.props.item;

    if (this.state.editMode) {
      return (
        <form onSubmit={this.submitEdiSite}>
          <div className={"row ctable-row"}>
            <div className={"col-md-4"}>
              <Select
                className={"col-md-12"}
                defaultValue={{ label: item.site_type, value: item.site_type }}
                options={this.props.filters.cosite_types}
                placeholder={"Type..."}
                classNamePrefix={"custom-select"}
                name={"site_type"}
              />
            </div>
            <div className={"col-md-3"}>
              <input
                name={"site_code"}
                defaultValue={item.site_code}
                placeholder={"Code eg. 4A16X002"}
                type="text"
                className={"form-control"}
              />
            </div>
            <div className={"col-md-5"}>
              <button type="submit" className={"task-action-btn"}>
                Sauvegarder <FontAwesomeIcon icon={faSave} />{" "}
              </button>
            </div>
          </div>
        </form>
      );
    } else
      return (
        <div className={"row ctable-row hoverable"}>
          <div className={"col-md-4"}>{item.site_type}</div>
          <div className={"col-md-3"}>{item.site_code}</div>
          <div className={"col-md-5 showOnHover"}>
            <Restricted scope={"pm"} permission={"edit_site"}>
              <button type="button" onClick={() => this.deleteCoSite(item.id)} className={"task-action-btn danger"}>
                Supprimer <FontAwesomeIcon icon={faTrash} />{" "}
              </button>
              <button type="button" onClick={() => this.editCoSite(item.id)} className={"task-action-btn"}>
                Modifier <FontAwesomeIcon icon={faEdit} />{" "}
              </button>
              {/*<button type="button" onClick={() => this.props.planCM(item)} className={"task-action-btn"}>
                New CM
                <FontAwesomeIcon icon={faInfoCircle} />{" "}
              </button>*/}
            </Restricted>
          </div>
        </div>
      );
  }
}

export default connect(state => ({ filters: state.filters }))(CoSiteRow);
