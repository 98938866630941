import React, { Suspense } from 'react';
import { NavLink, Switch, Route } from 'react-router-dom';
import LoadingOverlay from '../../Common/LoadingOverlay';
//import Agents from './CM/Agents';
import Tasks from './CM/Tasks';
import Sites from './CM/Sites';
import HsSites from './CM/HsSites';
import PendingCM from './CM/PendingCM';
import StateCM from './CM/StateCM';
import { connect } from "react-redux";
import { setAuthAction, } from "../../../Redux/actions/index";
import DjezzyApp from './CM/DjezzyApp';


const mapStateToProps = (state) => {
    return {
      user: state.session.user,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setAuth: (user) => dispatch(setAuthAction(user)),
});

//const AdminComp = React.lazy(() => import(/* webpackChunkName: "adminDashbaord" */"../CMAdminDashboard"));

class CMDashboardWrapper extends React.Component {


  render() {


    return (

        <>
        <div className={"page-menu dashboard"}>
            <ul>
                {/*<li><NavLink to={'/cm/dashboard/tasks'}>Plan & Execution</NavLink></li>
                <li><NavLink to={'/cm/dashboard/equip'}>Equipement & matériel</NavLink></li>
                <li><NavLink to={'/cm/dashboard/agents'}>Agents</NavLink></li>*/}
                <li><NavLink to={'/cm/dashboard/tasks'}>Tasks</NavLink></li>
                <li><NavLink to={'/cm/dashboard/sites'}>Sites</NavLink></li>
                <li><NavLink to={'/cm/dashboard/hssites'}>HS Sites</NavLink></li>
                {this.props.user.owner == 'SUPERVISION' ? <li><NavLink to={'/cm/dashboard/pendingcm'}>Pending CM</NavLink></li> : null}
                {this.props.user.owner == 'SUPERVISION' ? <li><NavLink to={'/cm/dashboard/statecm'}>Etat CM</NavLink></li> : null}
                {/*<li><NavLink to={'/cm/dashboard/djezzyapp'}>Djezzy App</NavLink></li>*/}
            </ul>
        </div>

        <div>
            <Switch>

                <Suspense  fallback={<LoadingOverlay active={true} />}>
                    {/*<Route exact path="/cm/dashboard" component={Sites} />
                    <Route exact path="/cm/dashboard/tasks" component={Tasks} />
                    <Route exact path="/cm/dashboard/equip" component={AdminComp} />
                    <Route exact path="/cm/dashboard/agents" component={Agents} />*/}
                    <Route exact path="/cm/dashboard" component={Tasks} />
                    <Route exact path="/cm/dashboard/tasks" component={Tasks} />
                    <Route exact path="/cm/dashboard/sites" component={Sites} />
                    <Route exact path="/cm/dashboard/hssites" component={HsSites} />
                    <Route exact path="/cm/dashboard/pendingcm" component={PendingCM} />
                    <Route exact path="/cm/dashboard/statecm" component={StateCM} />
                    {/*<Route exact path="/cm/dashboard/djezzyapp" component={DjezzyApp} />*/}
                </Suspense>

            </Switch>
        </div>
        </>
      );
  }


}

//export default CMDashboardWrapper;
export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(CMDashboardWrapper);