import React, { useState, useRef, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import api from "../../../Utils/Api";
import Select from 'react-select';
import Constants from "../../../Utils/Constants";


export default class CCActionConf extends React.Component {

  constructor(props)  {

    super(props);

    this.state = {
      loading: true,
      services: [],
    }
  }

  componentDidMount() {

    this.loadData();
  }

  loadData = () => {

    api.get('api/settings/cc/actions/conf')
      .then(res => {
        //console.log("hello res", res);
        this.setState({loading: false});
        if(res.data.success)
          this.setState({ ...res.data.data })
      })
  }

  addItem = (type) => {

    const selectedType = this.state[type];
    selectedType.push({
      service_id: "", 
      service_name: "",
      action_id: "",
      action_name: "",
      action_detail_id: "",
      action_detail_name: "",
      service_dst_id: "",
      service_dst_name: "", 
      editable: true
    })
    this.setState({ [type]: selectedType })
  }

  downloadItem = (e, type) => {

    e.preventDefault();

    const action = Constants.apiURL + `/api/settings/cc/actions/conf/download`;

    const form = document.createElement('form');
    form.action = action;
    form.method = 'GET';
    form.target = '_blank';  
    
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'token';
    input.value = this.props.user.token;
    form.appendChild(input);

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);

    this.setState({loading: false});
  }

  deleteItem = (item_id, item_idx, type) => {

    let selectedType = this.state[type];
    selectedType.splice(item_idx, 1);
    this.setState({ [type]: selectedType })
    if(item_id)
      this.loadData()
  }

  render() {

    //console.log("hello state", this.state);

    return (
      <>
        <h3>
          Configuration Actions CC
          {this.state.loading && <FontAwesomeIcon icon={faSpinner} spin className={"ml-2"} />}
        </h3>

        <div className={"row mt-12"}>

          <ItemType
            label={"Service / Action / Détails action  / Service destination"}
            type={"services"}
            deleteItem={this.deleteItem}
            addItem={this.addItem}
            downloadItem={this.downloadItem}
            data={this.state.services}
            selectData={[]}
            props={this.props}
          />

        </div>

      </>
    )
  }
}

const ItemType = ({type, label, deleteItem, addItem, downloadItem, data, selectData, props}) => {

  //console.log("hello state0", selectData);

  return (
    <div className={"col-md-12"}>
      <div className={"single-item-type"}>
        <h4>
          {label}
          <form action="" target={"_blank"} method="get" onSubmit={(e) => downloadItem(e, type)}>
            {/*<input type="hidden" name={"token"} value={props.user.token} />*/}
            <button type="submit" className={"float-right btn btn-primary btn-sm"} style={{ marginLeft: '10px' }}>
              <b>Exporter</b>
            </button>
          </form>
          <button className={"float-right btn btn-primary btn-sm"} onClick={() => addItem(type)}><b>Ajouter</b></button>
          <div className={"clearfix mb-3"}></div>
        </h4>

        <ul className={"custom-list"}>
          {data.map((item, idx) => (
            <SingleRow
              key={item.item_id}
              deleteItem={() => deleteItem(item.id, idx, type)}
              item={item}
              item_type={type}
              selectData={selectData}
              props={props}
            />
          ))}
        </ul>
      </div>
    </div>
  )
}

const SingleRow = ({ item, deleteItem, item_type, selectData, props }) => {

  //console.log("hello item", item);
  
  const [service, setService] = useState(item.service_name);
  const serviceRef = useRef();
  const [serviceId, setServiceId] = useState(item.service_id);
  const serviceIdRef = useRef(null);
  const [action, setAction] = useState(item.action_name);
  const actionRef = useRef(null);
  const [actionId, setActionId] = useState(item.action_id);
  const actionIdRef = useRef(null);
  const [actionDetail, setActionDetail] = useState(item.action_detail_name);
  const actionDetailRef = useRef(null);
  const [actionDetailId, setActionDetailId] = useState(item.action_detail_id);
  const actionDetailIdRef = useRef(null);
  const [serviceDst, setServiceDst] = useState(item.service_dst_name);
  const serviceDstRef = useRef(null);
  const [serviceDstId, setServiceDstId] = useState(item.service_dst_id);
  const serviceDstIdRef = useRef(null);

  const [isEditable, setEditable] = useState(item.editable);
  const [loading, setLoading] = useState(false);

  const [actionDetailsArray, setActionDetailsArray] = useState([]);

  useEffect(() => {
    console.log('hello updated:', service);
  }, [service]);

  const handleServiceChange = (selectedOption) => {
    setService(selectedOption.label);
    setServiceId(selectedOption.value);
  };

  const handleActionChange = (selectedOption) => {
    setAction(selectedOption.label);
    setActionId(selectedOption.value);

    var parent = selectedOption.label;

    setActionDetailsArray(props.filters.cc_action_details
        .filter(obj => String(obj.parent_label).toUpperCase() === String(parent).toUpperCase())
        .map(obj => ({ label: obj.label, value: obj.value }))
        .filter(el => el.label != null)
    );
  };

  const handleActionDetailChange = (selectedOption) => {
    setActionDetail(selectedOption.label);
    setActionDetailId(selectedOption.value);
  };

  const handleServiceDstChange = (selectedOption) => {
    setServiceDst(selectedOption.label);
    setServiceDstId(selectedOption.value);
  };

  const deleteReq = (e) => {
    if (item.id) {
      setLoading(true);
      const data = {
        item_id: item.id
      };

      api.post('/api/settings/cc/actions/conf/delete', data)
        .then(res => {
          setLoading(false);
          deleteItem();
        });
    } else {
      deleteItem();
    }
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (!isEditable) {
      setEditable(true);
      return;
    }

    setEditable(false);
    setLoading(true);

    const data = {
      id: item.id,
      service_id: serviceId,
      service_name: service,
      action_id: actionId,
      action_name: action,
      action_detail_id: actionDetailId,
      action_detail_name: actionDetail,
      service_dst_id: serviceDstId,
      service_dst_name: serviceDst,
    };

    //console.log("hello data", data);

    api.post('/api/settings/cc/actions/conf/update', data)
      .then(res => {
        setLoading(false);
      });
  }

  return (
    <li style={{ position: 'relative' }}>
      <form onSubmit={submitForm}>

        <span className={"item-label"}>
          {!isEditable ? service : (
            <Select
              name="service"
              classNamePrefix="custom-select"
              options={props.filters.services} 
              value={props.filters.services.find(option => option.value === serviceId) || null} 
              onChange={handleServiceChange}
              className="col-md-8"
              menuPortalTarget={document.body}
              styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                control: base => ({ ...base, minWidth: 300 }),
                menu: base => ({ ...base, width: 300 })
              }}
            />
          )}
        </span>

        <span className={"item-label"}>
          {!isEditable ? action : (
            <Select
              name="action"
              classNamePrefix="custom-select"
              options={props.filters.cc_actions} 
              value={props.filters.cc_actions.find(option => option.value === actionId) || null} 
              className="col-md-8"
              onChange={handleActionChange}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }), 
                control: base => ({ ...base, minWidth: 300 }), 
                menu: base => ({ ...base, width: 300 }) 
              }}
            />
          )}
        </span>

        <span className={"item-label"}>
          {!isEditable ? actionDetail : (
            <Select
              name="action_detail"
              classNamePrefix="custom-select"
              //options={props.filters.cc_action_details}
              options={actionDetailsArray}
              value={props.filters.cc_action_details.find(option => option.value === actionDetailId) || null} 
              className="col-md-8"
              onChange={handleActionDetailChange}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }), 
                control: base => ({ ...base, minWidth: 300 }),
                menu: base => ({ ...base, width: 300 }) 
              }}
            />
          )}
        </span>

        <span className={"item-label"}>
          {!isEditable ? serviceDst : (
            <Select
              name="service_dst"
              classNamePrefix="custom-select"
              options={props.filters.services}
              value={props.filters.services.find(option => option.value === serviceDstId) || null} 
              className="col-md-8"
              onChange={handleServiceDstChange}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                control: base => ({ ...base, minWidth: 300 }), 
                menu: base => ({ ...base, width: 300 }) 
              }}
            />
          )}
        </span>

        <span className={"buttons-col" + (loading ? " show" : "")}>
          {
            loading
              ? (<FontAwesomeIcon icon={faSpinner} spin={true} />)
              : (
                <>
                  <button type={"submit"} className={"task-action-btn"}> <FontAwesomeIcon size={22} icon={!isEditable ? faEdit : faSave} /></button>
                  <button type="button" onClick={deleteReq} className={"task-action-btn"}> <FontAwesomeIcon size={22} icon={faTrash} /></button>
                </>
              )
          }
        </span>
      </form>
    </li>
  );
}
