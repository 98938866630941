import React from "react";
import AnomalieRow from "./AnomaliesRow";
import { Trans } from "react-i18next";
import Pagination from "../../Utils/Pagination";
import Select from 'react-select';
import api from "../../Utils/Api";

class AnomaliesList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            current: 1,
			pages: 1,
            limit: 15,
            total: 0,
            anomalies: []
        }
    }

    changeLimit = (e) => {

		if(window.ga)
			window.ga('send', 'event', 'AnoamliesList', 'changeLimit');


		this.props.isLoading();
		this.setState({
			limit: e.value,
			current: 1
		}, () => this.loadAnomalies())

    }

    componentDidMount() {

        this.props.isLoading({
            message:"Chargement des anomalies",
            animated: true
        });
        this.loadAnomalies(1);
    }



    loadAnomalies(p = this.state.current) {
        let crits = "limit="+this.state.limit+"&"+this.props.buildFilters();
		// console.log('/api/anomalies/fetch?page='+p+'&'+crits);
		api.get('/api/anomalies/fetch?page='+p+'&'+crits)
		.then((response) => {
			if(response.data.success) {

				this.setState({
					anomalies: response.data.anomalies,
					pages: response.data.pages,
					total: response.data.total
				})
			} else {
                if (response.data.errcode === 101) {
                    this.props.setAuth(false);
                }
                this.props.setFlash({
                  active: true,
                  message: response.data.error,
                  classes: "warning",
                  style: {},
                });
              }
              this.props.doneLoading();
            })
            .catch((error) => {
              this.props.setFlash({
                active: true,
                message: error.message,
                classes: "warning",
                style: {},
              });
              // console.log(`error:  ${error}`);
              this.props.doneLoading();
            });
    }

    selectPage = (p) => {

		if(window.ga)
			window.ga('send', 'event', 'AnoamliesList', 'selectPage');

		this.props.isLoading();
		this.setState({current: p});
		this.loadAnomalies(p);
	}


    render() {


        return (
            <>

                <div className={"row mb-3"}>
                    <div className={"col-md-6 items-found"}>
                        <p>
                            <b>{this.state.total}</b> anomalies trouvés
                        </p>


                    </div>
                    <div className={"col-md-6 per-page-wrap"}>
                        <div className={"per-page-content"}>
                            <p>Résultat par page</p>
                            <Select
                                classNamePrefix={"custom-select"}
                                onChange={this.changeLimit}
                                className={"per-page nm"}
                                options={this.props.filters.result_per_page}
                                value={{label: this.state.limit, value: this.state.limit}}
                            />
                        </div>
                        <Pagination
                            data={this.state}
                            handleClick={this.selectPage}
                        />

                    </div>

                </div>
                <div>
                    <div className="col-md-12 ctable mb-3">


                        <div className={"d-none d-sm-block"}>
                            <div className={"row head-titles ctable-row"}>


                                <div className={"col-md-1"}>
                                    Status
                                </div>

                                <div className={"col-md-1"}>
                                    Id
                                </div>

                                <div className={"col-md-1"}>
                                    Id tache
                                </div>

                                <div className={"col-md-2"}>
                                    Anomalie
                                </div>

                                {/*<div className={"col-md-1"}>
                                    Relevé par
                                </div>*/}

                                <div className={"col-md-1"}>
                                    Type Anomalie
                                </div>

                                {/*<div className={"col-md-3"}>
                                    Faulty System
                                </div>*/}

                                <div className={"col-md-1"}>
                                    Assignement
                                </div>

                                <div className={"col-md-2"}>
                                    Modification
                                </div>

                                <div className={"col-md-2"}>
                                    PM
                                </div>


                                <div className={"col-md-1 task-details-col"}>
                                    <Trans i18nKey="label_details" />
                                </div>

                            </div>
                        </div>


                        {this.state.anomalies.map((anomalie, key) => (
                            <div className={"row "} key={"anomalie_"+key}>
                                <div className={"col-md-12"}>
                                    <AnomalieRow
                                        anomalie={anomalie}
                                        selected={this.props.rowProps.selected_list.indexOf(anomalie.id) > -1}
                                        {...this.props.rowProps}
                                    />
                            </div>
                        </div>
                        ))}


                    </div>

                </div>
            </>
        )
    }
}
export default AnomaliesList;
