import React, { useState, useRef, createRef } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../../Utils/Api";
import ApiService from "../../../Utils/ApiService";
import { connect } from "react-redux";
import Select from "react-select";

const moment = require("moment");

const CloseCMOverlay = ({
  toggle,
  open,
  task,
  time,
  fromtime,
  owner,
  demanded_by,
  user,
  reload,
  scope = "cm",
  ...props
}) => {
  var totime = moment(time).format("YYYY-MM-DDTHH:mm");

  const messageRef = useRef(null);
  //const timeRef = useRef(totime)
  const timeRef = createRef(totime);
  const solvedByRef = useRef(null);

  timeRef.current = time;

  //console.log("hello", time, totime, timeRef, solvedByRef);

  const returnTask = () => {
    //console.log("hello", solvedByRef.current.select.props.value.value);

    var dateFrom = new Date(fromtime);
    var dateTo = new Date(timeRef.current.value);
    var now = new Date();

    //console.log("hello", dateFrom , dateTo, dateFrom > dateTo);

    if (dateFrom > dateTo) {
      alert("Attention, la date de fin est antérieure à la d'alerting!");
    } else if (dateTo > now) {
      alert("Attention, la date de fin est postérieure à la date actuelle!");
    } else if (
      timeRef.current != null &&
      solvedByRef.current.select.props.value != null
    ) {
      if (
        timeRef.current !== null &&
        timeRef.current.value &&
        solvedByRef.current != null &&
        solvedByRef.current.select.props.value.value
      ) {
        props.isLoading({
          message: "Clôture de la tâche CM en cours",
          animated: true,
        });

        var data = {
          //site_plan_id : planid,
          //agent_id : agentid,
          site_plan_id: task,
          comment: messageRef.current.value,
          to_time: timeRef.current.value,
          agent_id: user,
          solved_by: solvedByRef.current.select.props.value.value,
        };

        api
          .post("/api/cm/approve", data)
          .then((response) => {
            if (response.data.success) {
              //added to prevent the forum being saved to the navigator
              //window.location.reload();
              solvedByRef.current.select.clearValue();
              messageRef.current.value = "";
              timeRef.current.value = "";
            } else {
              alert("Envoi echoué");
            }
            toggle();
            reload();
            props.doneLoading();
          })
          .catch((err) => {
            alert(`Envoi echoué: ${err.message}`);
            props.doneLoading();
          });
      } else {
        alert("Veuillez remplir tous les champs obligatoires!");
      }
    } else {
      alert("Veuillez remplir tous les champs obligatoires!");
    }
  };

  return (
    <>
      <div
        className={
          open ? "ui-overlay approve-task open" : "ui-overlay approve-task"
        }
      >
        <div
          className={
            "ui-overlay-content status-overlay-text col-md-4 offset-md-4"
          }
        >
          <button
            onClick={(e) => toggle()}
            className={"ui-overlay-close btn btn-danger btn-sm"}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <h5 className={"mb-4"}>Clôturer CM : </h5>

          <label>Owner</label>
          <Select
            name='owner'
            classNamePrefix={"disabled-custom-select"}
            className={"mb-4"}
            value={{ label: owner, value: owner }}
            disabled
            isDisabled={true}
          />

          <label>Confirmation demandé par</label>
          <Select
            name='owner'
            classNamePrefix={"disabled-custom-select"}
            className={"mb-4"}
            value={{ label: demanded_by, value: demanded_by }}
            disabled
            isDisabled={true}
          />

          <label>Date fin inséré par l'agent</label>
          <input
            name='time'
            type={"text"}
            className={"form-control"}
            value={time}
            disabled
            isDisabled={true}
          />

          <br />
          {/*<label>Date fin inséré par l'agent: {time ? time : '-'}</label>
                    <br/>*/}

          <label>Confirmer/Changer la date *</label>
          <input
            name='to_time'
            type={"datetime-local"}
            className={"form-control"}
            //onChange={e => {totime = e.target.value}}
            //defaultValue={totime}
            //value={totime} //gets only the initial value
            //value={timeRef.current.value}
            required
            ref={timeRef} //gets everything but the initial value
          />

          <br />
          <label>Solved by *</label>
          <Select
            name='solved_by'
            className={"form-control mb-4"}
            //value = {props.filters.services.filter(option => option.label === demanded_by)}
            options={[
              { label: "Sans intervention", value: "0" },
              ...props.filters.services,
            ]}
            required
            ref={solvedByRef}
            //cacheOptions
            //defaultOptions
          />

          <label>Commentaire</label>
          <textarea ref={messageRef} className={"form-control"} />
          <button onClick={returnTask} className={"btn btn-primary mt-3"}>
            Valider
          </button>
        </div>
      </div>
    </>
  );
};

export default CloseCMOverlay;
/*export default connect(
    null,
    null,
    null,
    {forwardRef: true}
  )(CloseCMOverlay);*/
