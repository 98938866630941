import http from "./Api"
import Constants from "./Constants";


const loginCall = data => http.post(Constants.apiEndPoints.CONNECT, data);
const fetchTasks = params => http.get();
const fetchTaskDetail = id => http.get(Constants.apiEndPoints.FETCH_TASK_DETAIL(id));
const fetchGPMTaskDetail = id => http.get(Constants.apiEndPoints.FETCH_GPM_TASK_DETAIL(id));
const fetchFilters = () => http.get(Constants.apiEndPoints.FETCH_FILTERS);
const fetchNotifications = () => http.get(Constants.apiEndPoints.FETCH_NOTIFICATIONS);

//const closeTask = (taskid) => http.post('/api/pm/close/'+taskid);
const closeTask = taskid => http.post('/api/pm/edit/close', taskid);
const returnTask = data => http.post('/api/pm/return', data);
        
export default {
    fetchTaskDetail,
    fetchGPMTaskDetail,
    returnTask,
    closeTask,
    loginCall,
    fetchTasks,
    fetchFilters,
    fetchNotifications
}
