import React from "react";
import { Chart } from "chart.js";
import * as ChartGeo from "chartjs-chart-geo";
import { Pie, HorizontalBar, Bar } from "react-chartjs-2";
import { chartColors, gradientColors } from "../../../../Utils/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Select from "react-select";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import api from "../../../../Utils/Api";
import ReactDatePicker from "react-datepicker";


const statesData = require("../../../../../data/algeria.json");
const countryData = require("../../../../../data/africa.json");
Chart.plugins.unregister(ChartDataLabels);
const moment = require("moment");


class Received extends React.Component {

  constructor() {

    super();

    this.state = {
      loading: true,
      wilayas: [],
      clusters: [],
      receivedByWilaya: [],
      received: [],
      selectedWilaya: "",
      selectedStatus: "",
      startDate: moment().format("YYYY-01-01 00:00:00"),
      endDate: moment().format("YYYY-12-31 23:59:00"),
      showAllWilayas: false,
    };

    this.wilayasColorsAr = {
      "ADRAR": chartColors[0],
      "CHLEF": chartColors[1],
      "LAGHOUAT": chartColors[2],
      "OEB": chartColors[3],
      "BATNA": chartColors[4],
      "BEJAIA": chartColors[5],
      "BISKRA": chartColors[6],
      "BECHAR": chartColors[7],
      "BLIDA": chartColors[8],
      "BOUIRA": chartColors[9],
      "TAMANRASSET": chartColors[10],
      "TEBESSA": chartColors[11],
      "TLEMCEN": chartColors[12],
      "TIARET": chartColors[13],
      "TIZI OUZOU": chartColors[14],
      "ALGER": chartColors[15],
      "DJELFA": chartColors[16],
      "JIJEL": chartColors[17],
      "SETIF": chartColors[18],
      "SAIDA": chartColors[19],
      "SKIKDA": chartColors[20],
      "SBA": chartColors[21],
      "ANNABA": chartColors[22],
      "GUELMA": chartColors[23],
      "CONSTANTINE": chartColors[24],
      "MEDEA": chartColors[25],
      "MOSTAGANEM": chartColors[26],
      "MSILA": chartColors[27],
      "MASCARA": chartColors[28],
      "OUARGLA": chartColors[29],
      "ORAN": chartColors[30],
      "EL BAYADH": chartColors[31],
      "ILLIZI": chartColors[32],
      "BORDJ BOU ARRERIDJ": chartColors[33],
      "BOUMERDES": chartColors[34],
      "EL_TARF": chartColors[35],
      "TINDOUF": chartColors[36],
      "TISSEMSILT": chartColors[37],
      "EL_OUED": chartColors[38],
      "KHENCHELA": chartColors[39],
      "SOUK-AHRAS": chartColors[40],
      "TIPAZA": chartColors[41],
      "MILA": chartColors[42],
      "AIN_DEFLA": chartColors[43],
      "NAAMA": chartColors[44],
      "AIN_TEMOUCHENT": chartColors[45],
      "GHARDAIA": chartColors[46],
      "RELIZANE": chartColors[47],
      "TIMIMOUN": chartColors[48],
      "BORDJ BADJI MOKHTAR": chartColors[49],
      "OULED DJELLAL": chartColors[50],
      "BÉNI ABBÈS": chartColors[51],
      "IN SALAH": chartColors[52],
      "IN GUEZZAM": chartColors[53],
      "TOUGGOURT": chartColors[54],
      "DJANET": chartColors[55],
      "EL MGHAIR": chartColors[56],
      "EL MENIAA": chartColors[57],
    };

    this.typeColorsAr = {
      "Quality": chartColors[0],
      "Coverage": chartColors[6],
      "Data": chartColors[2],
      "Core": chartColors[4],
    };
  }

  setStartDate = e => this.setState({ startDate: e });

  setEndDate = e => this.setState({ endDate: e });

  loadData = async () => {

    const sitesData = api.get(`/api/dashboard/cc/received?wilaya=${this.state.selectedWilaya}&status=${this.state.selectedStatus}&start_date=${moment(this.state.startDate).format("YYYY-MM-DD")}&end_date=${moment(this.state.endDate).format("YYYY-MM-DD")}`);

    const country = new Promise(resolve => resolve(countryData));

    const states = new Promise(resolve => resolve(statesData));

    return Promise.all([states, country, sitesData]);
  }

  componentDidMount() {

    this.drawGraphs();
  }

  drawGraphs = () => {

    this.setState({ loading: true });

    this.loadData().then(data => {
      this.setState({
        loading: false,
        received: data[2].data.data.received,
        receivedByWilaya: data[2].data.data.receivedByWilaya,
      });
    });
  }


  setStartDate = e => this.setState({ startDate: e });

  setEndDate = e => this.setState({ endDate: e });

  updateFilters = filters => {
    this.setState(filters);
  };


  render() {

    const totalReceived =  this.state.received.reduce((acc, curr) => acc + parseInt(curr.number),0);

    return (
      <>
        <div className={"page-loading " + (this.state.loading ? "active" : "")}/>

        <div className={"dashboard-page"}>
        <div className={"container-header header-spacing nmb"}>

          <div></div>
          <div className={"date-filters"}>
            <>
              <b>Status</b>
              <Select
                classNamePrefix={"custom-select"}
                className={"select-menu"}
                options={[
                  { label: "Tous", value: "" },
                  { label: "OPEN", value: "open" },
                  ...this.props.filters.cc_status,
                ]}
                placeholder={"Status"}
                onChange={e => this.updateFilters({ selectedStatus: e.value })}
              />

              <b>Wilaya</b>
              <Select
                classNamePrefix={"custom-select"}
                className={"select-menu"}
                options={[
                  { label: "Tous", value: "" },
                  ...this.props.filters.new_wilayas
                ]}
                placeholder={"Wilaya"}
                onChange={e => this.updateFilters({ selectedWilaya: e.value })}
              />

              <b>Periode </b>
              <ReactDatePicker
                dateFormat={"yyyy-MM-dd"}
                selected={
                  this.state.startDate
                    ? new Date(this.state.startDate)
                    : undefined
                }
                onChange={date => this.setStartDate(date)}
                selectsStart
                startDate={
                  this.state.startDate
                    ? new Date(this.state.startDate)
                    : undefined
                }
                endDate={
                  this.state.endDate ? new Date(this.state.endDate) : undefined
                }
                className={"dashboard-filter-input"}
              />

              <ReactDatePicker
                dateFormat={"yyyy-MM-dd"}
                selected={
                  this.state.endDate ? new Date(this.state.endDate) : undefined
                }
                onChange={date => this.setEndDate(date)}
                selectsEnd
                startDate={
                  this.state.startDate
                    ? new Date(this.state.startDate)
                    : undefined
                }
                endDate={
                  this.state.endDate ? new Date(this.state.endDate) : undefined
                }
                minDate={
                  this.state.startDate
                    ? new Date(this.state.startDate)
                    : undefined
                }
                className={"dashboard-filter-input ml-3"}
              />

              <button
                onClick={this.drawGraphs}
                type={"button"}
                className={"filter-apply"}
              >
                <FontAwesomeIcon icon={faFilter} />
              </button>
            </>
          </div>
        </div>

        <div className={"row dashboard-row "}>
          <div className={"col-md-12"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                
                <div className={"dashboard-box"}>
                  <span className={"dashboard-box-title"}>Received complaints</span>
                  <span className={"dashboard-box-value"}>{totalReceived}</span>
                </div>

                <div className={"single-chart"}>
                  
                  <Bar
                    options={{
                      layout: {
                        padding: {
                          right: 50
                        }
                      },
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              fontColor:"#333",
                              fontFamily:"Open sans"
                            },
                            gridLines: {
                              display: true
                            }
                          }
                        ],
                        xAxes: [
                          {
                            ticks: {
                              fontColor:"#333",
                              fontFamily:"Open sans"
                            },
                            gridLines: {
                              display: true
                            }
                          }
                        ]
                      },
                      plugins: {
                        datalabels: {
                          anchor: "end",
                          color: "#333",
                          align: "end",
                          backgroundColor: "rgba(0,0,0,.1)",
                          padding: 3,
                          font: {
                            family: "Open sans",
                            weight: "bold"
                          }
                        }
                      },
                      legend: {
                        display: false
                      }
                    }}
                    plugins={[ChartDataLabels]}
                    data={{
                      labels: this.state.received.map(item => item.complaint_type+" ("+(item.number/totalReceived*100).toFixed(2)+"%)"), 
                      datasets: [
                        {
                          data: this.state.received.map(item => item.number),
                          backgroundColor: this.state.received.map(item => this.typeColorsAr[item.complaint_type]),
                          hoverBackgroundColor: this.state.received.map(item => this.typeColorsAr[item.complaint_type])
                        }
                      ]
                    }}
                  />
                </div>
              </div>

              <div className={"col-md-6"}>
                <div className={"dashboard-box"}>
                  <span className={"dashboard-box-title"}>Received by type</span>
                  <span className={"dashboard-box-value"}>{totalReceived}</span>
                </div>

                <div className={"single-chart"}>

                  <Pie
                      data={{
                          labels: this.state.received.map(item => item.complaint_type+" ("+(item.number/totalReceived*100).toFixed(2)+"%)"),
                          datasets: [{
                              data: this.state.received.map(item => item.number),
                              backgroundColor: this.state.received.map(item => this.typeColorsAr[item.complaint_type]),
                              hoverBackgroundColor: this.state.received.map(item => this.typeColorsAr[item.complaint_type])
                          }]
                      }}
                  />

                </div>
                
              </div>

              <div className={"col-md-9"}>
                
                <div className={"dashboard-box"}>
                  <span className={"dashboard-box-title"}>Received by wilaya</span>
                  <span className={"dashboard-box-value"}>{totalReceived}</span>
                </div>

                <div className={"single-chart"}>
                  
                  <Bar
                    options={{
                      layout: {
                        padding: {
                          right: 50
                        }
                      },
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              fontColor:"#333",
                              fontFamily:"Open sans"
                            },
                            gridLines: {
                              display: true
                            }
                          }
                        ],
                        xAxes: [
                          {
                            ticks: {
                              fontColor:"#333",
                              fontFamily:"Open sans"
                            },
                            gridLines: {
                              display: true
                            }
                          }
                        ]
                      },
                      plugins: {
                        datalabels: {
                          anchor: "end",
                          color: "#333",
                          align: "end",
                          backgroundColor: "rgba(0,0,0,.1)",
                          padding: 3,
                          font: {
                            family: "Open sans",
                            weight: "bold"
                          }
                        }
                      },
                      legend: {
                        display: false
                      }
                    }}
                    plugins={[ChartDataLabels]}
                    data={{
                      labels: this.state.receivedByWilaya.map(item => item.wilaya+" ("+(item.number/totalReceived*100).toFixed(2)+"%)"), 
                      datasets: [
                        {
                          data: this.state.receivedByWilaya.map(item => item.number),
                          backgroundColor: this.state.receivedByWilaya.map(item => this.wilayasColorsAr[item.wilaya]),
                          hoverBackgroundColor: this.state.receivedByWilaya.map(item => this.wilayasColorsAr[item.wilaya])
                        }
                      ]
                    }}
                  />
                </div>
              </div>

              <div className={"col-md-3"}>
                <div className={"single-chart"}>
                  <h4  style={{ textAlign: 'right' }}>
                    <a
                      className={"text-center view-more d-block float-left ml-1"}
                      href="javascript:void(0)"
                      onClick={() =>
                        this.setState({
                          showAllWilayas: !this.state.showAllWilayas
                        })
                      }
                    >
                      {this.state.showAllWilayas ? "Réduire" : "Tous afficher"}
                    </a>
                    Wilayas
                    </h4>
                    
                  <table className={"table"}>
                    <thead>
                      {this.state.receivedByWilaya.map((item, k) => {
                        if (this.state.showAllWilayas || k < 9)
                          return (
                            <tr>
                              <th>{item.wilaya}</th>
                              <th>{item.number}</th>
                            </tr>
                          );
                      })}
                    </thead>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>
        </div>
      </>
    );
  }
}

const mapState = state => {
  return {
    filters: state.filters,
    session: state.session
  }
}
export default connect(mapState)(Received);
