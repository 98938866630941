import React from "react";
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

const ShowCMOverlay = ({open, task, close, reload, ...props}) => {
  
  //console.log("hello task", task, props.filters);

    return (
      <div className={"ui-overlay " + (open ? " open" : "")}>
        <div className={"ui-overlay-content site-overlay col-md-5"}>
          <button
            data-package-id="0"
            onClick={(e) => {
              close(e);
            }}
            className={"ui-overlay-close btn btn-danger btn-sm"}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <h5 className={"mb-3"}>Détails CC</h5>

          <form>
            {open && (
              <>
                <input
                  type="hidden"
                  name="id"
                  value={task.id}
                />

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>ID</label>
                  <input
                    type="text"
                    name="id"
                    className={"form-control col-md-8"}
                    value={task.id}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Classification</label>
                  <input
                    type="text"
                    name="classification"
                    className={"form-control col-md-8"}
                    value={task.classification}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>ID ticket parent</label>
                  <input
                    type="text"
                    name="idp_exist"
                    className={"form-control col-md-8"}
                    value={task.idp_exist}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Wilaya</label>
                  <input
                    type="text"
                    name="wilaya"
                    className={"form-control col-md-8"}
                    value={task.wilaya}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Commune</label>
                  <input
                    type="text"
                    name="commune"
                    className={"form-control col-md-8"}
                    value={task.commune}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Problème</label>
                  <input
                    type="text"
                    name="problem"
                    className={"form-control col-md-8"}
                    value={task.problem}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Sous problème</label>
                  <input
                    type="text"
                    name="sub_problem"
                    className={"form-control col-md-8"}
                    value={task.sub_problem}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Déscription</label>
                  <textarea
                    rows={2}
                    name="details"
                    className={"form-control col-md-8"}
                    value={task.details}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Action</label>
                  <input
                    type="text"
                    name="cc_history_last_action"
                    className={"form-control col-md-8"}
                    value={task.cc_history_last_action != 0 ? task.cc_history_last_action : ''}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Détails action</label>
                  <input
                    type="text"
                    name="cc_history_last_action_details"
                    className={"form-control col-md-8"}
                    value={task.cc_history_last_action_details != 0 ? task.cc_history_last_action_details : ''}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Dernier commentaire</label>
                  <textarea
                    rows={2}
                    name="cc_history_last_comment"
                    className={"form-control col-md-8"}
                    value={task.cc_history_last_comment != 0 ? task.cc_history_last_comment : ''}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Date action</label>
                  <input
                    type="text"
                    name="cc_history_last_action_date"
                    className={"form-control col-md-8"}
                    value={task.cc_history_last_action_date != 0 ? task.cc_history_last_action_date : ''}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Adresse</label>
                  <textarea
                    rows={2}
                    name="address"
                    className={"form-control col-md-8"}
                    value={task.address}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Type plainte</label>
                  <input
                    type="text"
                    name="complaint_type"
                    className={"form-control col-md-8"}
                    value={task.complaint_type}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Commentaire</label>
                  <textarea
                    rows={2}
                    name="comment"
                    className={"form-control col-md-8"}
                    value={task.comment}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Date réception</label>
                  <input
                    type="text"
                    name="reception_date"
                    className={"form-control col-md-8"}
                    value={task.reception_date}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Date inseertion</label>
                  <input
                    type="text"
                    name="created_at"
                    className={"form-control col-md-8"}
                    value={task.created_at}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Date problème</label>
                  <input
                    type="text"
                    name="problem_date"
                    className={"form-control col-md-8"}
                    value={task.problem_date}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Num source</label>
                  <input
                    type="text"
                    name="source_num"
                    className={"form-control col-md-8"}
                    value={task.source_num}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Num contact</label>
                  <input
                    type="text"
                    name="contact_num"
                    className={"form-control col-md-8"}
                    value={task.contact_num}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Type</label>
                  <input
                    type="text"
                    name="in_out"
                    className={"form-control col-md-8"}
                    value={task.in_out}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Status</label>
                  <input
                    type="text"
                    name="cc_status"
                    className={"form-control col-md-8"}
                    value={task.cc_status}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Date status</label>
                  <input
                    type="text"
                    name="status_date"
                    className={"form-control col-md-8"}
                    value={task.status_date}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Service</label>
                  <input
                    type="text"
                    name="service_name"
                    className={"form-control col-md-8"}
                    value={task.service_name}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Cause status</label>
                  <input
                    type="text"
                    name="status_cause"
                    className={"form-control col-md-8"}
                    value={task.status_cause}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Commentaire status</label>
                  <input
                    type="text"
                    name="status_comment"
                    className={"form-control col-md-8"}
                    value={task.status_comment}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>ID SO</label>
                  <input
                    type="text"
                    name="so_id"
                    className={"form-control col-md-8"}
                    value={task.so_id}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>ID OTAP</label>
                  <input
                    type="text"
                    name="otap_id"
                    className={"form-control col-md-8"}
                    value={task.otap_id}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>GPS X</label>
                  <input
                    type="text"
                    name="gps_x"
                    className={"form-control col-md-8"}
                    value={task.gps_x}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>GPS Y</label>
                  <input
                    type="text"
                    name="gps_y"
                    className={"form-control col-md-8"}
                    value={task.gps_y}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>ID ARPCE</label>
                  <input
                    type="text"
                    name="arpce_id"
                    className={"form-control col-md-8"}
                    value={task.arpce_id}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>IMSI</label>
                  <input
                    type="text"
                    name="imsi"
                    className={"form-control col-md-8"}
                    value={task.imsi}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Région</label>
                  <input
                    type="text"
                    name="region"
                    className={"form-control col-md-8"}
                    value={task.region}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Wait budget</label>
                  <input
                    type="text"
                    name="wait_budget"
                    className={"form-control col-md-8"}
                    value={task.wait_budget == "1" ? "Oui" : "Non"}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Validated</label>
                  <input
                    type="text"
                    name="validated"
                    className={"form-control col-md-8"}
                    value={task.validated == "1" ? "Oui" : "Non"}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Inseré par</label>
                  <input
                    type="text"
                    name="created_by_username"
                    className={"form-control col-md-8"}
                    value={task.created_by_username}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Date insertion</label>
                  <input
                    type="text"
                    name="created_at"
                    className={"form-control col-md-8"}
                    value={task.created_at}
                    disabled
                  />
                </div>

              </>
            )}
          </form>
        </div>
      </div>
    );
  
}

export default ShowCMOverlay;
