import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import {setAuthAction, startLoading, doneLoading, /*setFlash,*/ closeFlash} from '../../../Redux/actions/index';
import { setFlash, } from "../../../Redux/actions";
import { Trans } from "react-i18next";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {  faTimes } from '@fortawesome/free-solid-svg-icons'
import api from '../../../Utils/Api';
import Constants from '../../../Utils/Constants';
import ApiService from '../../../Utils/ApiService';
import CCTaskRow from './CCTaskRow';
import EditCCOverlay from "./EditCCOverlay";
import ShowCCOverlay from "./ShowCCOverlay";
import { Link, Redirect } from "react-router-dom";
import TreatCCOverlay from "./TreatCCOverlay";
import ChangeStatusCCOverlay from "./ChangeStatusCCOverlay";
import DeleteCCOverlay from './DeleteCCOverlay';
import ForceIdpCCOverlay from './ForceIdpCCOverlay';


const mapStateToProps = state => {
	return {
		session: state.session,
		filters: state.filters
	}
}

const mapDispatchToProps = (dispatch) => ({
	isLoading: e => dispatch(startLoading(e)),
	doneLoading: () => dispatch(doneLoading()),
	setAuth: e => dispatch(setAuthAction(e)),
	setFlash: (f) => dispatch(setFlash(f)),
	closeFlash: (f) => dispatch(closeFlash(f))
})


class CCTaskList extends Component {

	constructor(props) {

		super(props);

		this.state = {
			rows: [],
			cm_ids: [],
			wo_ids: [],
			cc_ids: [],
			showDetailsCCTaskOverlay: false,
			showTreatCCOverlay: false,
			showChangeStatusCCOverlay: false,
			showDeleteCCOverlay: false,
			showForceIdpCCOverlay: false,

			selected_pm: "",
			activeTest: "clockin",

			showCCTaskOverlay: false,
			
			redirect: false,
			parent_ref: "",
		}

		this.timerID = null;

		this.loadTasksId = this.loadTasksId.bind(this);

		//load CM tasks
		this.props.isLoading({
			message:"Chargement des tickets CC",
			animate:true
		});

		this.loadTasksId();
	}

	loadTasksId() {

		this.props.isLoading();

		const loadCM = api.get('/api/cm/taskid/fetch');
		const loadWO = api.get('/api/wo/taskid/fetch');
		const loadCC = api.get('/api/cc/taskid/fetch');

		Promise.all([loadCM, loadWO, loadCC])
			.then(([responseCM, responseWO, responseCC]) => {

				if (responseCM.data.success) {
					this.setState({ cm_ids: responseCM.data.cm_ids.map(id => ({label: id.ticket_ref, value: id.ticket_ref }))});
					
				} else {
					if (responseCM.data.errcode === 101) {
						this.props.setAuth(false);
						this.props.doneLoading();
					}
				}

				if (responseWO.data.success) {
					this.setState({ wo_ids: responseWO.data.wo_ids.map(id => ({label: id.ticket_ref, value: id.ticket_ref }))});
				} else {
					if (responseWO.data.errcode === 101) {
						this.props.setAuth(false);
						this.props.doneLoading();
					}
				}

				if (responseCC.data.success) {
					this.setState({ cc_ids: responseCC.data.ids.map(id => ({label: id.id, value: id.id }))});
				} else {
					if (responseCC.data.errcode === 101) {
						this.props.setAuth(false);
						this.props.doneLoading();
					}
				}

				if(responseCM.data.success && responseWO.data.success && responseCC.data.success) {
					this.props.doneLoading();
				}

			})
			.catch(e => {
				this.props.doneLoading();
			});
  	}

	//show details CC
	showTask = (task) => {

		this.setState({
			selected_pm: task,
			showDetailsCCTaskOverlay: true,
		})
	}

	toggleDetailtsCCTaskOverlay = () => this.setState({showDetailsCCTaskOverlay: !this.state.showDetailsCCTaskOverlay})

	//treat CC
	treatTask = (task) => {

		this.setState({
			selected_pm: task,
			showTreatCCOverlay: true,
		})
	}

	toggleTreatCCOverlay = () => this.setState({showTreatCCOverlay: !this.state.showTreatCCOverlay})

	//change status CC
	changeStatusTask = (task) => {

		this.setState({
			selected_pm: task,
			showChangeStatusCCOverlay: true,
		})
	}

	toggleChangeStatusCCOverlay = () => this.setState({showChangeStatusCCOverlay: !this.state.showChangeStatusCCOverlay})

	//wait budget
	waitBudgetTask = (task) => {

		//move to wait budget
		if(task.wait_budget == "0") {

			this.props.isLoading({
				message: "Déplacement vers wait budget en cours"
			});
	
			var data = {
				cc_task_id : task.id,
				wait_budget : "1",
			};
	
			api.post('/api/cc/wait/budget', data)
			.then(response => {
				if (response.data.success) {
					this.props.handlers.loadTasks();
					this.props.setFlash({
						active: false,
						message: "CC déplacée vers wait budget",
						classes: "success",
						style: {},
					});
				} else {
					alert('Envoi echoué');
					this.props.setFlash({
						active: false,
						message: "Une erreur est survenue, Envoi echoué.",
						classes: "warning",
						style: {},
					});
				}
			});
			//.catch(() => ));
			this.props.handlers.loadTasks();
			this.props.doneLoading();

		} else if(task.wait_budget == "1") {

			this.props.isLoading({
				message: "Retrait du wait budget en cours"
			});
	
			var data = {
				cc_task_id : task.id,
				wait_budget : "0",
			};
	
			api.post('/api/cc/wait/budget', data)
			.then(response => {
				if (response.data.success) {
					this.props.handlers.loadTasks();
					this.props.setFlash({
						active: false,
						message: "CC retirée du wait budget",
						classes: "success",
						style: {},
					});
				} else {
					alert('Envoi echoué');
					this.props.setFlash({
						active: false,
						message: "Une erreur est survenue, Envoi echoué.",
						classes: "warning",
						style: {},
					});
				}
			});
			//.catch(() => ));
			this.props.handlers.loadTasks();
			this.props.doneLoading();
		}
	};

	//delete CC
	deleteTask = (task) => {

		this.setState({
			selected_pm: task,
			showDeleteCCOverlay: true,
		})
	}

	toggleDeleteCCOverlay = () => this.setState({showDeleteCCOverlay: !this.state.showDeleteCCOverlay})

	//force idp_exist
	ForceIdpTask = (task) => {

		this.setState({
			selected_pm: task,
			showForceIdpCCOverlay: true,
		})
	}

	toggleForceIdpCCOverlay = () => this.setState({showForceIdpCCOverlay: !this.state.showForceIdpCCOverlay})

	selectTest = (e, test) => {

		if(window.ga)
			window.ga('send', 'event', 'TaskList', 'selectTest');

		e.preventDefault();
		
		this.setState({
			activeTest: test
		})
	}

	editTask = (task) => {

		this.setState({
			selected_pm: task,
			showCCTaskOverlay: true,
		})
	}

	toggleCCTaskOverlay = () => this.setState({showCCTaskOverlay: !this.state.showCCTaskOverlay})

	exportCC = (pm, anomalies) => {

		/*if(window.ga)
			window.ga('send', 'event', 'TaskList', 'exportPM');
		// let remote = Constants.apiURL; 
		if(anomalies) {
			this.pmExportForm.action = Constants.apiURL+"/api/cc/anomalies/checks_export/"+pm.id;
		} else {
			this.pmExportForm.action = Constants.apiURL+`/api/cc/task/fetch?&limit=-1&exporthistory=true&id=`+pm.id;
		}*/

		this.pmExportForm.action = Constants.apiURL+`/api/cc/task/fetch?&limit=-1&exporthistory=true&id=`+pm.id;
		
		this.pmInput.value = JSON.stringify(pm)
		this.userInfo.value = JSON.stringify(this.props.session.user)
		this.props.isLoading();
		this.pmExportForm.submit();
		this.props.doneLoading();
	}

	componentDidMount() {
		this.timerID = setInterval(this.props.handlers.loadTasks, 10*60*1000); 
	}
	
	componentWillUnmount() {
		clearInterval(this.timerID); 
	}

	render() {

		const data = this.props.data;
		const { showCmOverlay } = this.state;

		return(

			<React.Fragment>

				<form method="post" ref={ref => this.pmExportForm = ref}>
					<input type="hidden" name={"pm"} ref={ref => this.pmInput = ref} />
					<input type="hidden" name={"token"} value={this.props.session.user.token} />
					<input type="hidden" name={"userInfo"} ref={ref => this.userInfo = ref} />
				</form>

				{ this.state.redirect ? (<Redirect push to={{pathname:"/cc/new", state:this.state.parent_ref}}/>) : null }

				<ShowCCOverlay
					open={this.state.showDetailsCCTaskOverlay}
					task={this.state.selected_pm}
					close={this.toggleDetailtsCCTaskOverlay}
					reload={this.props.handlers.loadTasks}
					{...this.props}
				/>

				<TreatCCOverlay
					scope={"cc"}
					open={this.state.showTreatCCOverlay}
					task={this.state.selected_pm}
					toggle={this.toggleTreatCCOverlay}
					reload={this.props.handlers.loadTasks}
					cm_ids={this.state.cm_ids}
					wo_ids={this.state.wo_ids}
					selected={this.props.rowProps && this.props.rowProps.selected_list && this.props.rowProps.selected_list.length > 0}
					selectedList={this.props.rowProps && this.props.rowProps.selected_list && this.props.rowProps.selected_list}
					{...this.props}
				/>

				<ChangeStatusCCOverlay
					scope={"cc"}
					open={this.state.showChangeStatusCCOverlay}
					task={this.state.selected_pm}
					toggle={this.toggleChangeStatusCCOverlay}
					reload={this.props.handlers.loadTasks}
					selected={this.props.rowProps && this.props.rowProps.selected_list && this.props.rowProps.selected_list.length > 0}
					selectedList={this.props.rowProps && this.props.rowProps.selected_list && this.props.rowProps.selected_list}
					{...this.props}
				/>

				<DeleteCCOverlay
					scope={"cc"}
					open={this.state.showDeleteCCOverlay}
					task={this.state.selected_pm}
					toggle={this.toggleDeleteCCOverlay}
					reload={this.props.handlers.loadTasks}
					{...this.props}
				/>

				<EditCCOverlay
					open={this.state.showCCTaskOverlay}
					task={this.state.selected_pm}
					close={this.toggleCCTaskOverlay}
					reload={this.props.handlers.loadTasks}
					{...this.props}
				/>

				<ForceIdpCCOverlay
					scope={"cc"}
					open={this.state.showForceIdpCCOverlay}
					task={this.state.selected_pm}
					toggle={this.toggleForceIdpCCOverlay}
					reload={this.props.handlers.loadTasks}
					cc_ids={this.state.cc_ids}
					selected={this.props.rowProps && this.props.rowProps.selected_list && this.props.rowProps.selected_list.length > 0}
					selectedList={this.props.rowProps && this.props.rowProps.selected_list && this.props.rowProps.selected_list}
					{...this.props}
				/>

				<form method="post" ref={ref => this.pmExportForm = ref}>
					<input type="hidden" name={"pm"} ref={ref => this.pmInput = ref} />
					<input type="hidden" name={"token"} value={this.props.session.user.token} />
					<input type="hidden" name={"userInfo"} ref={ref => this.userInfo = ref} />
				</form>

				<div className="col-md-12 ctable mb-3">

					{
						this.props.session.user.service_name &&
						( this.props.session.user.service_name.toUpperCase() == "PRODUCTION" || this.props.session.user.service_name.toUpperCase() == "NSOC_BSS" ||
						this.props.session.user.service_name.toUpperCase() == "ROLLOUT" || this.props.session.user.service_name.toUpperCase() == "M_ALGER" ||
						this.props.session.user.service_name.toUpperCase() == "M_CHLEF" || this.props.session.user.service_name.toUpperCase() == "M_ORAN" ||
						this.props.session.user.service_name.toUpperCase() == "M_SETIF" || this.props.session.user.service_name.toUpperCase() == "M_ANNABA" || 
						this.props.session.user.service_name.toUpperCase() == "M_TLEMCN" || this.props.session.user.service_name.toUpperCase() == "M_BATNA" || 
						this.props.session.user.service_name.toUpperCase() == "M_BLIDA" || this.props.session.user.service_name.toUpperCase() == "M_CONSTANTINE" || 
						this.props.session.user.service_name.toUpperCase() == "O&M_ENV" )
						
						?

						<div className={"d-none d-sm-block"}>
							<div className={"row head-titles ctable-row"}>

								<div className={"col-md-1"}>
									ID ticket
								</div>

								<div className={"col-md-1"}>
									Région
								</div>

								<div className={"col-md-1"}>
									Code site
								</div>

								<div className={"col-md-1"}>
									ID WO
								</div>

								<div className={"col-md-1"}>
									ID CM
								</div>

								<div className={"col-md-1"}>
									Action
								</div>

								<div className={"col-md-1"}>
									Détails action
								</div>
								
								<div className={"col-md-1"}>
									Commentaire action
								</div>

								<div className={"col-md-2"}>
									Date action
								</div>

								<div className={"col-md-1"}>
									Wilaya
								</div>

								<div className={"col-md-1 task-details-col"}>
									<Trans i18nKey="label_details" />
								</div>

							</div>
						</div>

						:

						<div className={"d-none d-sm-block"}>
							<div className={"row head-titles ctable-row"}>

								<div className={"col-md-1"}>
									ID ticket
								</div>

								<div className={"col-md-1"}>
									Classification
								</div>

								<div className={"col-md-1"}>
									ID ticket parent
								</div>

								<div className={"col-md-1"}>
									Wilaya / Commune / Adresse
								</div>

								<div className={"col-md-1"}>
									Déscription
								</div>

								<div className={"col-md-1"}>
									Détails action
								</div>
								
								<div className={"col-md-1"}>
									Dernier commentaire
								</div>

								<div className={"col-md-1"}>
									ID SO
								</div>

								<div className={"col-md-1"}>
									Inséré par
								</div>

								<div className={"col-md-2"}>
									Dates
								</div>

								<div className={"col-md-1 task-details-col"}>
									<Trans i18nKey="label_details" />
								</div>

							</div>
						</div>
					}

					{data.tasks.map((task, i) =>
						<CCTaskRow
							key={i}
							exportPM={this.exportPM}
							task={task}
							handlers={this.props.handlers}
							editTask={this.editTask}
							showTask={this.showTask}
							closeTask={this.closeTask}
							exportCC={this.exportCC}
							mine={this.props.mine}
							user={this.props.session.user}
							treatTask={this.treatTask}
							changeStatusTask={this.changeStatusTask}
							waitBudgetTask={this.waitBudgetTask}
							deleteTask={this.deleteTask}
							ForceIdpTask={this.ForceIdpTask}
							filters={this.props.filters}

							selected={this.props.rowProps && this.props.rowProps.selected_list && this.props.rowProps.selected_list.indexOf(task.id) > -1}
							//selected={this.props.rowProps.selected_list.length > 0}
							selectedList={this.props.rowProps && this.props.rowProps.selected_list && this.props.rowProps.selected_list}
                        	{...this.props.rowProps}
						/>
					)}

					{data.tasks.length === 0 && (<div className={"col-md-12 text-center my-3"}>Aucune tâche CC trouvée</div>)}

				</div>

			</React.Fragment>
		)
	}
}





// export CheckOrTime;
export default connect(mapStateToProps, mapDispatchToProps)(CCTaskList);
