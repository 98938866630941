import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";


export default class SitesExport extends React.Component {

    constructor(props) {
        super (props)

    }


    selectFields = (fields) => {

        const nextState = !document.getElementById("site-field-"+fields[0]).checked;
        fields.forEach(item => {
            
            const el = document.getElementById("site-field-"+item);
            el.checked = nextState;

        })

    }

    

    exportSubmit = (e) => {
        e.preventDefault();
        e.target.action = this.props.getAction();        
        e.target.submit();
    }



    render() {

        return (
            <>

                <div className={"ui-overlay "+ (this.props.open ? " open" : "")}>
                    <div className={"ui-overlay-content custom-export status-overlay-text col-md-6 offset-md-3"}>

                        <button 
                            onClick={e => this.props.closeModal() } 
                            className={"ui-overlay-close btn btn-danger btn-sm"}>
                        <FontAwesomeIcon icon={faTimes} />

                        </button>

                        <h5 className={"mb-4"}>Exporter la liste des Sites</h5>
                        <p className={"mb-2"}>Séléctionnez les propriétés que vous voulez inclure dans le fichier à exporter</p>
                        
                        <form method="post" onSubmit={e => this.exportSubmit(e)}>
                        
                        <div className={"fields-wrapper"}>
                        

                        <ul className={"export-fields"}>
                            <li className={"mb-2"}>
                                <b>General information</b>
                                <span onClick={e => this.selectFields([1,2,3,4,5,29,30,31, 32])} className={"export-fields-select-all"}>Tout selectionner</span>
                            </li>
                            

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={true} name="site_id" type="checkbox" value="ID" className="custom-control-input" id="site-field-1" />
									<label className="custom-control-label" htmlFor="site-field-1">Site ID</label>
								</div>
                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={true} name="site_owner" type="checkbox" value="Site Owner" className="custom-control-input" id="site-field-32" />
									<label className="custom-control-label" htmlFor="site-field-32">Site Owner</label>
								</div>
                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={true} name="site_code" type="checkbox" value="Site code" className="custom-control-input" id="site-field-2" />
									<label className="custom-control-label" htmlFor="site-field-2">Site Code</label>
								</div>
                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={true} name="site_name" type="checkbox" value="Nom du site" className="custom-control-input" id="site-field-3" />
									<label className="custom-control-label" htmlFor="site-field-3">Site Name</label>
								</div>
                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={true} name="site_cluster" type="checkbox" value="Région" className="custom-control-input" id="site-field-4" />
									<label className="custom-control-label" htmlFor="site-field-4">Site Cluster</label>
								</div>
                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={true} name="site_address_wilaya" value="Wilaya" type="checkbox" className="custom-control-input" id="site-field-5" />
									<label className="custom-control-label" htmlFor="site-field-5">Site Wilaya</label>
								</div>
                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={true} name="site_manager_username" value="Site Manager" type="checkbox" className="custom-control-input" id="site-field-29" />
									<label className="custom-control-label" htmlFor="site-field-29">Site Manager</label>
								</div>
                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={true} name="site_colocalise" value="Sites Colocalisé" type="checkbox" className="custom-control-input" id="site-field-30" />
									<label className="custom-control-label" htmlFor="site-field-30">Sites Colocalisé</label>
								</div>
                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={true} name="site_colocalise" value="Sites Colocalisé" type="checkbox" className="custom-control-input" id="site-field-31" />
									<label className="custom-control-label" htmlFor="site-field-31">Sites Colocalisé</label>
								</div>
                            </li>


                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={true} name="site_owner_agent" value="Ingénieur responisable" type="checkbox" className="custom-control-input" id="site-field-32" />
									<label className="custom-control-label" htmlFor="site-field-32">Ingénieur responsable</label>
								</div>
                            </li>

                           <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={true} name="gps_position" value="Position GPS" type="checkbox" className="custom-control-input" id="site-field-33" />
									<label className="custom-control-label" htmlFor="site-field-33">Position GPS</label>
								</div>
                           </li> 


                            <li className={"mb-2"}>
                                <hr />
                                <b>PM</b>
                                <span onClick={e => this.selectFields([20,21, 25, 26, 28])} className={"export-fields-select-all"}>Tout selectionner</span>
                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={true} name="site_last_pm_at" value="Date Derniere PM" type="checkbox" className="custom-control-input" id="site-field-20" />
									<label className="custom-control-label" htmlFor="site-field-20">Derniere PM - Date</label>
								</div>
                            </li>
                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={true} name="site_last_pm_by" value="Agent Derniere PM" type="checkbox" className="custom-control-input" id="site-field-26" />
									<label className="custom-control-label" htmlFor="site-field-26">Derniere PM - Agent</label>
								</div>
                            </li>
                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={true} name="site_last_pm_id" value="ID Derniere PM" type="checkbox" className="custom-control-input" id="site-field-28" />
									<label className="custom-control-label" htmlFor="site-field-28">Derniere PM - ID</label>
								</div>
                            </li>
                            
                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={true} name="site_next_pm" type="checkbox" value="Date prochaine PM" className="custom-control-input" id="site-field-21" />
									<label className="custom-control-label" htmlFor="site-field-21">Prochaine PM - Date</label>
								</div>
                            </li>
                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={true} name="site_next_pm_agent" type="checkbox" value="Agent prochaine PM" className="custom-control-input" id="site-field-25" />
									<label className="custom-control-label" htmlFor="site-field-25">Prochaine PM - Agent</label>
								</div>
                            </li>


                            
                            <li className={"mb-2"}>

                                <hr />
                                <span onClick={e => this.selectFields([9,10,11,12])} className={"export-fields-select-all"}>Tout selectionner</span>

                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={true} name="site_shelter" value="Type" type="checkbox" className="custom-control-input" id="site-field-9" />
									<label className="custom-control-label" htmlFor="site-field-9">Shelter type</label>
								</div>
                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={true} name="site_typology" value="Typology" type="checkbox" className="custom-control-input" id="site-field-10" />
									<label className="custom-control-label" htmlFor="site-field-10">Typology</label>
								</div>
                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={true} name="antenne" value="Support antenne" type="checkbox" className="custom-control-input" id="site-field-11" />
									<label className="custom-control-label" htmlFor="site-field-11">Support Antenne</label>
								</div>
                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={true} name="site_pylone" value="Pylone" type="checkbox" className="custom-control-input" id="site-field-12" />
									<label className="custom-control-label" htmlFor="site-field-12">Pylone</label>
								</div>
                            </li>

                            <li className={"mb-2"}>

                                <hr />
                                <span onClick={e => this.selectFields([6,7,8])} className={"export-fields-select-all"}>Tout selectionner</span>

                            </li>


                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={true} name="igeneral_compteur" value="Compteur" type="checkbox" className="custom-control-input" id="site-field-6" />
									<label className="custom-control-label" htmlFor="site-field-6">Compteur</label>
								</div>
                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={true} name="igeneral_numserie" value="Numéro de série" type="checkbox" className="custom-control-input" id="site-field-7" />
									<label className="custom-control-label" htmlFor="site-field-7">Numéro de série</label>
								</div>
                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={true} name="igeneral_consommation" value="Consommation" type="checkbox" className="custom-control-input" id="site-field-8" />
									<label className="custom-control-label" htmlFor="site-field-8">Consommation</label>
								</div>
                            </li>



                        </ul>

                        <ul className={"export-fields"}>
                            <li className={"mb-2"}>
                                <b>Air Conditioning</b>
                                <span onClick={e => this.selectFields([13,14,15,16,17])} className={"export-fields-select-all"}>Tout selectionner</span>

                            </li>


                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={false} name="ac_type" value="Type AC" type="checkbox" className="custom-control-input" id="site-field-13" />
									<label className="custom-control-label" htmlFor="site-field-13">AC Type</label>
								</div>
                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={false} name="ac_gaz" value="Type gaz AC" type="checkbox" className="custom-control-input" id="site-field-14" />
									<label className="custom-control-label" htmlFor="site-field-14">Type de gaz</label>
								</div>
                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={false} name="ac_marque" value="Marque AC"  type="checkbox" className="custom-control-input" id="site-field-15" />
									<label className="custom-control-label" htmlFor="site-field-15">Marque</label>
								</div>
                            </li>


                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={false} name="ac_numserie" value="Numéro de série AC" type="checkbox" className="custom-control-input" id="site-field-16" />
									<label className="custom-control-label" htmlFor="site-field-16">Numéro de Série</label>
								</div>
                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={false} name="ac_capacity" value="Capacité AC" type="checkbox" className="custom-control-input" id="site-field-17" />
									<label className="custom-control-label" htmlFor="site-field-17">Capacité</label>
								</div>
                            </li>

                            <li className={"mb-2"}>
                                <hr />
                                <b>Power Supply</b>
                                <span onClick={e => this.selectFields([18,19,27,34])} className={"export-fields-select-all"}>Tout selectionner</span>

                            </li>


                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={false} name="ps_type" value="Type power supply" type="checkbox" className="custom-control-input" id="site-field-18" />
									<label className="custom-control-label" htmlFor="site-field-18">Type Power Supply</label>
								</div>
                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={false} name="ps_consommation" value="Consommation PS" type="checkbox" className="custom-control-input" id="site-field-19" />
									<label className="custom-control-label" htmlFor="site-field-19">Consommation</label>
								</div>
                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={false} name="ps_redresseur" value="Redresseur PS" type="checkbox" className="custom-control-input" id="site-field-27" />
									<label className="custom-control-label" htmlFor="site-field-27">Nombre de redresseur</label>
								</div>
                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={false} name="ps_number" value="Nombre PS" type="checkbox" className="custom-control-input" id="site-field-34" />
									<label className="custom-control-label" htmlFor="site-field-34">Nombre de power supply</label>
								</div>
                            </li>

                            <li className={"mb-2"}>
                                <hr />
                                <b>Batterie</b>
                                <span onClick={e => this.selectFields([22,23,24])} className={"export-fields-select-all"}>Tout selectionner</span>

                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={false} name="battery_strings" value="Nombre Strings Battery" type="checkbox" className="custom-control-input" id="site-field-22" />
									<label className="custom-control-label" htmlFor="site-field-22">Nombre Strings</label>
								</div>
                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={false} name="battery_volt" value="Voltage batteries" type="checkbox" className="custom-control-input" id="site-field-23" />
									<label className="custom-control-label" htmlFor="site-field-23">Voltage</label>
								</div>
                            </li>

                            <li>
                                <div className="custom-control custom-checkbox">
                                    <input defaultChecked={false} name="battery_marque" value="Marques batteries" type="checkbox" className="custom-control-input" id="site-field-24" />
									<label className="custom-control-label" htmlFor="site-field-24">Marques Batterie</label>
								</div>
                            </li>

                            

                        </ul>

                        </div>

                        

                        <button type="submit" className={"btn btn-success float-right"}>Générer le fichier Excel</button>
                        <button onClick={e => this.props.closeModal() }  type="button" className={"btn btn-danger float-right mr-2"}>Annuler</button>
                        <div className={"clearfix"}></div>
                        </form> 
                        

                        
                        
                    </div>
                </div>

                
            </>
            )

    }

}