import React, { useState, useRef, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import api from "../../../Utils/Api";


export default class Anomalies extends React.Component {

  constructor(props)  {

    super(props);

    this.state = {
      loading: true,
      anomalies_name: [],
      anomalies_type: [],
    }
  }

  componentDidMount() {

    this.loadData();
  }

  loadData = () => {

    api.get('/api/settings/anomalies')
      .then(res => {
        this.setState({loading: false});
        if(res.data.success)
          this.setState({ ...res.data.data })
      })
  }

  addItem = (type) => {

    const selectedType = this.state[type];
    selectedType.push({item_label: "", item_id: "", editable: true, item_faulty_system: ""})
    this.setState({ [type]: selectedType })
  }

  deleteItem = (item_type, item_id, item_idx, type) => {

    let selectedType = this.state[type];
    selectedType.splice(item_idx, 1);
    this.setState({ [type]: selectedType })
    if(item_id)
      this.loadData()
  }

  render() {

    return (
      <>
        <h3>
          Anomalies
          {this.state.loading && <FontAwesomeIcon icon={faSpinner} spin className={"ml-2"} />}
        </h3>

        <div className={"row mt-10"}>
        {/*  <ItemType
            label={"Noms d'anomalie"}
            type={"anomalies_name"}
            deleteItem={this.deleteItem}
            addItem={this.addItem}
            data={this.state.anomalies_name}
          /> */}

          <ItemType
            label={"Types d'anomalie"}
            label2={"Faulty System"}
            type={"anomalies_type"}
            deleteItem={this.deleteItem}
            addItem={this.addItem}
            data={this.state.anomalies_type}
          />
        </div>
      </>
    )
  }
}

const ItemType = ({type, label, label2, deleteItem, addItem, data, ...props}) => {

  return (
    <div className={"col-md-10"}>
      <div className={"single-item-type"}>
        <h4>
          {label}
          {/*<span> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
          {label2}*/}
          <button className={"float-right btn btn-primary btn-sm"} onClick={() => addItem(type)}><b>Ajouter</b></button>
          <div className={"clearfix mb-3"}></div>
        </h4>

        <ul className={"custom-list"}>
          {data.map((item, idx) => (
            <SingleRow
              key={item.item_id}
              deleteItem={() => deleteItem(item.item_type, item.item_id, idx, type)}
              item={item}
              item_type={type}
            />
          ))}
        </ul>
      </div>
    </div>
  )
}

const SingleRow = ({item, deleteItem, item_type, ...props}) => {

  const [isEditable, setEditable] = useState(item.editable);
  const [text, setText] = useState(item.item_label);
  const [text2, setText2] = useState(item.item_faulty_system);
  const [loading, setLoading] = useState(false);
  const textRef = useRef(null);
  const textRef2 = useRef(null);

  const deleteReq = (e) => {

    if(item.item_id) {
      setLoading(true);
      const data = {
        item_id: item.item_id
      }

      api.post('/api/settings/anomalies/delete', data)
        .then(res => {
          setLoading(false);
          //console.log(res.data);
          deleteItem();
        });
    } else {
      deleteItem();
    }
  }

  const submitForm = (e) => {

    e.preventDefault();
    if(!isEditable) {
      setEditable(true);
      return;
    }

    setText(textRef.current.value.length ? textRef.current.value : item.item_label);
    setText2(textRef2.current.value.length ? textRef2.current.value : item.item_faulty_system);
    setEditable(false);
    setLoading(true);

    const data = {
      item_id: item.item_id,
      item_label: textRef.current.value.length ? textRef.current.value : item.item_label,
      item_faulty_system: textRef2.current.value.length ? textRef2.current.value : item.item_faulty_system,
      item_type: item_type
    }

    api.post('/api/settings/anomalies/update', data)
      .then(res => {
        setLoading(false);
        //console.log(res.data)
      })
    }

    return (

      <li>
        <form onSubmit={submitForm}>
        <span className={"item-label"}>
          {!isEditable ? text : (
            <>
              <input ref={textRef} className={"form-control"} defaultValue={text} type="text" placeholder="Anomalie Type"/>
            </>
          )}
        </span>
        <span className={"item-faulty-system"}>
          {!isEditable ? text2 : (
            <>
              <input ref={textRef2} className={"form-control"} defaultValue={text2} type="text" placeholder="Faulty System" />
            </>
          )}
        </span>

        <span className={"buttons-col"+ ( loading ? " show" : "")}>
          {
            loading
            ?
            ( <FontAwesomeIcon icon={faSpinner} spin={true} /> )
            :
            (
              <>
                <button type={"submit"}  className={"task-action-btn"}> <FontAwesomeIcon size={22} icon={!isEditable ? faEdit : faSave} /></button>
                <button type="button" onClick={deleteReq} className={"task-action-btn"}> <FontAwesomeIcon size={22} icon={faTrash} /></button>
              </>
            )
          }
        </span>
      </form>
    </li>
  )
}
