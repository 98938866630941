import React from "react";
import RenderChecks from "../../components/RenderChecks";
import PhotoItem from "../../../../Utils/PhotoItem";

const Exterieur = ({ data, ...props }) => {
  return (
    <>
        <b>Nid de cigogne : </b> <b> {data.nid ? data.nid : "0"} </b><br />
      <RenderChecks data={data} />

      {data.photos && (
        <div className={"test-photos-wrapper"}>
          {data.photos.map((photo, key) => (
            <div className={"test-photos-single"}>
              <PhotoItem photo={photo} active={props.active} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Exterieur;
