import React from "react";

const Batteries = ({ data, ...props }) => {
  let strings = [];

  if (data.steps && data.steps.length) {
    for (let i = 0; i < data.steps.length; i++) {
      const step = data.steps[i];
      if (step.length > 0) {
        step.map((item, key) => {
          if (strings[key]) strings[key].push(item);
          else strings[key] = [item];
        });
      }
    }
  }

  return (
    <div className={"battery-test"}>
      <h4>Batterie STRING {data.batteryType}</h4>

      {strings.length > 0 &&
        strings.map((string, stringIndex) => (
          <div className={"string-wrapper"}>
            <h5>String #{stringIndex + 1}</h5>

            <div className={"battery-wrapper"}>
              <div className={"battery-step-col"}>
                <div className={"battery-row"}>Hour</div>

                {typeof strings[0][0] !== "undefined" && typeof strings[0][0].batteries !== "undefined" && strings[0][0].batteries.map((item, bid) => (
                    <>
                      <div className={"battery-row"}>
                        Battery #{(bid + 1).toString()}
                        {data.batteryMarque.length > 0 && (
                          <small>
                            <br /> {data.batteryMarque[stringIndex].batteries[bid]}{" "}
                          </small>
                        )}
                      </div>
                    </>
                  ))}

                <div className={"battery-row"}>
                  <label>Total : </label>
                </div>

                <div className={"battery-row"}>
                  <label>I (A) : </label>
                </div>

                <div className={"battery-row"}>
                  <label>Temp : </label>
                </div>
              </div>

              {string && string.length > 0 && string.map(
                  (step, stepIndex) =>
                    step.batteries.length > 0 &&
                    step.batteries[0] && (
                      <div className={"battery-step-col"}>
                        {stepIndex === 0 ? (
                          <span className={"step-label"}>Before test</span>
                        ) : (
                          <span className={"step-label"}>
                            {Math.floor((stepIndex * 15) / 60) + "h" + ((stepIndex * 15) % 60)}mn
                          </span>
                        )}

                        {step.batteries && step.batteries.length > 0 && step.batteries.map((battery, batteryIndex) => (
                            <>
                              <div className={"battery-row"}>{battery.toString()}</div>
                            </>
                        ))}

                        <div className={"battery-row"}>
                          <label>
                            {step.batteries && step.batteries.length > 0 && step.batteries.reduce((a, b) => parseFloat(a) + parseFloat(b)).toFixed(2)}
                          </label>
                        </div>
                        <div className={"battery-row"}>
                          <label>{step.amp}</label>
                        </div>
                        <div className={"battery-row"}>
                          <label>{step.temp}</label>
                        </div>
                      </div>
                    )
                )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default Batteries;
