import React from "react";
import PhotoItem from "../../../../Utils/PhotoItem";

const Anomalies = ({ data, active, ...props }) => {
  return (
    <>
      {data.items &&
        data.items.map(item => (
          <div className={"single-anomalie" + (item.resolved ? " resolved" : "")}>
            {item.resolved && (
              <div className={"row col-md-6 resolved-label"}>
                <label>Résolue </label>
              </div>
            )}

            <div className={"row col-md-6"}>
              <label>Anomalie : </label>
              {item.label}
            </div>

            <div className={"row col-md-6"}>
              <label>Cause de non correction : </label>
              {item.cause}
            </div>

            <div className={"row col-md-6"}>
              <label>Reference : </label>
              {item.ref}
            </div>

            <div className={"row col-md-6"}>
              <label>Commentaire : </label>
              {item.comment}
            </div>
          </div>
        ))}

      {data.photos && (
        <div className={"test-photos-wrapper"}>
          {data.photos.map((photo, key) => (
            <PhotoItem photo={photo} active={active} />
          ))}
        </div>
      )}
    </>
  );
};

export default Anomalies;
