import React, { PureComponent } from "react";
import { chartColors } from "../../../../Utils/Helpers";
import { connect } from "react-redux";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faFilter, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import api from "../../../../Utils/Api";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, LineChart, Tooltip, Line, CartesianGrid, Legend,  BarChart, Bar, XAxis, YAxis, } from 'recharts';


const moment = require("moment");

class TasksPage extends PureComponent {

  constructor(props) {

    super(props);

    this.state = {
      loading: true,
      selectedCluster: props.session.user.cluster,
      selectedOwner: "",
      cmPerAlarm: [],
      otherCmPerAlarm: [],
      cmPerAlarmDetail: [],
      startDate: moment().format("YYYY-01-01 00:00:00"),
      endDate: moment().format("YYYY-12-31 23:59:00"),
      cmPerOwner: [],
      waitAcceptPerOwner: [],
      waitArrivalPerOwner: [],
      waitProcessPerOwner: [],
      waitConfirmPerOwner: [],
      confirmedPerOwner: [],
      forceClosedPerOwner: [],
      canceledPerOwner: [],
      waitRestartPerOwner: [],
      waitReprocessPerOwner: [],
      rejectPerOwner: [],
      cmPerAssignment: [],
      //cmPerSite: [],
      selectedTask: "CM",
      selectedState: "pending",
    };

    this.ownersColorsAr = {
      "M_OTT": chartColors[0],
      "M_ALGER": chartColors[1],
      "MBS": chartColors[2],
      "ACTIC": chartColors[3]
    };

    this.statusColorsAr= {
      "Wait Accept": chartColors[0],
      "Wait Arrival": chartColors[1],
      "Wait Process": chartColors[2],
      "Wait Confirm": chartColors[3],
      "Closed": chartColors[4],
      "Canceled": chartColors[5],
      "Wait Restart": chartColors[6],
      "Wait Reprocess": chartColors[7],
      "Reject": chartColors[8],
      "Force Closed": chartColors[9],
    };
  }

  loadData = async () => {

    this.setState({ loading: true });

    //get all the data needed for the graphs
    const getData = await api.get(
      `/api/cm/dashboard/tasks?start_date=${moment(this.state.startDate).format("YYYY-MM-DD")}&end_date=${moment(this.state.endDate).format("YYYY-MM-DD")
    }&cluster=${this.state.selectedCluster}&owner=${this.state.selectedOwner}&task=${this.state.selectedTask}&state=${this.state.selectedState}`
    );

    //copy the data to the state
    this.setState({ ...getData.data.data});
    
    //normalize the data
    this.setState({ cmPerAlarm: this.state.cmPerAlarm.map(function (obj) {
			return ({'name':obj.alarm , 'value':parseInt(obj.count)})})
    });

    this.setState({ otherCmPerAlarm: this.state.otherCmPerAlarm.map(function (obj) {
			return ({'name':'Other' , 'value':parseInt(obj.other)})})
    });

    //append the other count ot the array
    this.state.cmPerAlarm.push(...this.state.otherCmPerAlarm);

    this.setState({ waitAcceptPerOwner: this.state.waitAcceptPerOwner.map(function (obj) {
			return ({'name':obj.owner , 'Wait Accept':parseInt(obj.number)})})
    });

    this.setState({ waitArrivalPerOwner: this.state.waitArrivalPerOwner.map(function (obj) {
			return ({'name':obj.owner , 'Wait Arrival':parseInt(obj.number)})})
    });

    this.setState({ waitProcessPerOwner: this.state.waitProcessPerOwner.map(function (obj) {
			return ({'name':obj.owner , 'Wait Process':parseInt(obj.number)})})
    });

    this.setState({ waitConfirmPerOwner: this.state.waitConfirmPerOwner.map(function (obj) {
			return ({'name':obj.owner , 'Wait Confirm':parseInt(obj.number)})})
    });

    this.setState({ confirmedPerOwner: this.state.confirmedPerOwner.map(function (obj) {
			return ({'name':obj.owner , 'Closed':parseInt(obj.number)})})
    });
    this.setState({ forceClosedPerOwner: this.state.forceClosedPerOwner.map(function (obj) {
			return ({'name':obj.owner , 'Force Closed':parseInt(obj.number)})})
    });

    this.setState({ canceledPerOwner: this.state.canceledPerOwner.map(function (obj) {
			return ({'name':obj.owner , 'Canceled':parseInt(obj.number)})})
    });

    this.setState({ waitRestartPerOwner: this.state.waitRestartPerOwner.map(function (obj) {
			return ({'name':obj.owner , 'Wait Restart':parseInt(obj.number)})})
    });

    this.setState({ waitReprocessPerOwner: this.state.waitReprocessPerOwner.map(function (obj) {
			return ({'name':obj.owner , 'Wait Reprocess':parseInt(obj.number)})})
    });

    this.setState({ rejectPerOwner: this.state.rejectPerOwner.map(function (obj) {
			return ({'name':obj.owner , 'Reject':parseInt(obj.number)})})
    });

    /*this.setState({ cmPerSite: this.state.cmPerSite.map(function (obj) {
			return ({'name':obj.site_code , 'done':parseInt(obj.done), 'ongoing':parseInt(obj.ongoing)})})
    });*/

    //merge the results
    let merged = [];

    /*for(let i=0; i<length; i++) {
      merged.push({
        ...this.state.waitAcceptPerOwner[i], 
        ...(this.state.waitArrivalPerOwner.find((itmInner) => itmInner.name === this.state.waitAcceptPerOwner[i].name)),
        ...(this.state.waitProcessPerOwner.find((itmInner) => itmInner.name === this.state.waitAcceptPerOwner[i].name)),
        ...(this.state.waitConfirmPerOwner.find((itmInner) => itmInner.name === this.state.waitAcceptPerOwner[i].name)),
        ...(this.state.confirmedPerOwner.find((itmInner) => itmInner.name === this.state.waitAcceptPerOwner[i].name)),
        ...(this.state.canceledPerOwner.find((itmInner) => itmInner.name === this.state.waitAcceptPerOwner[i].name)),
        ...(this.state.waitRestartPerOwner.find((itmInner) => itmInner.name === this.state.waitAcceptPerOwner[i].name)),
        ...(this.state.waitReprocessPerOwner.find((itmInner) => itmInner.name === this.state.waitAcceptPerOwner[i].name)),
        ...(this.state.rejectPerOwner.find((itmInner) => itmInner.name === this.state.waitAcceptPerOwner[i].name)),
      });
    }*/

    for(let i=0; i<this.state.cmPerOwner.length; i++) {
      merged.push({
        ...(this.state.waitAcceptPerOwner.find((itmInner) => itmInner.name === this.state.cmPerOwner[i].owner)),
        ...(this.state.waitArrivalPerOwner.find((itmInner) => itmInner.name === this.state.cmPerOwner[i].owner)),
        ...(this.state.waitProcessPerOwner.find((itmInner) => itmInner.name === this.state.cmPerOwner[i].owner)),
        ...(this.state.waitConfirmPerOwner.find((itmInner) => itmInner.name === this.state.cmPerOwner[i].owner)),
        ...(this.state.confirmedPerOwner.find((itmInner) => itmInner.name === this.state.cmPerOwner[i].owner)),
        ...(this.state.forceClosedPerOwner.find((itmInner) => itmInner.name === this.state.cmPerOwner[i].owner)),
        ...(this.state.canceledPerOwner.find((itmInner) => itmInner.name === this.state.cmPerOwner[i].owner)),
        ...(this.state.waitRestartPerOwner.find((itmInner) => itmInner.name === this.state.cmPerOwner[i].owner)),
        ...(this.state.waitReprocessPerOwner.find((itmInner) => itmInner.name === this.state.cmPerOwner[i].owner)),
        ...(this.state.rejectPerOwner.find((itmInner) => itmInner.name === this.state.cmPerOwner[i].owner)),
      });
    }

    this.setState({cmPerAssignment: merged, loading: false });

    return getData;
  };

  componentDidMount() {

    this.loadData();
  }

  setStartDate = e => this.setState({ startDate: e });

  setEndDate = e => this.setState({ endDate: e });

  updateFilters = filters => {

    this.setState(filters);
  };

  renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {

    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  render() {

    return (

      <div className={"dashboard-page"}>
        <div className={"container-header header-spacing nmb"}>
          
          <div></div>
          <div className={"date-filters"}>
            <>
              <b>Task</b>
              <Select
                classNamePrefix={"custom-select"}
                className={"select-menu"}
                options={[{ label: "Tous", value: "" }, { label: "CM", value: "CM" }, { label: "WO", value: "WO" }]}
                placeholder={"Type"}
                defaultValue={{label: "CM", value: "CM"}}
                onChange={e => this.updateFilters({ selectedTask: e.value })}
              />

              <b>Etat</b>
              <Select
                classNamePrefix={"custom-select"}
                className={"select-menu"}
                options={[{ label: "All", value: "" }, { label: "Pending", value: "pending" }, { label: "Closed", value: "closed" }]}
                placeholder={"Etat"}
                defaultValue={{label: "Pending", value: "pending"}}
                onChange={e => this.updateFilters({ selectedState: e.value })}
              />

              <b>Periode </b>
              <ReactDatePicker
                dateFormat={"yyyy-MM-dd"}
                selected={
                  this.state.startDate
                    ? new Date(this.state.startDate)
                    : undefined
                }
                onChange={date => this.setStartDate(date)}
                selectsStart
                startDate={
                  this.state.startDate
                    ? new Date(this.state.startDate)
                    : undefined
                }
                endDate={
                  this.state.endDate ? new Date(this.state.endDate) : undefined
                }
                className={"dashboard-filter-input"}
              />

              <ReactDatePicker
                dateFormat={"yyyy-MM-dd"}
                selected={
                  this.state.endDate ? new Date(this.state.endDate) : undefined
                }
                onChange={date => this.setEndDate(date)}
                selectsEnd
                startDate={
                  this.state.startDate
                    ? new Date(this.state.startDate)
                    : undefined
                }
                endDate={
                  this.state.endDate ? new Date(this.state.endDate) : undefined
                }
                minDate={
                  this.state.startDate
                    ? new Date(this.state.startDate)
                    : undefined
                }
                className={"dashboard-filter-input ml-3"}
              />

              <button
                onClick={this.loadData}
                type={"button"}
                className={"filter-apply"}
              >
                <FontAwesomeIcon icon={faFilter} />
              </button>
            </>
          </div>
        </div>

      <div className={"row dashboard-row "}>

        <div className={"col-md-12"}>
          <div className={"single-chart p-3"}>
            <h3 className={"mb-3"}>CM par assignement</h3>

            <ResponsiveContainer width="100%" height={500}>
              <BarChart data={this.state.cmPerAssignment} margin={{top: 0, right: 0, left: 0, bottom: 100}} >
                <CartesianGrid />
                <XAxis dataKey="name" angle={-60} textAnchor="end" interval={0} dy={10}/>
                <YAxis />
                <Tooltip />
                <Legend wrapperStyle={{position: 'relative', marginTop: "90px"}} />
                <Bar dataKey="Wait Accept" stackId="a" fill={chartColors[0]} />
                <Bar dataKey="Wait Arrival" stackId="a" fill={chartColors[3]} />
                <Bar dataKey="Wait Process" stackId="a" fill={chartColors[5]} />
                <Bar dataKey="Wait Confirm" stackId="a" fill={chartColors[1]} />
                <Bar dataKey="Closed" stackId="a" fill={chartColors[4]} />
                <Bar dataKey="Canceled" stackId="a" fill={chartColors[2]} />
                <Bar dataKey="Wait Restart" stackId="a" fill={chartColors[7]} />
                <Bar dataKey="Wait Reprocess" stackId="a" fill={chartColors[6]} />
                <Bar dataKey="Reject" stackId="a" fill={chartColors[11]} />
                <Bar dataKey="Force Closed" stackId="a" fill={chartColors[12]} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        
        <div className={"col-md-12"}>
          <div className={"single-chart p-3"}>
            <h3 className={"mb-3"}>CM par alarm</h3>

            <ResponsiveContainer width="100%" height={500}>
              <PieChart>
                <Legend layout="vertical" verticalAlign="middle" align="right"/>
                <Tooltip />
                <Pie
                  data={this.state.cmPerAlarm}
                  cx="30%"
                  cy="50%"
                  labelLine={false}
                  label={this.renderCustomizedLabel}
                  outerRadius={200}
                  innerRadius={0}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {this.state.cmPerAlarm.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={chartColors[index]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/*<div className={"col-md-12"}>
          <div className={"single-chart p-3"}>
            <h3 className={"mb-3"}>CM par site</h3>

            <ResponsiveContainer width="100%" height={500}>
              <BarChart data={this.state.cmPerSite} >
                <CartesianGrid />
                <XAxis dataKey="name" interval={0}/>
                <YAxis interval={0}/>
                <Tooltip />
                <Legend />
                <Bar dataKey="done" stackId="a" fill={chartColors[4]} />
                <Bar dataKey="ongoing" stackId="a" fill={chartColors[1]} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>*/}

      </div>   
    </div>
    );
  }
}

const mapState = state => {
  return {
    filters: state.filters,
    session: state.session
  };
};

export default connect(mapState)(TasksPage);
