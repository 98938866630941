import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import AddressNewContainer from "../Containers/FLM/AddressNewContainer";
import SiteDetail from "../Components/Addresses/SiteDetail";
import TaskListContainer from "../Containers/CC/TaskListContainer";
import CCNew from "../Components/Task/CC/CCNew";
//import CCAddressNewContainer from "../Containers/FLM/CCAddressNewContainer";
import CCDashboardWrapper from "../Components/Dashboard/Pages/CCDashboardWrapper";

function mapStateToProps(state) {
  return { auth: state.session.auth, session: state.session };
}

class CCRouter extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/cc" component={TaskListContainer} />
        <Route exact path="/cc/task" component={TaskListContainer} />
        <Route exact path="/cc/new" component={CCNew} />
        <Route path='/cc/dashboard' component={CCDashboardWrapper} />
        <Route exact path="/cc/task/agent/:agentid/" component={TaskListContainer} />
        <Route exact path="/cc/task/:taskid/" component={TaskListContainer} />
      </Switch>
    );
  }
}

export default connect(mapStateToProps)(CCRouter);
