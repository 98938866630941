import React from "react";
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { formToObject } from "../../../Utils/Helpers";
import { useDispatch } from "react-redux";
import { startLoading, setFlash, doneLoading } from "../../../Redux/actions";

const PlanCMOverlay = ({
  open,
  siteid,
  site,
  defaultAgent,
  loadSites,
  close,
  defaultDate,
  taskType,
  subsite,
  accessProblem,
  siteplanid,
  ...props
}) => {
  const dispatch = useDispatch();

  const isLoading = () => dispatch(startLoading());
  const flash = (d) => dispatch(setFlash(d));
  const notLoading = () => dispatch(doneLoading());

  const submitAssignForm = (e) => {
    e.preventDefault();

    // if (!this.state.date || !this.state.agentid) {
    //   return false;
    // }

    isLoading();

    let data = formToObject(e.target);

    data["selected_site"] = subsite;
    //data['task_type'] = taskType;
    data["site_plan_id"] = siteplanid;

    //console.log("hello", data);
    //console.log("hello data2 ", data);

    if (
      (data["agent"] != "" && (data["team"] != "" || data["service"] != "")) ||
      (data["team"] != "" && (data["agent"] != "" || data["service"] != "")) ||
      (data["service"] != "" && (data["agent"] != "" || data["team"] != ""))
    ) {
      //if((data['agent'] != "") && (data['team'] != "" )) {
      flash({
        active: true,
        message:
          "Veuillez ne sélectionner qu'une seule entité, le double assignement n'est pas autorisée!",
        classes: "warning",
        style: {},
      });
      notLoading();
    } else {
      if (data["comment"] == "") {
        flash({
          active: true,
          message:
            "Veuillez inserer un commentaire, le commentaire est obligatoire!",
          classes: "warning",
          style: {},
        });
        notLoading();
      } else {
        api
          .post("/api/cm/planning/reassign", data)
          .then((response) => {
            //console.log("hello response", response);
            if (response.data.success) {
              flash({
                active: true,
                message: "Tâche réassigné avec succès.",
                classes: "success",
                style: {},
              });

              if (typeof loadSites === "function") loadSites();

              close();
            } else {
              if (response.data.team_error) {
                flash({
                  active: true,
                  message:
                    "Une erreur est survenue, aucune équipe disponible à cette date à cette shift.",
                  classes: "warning",
                  style: {},
                });
              } else {
                flash({
                  active: true,
                  message:
                    "Une erreur est survenue, veuillez contacter l'administrateur.",
                  classes: "warning",
                  style: {},
                });
              }
            }
            notLoading();
          })
          .catch((err) => {
            flash({
              active: true,
              message: `Une erreur est survenue: ${err.message}`,
              classes: "warning",
              style: {},
            });
          })
          .finally(() => notLoading());
      }
    }
  };

  //console.log("hello filters", props.filters);

  return (
    <div className={open ? "ui-overlay open" : "ui-overlay"}>
      <div className={"ui-overlay-content col-md-4 offset-md-4"}>
        <button
          onClick={close}
          className={"ui-overlay-close btn btn-danger btn-sm"}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <h5>Réassigner une tâche CM</h5>
        <div>
          <span className={"font-exp"}>
            Veuillez ne sélectionner qu'une seule entité, le double assignement
            n'est pas autorisée!
          </span>
        </div>
        <form onSubmit={submitAssignForm}>
          <div className='form-group mt-3'>
            <label className={"font-exp"}>Select agent</label>
            <Select
              classNamePrefix={"custom-select"}
              name={"agent"}
              defaultValue={""}
              options={[{ label: "N/A", value: "" }, ...props.filters.agents]}
              cacheOptions
              defaultOptions
              placeholder='Agent'
              className={"d-block mt-1"}
            />
          </div>

          <div className='form-group mt-3'>
            <label className={"font-exp"}>Select team</label>
            <Select
              classNamePrefix={"custom-select"}
              name={"team"}
              defaultValue={""}
              options={[{ label: "N/A", value: "" }, ...props.filters.teams]}
              cacheOptions
              defaultOptions
              placeholder='Team'
              className={"d-block mt-1"}
            />
          </div>

          <div className='form-group mt-3'>
            <label className={"font-exp"}>Select Service</label>
            <Select
              classNamePrefix={"custom-select"}
              name={"service"}
              defaultValue={""}
              options={[
                { label: "N/A", value: "" },
                ...props.filters.servicesToEscalate,
              ]}
              cacheOptions
              defaultOptions
              placeholder='Service'
              className={"d-block mt-1"}
            />
          </div>

          <div className='form-group mt-3'>
            <label className={"font-exp"}>Commentaire</label>
            <textarea rows={2} name='comment' className={"form-control"} />
          </div>

          <div className='form-group mt-3'>
            <input
              type='submit'
              value='Réassigner'
              className={"btn btn-primary btn-sm mt-2"}
            />
          </div>
        </form>
        <div>
          <span className={"font-exp"}>Agent will receive a notification.</span>
        </div>
      </div>
    </div>
  );
};

export default PlanCMOverlay;
