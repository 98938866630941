import React from "react";
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

const EditCMSiteOverlay = ({
  site,
  physicSites,
  close,
  loadSites,
  ...props
}) => {
  //console.log("hello site", site);
  //console.log("hello site", physicSites);

  const submitSiteForm = (e) => {
    e.preventDefault();

    let that = this;

    props.isLoading({
      message: "Mise à jour du site",
      animate: true,
    });

    const formdata = new FormData(e.target);

    let data = {};

    for (var [key, value] of formdata.entries()) data[key] = value;

    data["user"] = props.user;

    //console.log("hello site", site.cm_site_status, data["site_status"]);
    //console.log("hello site", data["site_parent_id"]);

    api
      .post("/api/cm/sites/edit", data)

      .then((response) => {
        props.doneLoading();
        if (response.data.success) {
          close();
          //window.ga('send', 'event', 'task', 'add'); // Google Analytics
          props.setOverlay({
            message: "Site logique modifié avec succés",
            label: "success",
            button: {
              type: "close",
              label: "OK",
              link: "javascript:void(0)",
            },
          });
          //window.location.reload();
        } else {
          if (response.data.errcode === 101) {
            props.setAuth(response.data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        loadSites();
        props.doneLoading();
      });
  };

  return (
    <div className={"ui-overlay " + (props.open ? " open" : "")}>
      <div className={"ui-overlay-content site-overlay col-md-5"}>
        <button
          data-package-id='0'
          onClick={(e) => {
            close(e);
          }}
          className={"ui-overlay-close btn btn-danger btn-sm"}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <h5 className={"mb-3"}>Fiche du site logique</h5>

        <form onSubmit={submitSiteForm}>
          {props.open && (
            <>
              <input type='hidden' name='site_id' value={site.site_id} />

              <input
                type='hidden'
                name='site_parent_id'
                value={site.site_parent_id}
              />

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>Code site</label>
                <input
                  type='text'
                  name='site_code'
                  className={"form-control col-md-8"}
                  defaultValue={site.site_code}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>Nom site</label>
                <input
                  type='text'
                  name='site_name'
                  className={"form-control col-md-8"}
                  defaultValue={site.site_name}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3 no-gutters'>
                <label className={"font-exp"}>Site physique</label>
                <Select
                  name='site_parent_id'
                  classNamePrefix={"custom-select"}
                  defaultValue={{
                    value: site.site_parent_id,
                    label: site.physic_code,
                  }}
                  options={physicSites}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3 no-gutters'>
                <label className={"font-exp"}>Technologie</label>
                <Select
                  name='site_technology'
                  classNamePrefix={"custom-select"}
                  defaultValue={{
                    value: site.site_technology,
                    label: site.site_technology,
                  }}
                  options={props.filters.cm_site_technology}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3 no-gutters'>
                <label className={"font-exp"}>Owner</label>
                <Select
                  name='site_owner'
                  classNamePrefix={"custom-select"}
                  defaultValue={{
                    value: site.site_owner,
                    label: site.site_owner,
                  }}
                  options={props.filters.cm_site_owners}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3 no-gutters'>
                <label className={"font-exp"}>Location owner</label>
                <Select
                  name='location_owner'
                  classNamePrefix={"custom-select"}
                  defaultValue={{
                    value: site.location_owner,
                    label: site.location_owner,
                  }}
                  options={props.filters.location_owners}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3 no-gutters'>
                <label className={"font-exp"}>Catégorie</label>
                <Select
                  name='site_category'
                  classNamePrefix={"custom-select"}
                  defaultValue={{
                    value: site.site_category,
                    label: site.site_category,
                  }}
                  options={props.filters.cm_site_categories}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>X</label>
                <input
                  type='text'
                  name='site_x'
                  className={"form-control col-md-8"}
                  defaultValue={site.site_x}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>Y</label>
                <input
                  type='text'
                  name='site_y'
                  className={"form-control col-md-8"}
                  defaultValue={site.site_y}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>Adresse</label>
                <input
                  type='text'
                  name='site_address'
                  className={"form-control col-md-8"}
                  defaultValue={site.site_address}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3 no-gutters'>
                <label className={"font-exp"}>RT/GF</label>
                <Select
                  name='site_rt_gf'
                  classNamePrefix={"custom-select"}
                  defaultValue={{
                    value: site.site_rt_gf,
                    label: site.site_rt_gf,
                  }}
                  options={props.filters.cm_rt_gf}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3 no-gutters'>
                <label className={"font-exp"}>Cabinet</label>
                <Select
                  name='site_cabinet'
                  classNamePrefix={"custom-select"}
                  defaultValue={{
                    value: site.site_cabinet,
                    label: site.site_cabinet,
                  }}
                  options={props.filters.cm_site_cabinets}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>Commune</label>
                <input
                  type='text'
                  name='site_commune'
                  className={"form-control col-md-8"}
                  defaultValue={site.site_commune}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3 no-gutters'>
                <label className={"font-exp"}>Nature</label>
                <Select
                  name='site_nature'
                  classNamePrefix={"custom-select"}
                  defaultValue={{
                    value: site.site_nature,
                    label: site.site_nature,
                  }}
                  options={props.filters.cm_site_natures}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3 no-gutters'>
                <label className={"font-exp"}>Région</label>
                <Select
                  name='site_region'
                  classNamePrefix={"custom-select"}
                  defaultValue={{
                    value: site.site_region,
                    label: site.site_region,
                  }}
                  options={props.filters.wilayas}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3 no-gutters'>
                <label className={"font-exp"}>Wilaya</label>
                <Select
                  name='site_wilaya'
                  classNamePrefix={"custom-select"}
                  defaultValue={{
                    value: site.site_address_wilaya,
                    label: site.site_address_wilaya,
                  }}
                  options={props.filters.wilayas}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3 no-gutters'>
                <label className={"font-exp"}>Nombre de cellules</label>
                <Select
                  name='site_cell_number'
                  classNamePrefix={"custom-select"}
                  defaultValue={{
                    value: site.site_cell_number,
                    label: site.site_cell_number,
                  }}
                  options={props.filters.cm_site_cell_numbers}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>BSC</label>
                <input
                  type='text'
                  name='site_bsc'
                  className={"form-control col-md-8"}
                  defaultValue={site.site_bsc}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3 no-gutters'>
                <label className={"font-exp"}>BSS Vendor</label>
                <Select
                  name='site_bss_vendor'
                  classNamePrefix={"custom-select"}
                  defaultValue={{
                    value: site.site_bss_vendor,
                    label: site.site_bss_vendor,
                  }}
                  options={props.filters.cm_site_vendors}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3 no-gutters'>
                <label className={"font-exp"}>MW Vendor</label>
                <Select
                  name='site_mw_vendor'
                  classNamePrefix={"custom-select"}
                  defaultValue={{
                    value: site.site_mw_vendor,
                    label: site.site_mw_vendor,
                  }}
                  options={props.filters.cm_site_vendors}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>Power</label>
                <input
                  type='text'
                  name='site_power'
                  className={"form-control col-md-8"}
                  defaultValue={site.site_power}
                />
              </div>

              {/*<div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Mise en air</label>
                  <input
                    defaultValue={site.site_airing}
                    name="site_airing"
                    type={"date"}
                    className={"form-control"}
                  />
                </div>*/}

              <div className='form-group custom-inline-form form-inline mt-3 no-gutters'>
                <label className={"font-exp"}>Status</label>
                <Select
                  name='site_status'
                  classNamePrefix={"custom-select"}
                  defaultValue={props.filters.cm_site_status.filter(
                    (item) => item.value === site.cm_site_status,
                  )}
                  options={props.filters.site_status.filter(
                    (item) => item.value !== "all",
                  )}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3 no-gutters'>
                <label className={"font-exp"}>Site keeper</label>
                <Select
                  name='site_team'
                  classNamePrefix={"custom-select"}
                  defaultValue={{
                    value: site.site_team,
                    label: site.site_team_name,
                  }}
                  options={[
                    { label: "N/A", value: "" },
                    ...props.filters.teams,
                  ]}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form mt-3 no-gutters'>
                <label className={"font-exp"}>Remarque</label>
                <textarea rows={5} name='note' className={"form-control"} />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3 no-gutters'>
                <input
                  type='submit'
                  className={"btn btn-primary col-md-12"}
                  value='Valider les modifications'
                />
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default EditCMSiteOverlay;
