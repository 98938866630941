import React from "react";
import { Chart } from "chart.js";
import * as ChartGeo from "chartjs-chart-geo";
import { Pie, HorizontalBar, Bar } from "react-chartjs-2";
import { chartColors, gradientColors } from "../../../../Utils/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Select from "react-select";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import api from "../../../../Utils/Api";
import ReactDatePicker from "react-datepicker";


const statesData = require("../../../../../data/algeria.json");
const countryData = require("../../../../../data/africa.json");
Chart.plugins.unregister(ChartDataLabels);
const moment = require("moment");


class Open extends React.Component {

  constructor() {

    super();

    this.state = {
      loading: true,
      open_by_service: [],
      open_by_department: [],
      status: [],
      selectedWilaya: "",
      selectedStatus: "open",
      startDate: moment().format("YYYY-01-01 00:00:00"),
      endDate: moment().format("YYYY-12-31 23:59:00")
    };

    this.servicesColorsAr = {
      "TCM": chartColors[0],
      "O&M_ENV": chartColors[1],
      "NSOC_BSS": chartColors[2],
      "NSOC_NSS": chartColors[3],
      "NSOC_FO & VSAT": chartColors[4],
      "ROLLOUT": chartColors[5],
      "PRODUCTION": chartColors[6],
      "ENG_BSS": chartColors[7],
      "OPTIM": chartColors[8],
      "RADIO": chartColors[9],
      "Radio Alger": chartColors[10],
      "Radio Oran": chartColors[11],
      "Radio Constantine": chartColors[12],
      "ENG_TRANS": chartColors[13],
      "Traffic": chartColors[14],
      "Drive Test": chartColors[15],
      "Geomarketing": chartColors[16],
      "Traces": chartColors[17],
      "Wait Budget": chartColors[18],
    };

    this.departmentsColorsAr = {
      "Deployment": chartColors[0],
      "Access": chartColors[1],
      "TOSA": chartColors[2],
      "Commercial": chartColors[3],
      "Other": chartColors[4],
    };

    this.statusColorsAr = {
      "Solved": chartColors[4],
      "Closed": chartColors[1],
      "Open": chartColors[2],
      "Canceled": chartColors[3],
    };
  }

  setStartDate = e => this.setState({ startDate: e });

  setEndDate = e => this.setState({ endDate: e });

  loadData = async () => {

    const sitesData = api.get(`/api/dashboard/cc/open?wilaya=${this.state.selectedWilaya}&status=${this.state.selectedStatus}&start_date=${moment(this.state.startDate).format("YYYY-MM-DD")}&end_date=${moment(this.state.endDate).format("YYYY-MM-DD")}`);

    const country = new Promise(resolve => resolve(countryData));

    const states = new Promise(resolve => resolve(statesData));

    return Promise.all([states, country, sitesData]);
  }

  componentDidMount() {

    this.drawGraphs();
  }

  drawGraphs = () => {

    this.setState({ loading: true });

    this.loadData().then(data => {
      this.setState({
        loading: false,
        open_by_department: data[2].data.data.openByDepartment,
        open_by_service: data[2].data.data.openByService,
        status: data[2].data.data.status
      });
    });
  }


  setStartDate = e => this.setState({ startDate: e });

  setEndDate = e => this.setState({ endDate: e });

  updateFilters = filters => {
    this.setState(filters);
  };


  render() {

    const totalStatus = this.state.status.reduce((acc, curr) => acc + parseInt(curr.nb),0);
    const totalOpen =  this.state.open_by_service.reduce((acc, curr) => acc + parseInt(curr.number),0);

    return (
      <>
        <div className={"page-loading " + (this.state.loading ? "active" : "")}/>

        <div className={"dashboard-page"}>
        <div className={"container-header header-spacing nmb"}>

          <div></div>
          <div className={"date-filters"}>
            <>
              <b>Status</b>
              <Select
                classNamePrefix={"custom-select"}
                className={"select-menu"}
                options={[
                  { label: "OPEN", value: "open" },
                  ...this.props.filters.cc_status,
                ]}
                placeholder={"Status"}
                onChange={e => this.updateFilters({ selectedStatus: e.value })}
              />

              <b>Wilaya</b>
              <Select
                classNamePrefix={"custom-select"}
                className={"select-menu"}
                options={[
                  { label: "Tous", value: "" },
                  ...this.props.filters.new_wilayas
                ]}
                placeholder={"Wilaya"}
                onChange={e => this.updateFilters({ selectedWilaya: e.value })}
              />

              <b>Periode </b>
              <ReactDatePicker
                dateFormat={"yyyy-MM-dd"}
                selected={
                  this.state.startDate
                    ? new Date(this.state.startDate)
                    : undefined
                }
                onChange={date => this.setStartDate(date)}
                selectsStart
                startDate={
                  this.state.startDate
                    ? new Date(this.state.startDate)
                    : undefined
                }
                endDate={
                  this.state.endDate ? new Date(this.state.endDate) : undefined
                }
                className={"dashboard-filter-input"}
              />

              <ReactDatePicker
                dateFormat={"yyyy-MM-dd"}
                selected={
                  this.state.endDate ? new Date(this.state.endDate) : undefined
                }
                onChange={date => this.setEndDate(date)}
                selectsEnd
                startDate={
                  this.state.startDate
                    ? new Date(this.state.startDate)
                    : undefined
                }
                endDate={
                  this.state.endDate ? new Date(this.state.endDate) : undefined
                }
                minDate={
                  this.state.startDate
                    ? new Date(this.state.startDate)
                    : undefined
                }
                className={"dashboard-filter-input ml-3"}
              />

              <button
                onClick={this.drawGraphs}
                type={"button"}
                className={"filter-apply"}
              >
                <FontAwesomeIcon icon={faFilter} />
              </button>
            </>
          </div>
        </div>

        <div className={"row dashboard-row "}>
          <div className={"col-md-12"}>
            <div className={"row"}>
              <div className={"col-md-12"}>
                
                <div className={"dashboard-box"}>
                  <span className={"dashboard-box-title"}>Open by department</span>
                  <span className={"dashboard-box-value"}>{totalOpen}</span>
                </div>

                <div className={"single-chart"}>
                  
                  <Bar
                    options={{
                      layout: {
                        padding: {
                          right: 50
                        }
                      },
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              fontColor:"#333",
                              fontFamily:"Open sans"
                            },
                            gridLines: {
                              display: true
                            }
                          }
                        ],
                        xAxes: [
                          {
                            ticks: {
                              fontColor:"#333",
                              fontFamily:"Open sans"
                            },
                            gridLines: {
                              display: true
                            }
                          }
                        ]
                      },
                      plugins: {
                        datalabels: {
                          anchor: "end",
                          color: "#333",
                          align: "end",
                          backgroundColor: "rgba(0,0,0,.1)",
                          padding: 3,
                          font: {
                            family: "Open sans",
                            weight: "bold"
                          }
                        }
                      },
                      legend: {
                        display: false
                      }
                    }}
                    plugins={[ChartDataLabels]}
                    data={{
                      labels: this.state.open_by_department.map(item => item.department+" ("+(item.number/totalOpen*100).toFixed(2)+"%)"), 
                      datasets: [
                        {
                          data: this.state.open_by_department.map(item => item.number),
                          backgroundColor: this.state.open_by_department.map(item => this.departmentsColorsAr[item.department]),
                          hoverBackgroundColor: this.state.open_by_department.map(item => this.departmentsColorsAr[item.department])
                        }
                      ]
                    }}
                  />
                </div>
              </div>

              <div className={"col-md-12"}>
                
                <div className={"dashboard-box"}>
                  <span className={"dashboard-box-title"}>Open by service</span>
                  <span className={"dashboard-box-value"}>{totalOpen}</span>
                </div>

                <div className={"single-chart"}>
                  
                  <Bar
                    options={{
                      layout: {
                        padding: {
                          right: 50
                        }
                      },
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              fontColor:"#333",
                              fontFamily:"Open sans"
                            },
                            gridLines: {
                              display: true
                            }
                          }
                        ],
                        xAxes: [
                          {
                            ticks: {
                              fontColor:"#333",
                              fontFamily:"Open sans"
                            },
                            gridLines: {
                              display: true
                            }
                          }
                        ]
                      },
                      plugins: {
                        datalabels: {
                          anchor: "end",
                          color: "#333",
                          align: "end",
                          backgroundColor: "rgba(0,0,0,.1)",
                          padding: 3,
                          font: {
                            family: "Open sans",
                            weight: "bold"
                          }
                        }
                      },
                      legend: {
                        display: false
                      }
                    }}
                    plugins={[ChartDataLabels]}
                    data={{
                      labels: this.state.open_by_service.map(item => item.service+" ("+(item.number/totalOpen*100).toFixed(2)+"%)"), 
                      datasets: [
                        {
                          data: this.state.open_by_service.map(item => item.number),
                          backgroundColor: this.state.open_by_service.map(item => this.servicesColorsAr[item.service]),
                          hoverBackgroundColor: this.state.open_by_service.map(item => this.servicesColorsAr[item.service])
                        }
                      ]
                    }}
                  />
                </div>
              </div>

              <div className={"col-md-8"}>
                <div className={"dashboard-box"}>
                  <span className={"dashboard-box-title"}>Status</span>
                  <span className={"dashboard-box-value"}>{totalStatus}</span>
                </div>

                <div className={"single-chart"}>

                  <Pie
                      data={{
                          labels: this.state.status.map(item => item.status+" ("+(item.nb/totalOpen*100).toFixed(2)+"%)"),
                          datasets: [{
                              data: this.state.status.map(item => item.nb),
                              backgroundColor: this.state.status.map(item => this.statusColorsAr[item.status]),
                              hoverBackgroundColor: this.state.status.map(item => this.statusColorsAr[item.status])
                          }]
                      }}
                  />

                </div>
                
              </div>
            </div>
          </div>
        </div>
        </div>
      </>
    );
  }
}

const mapState = state => {
  return {
    filters: state.filters,
    session: state.session
  }
}
export default connect(mapState)(Open);
