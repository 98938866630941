import React, { Component } from "react";

const Footer = () => (
  <div className="footer-wrap">
    <div className="footer-content">
      <center>
        <small>
          Copyright © 2024 Djezzy FLM -{" "}
          <b>
            <a
              //href="https://play.google.com/store/apps/details?id=com.djezzy"
              href="https://drive.google.com/file/d/1bzyTut75nkWBHGlEsAGnMhKjCxS02-w6/view?usp=drive_link"
              target="_blank"
            >
              Mobile App
            </a>
          </b>
        </small>
      </center>
    </div>
  </div>
);

export default Footer;
