import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";


/*const CheckOrTimeNew = ({array, value}) => (array && value && array.includes(value)
	? <FontAwesomeIcon color='rgb(48, 209, 88)' icon={faCheck} size={22} /> 
    : <FontAwesomeIcon color="#FF3B30" icon={faTimes} size={22} />)*/

    const CheckOrTimeNew = ({ array, value }) => {
        //console.log('hello array:', array);
        //console.log('hello value:', value);
      
        if (array && value && array.includes(value)) {
          return <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faCheck} size={22} />;
        } else if (value == 'clockin') {
          return <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faCheck} size={22} />;
        } else {
          return <FontAwesomeIcon color="#FF3B30" icon={faTimes} size={22} />;
        }
      };
    
export default CheckOrTimeNew;