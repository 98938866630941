import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, Redirect, Route,  Switch} from 'react-router-dom';

import { setAuthAction, startLoading, doneLoading } from './../Redux/actions/index';
import api from '../Utils/Api';

import AgentDetail from '../Components/Agent/AgentDetail';
import AgentActivity from './../Components/Admin/AgentActivity';
import AgentOverview from '../Components/Agent/AgentOverview';
import { useTranslation, Trans } from "react-i18next";

const mapStateToProps = state => {
	return {
		auth: state.session.auth,
		user: state.session.user
	}
}

const mapDispatchToProps = dispatch => {
	return {
		setAuth: user => dispatch(setAuthAction(user)),
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: () => dispatch(doneLoading())
	}
}


class ServiceContainer extends Component {

    constructor(props){
        super(props)

        this.state = {
            ready: false,
			info: {
				userid: 0,
				username: "",
				password: "",
				firstName: "",
				lastName: "",
				email: "",
				phone: "",
				activity: []
			},
			data: {}
		}

        this.props.isLoading();

    }


	componentDidMount() {

		let that = this;

		api.get('/api/agent/overview/'+this.props.match.params.userid)
		.then(function(response) {
			if(response.data.success)
				that.setState({data: response.data})


		}).then(r => that.props.doneLoading());




	}

	render() {
		//console.log('agent container')

		return(
			<div>

			{!this.props.auth ? <Redirect to="/login" /> :  ""}

			<div className={"container-header nmb"}>
				<Trans i18nKey="agent_details" />
			</div>


			<div className={"page-menu"}>

					<ul>
						<li><NavLink strict exact to={"/agents/"+this.props.match.params.userid}><Trans i18nKey="account_overview" /></NavLink></li>
						<li><NavLink strict exact to={"/agents/"+this.props.match.params.userid+"/activity"}><Trans i18nKey="label_activity" /></NavLink></li>
                    </ul>

			</div>

			<div className={"row"}>

                <div className={"col-md-12"}>


			    <Switch>


					<Route
                    path='/agents/:userid/account'
                    exact
                    component={AgentDetail} />

                    <Route
                    path='/agents/:userid/activity'
                    exact
                    component={AgentActivity} />


                    <Route
					path='/agents/:userid'
                    exact
					component={AgentOverview}
					/>





				</Switch>

                </div>
  			</div>


			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceContainer)
