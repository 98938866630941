import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEye, faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons';
import NewFile from './NewFile';
import api from '../../../Utils/Api';
import Constants from '../../../Utils/Constants';
import { setFlash} from "./../../../Redux/actions/index";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
    return {
      session: state.session,
      filters: state.filters,
      user: state.session.user,
      auth: state.session.auth,
    };
  };

function mapDispatchToProps(dispatch) {
    return {
      setFlash: f => dispatch(setFlash(f)),
    };
}

const moment = require("moment");

class Files extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showNew: false,
            files: [],
            loading: true,
            selectedFile: {}
        }
    }


    componentDidMount() {
        this.loadFiles();
    }

    loadFiles = () => {
        this.setState({loading: true})
        api.get('/api/settings/files')
        .then(res => {
            if(res.data.success)
                this.setState({files: res.data.files})

            this.setState({loading: false})
        })
    }

    afterNew = (error) => {
        if(error) {
            this.props.setFlash({
                active: true,
                message: error,
                classes: "warning",
                style: {}
            });
        }
        this.loadFiles();
        this.setState({showNew: false, selectedFile: {}});
    }


    editFile = file => {

        this.setState({
            selectedFile: file,
            showNew: true
        })
    }


    deleteFile = id => {
        this.setState({ loading: true });

        api.post('/api/settings/files/delete/'+id)
        .then(res => {
            if(res.data.success)
                this.loadFiles();
        })
    }

  render() {

   

    return (<>
        <h3>
            Partagez des fichiers & des guides
            {this.state.loading && <FontAwesomeIcon className={"ml-4"} icon={faSpinner} spin />}
            <button onClick={() => this.setState({ showNew: true })} className={"btn btn-sm btn-primary float-right"} >Ajouter nouveau</button>
        </h3>

        {this.state.showNew && <NewFile data={this.state.selectedFile} after={this.afterNew} close={() => this.setState({ showNew: false, selectedFile: {} })} props={this.props} />}

        

        <div className={"table-resposive mt-4"}>
        
            <table className={"table"}>
                <thead>
                    <tr>
                        <th>Fichier</th>
                        <th>Description</th>
                        <th>Date d'ajout</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.files.map((item, idx) => (
                            <tr key={idx}>
                                <td>{item.url}</td>
                                <td>{item.description}</td>
                                <td>{moment(item).format("YYYY-MM-DD")}</td>
                                <td className={"site-plan-col-action"}>
                                    <a target={"_blank"} href={Constants.apiURL+"/upload/"+item.url} className={"task-action-btn"}>
                                        <FontAwesomeIcon icon={faEye} />
                                        Voir
                                    </a>
                                    <button onClick={() => this.editFile(item)} className={"task-action-btn"}>
                                        <FontAwesomeIcon icon={faEdit} />
                                        Modifier
                                    </button>
                                    <button onClick={() => this.deleteFile(item.id)} className={"task-action-btn danger"}>
                                        <FontAwesomeIcon icon={faTrash} />
                                        Supprimer
                                    </button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    </>);
  }

  
}

//export default Files;
export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Files);
