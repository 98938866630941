import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const Security = ({ data, ...props }) => {
  return (
    <>
      <b>Sécurisé : </b>
      {data.secured ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />} <br />
      <b>Balisage : </b> {data.balisage}
      <div className={"clear-fix mt-3"} />
    </>
  );
};

export default Security;
