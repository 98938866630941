import React from "react";
import PhotoItem from "../../../../Utils/PhotoItem";

const Photos = ({ data, active = true, ...props }) => {
  return (
    <div className={"test-photos-wrapper"}>
      {data.photos &&
        data.photos.length &&
        data.photos.map((photo, key) => (
          <div className={"test-photos-single"}>
            <PhotoItem photo={photo} active={true} />
          </div>
        ))}
    </div>
  );
};

export default Photos;
