import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TaskListContainer from "../Containers/FLM/TaskListContainer";
import CManagerList from "../Components/ClusterManager/CManagerList";
import ManagerList from "../Components/Manager/ManagerList";
import ManagerNew from "../Components/Manager/ManagerNew";
import ManagerDetail from "../Components/Manager/ManagerDetail";

import AgentNew from "../Components/Agent/AgentNew";
import AgentContainer from "../Containers/AgentContainer";
import Settings from "./../Components/Settings/Settings";
import LoginBox from "../Components/Login/LoginBox";
import DashboardWrapper from "../Components/Dashboard/Pages/DashboardWrapper";
import CalendarPage from "../Components/Planning/CalendarPage";
import PlanningList from "../Components/Planning/PlanningList";
import AnomaliesContainer from "../Containers/FLM/AnomaliesContainer";
import UserAccount from "../Components/User/UserAccount";
import CManagerNew from "../Components/ClusterManager/CManagerNew";
import AgentList from "../Components/Agent/AgentList/index";
import Manuals from "./../Components/Manuals/Manuals";
import TeamsList from "../Components/Team/TeamList/index";
import TeamNew from "../Components/Team/TeamNew";
import TeamContainer from "../Containers/TeamContainer";
import ProblemManagerList from "../Components/ProblemManager/ProblemManagerList";
import ProblemManagerNew from "../Components/ProblemManager/ProblemManagerNew";
//import ProblemManagerDetail from "../Components/ProblemManager/ProblemManagerDetail";
import CMCreatorList from "../Components/CMCreator/CMCreatorList";
import CMCreatorNew from "../Components/CMCreator/CMCreatorNew";
import ServiceList from "../Components/Service/ServiceList/index";
import ServiceNew from "../Components/Service/ServiceNew";
import ServiceContainer from "../Containers/ServiceContainer";


function mapStateToProps(state) {
  return { auth: state.session.auth };
}

class CommonRouter extends Component {
  render() {
    return (
      <Switch>




        {/**
         * Users routes
         */}
        <Route exact path='/clustermanagers' component={CManagerList} />
        <Route exact path='/clustermanagers/new' component={CManagerNew} />

        <Route exact path='/managers' component={ManagerList} />
        <Route exact path='/managers/new' component={ManagerNew} />
        <Route exact path='/managers/detail/:userid' component={ManagerDetail} />

        <Route exact path='/agents' component={AgentList} />
        <Route exact path='/agents/new' component={AgentNew} />
        <Route path='/agents/:userid' component={AgentContainer} />

        <Route exact path='/teams' component={TeamsList} />
        <Route exact path='/teams/new' component={TeamNew} />
        <Route path='/teams/:userid' component={TeamContainer} />

        <Route exact path='/problemmanagers' component={ProblemManagerList} />
        <Route exact path='/problemmanagers/new' component={ProblemManagerNew} />
        {/*<Route exact path='/problemmanagers/detail/:userid' component={ProblemManagerDetail} />*/}

        <Route exact path='/cmcreators' component={CMCreatorList} />
        <Route exact path='/cmcreators/new' component={CMCreatorNew} />

        <Route exact path='/services' component={ServiceList} />
        <Route exact path='/services/new' component={ServiceNew} />
        <Route path='/services/:userid' component={ServiceContainer} />

        <Route path="/settings" component={Settings} />
        <Route path='/account' component={UserAccount} />
        <Route path='/flm/anomalies' component={AnomaliesContainer} />
        <Route path='/flm/planning' component={PlanningList} />
        <Route path='/flm/calendar' component={CalendarPage} />
        <Route path='/dashboard' component={DashboardWrapper} />

        <Route path="/manuals" component={Manuals} />

      </Switch>
    );
  }
}

export default withRouter(connect(mapStateToProps)(CommonRouter));
