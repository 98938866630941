import React, { useState, useRef, Component, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUndo,
  faFileExport,
  faChevronDown,
  faTrash,
  faAngleRight,
  faCheck,
  faLock,
  faSpinner,
  faHourglass,
  faCalendarDay,
  faWindowClose,
  faPlus,
  faEdit,
  faCheckCircle,
  faArrowUp,
  faInfo,
  faFileImport,
} from "@fortawesome/free-solid-svg-icons";
import MoreInfoDialog, { Item } from "../../../Utils/MoreInfoDialog";
import moment from "moment";
import CMTests from "./Tests";
import CheckOrTime from "../components/CheckOrTime";
import Restricted from "../../../Utils/Restricted";
import RenderComponent from "../../../Utils/RenderComponent";
import Constants from "../../../Utils/Constants";
import ApiService from "../../../Utils/ApiService";
import { useSelector } from "react-redux";
import Select from "react-select";

const CMTaskRow = ({
  task,
  key,
  exportCM,
  editTask,
  showTask,
  cancelTask,
  startTask,
  restartTask,
  finishTask,
  deleteTask,
  returnTask,
  closeTask,
  solveProblem,
  reassignTask,
  escalateTask,
  acceptTask,
  rejectTask,
  transferTask,
  createChild,
  updateFinal,
  mine,
  user,
  filters,
  agentsToTransfer,
  teamsToTransfer,
  attachFile,
  ...props
}) => {
  var array = Array.from(CMTests);

  //console.log("hello", agentsToTransfer, teamsToTransfer);
  //console.log("hello", user);

  var problem_type_final = "";
  var service_final = "";

  if (task.cm_code != "Transfert de Sites") {
    array = CMTests.slice(0, -1);
  }

  var impactedSitesArray = task.cm_impacted_site_list
    ? task.cm_impacted_site_list.split(",")
    : [];

  //split the array by category
  var impacted2gSites = [];
  var impacted3gSites = [];
  var impacted4gSites = [];

  for (var i = 0; i < impactedSitesArray.length; i++) {
    if (
      impactedSitesArray[i].trim().startsWith("3") ||
      impactedSitesArray[i].trim().startsWith(" 3")
    ) {
      impacted3gSites.push(impactedSitesArray[i].trim());
    } else if (
      impactedSitesArray[i].trim().startsWith("4") ||
      impactedSitesArray[i].trim().startsWith(" 4")
    ) {
      impacted4gSites.push(impactedSitesArray[i].trim());
    } else if (!/^\s*$/.test(impactedSitesArray[i].trim())) {
      impacted2gSites.push(impactedSitesArray[i].trim());
    }
  }

  const { session } = useSelector((state) => ({ session: state.session }));

  const [activeTest, selectTest] = useState("cm_clockin");
  const [showDetail, setShowDetail] = useState(false);
  const [showStatus, setShowStatus] = useState(false);

  var history_from_time = [];
  var history_to_time = [];
  var history_process = [];
  var history_status = [];
  var history_comment = [];
  var history_problem_type = [];
  var operator = [];
  var operator_service = [];
  var comm = [];
  var j = 0;
  var k = 0;
  var id = [];
  var history_problem_type_final = [];
  var history_service_id_final = [];
  var history_service_name_final = [];
  var history_attachment = [];

  if (task.cm_history_from_time) {
    history_from_time = task.cm_history_from_time.split(",");
  }
  if (task.cm_history_to_time) {
    history_to_time = task.cm_history_to_time.split(",");
  }
  if (task.cm_history_process) {
    history_process = task.cm_history_process.split(",");
  }
  if (task.cm_history_status) {
    history_status = task.cm_history_status.split(",");
  }
  if (task.cm_history_comment) {
    history_comment = task.cm_history_comment.split(",");
  }
  if (task.cm_history_problem_type) {
    history_problem_type = task.cm_history_problem_type.split(",");
  }
  if (task.operator) {
    operator = task.operator.split(",");
  }
  if (task.operator_service) {
    operator_service = task.operator_service.split(",");
  }
  if (task.cm_history_id) {
    id = task.cm_history_id.split(",");
  }
  if (task.cm_history_problem_type_final) {
    history_problem_type_final = task.cm_history_problem_type_final.split(",");
  }
  if (task.cm_history_service_id_final) {
    history_service_id_final = task.cm_history_service_id_final.split(",");
  }
  if (task.cm_history_service_name_final) {
    history_service_name_final = task.cm_history_service_name_final.split(",");
  }
  if (task.cm_history_attachment) {
    history_attachment = task.cm_history_attachment.split(",");
  }

  var history = [];
  for (var i = 0; i < id.length; i++) {
    var obj = {
      from_time: "----/--/-- --:--:--",
      to_time: "----/--/-- --:--:--",
      process: "Unkown",
      status: "Unkown",
      comment: "-",
      problem_type: "-",
      operator: "-",
      operator_service: "-",
      id: "-",
      problem_type_final: "",
      service_id_final: "",
      service_name_final: "",
      attachment: "-",
    };
    if (history_from_time[i]) {
      obj["from_time"] = history_from_time[i];
    }
    if (history_to_time[i]) {
      obj["to_time"] = history_to_time[i];
    }
    if (history_process[i]) {
      obj["process"] = history_process[i];
    }
    if (history_status[i]) {
      obj["status"] = history_status[i];
    }
    if (history_comment[i]) {
      obj["comment"] = history_comment[i];
    }
    if (history_problem_type[i]) {
      obj["problem_type"] = history_problem_type[i];
    }
    if (operator[i]) {
      obj["operator"] = operator[i];
    }
    if (operator_service[i]) {
      obj["operator_service"] = operator_service[i];
    }
    if (id[i]) {
      obj["id"] = id[i];
    }
    if (history_problem_type_final[i]) {
      obj["problem_type_final"] = history_problem_type_final[i];
    }
    if (history_service_id_final[i]) {
      obj["service_id_final"] = history_service_id_final[i];
    }
    if (history_service_name_final[i]) {
      obj["service_name_final"] = history_service_name_final[i];
    }
    if (history_attachment[i]) {
      obj["attachment"] = history_attachment[i];
    }

    history[i] = obj;
  }

  var arr = [];
  for (var j = 0; j < history.length; j++) {
    arr.push(false);
  }

  const [editing, setEditing] = useState([]);
  useEffect(() => {
    setEditing(arr);
  }, []);

  var now = new Date();

  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const exportForm = useRef(null);
  const detailInput = useRef(null);

  if (history.length > 0) {
    return (
      <React.Fragment>
        <form method='post' ref={exportForm} target={"_blank"}>
          <input type='hidden' name={"pm"} ref={detailInput} />
          <input type='hidden' name={"token"} value={session.user.token} />
        </form>

        <div className={"row "}>
          <div className={"col-md-12"}>
            <div className={"row ctable-row"}>
              <div className={"col-md-1 table-center"}>
                <span className={"center"}>
                  <a title='Détails' onClick={() => showTask(task)} href={"javascript:void(0)"} style={{ color: "inherit" }} className={"site-link"} > {task.ticket_ref ? task.ticket_ref : "-"} </a>
                </span>
                {
                  /*
                  history[history.length-1] 
                  && history[history.length-1]['status'] == "Wait Accept"
                  && history[history.length-1]['process'] !== "Escalation"
                  && task.cm_last_service_id != null && task.cm_last_service_id != "0"
                  && user.role == "service"
                  && (/*user.userid == parseInt(task.created_by_id, 10) || task.my_cm == "1" || task.created_by_me == "1" || ** task.assigned_to_me == "1")
                  && task.cm_state == null
                  ?
                  <span className={"center"} ><a title='Commencer' onClick={() => startTask(task.cm_last_service_id, task.site_plan_id)} href={"javascript:void(0)"} style={{color: "inherit"}} className={"site-link"}>{task.cm_last_status ? task.cm_last_status : '-'}</a></span>
                  :
                  history[history.length-1] 
                  && history[history.length-1]['status'] == "Wait Restart"
                  && history[history.length-1]['process'] == "Assignment"
                  && task.cm_last_service_id != null && task.cm_last_service_id != "0"
                  && user.role == "service"
                  && (/*user.userid == parseInt(task.created_by_id, 10) || task.my_cm == "1" || task.created_by_me == "1" || ** task.assigned_to_me == "1")
                  && task.cm_state == null
                  ?
                  <span className={"center"} ><a title='Recommencer' onClick={() => restartTask(task.cm_last_service_id, task.site_plan_id)} href={"javascript:void(0)"} style={{color: "inherit"}} className={"site-link"}>{task.cm_last_status ? task.cm_last_status : '-'}</a></span>
                  :
                  history[history.length-1] 
                  && history[history.length-1]['status'] == "Wait Process"
                  && history[history.length-1]['process'] !== "Escalation"
                  && task.cm_last_service_id != null && task.cm_last_service_id != "0"
                  && user.role == "service"
                  && (/*user.userid == parseInt(task.created_by_id, 10) || task.my_cm == "1" || task.created_by_me == "1" || ** task.assigned_to_me == "1")
                  && task.cm_state == null
                  ?
                  <span className={"center"} ><a title='Terminer' onClick={() => finishTask(task.cm_last_service_id, task.site_plan_id)} href={"javascript:void(0)"} style={{color: "inherit"}} className={"site-link"}>{task.cm_last_status ? task.cm_last_status : '-'}</a></span>
                  :
                  history[history.length-1] 
                  && history[history.length-1]['status'] == "Wait Reprocess"
                  && history[history.length-1]['process'] == "Assignment"
                  && task.cm_last_service_id != null && task.cm_last_service_id != "0"
                  && user.role == "service"
                  && (/*user.userid == parseInt(task.created_by_id, 10) || task.my_cm == "1" || task.created_by_me == "1" || ** task.assigned_to_me == "1")
                  && task.cm_state == null
                  ?
                  <span className={"center"} ><a title='Terminer' onClick={() => finishTask(task.cm_last_service_id, task.site_plan_id)} href={"javascript:void(0)"} style={{color: "inherit"}} className={"site-link"}>{task.cm_last_status ? task.cm_last_status : '-'}</a></span>
                  :
                  history[history.length-1] 
                  && (history[history.length-1]['process'] === "Escalation" && history[history.length-1]['status'] === "Wait Accept")
                  && history[history.length-1]['status'] != "Canceled"
                  && (/*user.userid == parseInt(task.created_by_id, 10) || task.my_cm == "1" || task.created_by_me == "1" || ** task.assigned_to_me == "1")
                  && task.cm_state == null
                  ?
                  <span className={"center"} ><a title='Accepter Escalade' onClick={() => acceptTask(task.cm_last_service_id, task.site_plan_id)} href={"javascript:void(0)"} style={{color: "inherit"}} className={"site-link"}>{task.cm_last_status ? task.cm_last_status : '-'}</a></span>
                  :
                  history[history.length-1] 
                  && (history[history.length-1]['process'] === "Escalation" && history[history.length-1]['status'] === "Wait Process")
                  && history[history.length-1]['status'] != "Canceled"
                  && (user.userid == parseInt(task.created_by_id, 10) || task.my_cm == "1" || task.created_by_me == "1" || task.assigned_to_me == "1")
                  && task.cm_state == null
                  && task.is_supervision == "0"
                  ?
                  <span className={"center"} ><a title='Régler problème' onClick={() => solveProblem(task.task_id, task.site_plan_id, task)} href={"javascript:void(0)"} style={{color: "inherit"}} className={"site-link"}>{task.cm_last_status ? task.cm_last_status : '-'}</a></span>
                  :
                  history[history.length-1] 
                  && (history[history.length-1]['status'] === "access problem" 
                    || history[history.length-1]['status'] === "spare part problem") 
                  && history[history.length-1]['status'] != "Canceled"
                  && history[history.length-1]['status'] != "Closed"
                  && history[history.length-1]['status'] != "Force Closed"
                  && task.cm_state == null
                  && task.is_supervision == "0"
                  ?
                  <span className={"center"} ><a title='Régler problème' onClick={() => solveProblem(task.task_id, task.site_plan_id, task)} href={"javascript:void(0)"} style={{color: "inherit"}} className={"site-link"}>{task.cm_last_status ? task.cm_last_status : '-'}</a></span>
                  :
                  history[history.length-1] 
                  && history[history.length-1]['status'] != "Canceled" 
                  && history[history.length-1]['status'] != "Closed"
                  && history[history.length-1]['status'] != "Force Closed"
                  && history[history.length-1]['status'] == "Wait Confirm" 
                  && (user.userid == parseInt(task.created_by_id, 10) /*|| task.my_cm == "1"** || task.created_by_me == "1" /*|| task.is_supervision == "1"**)
                  && task.cm_state == null
                  ?
                  <span className={"center"} ><a title='Cloturer' onClick={() => closeTask(task.task_id, task.site_plan_id, user.userid, task.cm_end_date, task.task_created, task.owner, task.cm_last_service_agent_name)} href={"javascript:void(0)"} style={{color: "inherit"}} className={"site-link"}>{task.cm_last_status ? task.cm_last_status : '-'}</a></span>
                  :**/
                  <span className={"center"}>
                    <a title='Détails' onClick={() => showTask(task)} href={"javascript:void(0)"} style={{ color: "inherit" }} className={"site-link"}> {task.cm_last_status ? task.cm_last_status : "-"} </a>
                  </span>
                }
                <span className={"center"}>
                  {
                    task.task_status === "return" 
                    ? 
                    (<FontAwesomeIcon color='orange' icon={faUndo} />) 
                    : 
                    history[history.length - 1] 
                    && ((history[history.length - 1]["status"] === "Wait Accept" && history[history.length - 1]["process"] === "Transfer") 
                    || (history[history.length - 1]["status"] === "Wait Accept" && history[history.length - 1]["process"] === "Escalation") 
                    || history[history.length - 1]["status"] === "access problem" 
                    || history[history.length - 1]["status"] === "spare part problem") 
                    ? 
                    (<FontAwesomeIcon color='red' icon={faWindowClose} />) 
                    : 
                    history[history.length - 1] 
                    && ((history[history.length - 1]["status"] === "Wait Accept" && history[history.length - 1]["process"] === "Assignment") 
                    || (history[history.length - 1]["status"] === "Wait Accept" && history[history.length - 1]["process"] === "Reassignment")) 
                    && moment(now, "YYYY-MM-DD HH:mm:ss").diff(moment(history[history.length - 1]["from_time"], "YYYY-MM-DD HH:mm:ss",),) >= 14400000 
                    ? (<FontAwesomeIcon color='red' icon={faHourglass} />) 
                    : 
                    history[history.length - 1] 
                    && history[history.length - 1]["status"] === "Canceled" 
                    ? 
                    (<FontAwesomeIcon color='red' icon={faCheck} />) 
                    : 
                    history[history.length - 1] 
                    && (history[history.length - 1]["status"] === "Closed" 
                    || history[history.length - 1]["status"] === "Force Closed") 
                    ? 
                    (<FontAwesomeIcon color='rgb(48, 209, 88)' icon={faCheck} />) 
                    : 
                    history[history.length - 1] 
                    && history[history.length - 1]["status"] === "Wait Process" 
                    ? 
                    (<FontAwesomeIcon color='rgb(48, 209, 88)' icon={faSpinner}/>) 
                    : 
                    history[history.length - 1] 
                    && history[history.length - 1]["status"] === "Wait Confirm" 
                    ? 
                    (<FontAwesomeIcon color='orange' icon={faSpinner} />) 
                    : 
                    (history[history.length - 1]["status"] ==="Wait Accept" && history[history.length - 1]["process"] === "Assignment") 
                    || (history[history.length - 1]["status"] === "Wait Accept" && history[history.length - 1]["process"] === "Reassignment") 
                    ? 
                    (<FontAwesomeIcon color='grey' icon={faHourglass} />) 
                    : 
                    !task.task_status 
                    ? (<FontAwesomeIcon color='rgb(48, 209, 88)' icon={faCheck} />) 
                    : 
                    (<FontAwesomeIcon color='rgb(48, 209, 88)' icon={faSpinner}/>)
                  }
                </span>
              </div>

              <div className={"col-md-1 table-center"}>
                {task.cm_parent_ref ? task.cm_parent_ref : "-"}
              </div>

              <div className={"col-md-1 table-center"}>
                <a title={task.task_site_name} onClick={() => props.handlers.updateFilter({ value: task.task_site_code }, "site",)} href={"javascript:void(0)"} style={{ color: "inherit" }} className={"site-link"}>
                  {task.task_site_logique_code + ` (${task.task_site_logique_type})`}
                  <br />
                  {task.task_physic_code} 
                  <br />
                  {task.owner ? task.owner : null}
                </a>
              </div>

              <div className={"col-md-1"}>{task.cm_code}</div>

              <div className={"col-md-1"}>
                {task.cm_problem_type ? task.cm_problem_type : "-"}
              </div>

              <div className={"col-md-1"}>
                <label title={task.comment}> {task.comment ? task.comment.length > 55 ? `${task.comment.substr(0, 55)}...` : task.comment : "-"} </label>
              </div>

              <div className={"col-md-2"}>
                <span className={"task-plan-col"}>
                  <span className={"line task-" + task.assignment_delay}>
                    <span className={"line-label"}>Alarme</span>
                    <span className={"line-value"}>
                      {/*task.task_plan*/ task.cm_start_date ? moment(task.cm_start_date).format("YYYY-MM-DD HH:mm") : task.task_created}{" "}
                    </span>
                  </span>
                  <span className={"line task-" + task.task_delay}>
                    <span className={"line-label"}>Alerting</span>
                    <span className={"line-value"}>
                      {/*task.task_created ? moment(task.task_created).format("YYYY-MM-DD HH:mm") : ""*/}
                      {task.task_plan ? moment(task.task_plan).format("YYYY-MM-DD HH:mm") : ""}
                    </span>
                  </span>
                  <span className={"line"}>
                    <span className={"line-label"}>Fin</span>
                    <span className={"line-value"}>
                      {task.cm_end_date ? moment(task.cm_end_date).format("YYYY-MM-DD HH:mm") : ""}{" "}
                    </span>
                  </span>
                </span>
              </div>

              <div className={"col-md-1"}>
                <span className={"task-plan-col"}>
                  {
                    task.solved_by_id && task.solved_by_id != "0" 
                    ? 
                    (<span> <span> <a className={"site-link"}>{task.solved_by}</a> </span> </span>) 
                    : 
                    task.solved_by_id && task.solved_by_id == "0" 
                    ? 
                    (<span> <span> <a className={"site-link"}>Sans intervention</a> </span> </span>) 
                    : 
                    null
                  }
                  {
                    !task.solved_by_id && task.last_operator_service != 0 
                    ? 
                    (<span> <span> <a className={"site-link"}> {task.last_operator_service} </a> </span> </span>) 
                    : 
                    !task.solved_by_id && history[history.length - 1]["status"] == "Wait Confirm" 
                    ? 
                    (<span> <span> <a className={"site-link"}> {task.created_by_service ? task.created_by_service : null} </a> </span> </span>) 
                    : 
                    !task.solved_by_id && history[history.length - 1]["status"] != "Wait Confirm" 
                    ? 
                    (<span> <span> <a className={"site-link"}> {task.cm_last_service_name != 0 ? task.cm_last_service_name : null} </a> </span> </span>) 
                    : 
                    null
                  }
                </span>
              </div>

              <div className={"col-md-1"}>
                <span className={"task-plan-col"}>
                  {
                    !task.solved_by_id && task.last_operator != 0 
                    ? 
                    ( <span> <span> <a className={"site-link"}>{task.last_operator}</a> </span> </span> ) 
                    : 
                    !task.solved_by_id && history[history.length - 1]["status"] == "Wait Confirm" 
                    ? 
                    ( <span> <span> <a className={"site-link"}> {/*task.approved_by ? task.approved_by : task.created_by ? task.created_by : null*/} </a> </span> </span> ) 
                    : 
                    !task.solved_by_id && history[history.length - 1]["status"] != "Wait Confirm" 
                    ? 
                    ( <span> <span> <a className={"site-link"}> 
                      {
                        task.cm_last_agent_username != 0
                        ? 
                        task.cm_last_agent_username
                        :
                        task.cm_last_team_name != 0
                        ? 
                        task.cm_last_team_name
                        : 
                        task.cm_last_manager_username != 0
                        ? 
                        task.cm_last_manager_username
                        : 
                        null
                      }
                    </a> </span> </span>) 
                    : 
                    null
                  }
                </span>
              </div>

              <div className={"col-md-1"}>
                <span className={"task-plan-col"}>
                  {!task.cm_impacted_site_list && !task.cm_impacted_2g_sectors && !task.cm_impacted_3g_sectors && !task.cm_impacted_4g_sectors ? "-" : null}
                  {
                    impacted2gSites.length > 0 && task.cm_impacted_site_list 
                    ? 
                    ( 
                      <label title={task.cm_impacted_site_list}>
                        <span>
                          <span>Sites 2G : </span>
                          <span> {impacted2gSites ? impacted2gSites.length : "0"} </span>
                        </span>
                      </label> 
                    ) 
                    : 
                    null
                  }
                  {
                    impacted3gSites.length > 0 && task.cm_impacted_site_list 
                    ? 
                    (
                      <label title={task.cm_impacted_site_list}>
                        <span>
                          <span>Sites 3G : </span>
                          <span> {impacted3gSites ? impacted3gSites.length : "0"} </span>
                        </span>
                      </label>
                    ) 
                    : 
                    null
                  }
                  {
                    impacted4gSites.length > 0 && task.cm_impacted_site_list 
                    ? 
                    (
                      <label title={task.cm_impacted_site_list}>
                        <span>
                          <span>Sites 4G : </span>
                          <span> {impacted4gSites ? impacted4gSites.length : "0"} </span>
                        </span>
                      </label>
                    ) 
                    : 
                    null
                  }
                  {
                    task.cm_impacted_2g_sectors 
                    ? 
                    (
                      <span>
                        <span>Secteurs : </span>
                        <span> {task.cm_impacted_2g_sectors ? task.cm_impacted_2g_sectors : "-"} </span>
                      </span>
                    ) 
                    : 
                    null
                  }
                  {/*task.cm_impacted_3g_sectors ? (
                    <span>
                      <span>Secteurs 3G : </span>
                      <span>
                        {task.cm_impacted_3g_sectors
                          ? task.cm_impacted_3g_sectors
                          : "-"}
                      </span>
                    </span>
                  ) : null}
                  {task.cm_impacted_4g_sectors ? (
                    <span>
                      <span>Secteurs 4G : </span>
                      <span>
                        {task.cm_impacted_4g_sectors
                          ? task.cm_impacted_4g_sectors
                          : "-"}
                      </span>
                    </span>
                  ) : null*/}
                </span>
              </div>

              <div className={"col-md-1 task-details-col"}>
                <span>
                  <button onClick={() => setShowStatus(!showStatus)} className='task-action-btn'>
                    <FontAwesomeIcon icon={faChevronDown} /> Hist
                  </button>
                  <button onClick={() => setShowDetail(!showDetail)} className='task-action-btn'>
                    <FontAwesomeIcon icon={faChevronDown} /> Info
                  </button>
                </span>

                <MoreInfoDialog title={"more"}>
                  {
                    history[history.length - 1] 
                    && history[history.length - 1]["status"] != "Canceled" 
                    && history[history.length - 1]["status"] != "Closed" 
                    && history[history.length - 1]["status"] != "Force Closed" 
                    && task.cm_state == null 
                    && (user.userid == parseInt(task.created_by_id, 10) /*|| task.my_cm == "1"*/ || task.created_by_me == "1") /*|| task.is_supervision == "1"*/ 
                    ? 
                    (<Item onClick={(e) => closeTask(task.task_id, task.site_plan_id, user.userid, task.cm_end_date, task.task_created, task.owner, task.cm_last_service_agent_name,)}> <FontAwesomeIcon className={"dialog-icon"} icon={faLock} /> Cloturer </Item>) 
                    : 
                    null
                  }
                  {
                    history[history.length - 1] 
                    && history[history.length - 1]["status"] === "Wait Confirm" 
                    && (user.userid == parseInt(task.created_by_id, 10) || task.created_by_me == "1" || task.is_supervision == "1") 
                    && history[history.length - 1]["status"] != "Canceled" 
                    && history[history.length - 1]["status"] != "Closed" 
                    && history[history.length - 1]["status"] != "Force Closed" 
                    && task.cm_state == null 
                    ? 
                    (<Item onClick={(e) => reassignTask(task.task_id, task.site_plan_id, task.task_agent_id, task.task_site_id, task.task_site_logique_id,)}> <FontAwesomeIcon className={"dialog-icon danger"} icon={faUndo}/>{" "} Reprocess</Item>) 
                    : 
                    null
                  }
                  {
                    history[history.length - 1] 
                    && history[history.length - 1]["status"] != "Canceled" 
                    && (user.userid == parseInt(task.created_by_id, 10) /*|| task.my_cm == "1"*/ || task.created_by_me == "1" /*|| task.is_supervision == "1"*/ || user.role == "admin") 
                    ? 
                    (<Item onClick={(e) => editTask(task)}> <FontAwesomeIcon className={"dialog-icon danger"} icon={faEdit} />{" "} Modifier</Item>) 
                    : 
                    null
                  }
                  {
                    history[history.length - 1] 
                    && history[history.length - 1]["status"] != "Canceled" 
                    && history[history.length - 1]["status"] != "Closed" 
                    && history[history.length - 1]["status"] != "Force Closed" 
                    && task.cm_state == null 
                    && (user.userid == parseInt(task.created_by_id, 10) /*|| task.my_cm == "1"*/ || task.created_by_me == "1" || task.is_supervision == "1") 
                    ? 
                    (<Item onClick={(e) => createChild(task.ticket_ref)}> <FontAwesomeIcon className={"dialog-icon danger"} icon={faPlus}/>{" "} Créer enfant </Item>) 
                    : 
                    null
                  }
                  {
                    history[history.length - 1] 
                    && history[history.length - 1]["status"] != "Canceled" 
                    && history[history.length - 1]["status"] != "Closed" 
                    && history[history.length - 1]["status"] != "Force Closed" 
                    && task.cm_state == null 
                    && (user.userid == parseInt(task.created_by_id, 10) /*|| task.my_cm == "1"*/ || task.created_by_me == "1") 
                    ? 
                    (<Item onClick={(e) => cancelTask(task.task_id, task.site_plan_id) }> <FontAwesomeIcon className={"dialog-icon danger"} icon={faTrash}/>{" "} Annuler</Item>) 
                    : 
                    null
                  }
                  {
                    history[history.length - 1] 
                    ? 
                    (<Item onClick={(e) => exportCM(task)} className='task-action-btn'> <FontAwesomeIcon className={"dialog-icon"} icon={faFileExport}/>{" "} Exporter </Item>) 
                    : 
                    null
                  }
                  {
                    (agentsToTransfer.length != 0 || teamsToTransfer.length != 0) 
                    && history[history.length - 1]  
                    /*&& (history[history.length-1]['status'] === "Wait Process" || history[history.length-1]['status'] == "Wait Reprocess")*/
                    /*&& (/*user.userid == parseInt(task.created_by_id, 10) || task.my_cm == "1" || task.created_by_me == "1" || *** task.assigned_to_me == "1")*/
                    && task.my_team == "1" 
                    && history[history.length - 1]["status"] != "Canceled" 
                    && history[history.length - 1]["status"] != "Closed" 
                    && history[history.length - 1]["status"] != "Force Closed" 
                    && task.cm_state == null 
                    && (!user.external || user.external == "false") 
                    ? 
                    (<Item onClick={(e) => transferTask(task)}> <FontAwesomeIcon className={"dialog-icon danger"} icon={faUndo} /> Transférer </Item>) 
                    : 
                    null
                  }
                  {
                    history[history.length - 1] 
                    ? 
                    (<Item onClick={(e) => showTask(task)}> <FontAwesomeIcon className={"dialog-icon danger"} icon={faInfo} />{" "} Détails </Item>) 
                    : 
                    null
                  }


                  {
                    history[history.length - 1] 
                    && history[history.length - 1]["status"] == "Wait Accept" 
                    && history[history.length - 1]["process"] !== "Escalation" 
                    && task.cm_last_service_id != null 
                    && task.cm_last_service_id != "0" 
                    && user.role == "service" 
                    && /*user.userid == parseInt(task.created_by_id, 10) || task.my_cm == "1" || task.created_by_me == "1" || */ task.assigned_to_me == "1" 
                    && history[history.length - 1]["status"] != "Canceled" 
                    && history[history.length - 1]["status"] != "Closed" 
                    && history[history.length - 1]["status"] != "Force Closed" 
                    && task.cm_state == null 
                    && task.is_supervision == "0" 
                    ? 
                    (<Item onClick={(e) => startTask(task.cm_last_service_id, task.site_plan_id)}> <FontAwesomeIcon className={"dialog-icon danger"} icon={faEdit}/>{" "} Commencer </Item>) 
                    : 
                    null
                  }
                  {
                    history[history.length - 1] 
                    && history[history.length - 1]["status"] == "Wait Restart" 
                    && history[history.length - 1]["process"] == "Assignment" 
                    && task.cm_last_service_id != null 
                    && task.cm_last_service_id != "0" 
                    && user.role == "service" 
                    && /*user.userid == parseInt(task.created_by_id, 10) || task.my_cm == "1" || task.created_by_me == "1" || */ task.assigned_to_me == "1" 
                    && history[history.length - 1]["status"] != "Canceled" 
                    && history[history.length - 1]["status"] != "Closed" 
                    && history[history.length - 1]["status"] != "Force Closed" 
                    && task.cm_state == null 
                    && task.is_supervision == "0" 
                    ? 
                    (<Item onClick={(e) => restartTask(task.cm_last_service_id, task.site_plan_id)}> <FontAwesomeIcon className={"dialog-icon danger"} icon={faEdit}/>{" "} Recommencer </Item>) 
                    : 
                    null
                  }
                  {
                    history[history.length - 1] 
                    && history[history.length - 1]["status"] == "Wait Process" 
                    && history[history.length - 1]["process"] !== "Escalation" 
                    && task.cm_last_service_id != null 
                    && task.cm_last_service_id != "0" 
                    && user.role == "service" 
                    && /*user.userid == parseInt(task.created_by_id, 10) || task.my_cm == "1" || task.created_by_me == "1" || */ task.assigned_to_me == "1" 
                    && history[history.length - 1]["status"] != "Canceled" 
                    && history[history.length - 1]["status"] != "Closed" 
                    && history[history.length - 1]["status"] != "Force Closed" 
                    && task.cm_state == null 
                    ? 
                    (<Item onClick={(e) => finishTask(task.cm_last_service_id, task.site_plan_id)}> <FontAwesomeIcon className={"dialog-icon danger"} icon={faCheck}/>{" "} Terminer </Item>) 
                    : 
                    null
                  }
                  {
                    history[history.length - 1] 
                    && history[history.length - 1]["status"] == "Wait Reprocess" 
                    && history[history.length - 1]["process"] == "Assignment" 
                    && task.cm_last_service_id != null 
                    && task.cm_last_service_id != "0" 
                    && user.role == "service" 
                    && /*user.userid == parseInt(task.created_by_id, 10) || task.my_cm == "1" || task.created_by_me == "1" || */ task.assigned_to_me == "1" 
                    && history[history.length - 1]["status"] != "Canceled" 
                    && history[history.length - 1]["status"] != "Closed" 
                    && history[history.length - 1]["status"] != "Force Closed" 
                    && task.cm_state == null 
                    ? 
                    (<Item onClick={(e) => finishTask(task.cm_last_service_id, task.site_plan_id)}> <FontAwesomeIcon className={"dialog-icon danger"} icon={faCheck}/>{" "} Terminer </Item>) 
                    : 
                    null
                  }
                  {
                    history[history.length - 1] 
                    && (history[history.length - 1]["status"] == "Wait Process" || history[history.length - 1]["status"] == "Wait Reprocess") 
                    && history[history.length - 1]["process"] !== "Escalation" 
                    && task.cm_last_service_id != null 
                    && task.cm_last_service_id != "0" 
                    && user.role == "service" 
                    && /*user.userid == parseInt(task.created_by_id, 10) || task.my_cm == "1" || task.created_by_me == "1" || */ task.assigned_to_me == "1" 
                    && history[history.length - 1]["status"] != "Canceled" 
                    && history[history.length - 1]["status"] != "Closed" 
                    && history[history.length - 1]["status"] != "Force Closed" 
                    && task.cm_state == null 
                    && (!user.external || user.external == "false") 
                    ? 
                    (<Item onClick={(e) => escalateTask(task)}> {" "} <FontAwesomeIcon icon={faArrowUp} className={"mr-2"} /> Escalader </Item>) 
                    : 
                    null
                  }
                  {
                    history[history.length - 1] 
                    && (history[history.length - 1]["process"] === "Escalation" && history[history.length - 1]["status"] === "Wait Process") 
                    && /*user.userid == parseInt(task.created_by_id, 10) || task.my_cm == "1" || task.created_by_me == "1" || */ task.assigned_to_me == "1" 
                    && history[history.length - 1]["status"] != "Canceled" 
                    && history[history.length - 1]["status"] != "Closed" 
                    && history[history.length - 1]["status"] != "Force Closed" 
                    && task.cm_state == null 
                    && (!user.external || user.external == "false") 
                    ? 
                    (<Item onClick={(e) => escalateTask(task)}> {" "} <FontAwesomeIcon icon={faArrowUp} className={"mr-2"} /> Escalader </Item>) 
                    : 
                    null
                  }
                  {
                    history[history.length - 1] 
                    && (history[history.length - 1]["process"] === "Escalation" && history[history.length - 1]["status"] === "Wait Accept") 
                    && /*user.userid == parseInt(task.created_by_id, 10) || task.my_cm == "1" || task.created_by_me == "1" || */ task.assigned_to_me == "1" 
                    && history[history.length - 1]["status"] != "Canceled" 
                    && history[history.length - 1]["status"] != "Closed" 
                    && history[history.length - 1]["status"] != "Force Closed" 
                    && task.cm_state == null 
                    && task.is_supervision == "0" 
                    ? 
                    (<Item onClick={(e) => acceptTask(task.cm_last_service_id, task.site_plan_id)}> {" "} <FontAwesomeIcon icon={faCheck} className={"mr-2"} />{" "} Accepter Escalade </Item>) 
                    : 
                    null
                  }
                  {
                    history[history.length - 1] 
                    && (history[history.length - 1]["process"] === "Escalation" && history[history.length - 1]["status"] === "Wait Process") 
                    && (user.userid == parseInt(task.created_by_id, 10) || task.my_cm == "1" || task.created_by_me == "1" || task.assigned_to_me == "1") 
                    && history[history.length - 1]["status"] != "Canceled" 
                    && history[history.length - 1]["status"] != "Closed" 
                    && history[history.length - 1]["status"] != "Force Closed" 
                    && task.cm_state == null 
                    && task.is_supervision == "0" 
                    ? 
                    (<Item onClick={(e) => solveProblem(task.task_id, task.site_plan_id, task) } > {" "} <FontAwesomeIcon icon={faCalendarDay} className={"mr-2"}/>{" "} Régler problème </Item> ) 
                    : 
                    history[history.length - 1] 
                    && (history[history.length - 1]["status"] === "access problem" || history[history.length - 1]["status"] === "spare part problem") 
                    && history[history.length - 1]["status"] != "Canceled" 
                    && history[history.length - 1]["status"] != "Closed" 
                    && history[history.length - 1]["status"] != "Force Closed" 
                    && task.cm_state == null 
                    && task.is_supervision == "0" 
                    ? 
                    (<Item onClick={(e) => solveProblem(task.task_id, task.site_plan_id, task)}> {" "} <FontAwesomeIcon icon={faCalendarDay} className={"mr-2"}/>{" "} Régler problème </Item>) 
                    : 
                    null
                  }
                  {
                    history[history.length - 1] 
                    && (history[history.length - 1]["process"] === "Escalation" && history[history.length - 1]["status"] === "Wait Accept") 
                    && /*user.userid == parseInt(task.created_by_id, 10) || task.my_cm == "1" || task.created_by_me == "1" || */ task.assigned_to_me == "1" 
                    && history[history.length - 1]["status"] != "Canceled" 
                    && history[history.length - 1]["status"] != "Closed" 
                    && history[history.length - 1]["status"] != "Force Closed" 
                    && task.cm_state == null 
                    && task.is_supervision == "0" 
                    ? 
                    (<Item onClick={(e) => rejectTask(task)}> <FontAwesomeIcon className={"dialog-icon danger"} icon={faUndo}/>{" "} Rejeter Escalade </Item>) 
                    : 
                    /*history[history.length-1]
                    && (history[history.length-1]['process'] == "Transfer" && history[history.length-1]['status'] === "Wait Accept") 
                    && history[history.length-1]['status'] != "Canceled" 
                    && history[history.length-1]['status'] != "Closed"
                    && history[history.length-1]['status'] != "Force Closed"
                    && task.cm_state == null
                    && (user.userid == parseInt(task.created_by_id, 10) || user.role == "cluster_manager" || user.role == "admin" || task.my_cm == "1" /*|| task.created_by_me == "1"*** || task.assigned_to_me == "1")
                    && task.is_supervision == "0"
                    && (!user.external || user.external == 'false')
                    ?
                    <Item onClick={(e) => reassignTask(task.task_id, task.site_plan_id, task.task_agent_id, task.task_site_id, task.task_site_logique_id)}><FontAwesomeIcon className={"dialog-icon danger"} icon={faUndo}/> Réassigner</Item>
                    :*/
                    null
                  }
                  {
                    history[history.length - 1] 
                    && task.cm_code == "Transfert de Sites" 
                    ? 
                    (<Item onClick={(e) => exportCM(task, true)}> <FontAwesomeIcon className={"dialog-icon"} icon={faFileExport}/> Exporter NOK </Item>) 
                    : 
                    null
                  }
                  {
                    user.role == "admin" 
                    ? 
                    (<Item onClick={(e) => deleteTask(task.task_id, task.site_plan_id) } > <FontAwesomeIcon className={"dialog-icon danger"} icon={faTrash} />{" "} Supprimer </Item>) 
                    : 
                    null
                  }
                  {/*
                    history[history.length-1] 
                    && (task.assigned_to_me == "1")
                    && history[history.length-1]['status'] != "Canceled"
                    && history[history.length-1]['status'] != "Closed"
                    && history[history.length-1]['status'] != "Force Closed"
                    && task.cm_state == null
                    ?
                    <Item onClick={(e) => attachFile(task)}> <FontAwesomeIcon icon={faFileImport} className={"mr-2"}/> Attacher fichier</Item>
                    :
                    null
                  */}
                  {/*
                    history[history.length-1] 
                    && ((history[history.length-1]['process'] === "Assignment" || history[history.length-1]['process'] === "Reassignment") && (history[history.length-1]['status'] === "Wait Accept" || history[history.length-1]['status'] === "Wait Restart")) 
                    && history[history.length-1]['status'] != "Canceled"
                    && history[history.length-1]['status'] != "Closed"
                    && history[history.length-1]['status'] != "Force Closed"
                    && task.cm_state == null
                    && (task.my_team == "1")
                    && task.is_supervision == "0"
                    ?
                    <Item onClick={(e) => reassignTask(task.task_id, task.site_plan_id, task.task_agent_id, task.task_site_id, task.task_site_logique_id)}><FontAwesomeIcon className={"dialog-icon danger"} icon={faUndo}/> Réassigner</Item>
                    :
                    null
                  */}
                  {/*history[history.length-1] /*&& history[history.length-1]['status'] != "Canceled" && (task.task_id)*****
                    ?
                    <Item onClick={() => exportCM(task, true)}><FontAwesomeIcon className={"dialog-icon"} icon={faFileExport}/>Export NOK</Item>
                    :
                    null
                    */}
                  {/*mine==="mine" && history[history.length-1] && history[history.length-1]['status'] != "Canceled"
                    ?
                    <Item onClick={() => returnTask(task.task_id)}><FontAwesomeIcon className={"dialog-icon"} icon={faUndo}  />Retourner</Item>
                    :
                    null*/}
                </MoreInfoDialog>
              </div>
            </div>

            {!!showStatus && (
              <div className={"animateRowCM row"}>
                <div className={"pm-wrapper col-md-12 row no-gutters"}>
                  <div className={"col-md-12"}>
                    <div className={"row "}>
                      <div className={"col-md-12"}>
                        <div className={"row history-table-row"}>
                          <div className={"col-md-1"}>Etat</div>
                          <div className={"col-md-1"}>De</div>
                          <div className={"col-md-1"}>A</div>
                          <div className={"col-md-1"}>Durée</div>
                          <div className={"col-md-1"}>Commentaire</div>
                          <div className={"col-md-1"}>Problème</div>
                          <div className={"col-md-1"}>Assignement</div>
                          <div className={"col-md-1"}>Opérateur</div>
                          <div className={"col-md-1"}>Attachement</div>
                          <div className={"col-md-1"}>Problème final</div>
                          <div className={"col-md-1"}>Assignement final</div>
                          <div className={"col-md-1"}>Modification</div>
                        </div>
                      </div>
                    </div>

                    <div className={"row "}>
                      <div className={"col-md-12"}>
                        <div className={"row history-table-row"}>
                          <div className={"col-md-1"}>Created</div>
                          <div className={"col-md-1"}> {task.task_created ? task.task_created : "-"} </div>
                          <div className={"col-md-1"}> {task.task_created ? task.task_created : "-"} </div>
                          <div className={"col-md-1"}> {task.task_created ? "quelques secondes" : "-"} </div>
                          <div className={"col-md-1"}> {task.comment ? task.comment : "-"} </div>
                          <div className={"col-md-1"}>{"-"}</div>
                          <div className={"col-md-1"}> <div>{task.created_by_service ? task.created_by_service : null}</div> </div>
                          <div className={"col-md-1"}><div> {task.created_by ? task.created_by : null} </div> </div>
                          <div className={"col-md-1"}>{task.attachement ? (<a target={"_blank"} href={Constants.apiURL + "/" + task.attachement} className={"task-action-btn"}> {task.attachement.replace("upload/", "")} </a>) : ("-")}</div>
                          <div className={"col-md-1"}>{"-"}</div>
                          <div className={"col-md-1"}>{"-"}</div>
                          <div className={"col-md-1"} />
                        </div>
                      </div>
                    </div>

                    {history.map((h, index) => (
                      <div className={"row "}>
                        <div className={"col-md-12"}>
                          <div className={"row history-table-row"}>
                            <div className={"col-md-1"}>{h["status"] ? h["process"] + " " + h["status"] : "Unkown"} </div>
                            <div className={"col-md-1"}> {h["from_time"] ? h["from_time"] : "----/--/-- --:--:--"} </div>
                            <div className={"col-md-1"}>{h["to_time"] ? h["to_time"] : "----/--/-- --:--:--"} </div>
                            <div className={"col-md-1"}> 
                              {
                                h["to_time"] && moment(h["from_time"], "YYYY-MM-DD HH:mm:ss").to(moment(h["to_time"], "YYYY-MM-DD HH:mm:ss"), ) != "Invalid date"
                                ? 
                                moment(h["from_time"], "YYYY-MM-DD HH:mm:ss").to(moment(h["to_time"], "YYYY-MM-DD HH:mm:ss",),).replace("dans ", "").replace("il y a ", ""): "en cours"
                              }
                            </div>
                            <div className={"col-md-1"}> {h["comment"] != 0 ? h["comment"] : "-"}</div>
                            <div className={"col-md-1"}>{h["problem_type"] != 0 ? h["problem_type"] : "-"}</div>
                            <div className={"col-md-1"}>
                              {
                                h["operator_service"] != 0 ? ( h["operator_service"]) : h["status"] === "Closed" || h["status"] === "Force Closed" 
                                ? 
                                (<div> {task.approved_by_service ? task.approved_by_service : null} </div>) 
                                : 
                                h["status"] === "Wait Confirm" 
                                ? 
                                (<div>{task.created_by_service ? task.created_by_service : null} </div>) 
                                : 
                                (<div> {task.cm_last_service_name != 0 ? task.cm_last_service_name : null} </div> )
                              }
                            </div>
                            <div className={"col-md-1"}> 
                              {
                                h["operator"] != 0 ? ( h["operator"]) : h["status"] === "Closed" || h["status"] === "Force Closed" 
                                ? 
                                (<div>{task.approved_by ? task.approved_by : null}</div>) 
                                : 
                                h["status"] === "Wait Confirm" 
                                ? 
                                (<div>{/*task.created_by ? task.created_by : null*/} </div>) 
                                : 
                                (<div> 
                                  {
                                    task.cm_last_agent_username != 0 
                                    ? 
                                    task.cm_last_agent_username 
                                    : 
                                    task.cm_last_team_name != 0 
                                    ? 
                                    task.cm_last_team_name 
                                    : 
                                    task.cm_last_manager_username != 0 
                                    ? 
                                    task.cm_last_manager_username 
                                    : 
                                    null
                                  } 
                                </div>)
                              }
                            </div>
                            <div className={"col-md-1"}> 
                              <span> 
                                {
                                  h["attachment"] != 0 
                                  ? 
                                  (<a target={"_blank"} href={ Constants.apiURL + "/" + h["attachment"]} className={"task-action-btn"} > {h["attachment"].replace("upload/", "")} </a> ) 
                                  : 
                                  ("-")
                                }
                              </span>
                            </div>
                            <div className={"col-md-1"}> {!editing[index] ? ( h["problem_type_final"] != 0 ? ( h["problem_type_final"]) : ("-")) : (
                                <Select
                                  name={"problem_type_final"}
                                  options={filters.problem_types}
                                  //options={problem_types}
                                  classNamePrefix={"custom-select"}
                                  required
                                  defaultValue={{
                                    label: h["problem_type_final"],
                                    value: h["problem_type_final"],
                                  }}
                                  onChange={(e) =>
                                    (problem_type_final = e.value
                                      ? e.value
                                      : null)
                                  }
                                />
                              )}
                            </div>
                            <div className={"col-md-1"}>
                              {!editing[index] ? (
                                h["service_name_final"] != 0 ? (
                                  h["service_name_final"]
                                ) : (
                                  "-"
                                )
                              ) : (
                                <Select
                                  name={"service_id_final"}
                                  options={filters.services}
                                  //options={services}
                                  classNamePrefix={"custom-select"}
                                  required
                                  defaultValue={{
                                    label: h["service_name_final"],
                                    value: h["service_id_final"],
                                  }}
                                  onChange={(e) =>
                                    (service_final = e.value ? e.value : null)
                                  }
                                />
                              )}
                            </div>
                            <div className={"col-md-1"}>
                              {user.role == "admin" ? (
                                !editing[index] ? (
                                  <button
                                    onClick={() => {
                                      var array = [];
                                      for (var j = 0; j < history.length; j++) {
                                        if (j == index) {
                                          array[j] = !editing[index];
                                        } else {
                                          array[j] = editing[index];
                                        }
                                      }
                                      setEditing([...array]);
                                    }}
                                    className='task-action-btn'
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                    Modifier
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => {
                                      setEditing(!editing[index]);
                                      updateFinal(
                                        h["id"],
                                        problem_type_final
                                          ? problem_type_final
                                          : h["problem_type_final"],
                                        service_final
                                          ? service_final
                                          : h["service_id_final"],
                                      );
                                    }}
                                    className='task-action-btn'
                                  >
                                    <FontAwesomeIcon
                                      className={"text-success"}
                                      icon={faCheckCircle}
                                    />{" "}
                                    Sauvegarder{" "}
                                  </button>
                                )
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {!!showDetail && (
              <div className={"animateRow row"}>
                <div className={"pm-wrapper col-md-12 row no-gutters"}>
                  <div className={"col-md-2 pm-sidebar"}>
                    <ul>
                      {task.cm_code == "Transfert de Sites"
                        ? CMTests.map((test) => (
                            <li
                              className={
                                activeTest === test.key ? "active" : ""
                              }
                            >
                              <a onClick={(e) => selectTest(test.key)}>
                                <CheckOrTime task={task.task_tests[test.key]} />
                                {test.name}
                                <FontAwesomeIcon
                                  icon={faAngleRight}
                                  size={22}
                                />
                              </a>
                            </li>
                          ))
                        : array.map((test) => (
                            <li
                              className={
                                activeTest === test.key ? "active" : ""
                              }
                            >
                              <a onClick={(e) => selectTest(test.key)}>
                                <CheckOrTime task={task.task_tests[test.key]} />
                                {test.name}
                                <FontAwesomeIcon
                                  icon={faAngleRight}
                                  size={22}
                                />
                              </a>
                            </li>
                          ))}
                    </ul>
                  </div>
                  <div className={"col-md-10 pm-content"}>
                    {CMTests.map(
                      (test) =>
                        task.task_tests[test.key] &&
                        activeTest === test.key && (
                          <div className={"pm-test-single"}>
                            <h5>
                              {test.name}{" "}
                              {task.task_tests[test.key].data.finished_at ? (
                                <b>
                                  {moment(
                                    task.task_tests[test.key].data.finished_at,
                                  )
                                    .format("YYYY-MM-DD HH:mm")
                                    .toString()}{" "}
                                  <br />
                                </b>
                              ) : (
                                ""
                              )}
                            </h5>
                            <RenderComponent
                              component={test.component}
                              data={task.task_tests[test.key].data}
                            />
                            {task.task_tests[test.key].data.otherAgents ? (
                              <>
                                Autres agents:{" "}
                                <b>
                                  {task.task_tests[test.key].data.otherAgents}{" "}
                                  <br />
                                </b>{" "}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        ),
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

export default CMTaskRow;
