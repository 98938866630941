import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faSpinner,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import api from "../../../Utils/Api";
import Constants from "../../../Utils/Constants";
import { connect } from "react-redux";
import Restricted from "../../../Utils/Restricted";

class CMSiteImportOverlay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      history: [],
      activeTab: "sites",
      error: "",
      success: "",
      loading: false,
      notImported: [],
    };
  }

  switchTab = (tab) =>
    this.setState({ activeTab: tab, notImported: [], error: "", success: "" });

  submitForm = (e, success) => {
    e.preventDefault();
    this.setState({ error: "", notImported: [], loading: true });

    const data = new FormData(e.target);
    api
      .post(e.target.action, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.error) this.setState({ error: res.data.error });

        if (res.data.success)
          this.setState({
            success: success || "Opération effectué avec succés",
          });

        if (res.data.notfound)
          this.setState({ notImported: res.data.notfound });

        this.setState({ loading: false });

        console.log(res.data);
      })
      .catch((err) => {
        this.setState({
          error: "Une erreur est survenu, veuillez contactez l'administrateur.",
          loading: false,
        });
      });
  };

  render() {
    //console.log("hello", Constants.apiURL);

    return (
      <div className={"ui-overlay open"}>
        <div className={"ui-overlay-content import-overlay col-md-8"}>
          <button
            onClick={this.props.close}
            className={"ui-overlay-close btn btn-danger btn-sm"}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <h5>Importer fichier excel</h5>

          <div className={"row"}>
            <div className={"col-md-4 sidemenu"}>
              <ul>
                <Restricted
                  condition={this.props.type !== "gpm"}
                  scope={"pm"}
                  permission={"edit_agent_site"}
                >
                  <li className={this.state.activeTab === "agents" && "active"}>
                    <a onClick={(e) => this.switchTab("agents")} href='#'>
                      Attribuer des sites
                    </a>
                  </li>
                </Restricted>
                <Restricted
                  condition={this.props.type !== "gpm"}
                  scope={"pm"}
                  permission={"add_site"}
                >
                  <li className={this.state.activeTab === "sites" && "active"}>
                    <a onClick={(e) => this.switchTab("sites")} href='#'>
                      Nouveaux sites logiques
                    </a>
                  </li>
                </Restricted>
              </ul>
            </div>

            <div className={"col-md-8"}>
              {this.state.error && (
                <span className={"alert alert-warning d-block mb-2"}>
                  {this.state.error}
                </span>
              )}

              {this.state.success && (
                <span className={"alert alert-success d-block mb-2"}>
                  {this.state.success}
                </span>
              )}

              {this.state.notImported.length > 0 && (
                <span className={"alert alert-warning d-block"}>
                  Certains élements n'ont pas été importés correctement.
                </span>
              )}

              {this.state.activeTab === "sites" && this.props.type !== "gpm" && (
                <>
                  <h4>Importer une liste de nouveaux site logique à ajouter</h4>
                  <a
                    href={Constants.apiURL + "/template_sites_logiques.xlsx"}
                    target={"_blank"}
                    className={"btn btn-sm btn-info"}
                  >
                    Télécharger le fichier template
                  </a>

                  {this.state.notImported.length > 0 && (
                    <div className={"my-3"}>
                      <div className={"ctable col-md-12"}>
                        <div className={"row head-titles ctable-row"}>
                          <div className={"col-md-2"}>Site Code</div>
                          <div className={"col-md-3"}>Agent</div>
                          <div className={"col-md-3"}>Manager</div>
                          <div className={"col-md-4"}>Raison</div>
                        </div>
                        {this.state.notImported.map((item) => (
                          <div className={"row ctable-row"}>
                            <div className={"col-md-2"}>{item.site_code}</div>
                            <div className={"col-md-3"}>
                              {item.site_agent_owner}
                            </div>
                            <div className={"col-md-3"}>{item.pm_manager}</div>
                            <div className={"col-md-4"}>{item.cause}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  <form
                    onSubmit={this.submitForm}
                    action={Constants.apiURL + "/api/cm/sites/bulk"}
                    method='post'
                  >
                    <div className={"form-group mt-5"}>
                      <label>Fichier</label>
                      <input
                        type='file'
                        name='sites_file'
                        className={"form-control"}
                      />
                    </div>
                    <div className={"form-group"}>
                      <button
                        disabled={this.state.loading}
                        type='submit'
                        className={"btn btn-success float-right"}
                      >
                        {this.state.loading ? (
                          <FontAwesomeIcon icon={faSpinner} spin={true} />
                        ) : (
                          <>
                            <FontAwesomeIcon
                              icon={faArrowUp}
                              className={"mr-2"}
                            />
                            Envoyer
                          </>
                        )}
                      </button>
                    </div>
                  </form>
                </>
              )}

              {this.state.activeTab === "agents" && this.props.type !== "gpm" && (
                <>
                  <h4>Importer une liste de site assigné à des agents</h4>

                  <a
                    href={Constants.apiURL + "/template_agents.xlsx"}
                    target={"_blank"}
                    className={"btn btn-sm btn-info"}
                  >
                    Télécharger le fichier template
                  </a>

                  {this.state.notImported.length > 0 && (
                    <div className={"my-3"}>
                      <div className={"ctable col-md-12"}>
                        <div className={"row head-titles ctable-row"}>
                          <div className={"col-md-2"}>ID</div>
                          <div className={"col-md-3"}>Site Code</div>
                          <div className={"col-md-3"}>Agent</div>
                          <div className={"col-md-4"}>Raison</div>
                        </div>
                        {this.state.notImported.map((item) => (
                          <div className={"row ctable-row"}>
                            <div className={"col-md-2"}>{item.site_id}</div>
                            <div className={"col-md-3"}>{item.site_code}</div>
                            <div className={"col-md-3"}>{item.username}</div>
                            <div className={"col-md-4"}>{item.cause}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  <form
                    onSubmit={this.submitForm}
                    action={Constants.apiURL + "/api/cm/sites/set_agent"}
                    method='post'
                  >
                    <div className={"form-group mt-5"}>
                      <label>Fichier</label>
                      <input
                        type='file'
                        name='file'
                        className={"form-control"}
                      />
                    </div>
                    <div className={"form-group"}>
                      <button
                        type='submit'
                        className={"btn btn-success float-right"}
                      >
                        {this.state.loading ? (
                          <FontAwesomeIcon icon={faSpinner} spin={true} />
                        ) : (
                          <>
                            <FontAwesomeIcon
                              icon={faArrowUp}
                              className={"mr-2"}
                            />
                            Envoyer
                          </>
                        )}
                      </button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({ session: state.session });
export default connect(mapState)(CMSiteImportOverlay);
