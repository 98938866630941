import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import RenderChecks from "../../components/RenderChecks";

const PDH = ({ data, ...props }) => {
  return (
    <>
      {data.sites &&
        data.sites.map((site, key) => (
          <>
            <div className={"row col-md-6"}>
              <label>Site name : </label>
              {site.siteName}
            </div>

            <div className={"row col-md-6"}>
              <label>Site code : </label>
              {site.siteCode}
            </div>

            <div className={"row col-md-6"}>
              <label>Equipment : </label>
              {site.equipment_type}
            </div>

            <div className={"row col-md-6"}>
              <label>Slot ISV3 : </label>
              {site.slot_isv3}
            </div>

            <div className={"row col-md-6"}>
              <label>Type de protection : </label>
              {site.protect_type}
            </div>

            <div className={"row col-md-6"}>
              <label>TX Frequency : </label>
              {site.tx_freq}
            </div>

            <div className={"row col-md-6"}>
              <label>RX Frequency : </label>
              {site.rx_freq}
            </div>

            <div className={"row col-md-6"}>
              <label>Debit : </label>
              {site.debit}
            </div>

            <div className={"row col-md-6"}>
              <label>Dish : </label>
              {site.dish}
            </div>

            <div className={"row col-md-6"}>
              <label>Niveau nominal : </label>
              {site.nominal ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}
            </div>

            <div className={"row col-md-6"}>
              <label>Champ recu : </label>
              {site.champRecu ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}
            </div>

            <hr />
          </>
        ))}

      <div className={"clear-fix mt-3"} />
      <RenderChecks data={data} />
    </>
  );
};

export default PDH;
