import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import RenderChecks from "../../components/RenderChecks";
import PhotoItem from "../../../../Utils/PhotoItem";

const BSS = ({ data, ...props }) => {
  return (
    <>
      {data.equipment &&
        data.equipment.map((equip, index) => (
          <>
            <div className={"row col-md-6"}>
              <label>
                <b>Type : {equip.label ? equip.label : equip.type}</b>{" "}
              </label>
            </div>

            {equip.racks.map((rack, rackIndex) => (
              <div className={"row col-md-6"}>
                <label>Rack {rackIndex + 1} : </label>
                {rack}
              </div>
            ))}

            <div className={"row col-md-6"}>
              <label>Mixed configuration : </label>
              {equip.mixed ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}
            </div>

            {equip.config && (
              <div className={"table"}>
                <b>Configuration OP </b>
                <br />
                <br />
                <table className={"table-responsive"}>
                  <tr>
                    <td />
                    <td>GSM</td>
                    <td>DCS</td>
                  </tr>

                  {equip.secteurs.map((secteur, secteurIndex) => (
                    <tr>
                      <td>Secteur {secteurIndex + 1}</td>
                      <td>{secteur.gsm}</td>
                      <td>{secteur.dcs}</td>
                    </tr>
                  ))}
                </table>
              </div>
            )}

            <hr />
          </>
        ))}

      <div className={"clear-fix mt-3"} />
      <RenderChecks data={data} />

      {data.photos && (
        <div className={"test-photos-wrapper"}>
          {data.photos.map((photo, key) => (
            <div className={"test-photos-single"}>
              <PhotoItem photo={photo} active={props.active} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default BSS;
