import React from "react";
import RenderChecks from "../../components/RenderChecks";
import PhotoItem from "../../../../Utils/PhotoItem";

const AC = ({ data, ...props }) => {
  const up = str => (str ? String(str).toUpperCase() : "");
  return (
    <>
      <div className={"table-responsive"}>
        <table className={"table"}>
          <thead>
            <th>Type</th>
            <th>Marque</th>
            <th>Modèle</th>
            <th>Num. série</th>
            <th>Capacité</th>
            <th>Split</th>
          </thead>
          <tbody>
            {data.items &&
              data.items.map(item => (
                <tr>
                  <td>{up(item.type)}</td>
                  <td>{up(item.marque)}</td>
                  <td>{up(item.modele)}</td>
                  <td>{up(item.numserie)}</td>
                  <td>{up(item.capacity)}</td>
                  <td>{up(item.split)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>


      {/* 
            OLD FIELDS
            <b>AC Type : {data.type} </b><br />
            <b>Type de Gaz : {gasType[data.type_gaz]} </b><br />
            <b>Numéro de série : {data.num_serie} </b><br />
            <b>Marque : {data.marque} </b><br /> */}

      {data.type === "split" && (
        <>
          <div className={"row col-md-6"}>
            <label>Nombre de split : </label>
            {data.split_nb}
          </div>

          <div className={"row col-md-6"}>
            <label>Marque : </label>
            {data.split_marque}
          </div>

          <div className={"row col-md-6"}>
            <label>Modèle : </label>
            {data.split_modele}
          </div>

          <div className={"row col-md-6"}>
            <label>Capacité : </label>
            {data.split_capacity}
          </div>
        </>
      )}

      {data.type === "monobloc" && (
        <>
          <div className={"row col-md-6"}>
            <label>Heure machine : </label>
            {data.mono_heure_machine}
          </div>

          <div className={"row col-md-6"}>
            <label>Heure compresseur : </label>
            {data.mono_heure_compresseur}
          </div>

          <div className={"row col-md-6"}>
            <label>Nombre d'alarme porte ouverte : </label>
            {data.mono_alarme_porte_ouverte}
          </div>

          <div className={"row col-md-6"}>
            <label>Chauffage électrique (heures): </label>
            {data.mono_heure_chauffage}
          </div>

          <div className={"row col-md-6"}>
            <label>Nbre d'alarmes Basse pression: </label>
            {data.mono_alarme_basse_pression}
          </div>

          <div className={"row col-md-6"}>
            <label>Nbre d'alarmes Haute pression: </label>
            {data.mono_alarme_haute_pression}
          </div>

          <div className={"row col-md-6"}>
            <label>Nbre d'alarmes Filtre sale: </label>
            {data.mono_alarme_filtre_sale}
          </div>

          <div className={"row col-md-6"}>
            <label>Nbre d'alarmes Défaut ventilation: </label>
            {data.mono_alarme_defaut_ventilation}
          </div>

          <div className={"row col-md-6"}>
            <label>Nbre d'alarmes Défaut compresseur: </label>
            {data.mono_alarme_defaut_compresseur}
          </div>

          <div className={"row col-md-6"}>
            <label>Nbre d'alarmes Alarmes incendie: </label>
            {data.mono_alarme_incendie}
          </div>

          <div className={"row col-md-6"}>
            <label>Nbre d'alarmes Tension réseau: </label>
            {data.mono_alarme_tension_reseau}
          </div>

          <div className={"row col-md-6"}>
            <label>Nbre d'alarmes Tension continue: </label>
            {data.mono_alarme_tension_continue}
          </div>

          <div className={"row col-md-6"}>
            <label>Nbre d'alarmes Très haute température: </label>
            {data.mono_alarme_tres_haute_temp}
          </div>

          <div className={"row col-md-6"}>
            <label>Nbre d'alarmes Haute température: </label>
            {data.mono_alarme_haute_temp}
          </div>

          <div className={"row col-md-6"}>
            <label> Nbre d'alarmes Basse température: </label>
            {data.mono_alarme_basse_temp}
          </div>

          <div className={"row col-md-6"}>
            <label>Nbre d'alarmes Basse humidité: </label>
            {data.mono_alarme_basse_humidite}
          </div>

          <div className={"row col-md-6"}>
            <label>Nbre d'alarmes Haute humidité: </label>
            {data.mono_alarme_haute_humidite}
          </div>

          <div className={"row col-md-6"}>
            <label>Antigel: </label>
            {data.mono_antigel}
          </div>

          <div className={"clear-fix mt-3"} />
        </>
      )}

      <div className={"clear-fix mt-3"} />
      {data.checks && data.checks.split && <RenderChecks isAc={true} data={data.checks.split} />}

      <div className={"clear-fix mt-3"} />
      {data.checks && data.checks.monobloc && <RenderChecks isAc={true} data={data.checks.monobloc} />}

      {data.photos && (
        <div className={"test-photos-wrapper"}>
          {data.photos.map((photo, key) => (
            <div key={"ac_photo_" + key} className={"test-photos-single"}>
              <PhotoItem photo={photo} active={true} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default AC;
