import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import api from "../../../Utils/Api";


export default class OwnerHistory extends React.Component {

    constructor(props) {
    
        super(props);
        this.state = {
            history_data: [],
            loading: true
        }
    }

    componentDidMount() {

        api.get("/api/sites/owner/history/" + this.props.match.params.id).then((response) => {
            this.setState({
                loading: false,
                history_data: response.data.data,
            });
        });   
    }

    render() {
        return (<>
        
            <h3>Historique Owner</h3>
            
            <div className="col-md-12 ctable my-3">

                <div className={"row head-titles ctable-row"}>
                    <div className={"col-md-4"}>Owner</div>
                    <div className={"col-md-4"}>Date début</div>
                    <div className={"col-md-4"}>Date fin</div>
                </div>

                {this.state.loading && (
                    <FontAwesomeIcon
                        style={{ fontSize: 24 }}
                        className={"mt-2"}
                        spin={true}
                        icon={faSpinner}
                    />
                )}

                {this.state.history_data.map((item) => (
                    <div className={"row ctable-row"}>
                        <div className={"col-md-4"}>{item.owner}</div>
                        <div className={"col-md-4"}>{item.from_date}</div>
                        <div className={"col-md-4"}>{item.to_date}</div>
                    </div>
                ))}
            </div>

        </>)
    }
}