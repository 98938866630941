import React from "react";
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";




const DeletePlanOverlay = ({open, site, toggle, ...props}) => {



	const deletePlan = (e) => {
		e.preventDefault();

		
		toggle();
		
		props.isLoading({
			message:"Suppression PM Plan",
			animate:true
		});

		
		api.post('/api/planning/delete/'+site)

		.then((response) => {
			if(response.data.success) {
				props.setOverlay({
					message: 'PM Plan supprimé.',
					label: "success",
					button: {
						type: "close",
						label: "OK",
						link: "javascript:void(0)"
					}
				});
			} 

			props.doneLoading();	
			props.reload();		
		}).catch(err => props.doneLoading());
	
		
	}

	return (
		<div className={open ? "ui-overlay open" : "ui-overlay"}>
			<div className={"ui-overlay-content delete-overlay col-md-4 offset-md-4"}>
				<button onClick={e => { toggle(); } } className={"ui-overlay-close btn btn-danger btn-sm"}>
				<FontAwesomeIcon icon={faTimes} />
				</button>

				Voulez-vous vraiment supprimer ce PM PLAN ?<br />
				Cette action est irreversible
				<div className={"delete-buttons"}>
				<button  onClick={e => { toggle(); } } className={"btn btn-info"}>No</button>
				<button onClick={deletePlan} className={"btn btn-danger ml-2"}>Delete</button>
				</div>
			</div>
		</div>
	)
}

export default DeletePlanOverlay;