import React, { PureComponent } from "react";
import { chartColors } from "../../../../Utils/Helpers";
import { connect } from "react-redux";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faFilter, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import api from "../../../../Utils/Api";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, LineChart, Tooltip, Line, CartesianGrid, Legend,  BarChart, Bar, XAxis, YAxis, } from 'recharts';


const moment = require("moment");

class TasksPage extends PureComponent {

  constructor(props) {

    super(props);

    this.state = {
      loading: true,
      selectedCluster: props.session.user.cluster,
      selectedOwner: "",
      anomaliePerStatus: [],
      //anomaliePerSite: [],
      anomaliePerType: [],
      otherAnomaliePerType: [],
      startDate: moment().format("YYYY-01-01 00:00:00"),
      endDate: moment().format("YYYY-12-31 23:59:00"),
    };

    this.statusColorsAr = {
      "resolved": chartColors[4],
      "unresolved": chartColors[1],
      "repeated": chartColors[2],
      "canceled": chartColors[0]
    };
  }

  loadData = async () => {

    this.setState({ loading: true });

    //get all the data needed for the graphs
    const getData = await api.get(
      `/api/pm/dashboard/anomalies?start_date=${moment(this.state.startDate).format(
        "YYYY-MM-DD"
      )}&end_date=${moment(this.state.endDate).format("YYYY-MM-DD")}&cluster=${
        this.state.selectedCluster
      }&owner=${this.state.selectedOwner}`
    );

    //copy the data to the state
    this.setState({ ...getData.data.data});
    
    //normalize the data
    this.setState({ anomaliePerStatus: this.state.anomaliePerStatus.map(function (obj) {
			return ({'name':obj.status , 'value':parseInt(obj.count)})})
    });

    /*this.setState({ anomaliePerSite: this.state.anomaliePerSite.map(function (obj) {
			return ({'name':obj.site_code , 'value':parseInt(obj.count)})})
    });*/

    this.setState({ anomaliePerType: this.state.anomaliePerType.map(function (obj) {
			return ({'name':obj.type , 'value':parseInt(obj.count)})})
    });

    this.setState({ otherAnomaliePerType: this.state.otherAnomaliePerType.map(function (obj) {
			return ({'name':'Other' , 'value':parseInt(obj.other)})})
    });

    //append the other count ot the array
    this.state.anomaliePerType.push(...this.state.otherAnomaliePerType);

    return getData;
  };

  componentDidMount() {

    this.loadData();
  }

  setStartDate = e => this.setState({ startDate: e });

  setEndDate = e => this.setState({ endDate: e });

  updateFilters = filters => {

    this.setState(filters);
  };

  renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {

    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  render() {

    return (

      <div className={"row dashboard-row "}>

        <div className={"col-md-6"}>
          <div className={"single-chart p-3"}>
            <h3 className={"mb-3"}>Anomalies par status</h3>

            <ResponsiveContainer width="100%" height={500}>
              <PieChart>
                <Legend layout="vertical" verticalAlign="middle" align="right"/>
                <Tooltip />
                <Pie
                  data={this.state.anomaliePerStatus}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={this.renderCustomizedLabel}
                  outerRadius={200}
                  innerRadius={0}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {this.state.anomaliePerStatus.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={this.statusColorsAr[entry.name]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className={"col-md-6"}>
          <div className={"single-chart p-3"}>
            <h3 className={"mb-3"}>Anomalies par type</h3>

            <ResponsiveContainer width="100%" height={500}>
              <PieChart>
                <Legend layout="vertical" verticalAlign="middle" align="right"/>
                <Tooltip />
                <Pie
                  data={this.state.anomaliePerType}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={this.renderCustomizedLabel}
                  outerRadius={200}
                  innerRadius={0}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {this.state.anomaliePerType.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={chartColors[index+1]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

      </div>   
    );
  }
}

const mapState = state => {
  return {
    filters: state.filters,
    session: state.session
  };
};

export default connect(mapState)(TasksPage);
