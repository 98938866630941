import React from "react";
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { formToObject } from "../../../Utils/Helpers";
import { useDispatch } from "react-redux";
import { startLoading, setFlash, doneLoading } from "../../../Redux/actions";

const PlanPmOverlay = ({
  open,
  siteid,
  site,
  defaultAgent,
  loadSites,
  close,
  defaultDate,
  taskType,
  subsite,
  accessProblem,
  siteplanid,
  ...props
}) => {

  const dispatch = useDispatch();

  const isLoading = () => dispatch(startLoading());
  const flash = (d) => dispatch(setFlash(d));
  const notLoading = () => dispatch(doneLoading())

  const submitAssignForm = (e) => {
    e.preventDefault();

    // if (!this.state.date || !this.state.agentid) {
    //   return false;
    // }


    isLoading();

    let data = formToObject(e.target);

    if(taskType === 'cm') {

      data['selected_site'] = subsite;
      data['task_type'] = taskType;
      data['site_plan_id'] = siteplanid;
      //console.log("hello data2 ", subsite, data);

      /*api.post("/api/cm/planning/reassign", data).then((response) => {
        //console.log("hello response", response);
        if (response.data.success) {
          flash({
            active: true,
            message: "Tâche réassigné avec succès.",
            classes: "success",
            style: {},
          });

          if(typeof loadSites === "function")
            loadSites();

          close();
        } else {
          if(response.data.team_error) {
            flash({
              active: true,
              message: "Une erreur est survenue, aucune équipe disponible à cette date à cette shift.",
              classes: "warning",
              style: {},
            });
          } else {
            flash({
              active: true,
              message: "Une erreur est survenue, veuillez contacter l'administrateur.",
              classes: "warning",
              style: {},
            });
          }
        }
        notLoading();
      });*/

      flash({
        active: true,
        message: "Tâche affecté au CM Manager avec succès.",
        classes: "success",
        style: {},
      });

    } else {

      data['selected_site'] = subsite ? subsite.id : site.site_id;
      data['task_type'] = taskType;

      api.post("/api/planning/assign", data).then((response) => {
        if (response.data.success) {
          flash({
            active: true,
            message: "Tâche planifié avec succès.",
            classes: "success",
            style: {},
          });

          if(typeof loadSites === "function")
            loadSites();

          close();
        } else {
          flash({
            active: true,
            message:
              "Une erreur est survenue, veuillez contacter l'administrateur.",
            classes: "warning",
            style: {},
          });
        }

        notLoading();
      });
    }
    /*api.post("/api/planning/assign", data).then((response) => {
      if (response.data.success) {
        flash({
          active: true,
          message: "Tâche planifié avec succès.",
          classes: "success",
          style: {},
        });

        if(typeof loadSites === "function")
          loadSites();

        close();
      } else {
        flash({
          active: true,
          message:
            "Une erreur est survenue, veuillez contacter l'administrateur.",
          classes: "warning",
          style: {},
        });
      }

      notLoading();
    });*/
  };

  //console.log("hello filters", props.filters);

  return (
    <div className={open ? "ui-overlay open" : "ui-overlay"}>
      <div
        className={
          "ui-overlay-content col-md-4 offset-md-4"
        }
      >
        <button
          onClick={close}
          className={"ui-overlay-close btn btn-danger btn-sm"}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <h5>Planifier une tâche</h5>
        <form onSubmit={submitAssignForm}>
            <div className="form-group mt-3">
                <label className={"font-exp"}>Type</label>
                <Select
                    classNamePrefix={"custom-select"}
                    value={{label: taskType, value: taskType}}
                    options={[
                      {value:"PM", label: "PM"},
                      {value:"GPM", label: "GPM"},
                      {value:"CM", label: "CM"}
                    ]}
                    cacheOptions
                    defaultOptions
                    placeholder="Agent"
                    className={"d-block mt-1"}
                    disabled
                    isDisabled={true}
                />

          </div>

          {taskType !== 'cm' && (
            <div className={"form-group mt-3"}>
              <label className={"font-exp"}>Date</label>
              <input
                required
                defaultValue={site.site_next_pm}
                name="planning_date"
                type={"date"}
                className={"form-control"}
              />
            </div>
          )}

          {taskType === 'cm' && (
            <div className={"form-group"}>
              <label>Description</label>
              <textarea name="instruction" className={"form-control"} />
            </div>
          )}

          {/*taskType === 'cm' && (
          <div className="form-group mt-3">
            <label className={"font-exp"}>Select agent</label>
            <Select
              classNamePrefix={"custom-select"}
              name={"agent"}
              defaultValue={""}
              options={props.filters.agents}
              cacheOptions
              defaultOptions
              placeholder="Agent"
              className={"d-block mt-1"}
            />
          </div>
        )*/}

        {accessProblem === true && (
        <div className="form-group mt-3">
          <label className={"font-exp"}>Select CM Manager</label>
          <Select
            classNamePrefix={"custom-select"}
            name={"agent"}
            defaultValue={""}
            options={props.filters.cmManagerAgents}
            cacheOptions
            defaultOptions
            placeholder="CM Manager"
            className={"d-block mt-1"}
          />
        </div>
      )}

      {/*accessProblem === true && (
        <div className="form-group mt-3">
          <label className={"font-exp"}>Select team</label>
          <Select
            classNamePrefix={"custom-select"}
            name={"team"}
            defaultValue={""}
            options={props.filters.teams}
            cacheOptions
            defaultOptions
            placeholder="Team"
            className={"d-block mt-1"}
          />
        </div>
      )*/}

          {taskType !== 'cm' && (
          <div className="form-group mt-3">
            <label className={"font-exp"}>Select agent</label>
            <Select
              classNamePrefix={"custom-select"}
              name={"agent"}
              defaultValue={{
                label: site.site_owner_agent || site.site_next_pm_agent || "",
                value: site.site_owner_agent_id || site.site_next_pm_agent_id || ""
              }}
              options={props.filters.agents}
              cacheOptions
              defaultOptions
              placeholder="Agent"
              className={"d-block mt-1"}
            />
          </div>
        )}

          {taskType === 'cm' && accessProblem === false && (
            <>
            {/*<div className="form-group mt-3">
              <label className={"font-exp"}>Select team</label>
              <Select
                classNamePrefix={"custom-select"}
                name={"team"}
                defaultValue={""}
                options={props.filters.teams}
                cacheOptions
                defaultOptions
                placeholder="Team"
                className={"d-block mt-1"}
              />
            </div>*/}

          <div className="form-group mt-3">
            <label className={"font-exp"}>Select problem type</label>
            <Select
              classNamePrefix={"custom-select"}
              name={"problem_type"}
              options={props.filters.problem_types}
              cacheOptions
              defaultOptions
              placeholder="Problem type"
              className={"d-block mt-1"}
            />
          </div>

          <div className="form-group mt-3">
            <label className={"font-exp"}>Select alarm type</label>
            <Select
              classNamePrefix={"custom-select"}
              name={"alarm_type"}
              options={props.filters.alarm_types}
              cacheOptions
              defaultOptions
              placeholder="Alarm type"
              className={"d-block mt-1"}
            />
            </div>
            </>
          )}

          <div className="form-group mt-3">
            <input
              type="submit"
              value="Planifier"
              className={"btn btn-primary btn-sm mt-2"}
            />
          </div>

        </form>
        <div>
          <span className={"font-exp"}>Agent will receive a notification.</span>
        </div>
      </div>
    </div>
  );
};

export default PlanPmOverlay;
