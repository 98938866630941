import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import LoginBox from './Login/LoginBox';
import HomeView from './Home/HomeView';
import CommonRouter from '../Routers/CommonRouter';
import FLMRouter from '../Routers/FLMRouter';
import GPMRouter from '../Routers/GPMRouter';
import CMRouter from '../Routers/CMRouter';
import CCRouter from '../Routers/CCRouter';
import api from "../Utils/Api";
import { setAuthAction } from "../Redux/actions/index";

var logout = false;
var propss = null;

const mapStateToProps = state => {
	return {
		session: state.session,
		auth: state.session.auth,
	}
}

function mapDispatchToProps(dispatch) {
	return {
	  setAuth: (e) => dispatch(setAuthAction(e)),
	};
  }

class AppRouter extends Component {

	constructor(props) {
		super(props);
	
		const { user, auth } = localStorage.user_session ? JSON.parse(localStorage.user_session) : { user: {}, auth: false };
	
		this.state = {
		  user: user,
		}
	
		this.timerID = null;
		this.user = user;
		propss = props;
	
		this.checkLastActive = this.checkLastActive.bind(this);
	}

	componentDidMount() {

		//---security changes---//
		this.timerID = setInterval(this.checkLastActive, 60000*15); 
		//---security changes---//
	}

	componentWillUnmount() {

		clearInterval(this.timerID); 
	}

  	checkLastActive= () => {

		var token = this.user.token;
		var id = this.user.userid;

		var data = {
			'token': '',
			'id': '',
		};
		data['token'] = token;
		data['id'] = id;

    	api
		.post(`/api/token/check`, data)
		.then(function(response) {

			if (response.data.success) {
			
			} else {
				if (response.data.errcode === 101) {
					propss.setAuth(false);
					//this.setState({logout: true});
					logout = true;
				}
				if (response.data.errcode === 401) {
					propss.setAuth(false);
					//this.setState({logout: true});
					logout = true;
				}
			}
		});
  	}
	
	render() {
		
		return(
			<div>
				<Switch>
					<Route exact path='/login' component={LoginBox} />
					<Route exact path='/' component={() => <HomeView auth={this.props.session.auth} />} />
		      	  	

					{this.props.session.auth && (
					<>

						{this.props.session.user.role !== "agent" && <CommonRouter />}
						<Route path='/flm' component={FLMRouter} />
						<Route path='/gpm' component={GPMRouter} />
						<Route path='/cm' component={CMRouter} />
						<Route path='/cc' component={CCRouter} />
					</>
					)}
					
					<Route path='/' component={() => <HomeView auth={this.props.session.auth} />} />

				</Switch>
			</div>

			
		)
	}
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppRouter));