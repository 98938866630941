import React, { useState } from "react";
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import WindowedSelect from "react-windowed-select";


const DeleteCCOverlay = ({open, task, toggle, reload, ...props}) => {

  const submitSiteForm = (e) => {
    
    e.preventDefault();

    let that = this;

    const formdata = new FormData(e.target);

    let data = {};

    for (var [key, value] of formdata.entries()) data[key] = value;

    if(data["delete_cause"] != "") {

      props.isLoading({
        message: "Suppression en cours",
        animate: true,
      });
      
		api.post('/api/cc/delete', data)
      	.then(response => {
				if (response.data.success) {
				  props.doneLoading();
					props.setFlash({
						active: true,
						message: "CC supprimé",
						classes: "success",
						style: {},
					});
				} else {
          props.setFlash({
						active: true,
						message: "Une erreur est survenue, Envoi echoué.",
						classes: "warning",
						style: {},
					});
				}
        toggle();
        props.doneLoading();
        reload();
			}).catch((err) => props.doneLoading());
    
    } else {
      props.setFlash({
        active: true,
        message: "Veuillez d'abord choisir une cause !",
        classes: "warning",
        style: {},
      });
    } 
  };

    return (
      <div className={"ui-overlay " + (open ? " open" : "")}>
        <div className={"ui-overlay-content site-overlay col-md-5"}>
          <button
            data-package-id="0"
            onClick={(e) => {
                toggle(e);
            }}
            className={"ui-overlay-close btn btn-danger btn-sm"}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <h5 className={"mb-3"}>Supprimer</h5>

          <form onSubmit={submitSiteForm}>
            {open && (
              <>
                <input
                  type="hidden"
                  name="cc_task_id"
                  value={task.id}
                />

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>ID plainte</label>
                  <input
                    type="text"
                    name="id"
                    className={"form-control-read col-md-8"}
                    value={task.id}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Cause</label>
                  <Select
                    name="delete_cause"
                    classNamePrefix={"custom-select"}
                    options={props.filters.cc_delete_cause}
                    className={"col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Commentaire</label>
                  <textarea
                    rows={2}
                    name="delete_comment"
                    className={"col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                  <input
                    type="submit"
                    className={"btn btn-primary col-md-12"}
                    value="Supprimer"
                  />
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    );
  
}

export default DeleteCCOverlay;
