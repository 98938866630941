import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import SitesListContainer from "../Containers/FLM/SitesContainer";
import AddressNewContainer from "../Containers/FLM/AddressNewContainer";
import PlanningList from "../Components/Planning/PlanningList";
import SiteDetail from "../Components/Addresses/SiteDetail";
import TaskListContainer from "../Containers/FLM/TaskListContainer";
import AnomaliesContainer from "../Containers/FLM/AnomaliesContainer";
import CalendarPage from "../Components/Planning/CalendarPage";
import DashboardWrapper from "../Components/Dashboard/Pages/DashboardWrapper";

function mapStateToProps(state) {
  return { auth: state.session.auth, session: state.session };
}

class FLMRouter extends Component {
  render() {
    return (
      <Switch>
        
            <Route exact path="/flm/sites" component={SitesListContainer} />
            <Route exact path="/flm/sites/new" component={AddressNewContainer} />
            <Route path="/flm/sites/detail/:id" component={SiteDetail} />


            <Route exact path="/flm" component={TaskListContainer} />
            <Route exact path="/flm/task" component={TaskListContainer} />
            <Route exact path="/flm/task/:taskid/" component={TaskListContainer} />
            <Route exact path="/flm/task/agent/:agentid/" component={TaskListContainer} />
            <Route exact path="/flm/task/site/:site/" component={TaskListContainer} />
        
            
            {/* <Route path='/flm/anomalies' component={AnomaliesContainer} /> */}
            {/* <Route exact path='/flm/planning' component={PlanningList} /> */}
            {/* <Route path='/flm/calendar' component={CalendarPage} /> */}
            <Route path='/flm/dashboard' component={DashboardWrapper} />

      </Switch>
    );
  }
}

export default connect(mapStateToProps)(FLMRouter);
