import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { startLoading, doneLoading, setFlash } from '../../../Redux/actions/index';
import * as helpers from '../../../Utils/Helpers';
import api from '../../../Utils/Api';
import { Trans } from "react-i18next";
import { components, createFilter } from 'react-windowed-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSpinner, faArrowUp, faArrowDown} from "@fortawesome/free-solid-svg-icons";
import Constants from "../../../Utils/Constants";
import Restricted from "../../../Utils/Restricted";
import FixRequiredSelect from "./FixRequiredSelect";
import WindowedSelect from "react-windowed-select";


const moment = require("moment");

const RequiredSelect = props => (
	<FixRequiredSelect
	  {...props}
	  SelectComponent={Select}
	  options={props.options}
	/>
  );

const mapStateToProps = state => {
	return {
		user: state.session.user,
		filters: state.filters
	}
}

const mapDispatchToProps = dispatch => {
	return {
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: () => dispatch(doneLoading()),
  		flash: d => dispatch(setFlash(d)),
	}
}

const customFilter = createFilter({ ignoreAccents: false });

const customComponents = {
	ClearIndicator: (props) => <components.ClearIndicator {...props}>clear</components.ClearIndicator>
};


class CCNew extends Component {

	constructor(props) {

		super(props);

		this.state = {
			source_num: '',
			contact_num: '',
			reception_date: '',
			problem_date: '',
			sub_problems: [],
			currentDate: this.getCurrentDateTime(),
			so_id: true,
			otap_id: true,
			arpce_id: true,
			ids: [],
			communes: [],
			regions: [],
			commune: { label: null, value: null },
			sub_problem: { label: null, value: null },
			core: false,
		}

		this.formSubmit = this.formSubmit.bind(this);
		this.loadCCTasksId = this.loadCCTasksId.bind(this);
		this.handleReceptionDateChange = this.handleReceptionDateChange.bind(this);
		this.handleProblemDateChange = this.handleProblemDateChange.bind(this);
		this.handleProblemsChange = this.handleProblemsChange.bind(this);
		this.getCurrentDateTime = this.getCurrentDateTime.bind(this);
		this.handleSourceChange = this.handleSourceChange.bind(this);
		this.handleClassificationChange = this.handleClassificationChange.bind(this);
		this.handleSourceNumChange = this.handleSourceNumChange.bind(this);
		this.handleContactNumChange = this.handleContactNumChange.bind(this);
		this.handleWilayaChange = this.handleWilayaChange.bind(this);
		this.handleCommuneChange = this.handleCommuneChange.bind(this);
		this.handleSubProblemChange = this.handleSubProblemChange.bind(this);
		this.handleComplaintTypeChange = this.handleComplaintTypeChange.bind(this);

		//load CC tickets
		this.props.isLoading({
			message:"Chargement des tickets CC",
			animate:true
		});

		this.loadCCTasksId();
	}

	loadCCTasksId() {

		this.props.isLoading();

		api.get(`/api/cc/taskid/fetch`)
		.then((response) => {
			if (response.data.success) {
				//console.log("hello response", response.data.ids);
				this.setState({ ids: response.data.ids.map(id => ({label: id.id, value: id.id }))});
				this.props.doneLoading();
			} else {
			if (response.data.errcode === 101) {
				this.props.setAuth(false);
				this.props.doneLoading();
			}
			}
		})
		.catch((error) => {
			this.props.doneLoading();
		});
  	}

	handleReceptionDateChange(e) {

		this.setState({ reception_date: e.target.value,});
	}

	handleProblemDateChange(e) {

		this.setState({ problem_date: e.target.value,});
	}

	handleProblemsChange(e) {

		var parent = e.label;

		//take the matching sub problems
		var sub_problem = this.props.filters.cc_sub_problems.map(obj => {
			return String(obj.parent_label).toUpperCase() === String(parent).toUpperCase() 
			? 
			({'label':obj.label , 'value':obj.value})
			:
			({'label':null , 'value':null})
		});

		//remove empty ones
		var filtered = sub_problem.filter(function (el) {
			return el.label != null;
		});

		this.setState({ sub_problems: filtered,}, () => {this.setState({ sub_problem: { label: null, value: null } });/*console.log("hello state", this.state.sub_problems);*/});

		//if the problem = core issue then disable wilaya, commune and type
		var core = false;
		if(String(parent).toUpperCase() === "CORE ISSUE") {
			core = true;
		} 

		this.setState({ core: core,}, () => {console.log("hello state", this.state.core);});
	}

	getCurrentDateTime() {

		const now = new Date();
		const year = now.getFullYear();
		const month = String(now.getMonth() + 1).padStart(2, '0');
		const day = String(now.getDate()).padStart(2, '0');
		const hours = String(now.getHours()).padStart(2, '0');
		const minutes = String(now.getMinutes()).padStart(2, '0');
	
		return `${year}-${month}-${day}T${hours}:${minutes}`;
	}

	handleSourceChange(e) {

		var source = e.value;

		if(source == 'SO') {
			this.setState({ so_id: false, otap_id: true,});
		} else if(source == 'Otaportail') {
			this.setState({ otap_id: false, so_id: true,});
		} else {
			this.setState({ otap_id: true, so_id: true,});
		}
	}

	handleClassificationChange(e) {

		var classification = e.value;

		if(classification == 'ARPCE') {
			this.setState({ arpce_id: false,});
		} else {
			this.setState({ arpce_id: true,});
		}
	}

	handleSourceNumChange(e) {

		var value = e.target.value;

		if (value.startsWith('0')) {
			value = value.replace(/^0+/, '');
		} else if (value.startsWith('213')) {
			value = value.replace(/^213+/, '');
		}
		this.setState({ source_num: value,});
	}

	handleContactNumChange(e) {

		var value = e.target.value;

		if (value.startsWith('0')) {
			value = value.replace(/^0+/, '');
		} else if (value.startsWith('213')) {
			value = value.replace(/^213+/, '');
		}
		this.setState({ contact_num: value,});
	}

	handleWilayaChange(e) {

		var parent = e.label;

		//take the matching communes
		var commune = this.props.filters.communes.map(obj => {
			return String(obj.parent).toUpperCase() === String(parent).toUpperCase() 
			? 
			({'label':obj.label , 'value':obj.value})
			:
			({'label':null , 'value':null})
		});

		//remove empty ones
		var filtered = commune.filter(function (el) {
			return el.label != null;
		});

		this.setState({ communes: filtered}, () => {this.setState({ commune: { label: null, value: null } }); /*console.log("hello state", this.state.communes);*/});

		var parent = e.parent;

		//take the matching regions
		var region = this.props.filters.regions.map(obj => {
			return String(obj.value).toUpperCase() === String(parent).toUpperCase() 
			? 
			({'label':obj.label , 'value':obj.value})
			:
			({'label':null , 'value':null})
		});

		//remove empty ones
		var filtered = region.filter(function (el) {
			return el.label != null;
		});

		this.setState({ regions: filtered,}, () => {/*console.log("hello state", this.state.regions);*/});
	}

	handleCommuneChange(e) {

		this.setState({ commune: { label: e.label, value: e.value }});
	}

	handleSubProblemChange(e) {

		this.setState({ sub_problem: { label: e.label, value: e.value }});
	}

	handleComplaintTypeChange(e) {

		var core = e.value;

		if(core == 'Core') {
			this.setState({ core: true,});
		} else {
			this.setState({ core: false,});
		}
	}

	formSubmit(e) {

		//console.log("hello data", helpers.formToObject(e.target));

		e.preventDefault();
		this.props.isLoading();

		if(this.state.reception_date < this.state.problem_date) {

			this.props.flash({
				active: true,
				message: "Attention, la date de réception vient avant la date du problème!",
				classes: "warning",
				style: {},
			});

			this.props.doneLoading();

		} else {

			let that = this;
			const data = helpers.formToObject(e.target);

			const fileData = new FormData(e.target);
			//console.log("hello fileData", fileData);

			api.post("/api/cc/planning/assign", fileData, {headers: {'Content-Type': 'multipart/form-data'}}).then((response) => {
			//api.post("/api/cc/planning/assign", data).then((response) => {

				//console.log("hello response", response);

				if (response.data.success) {
					this.props.flash({
						active: true,
						message: "Tâche planifié avec succès.",
						classes: "success",
						style: {},
					});
			
					that.props.history.push('/cc');

					/*if(response.data.alarm_error) {
						this.props.flash({
						active: true,
						message: "Attention, alarme existe déjà.",
						classes: "warning",
						style: {},
						});
					}*/

				} else {

					/*if(response.data.team_error) {
						this.props.flash({
						active: true,
						message: "Une erreur est survenue, veuillez contacter l'administrateur.",
						classes: "warning",
						style: {},
						});
					} else { */
						this.props.flash({
						active: true,
						message: "Une erreur 3est survenue, veuillez contacter l'administrateur.",
						classes: "warning",
						style: {},
						});
					//}
				}
		
				this.props.doneLoading();
			})
			.catch((err) => {console.log("hello err", err)})
		}
	}

	
	render() {

		//console.log("hello user", this.props.user);

		return(
			<div>

				<div className={"container-header"}>
					<Trans i18nKey="label_new_cc" />
				</div>

				<form method="#" onSubmit={this.formSubmit}>
					<div className={"row"}>
						<div className={"col-md-4"}>

							<h4 className={"mb-4"}><Trans i18nKey="client_details"/></h4>

							{/*<div className={"form-group"}>
								<label>ID plnt</label>
								<input
									name="cc_id"
									type={"text"}
									className={"form-control"}
									isDisabled={true}
								/>
							</div>*/}

							<div className={"form-group"}>
								<label>Num source *</label>
								<input
									name="source_num"
									type={"number"}
									className={"form-control"}
									required
									value={this.state.source_num}
          							onChange={this.handleSourceNumChange}
								/>
							</div>

							<div className={"form-group"}>
								<label>Num contact</label>
								<input
									name="contact_num"
									type={"number"}
									className={"form-control"}
									value={this.state.contact_num}
          							onChange={this.handleContactNumChange}
								/>
							</div>

							<div className={"form-group"}>
								<label>IMSI</label>
								<input
									name="imsi"
									type={"number"}
									className={"form-control"}
								/>
							</div>

							<div className={"form-group"}>
								<label>Date réception *</label>
								<input
									name="reception_date"
									type={"datetime-local"}
									className={"form-control"}
									onChange={this.handleReceptionDateChange}
									value={this.state.reception_date}
									required
								/>
							</div>

							<div className={"form-group"}>
								<label>Wilaya *</label>
								{
									this.state.core
									?
									<input
										name="wilaya"
										type={"text"}
										className={"form-control" + (this.state.core ? " read" : "")}
										readOnly={this.state.core}
										value={"-"}
									/>
									:
									<RequiredSelect
										name={"wilaya"}
										options={this.props.filters.new_wilayas}
										classNamePrefix={"custom-select"}
										required
										isSearchable 
										onChange={(e) => this.handleWilayaChange(e)}
									/>
								}
							</div>

							<div className={"form-group"}>
								<label>Commune *</label>
								{
									this.state.core
									?
									<input
										name="commune"
										type={"text"}
										className={"form-control" + (this.state.core ? " read" : "")}
										readOnly={this.state.core}
										value={"-"}
									/>
									:
									<RequiredSelect
										name={"commune"}
										//options={this.props.filters.communes}
										options={this.state.communes}
										classNamePrefix={"custom-select"}
										required
										isSearchable 
										value={this.state.commune}
										onChange={(e) => this.handleCommuneChange(e)}
										readOnly={this.state.core}
									/>
								}
							</div>

							<div className={"form-group"}>
								<label>Addresse *</label>
								<textarea 
									name="address" 
									className={"form-control"}
									required
								/>
							</div>

							<div className={"row"}>
								<div className={"col-md-6"}>
									<label>GPS X</label>
									<input
										name="gps_x"
										type={"text"}
										className={"form-control"}
									/>
								</div>

								<div className={"col-md-6"}>
									<label>GPS Y</label>
									<input
										name="gps_y"
										type={"text"}
										className={"form-control"}
									/>
								</div>
							</div>

							<div className={"form-group"}>
								<label>Région</label>
								<input
									name="region"
									type={"text"}
									className={"form-control read"}
									readOnly
									//options={this.props.filters.regions}
									value={this.state.regions[0] ? this.state.regions[0].label : ''}
								/>
							</div>

						</div>

						<div className={"col-md-4"}>

							<h4 className={"mb-4"}><Trans i18nKey="case_info"/></h4>

							<div className={"form-group"}>
								<label>Type plainte *</label>
								<RequiredSelect
									name={"complaint_type"}
									options={this.props.filters.cc_complaint_types}
									classNamePrefix={"custom-select"}
									required
									isSearchable 
									//onChange={(e) => this.handleComplaintTypeChange(e)}
								/>
							</div>

							<div className={"form-group"}>
								<label>Date problème</label>
								<input
									name="problem_date"
									type={"datetime-local"}
									className={"form-control"}
									onChange={this.handleProblemDateChange}
									value={this.state.problem_date}
								/>
							</div>

							<div className={"form-group"}>
								<label>Problème *</label>
								<RequiredSelect
									name={"problem"}
									options={this.props.filters.cc_problems}
									classNamePrefix={"custom-select"}
									required
									onChange={(e) => this.handleProblemsChange(e)}
									isSearchable 
								/>
							</div>

							<div className={"form-group"}>
								<label>Sous prolème *</label>
								<RequiredSelect
									name={"sub_problem"}
									options={this.state.sub_problems}
									classNamePrefix={"custom-select"}
									required
									isSearchable
									value={this.state.sub_problem}
									onChange={(e) => this.handleSubProblemChange(e)}
								/>
							</div>

							<div className={"form-group"}>
								<label>Détails *</label>
								<textarea 
									name="details" 
									className={"form-control"}
									required
								/>
							</div>

							<div className={"form-group"}>
								<label>Type *</label>
								{
									this.state.core
									?
									<input
										name="in_out"
										type={"text"}
										className={"form-control" + (this.state.core ? " read" : "")}
										readOnly={this.state.core}
										value={"-"}
									/>
									:
									<RequiredSelect
										name={"in_out"}
										options={this.props.filters.cc_in_out}
										classNamePrefix={"custom-select"}
										required
										isSearchable 
										readOnly={this.state.core}
									/>
								}
							</div>

							<div className={"form-group"}>
								<label>Commentaire</label>
								<textarea 
									name="comment" 
									className={"form-control"}
								/>
							</div>

							{/*<div className={"form-group"}>
								<label>Email</label>
								<input
									name="email"
									type={"text"}
									className={"form-control"}
								/>
							</div>*/}

							<div className={"form-group"}>
								<label>Attachement</label>
								<input type="file" name="attachment" className={"form-control"} /*accept={"application/pdf"}*/ />
							</div>

						</div>

						<div className={"col-md-4"}>

							<h4 className={"mb-4"}><Trans i18nKey="case_details"/></h4>

							<div className={"form-group"}>
								<label>Source *</label>
								<RequiredSelect
									name={"source"}
									options={this.props.filters.cc_sources}
									classNamePrefix={"custom-select"}
									required
									isSearchable
									onChange={(e) => this.handleSourceChange(e)}
								/>
							</div>

							<div className={"form-group"}>
								<label>Classification *</label>
								<RequiredSelect
									name={"classification"}
									options={this.props.filters.cc_classifications}
									classNamePrefix={"custom-select"}
									required
									isSearchable 
									onChange={(e) => this.handleClassificationChange(e)}
								/>
							</div>

							<div className={"form-group"}>
								<label>ID SO</label>
								<input
									name="so_id"
									type={"number"}
									className={"form-control" + (this.state.so_id ? " read" : "")}
									readOnly={this.state.so_id}
								/>
							</div>

							<div className={"form-group"}>
								<label>ID OTAP</label>
								<input
									name="otap_id"
									type={"number"}
									className={"form-control" + (this.state.otap_id ? " read" : "")}
									readOnly={this.state.otap_id}
								/>
							</div>

							<div className={"form-group"}>
								<label>ID ARPCE</label>
								<input
									name="arpce_id"
									type={"number"}
									className={"form-control" + (this.state.arpce_id ? " read" : "")}
									readOnly={this.state.arpce_id}
								/>
							</div>

							<div className={"form-group"}>
								<label>ID ticket parent</label>
								<WindowedSelect 
									name={"idp_exist"}
									options={this.state.ids}
									classNamePrefix={"custom-select"}
									defaultValue={{label: '0', value: '0'}}
								/>
							</div>

							<div className={"form-group"}>
								<label>Ajouté par</label>
								<input
									name="created_by_username"
									type={"text"}
									className={"form-control read"}
									value={this.props.user.username}
									readOnly
								/>
							</div>

							<div className={"form-group"}>
								<label>Date insertion</label>
								<input
									name="created_at"
									type={"datetime-local"}
									className={"form-control read"}
									readOnly
          							value={this.state.currentDate}
								/>
							</div>

							<div className={"mt-5"}>
								<button
									style={{position: "absolute", bottom: "0px", right:"10px"}}
									//disabled={! (this.state.validEmail && this.state.validUsername)}
									className={"btn btn-success float-right col-md-6"}>
									<Trans i18nKey="button_create" />
								</button>
							</div>
							
						</div>

					</div>
				</form>
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CCNew)
