import React from "react";
import RenderChecks from "../../components/RenderChecks";
import PhotoItem from "../../../../Utils/PhotoItem";

const shelterTypes = ["Indoor", "Outdoor"];

const Shelter = ({ data, ...props }) => {
  return (
    <>
      <b>Shelter Type : {shelterTypes[data.type]}</b>
      <div className={"clear-fix mt-3"} />

      <RenderChecks data={data} />

      {data.photos && (
        <div className={"test-photos-wrapper"}>
          {data.photos.map((photo, key) => (
            <div className={"test-photos-single"}>
              <PhotoItem photo={photo} active={props.active} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Shelter;
