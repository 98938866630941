import React, { useState, useRef, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import api from "../../../Utils/Api";
import Select from 'react-select';
import Constants from "../../../Utils/Constants";


export default class Problems extends React.Component {

  constructor(props)  {

    super(props);

    this.state = {
      loading: true,
      cm_problem_type: [],
      cm_alarm_type: [],
      cm_category: [],
      cm_code: [],
      cm_priority: [],
      cm_tag: [],
    }
  }

  componentDidMount() {

    this.loadData();
  }

  loadData = () => {

    api.get('/api/settings/problems')
      .then(res => {
        this.setState({loading: false});
        if(res.data.success)
          this.setState({ ...res.data.data })
      })
  }

  addItem = (type) => {

    const selectedType = this.state[type];
    selectedType.push({item_label: "", item_id: "", editable: true})
    this.setState({ [type]: selectedType })
  }

  downloadItem = (e, type) => {

    e.preventDefault();

    const action = Constants.apiURL + `/api/settings/cm/conf/download`;

    const form = document.createElement('form');
    form.action = action;
    form.method = 'GET';
    form.target = '_blank';  
    
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'token';
    input.value = this.props.user.token;
    form.appendChild(input);

    const input2 = document.createElement('input');
    input2.type = 'hidden';
    input2.name = 'type';
    input2.value = type;
    form.appendChild(input2);
    
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);

    this.setState({loading: false});
  }

  deleteItem = (item_type, item_id, item_idx, type) => {

    let selectedType = this.state[type];
    selectedType.splice(item_idx, 1);
    this.setState({ [type]: selectedType })
    if(item_id)
      this.loadData()
  }

  render() {

    //console.log("hello", this.state.cm_category);
    var cmCategory = this.state.cm_category.map(obj => ({'label':obj.item_label , 'value':obj.item_id}));

    return (
      <>
        <h3>
          Configuration CM
          {this.state.loading && <FontAwesomeIcon icon={faSpinner} spin className={"ml-2"} />}
        </h3>

        <div className={"row mt-6"}>
          <ItemType
            label={"Types de problèmes"}
            type={"cm_problem_type"}
            deleteItem={this.deleteItem}
            addItem={this.addItem}
            downloadItem={this.downloadItem}
            data={this.state.cm_problem_type}
          />

          <ItemType
            label={"Priorités CM"}
            type={"cm_priority"}
            deleteItem={this.deleteItem}
            addItem={this.addItem}
            downloadItem={this.downloadItem}
            data={this.state.cm_priority}
          />

          {/*<ItemType
            label={"Types d'alarmes"}
            type={"cm_alarm_type"}
            deleteItem={this.deleteItem}
            addItem={this.addItem}
            downloadItem={this.downloadItem}
            data={this.state.cm_alarm_type}
          />*/}
        </div>

        <div className={"row mt-6"}>
         
          {/*<ItemType
            label={"Codes CM"}
            type={"cm_code"}
            deleteItem={this.deleteItem}
            addItem={this.addItem}
            downloadItem={this.downloadItem}
            data={this.state.cm_code}
            selectData={cmCategory}
          />*/}

          <ItemType
            label={"Catégories CM"}
            type={"cm_category"}
            deleteItem={this.deleteItem}
            addItem={this.addItem}
            downloadItem={this.downloadItem}
            data={this.state.cm_category}
          />

          <ItemType
            label={"Types d'alarmes"}
            type={"cm_code"}
            deleteItem={this.deleteItem}
            addItem={this.addItem}
            downloadItem={this.downloadItem}
            data={this.state.cm_code}
            selectData={cmCategory}
          />

        </div>

        <div className={"row mt-6"}>

          <ItemType
            label={"Tags CM"}
            type={"cm_tag"}
            deleteItem={this.deleteItem}
            addItem={this.addItem}
            downloadItem={this.downloadItem}
            data={this.state.cm_tag}
          />

        </div>
      </>
    )
  }
}

const ItemType = ({type, label, deleteItem, addItem, downloadItem, data, selectData, ...props}) => {

  //console.log("hello state0", selectData);

  return (
    <div className={"col-md-6"}>
      <div className={"single-item-type"}>
        <h4>
          {label}
          <form action="" target={"_blank"} method="get" onSubmit={(e) => downloadItem(e, type)}>
            {/*<input type="hidden" name={"token"} value={props.user.token} />*/}
            <button type="submit" className={"float-right btn btn-primary btn-sm"} style={{ marginLeft: '10px' }}>
              <b>Exporter</b>
            </button>
          </form>
          <button className={"float-right btn btn-primary btn-sm"} onClick={() => addItem(type)}><b>Ajouter</b></button>
          <div className={"clearfix mb-3"}></div>
        </h4>

        <ul className={"custom-list"}>
          {data.map((item, idx) => (
            <SingleRow
              key={item.item_id}
              deleteItem={() => deleteItem(item.item_type, item.item_id, idx, type)}
              item={item}
              item_type={type}
              selectData={selectData}
            />
          ))}
        </ul>
      </div>
    </div>
  )
}

const SingleRow = ({item, deleteItem, item_type, selectData, ...props}) => {

  //console.log("hello state1", selectData);

  const [isEditable, setEditable] = useState(item.editable);
  const [text, setText] = useState(item.item_label);
  const [loading, setLoading] = useState(false);
  const textRef = useRef(null);
  const textRef2 = useRef(null);
  const [text2, setText2] = useState(item.item_parent_label);

  const deleteReq = (e) => {

    if(item.item_id) {
      setLoading(true);
      const data = {
        item_id: item.item_id
      }

      api.post('/api/settings/problems/delete', data)
        .then(res => {
          setLoading(false);
          //console.log(res.data);
          deleteItem();
        });
    } else {
      deleteItem();
    }
  }

  const submitForm = (e) => {

    //console.log("hello state2", selectData);

    e.preventDefault();
    if(!isEditable) {
      setEditable(true);
      return;
    }

    setText(textRef.current.value.length ? textRef.current.value : item.item_label);
    //if(item_type === 'cm_code') {
      setText2(item_type != 'cm_code' ? "" : textRef2.current.value.length ? textRef2.current.value : item.item_parent_label);
    //}
    setEditable(false);
    setLoading(true);

    const data = {
      item_id: item.item_id,
      item_label: textRef.current.value.length ? textRef.current.value : item.item_label,
      item_parent_label: item_type != 'cm_code' ? "" : textRef2.current.value.length ? textRef2.current.value : item.item_parent_label,
      item_type: item_type
    }

    /*if(item_type === 'cm_code') {

      const data = {
        item_id: item.item_id,
        item_label: textRef.current.value.length ? textRef.current.value : item.item_label,
        item_parent_label: textRef2.current.value.length ? textRef2.current.value : item.item_parent_label,
        item_type: item_type
      }
    }*/

    api.post('/api/settings/problems/update', data)
      .then(res => {
        setLoading(false);
        //console.log(res.data)
      })
    }

    {/*<Select
            name={"cm_code_category"}
            options={selectData}
            classNamePrefix={"custom-select"}
            required
          />*/}
          
              {/*<input ref={textRef2} className={"form-control"} defaultValue={text2} type="text" placeholder="Catégorie"/>*/}

    return (

      <li>
        <form onSubmit={submitForm}>
        <span className={"item-label"}>
          {!isEditable ? text : (
            <input ref={textRef} className={"form-control"} defaultValue={text} type="text" placeholder="Param" />
          )}
        </span>

        {
          item_type === 'cm_code' 
          ?
          <span className={"item-label"}>
            {!isEditable ? text2 : (
              <input ref={textRef2} className={"form-control"} defaultValue={text2} type="text" placeholder="Catégorie"/>
            )}
          </span>
          :
          <></>
        }

        <span className={"buttons-col"+ ( loading ? " show" : "")}>
          {
            loading
            ?
            ( <FontAwesomeIcon icon={faSpinner} spin={true} /> )
            :
            (
              <>
                <button type={"submit"}  className={"task-action-btn"}> <FontAwesomeIcon size={22} icon={!isEditable ? faEdit : faSave} /></button>
                <button type="button" onClick={deleteReq} className={"task-action-btn"}> <FontAwesomeIcon size={22} icon={faTrash} /></button>
              </>
            )
          }
        </span>
      </form>
    </li>
  )
}
