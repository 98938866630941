import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
//import SitesListContainer from "../Containers/GPM/Site/SitesContainer";
//import SitesListContainer from "../Containers/FLM/SitesContainer";
import SitesListContainer from "../Containers/CM/SitesContainer";
import AddressNewContainer from "../Containers/FLM/AddressNewContainer";
import SiteDetail from "../Components/Addresses/SiteDetail";
import TaskListContainer from "../Containers/CM/TaskListContainer";
import CMNew from "../Components/Task/CM/CMNew";
import CMAddressNewContainer from "../Containers/FLM/CMAddressNewContainer";
import CMDashboardWrapper from "../Components/Dashboard/Pages/CMDashboardWrapper";

function mapStateToProps(state) {
  return { auth: state.session.auth, session: state.session };
}

class CMRouter extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/cm" component={TaskListContainer} />
        <Route exact path="/cm/sites" component={SitesListContainer} />
        <Route exact path="/cm/task" component={TaskListContainer} />
        <Route exact path="/cm/new" component={CMNew} />
        <Route exact path="/cm/sites/new" component={CMAddressNewContainer} />
        <Route path='/cm/dashboard' component={CMDashboardWrapper} />

        <Route exact path="/cm/task/agent/:agentid/" component={TaskListContainer} />
        <Route exact path="/cm/task/:taskid/" component={TaskListContainer} />
      </Switch>
    );
  }
}

export default connect(mapStateToProps)(CMRouter);
