import React from "react";
import RenderChecks from "../../components/RenderChecks";

const Alarms = ({ data, ...props }) => {
  return (
    <>
      <b>Main power : </b> {data.main_power} <br />
      <b>Rectifier failure : </b> {data.recrifier_failure} <br />
      <b>Battery discharge : </b> {data.battery_discharge} <br />
      <b>Porte ouvetre : </b> {data.porte_ouverte} <br />
      <b>AC : </b> {data.ac} <br />
      <RenderChecks data={data} />
    </>
  );
};

export default Alarms;
