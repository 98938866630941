import React, { useState } from "react";
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import WindowedSelect from "react-windowed-select";


const TreatCCOverlay = ({open, task, toggle, reload, cm_ids, wo_ids, selected, selectedList, ...props}) => {

  //console.log("hello treat", selectedList.length, list, task.id);

  //console.log("hello user", props.session.user.service_name);

  const [actionDetails, setActionDetails] = useState([]);
  const [transfer, setTransfer] = useState(false);
  const [cm_id, setCmId] = useState(false);
  const [wo_id, setWoId] = useState(false);
  const [dstServices, setDstServices] = useState([]);

  const handleActionChange = (e) => {

		var parent = e.label;

    setActionDetails(props.filters.cc_action_details
        .filter(obj => String(obj.parent_label).toUpperCase() === String(parent).toUpperCase())
        .map(obj => ({ label: obj.label, value: obj.value }))
        .filter(el => el.label != null)
    );
	};

  const handleTransferChange = (e) => {

		setTransfer(e.target.checked);
	};

  const handleCmIdChange = (e) => {

    if(e.label != "NA") {
      setCmId(true);
    } else {
      setCmId(false);
    }
	};

  const handleWoIdChange = (e) => {

    if(e.label != "NA") {
      setWoId(true);
    } else {
      setWoId(false);
    }
	};

  const handleActionDetailChange = (e) => {

		var parent = e.label;

    setDstServices(props.filters.cc_dst_services
        .filter(obj => String(obj.action_detail).toUpperCase() === String(parent).toUpperCase())
        .map(obj => ({ label: obj.label, value: obj.value }))
        .filter(el => el.label != null)
    );

    //console.log("hello", dstServices);
	};

  const submitSiteForm = (e) => {
    
    e.preventDefault();

    let that = this;

    const formdata = new FormData(e.target);

    let data = {};

    for (var [key, value] of formdata.entries()) data[key] = value;

    formdata["user"] = props.session.user;

    var action = formdata.get("action");
    var action_details = formdata.get("action_details");
    var dst_service_id = formdata.get("dst_service_id");
    var wo_id = formdata.get("wo_id");
    var cm_id = formdata.get("cm_id");
    var site_code = formdata.get("site_code");
    var cell = formdata.get("cell");
    var validated = 0;

    //get the service name
    const selectedService = props.filters.services.find(service => service.value === dst_service_id);
    const dst_service_name = selectedService.label;

    //console.log("hello", dst_service_name.toUpperCase());
    //console.log("hello data", action, action_details, dst_service_id, wo_id, cm_id);

    //if the CC is transferred then we keep the previous action and action details
    if(transfer) {
      formdata["action"] = task.cc_history_last_action;
      action = task.cc_history_last_action;
      formdata["action_details"] = task.cc_history_last_action_details;
      action_details = task.cc_history_last_action_details;

      formdata.append('action', action);
      formdata.append('action_details', action_details);
    }

    if(action && action != "" && action_details && action_details != "" && dst_service_id && dst_service_id != "") {

      //if the service = geomarketing and action = bc profitable and action_details = site rentable then validated = 1
      if((props.session.user.service_name).toUpperCase() == "GEOMARKETING" && action.toUpperCase() == "BC PROFITABLE" && action_details.toUpperCase() == "SITE RENTABLE") {
        formdata["validated"] = 1;
        validated = 1;
      } else {
        formdata["validated"] = 0;
        validated = 0;
      }

      formdata.append('validated', validated);

      //if the action contains WO then the wo_id must be selected
      if(/*action.includes("wo") || */action.toUpperCase() == "MECHANICAL ACTION (WO)" || action.toUpperCase() == "NEED ADD SECTOR" || action.toUpperCase() == "WO PARAMETERS") {
        if(wo_id == null || wo_id == "" ) {
          props.setFlash({
            active: true,
            message: "Vous devez sélectionner un ID WO!",
            classes: "warning",
            style: {},
          });

        } else if (site_code == null || site_code == "") {
          props.setFlash({
            active: true,
            message: "Vous devez insérer un code site!",
            classes: "warning",
            style: {},
          });

        } else {
          props.isLoading({
            message: "Traitement en cours",
            animate: true,
          });
          
          //api.post('/api/cc/treat', data)
          api.post('/api/cc/treat', formdata, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => {
            if (response.data.success) {
              props.doneLoading();
              props.setFlash({
                active: true,
                message: "CC traitéé",
                classes: "success",
                style: {},
              });
            } else if (response.data.errcode == 102) {
              props.doneLoading();
              props.setFlash({
                active: true,
                message: "Extension de fichier non compatible!",
                classes: "warning",
                style: {},
              });
            } else {
              props.setFlash({
                active: true,
                message: "Une erreur est survenue, Envoi echoué.",
                classes: "warning",
                style: {},
              });
            }
            toggle();
            props.doneLoading();
            reload();
          }).catch((err) => props.doneLoading());
        }

        //if the action = site down, access issue, CM Opened, Problem Qos then the cm_id must be selected
      } else if(action.toUpperCase() == "SITE DOWN" || action.toUpperCase() == "ACCESS ISSUE" || action.toUpperCase() == "PB HARDWARE" || action.toUpperCase() == "PROBLEM QOS"
      || action_details.toUpperCase() == "CM OPENED") {
        if(cm_id == null || cm_id == "") {
          props.setFlash({
            active: true,
            message: "Vous devez sélectionner un ID CM!",
            classes: "warning",
            style: {},
          });

        } else if (site_code == null || site_code == "") {
          props.setFlash({
            active: true,
            message: "Vous devez insérer un code site!",
            classes: "warning",
            style: {},
          });

        } else {
          props.isLoading({
            message: "Traitement en cours",
            animate: true,
          });
          
          //api.post('/api/cc/treat', data)
          api.post('/api/cc/treat', formdata, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => {
            if (response.data.success) {
              props.doneLoading();
              props.setFlash({
                active: true,
                message: "CC traitéé",
                classes: "success",
                style: {},
              });
            } else if (response.data.errcode == 102) {
              props.doneLoading();
              props.setFlash({
                active: true,
                message: "Extension de fichier non compatible!",
                classes: "warning",
                style: {},
              });
            } else {
              props.setFlash({
                active: true,
                message: "Une erreur est survenue, Envoi echoué.",
                classes: "warning",
                style: {},
              });
            }
            toggle();
            props.doneLoading();
            reload();
          }).catch((err) => props.doneLoading());
        }

        //CC sent to traffic must contain site or CI
      } else if(dst_service_name.toUpperCase() == "TRAFFIC") {
        if((site_code == null || site_code == "") && (cell == null || cell == "")) {
          props.setFlash({
            active: true,
            message: "Vous devez insérer un code site ou cell!",
            classes: "warning",
            style: {},
          });

        } else {
          props.isLoading({
            message: "Traitement en cours",
            animate: true,
          });
          
          //api.post('/api/cc/treat', data)
          api.post('/api/cc/treat', formdata, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => {
            if (response.data.success) {
              props.doneLoading();
              props.setFlash({
                active: true,
                message: "CC traitéé",
                classes: "success",
                style: {},
              });
            } else if (response.data.errcode == 102) {
              props.doneLoading();
              props.setFlash({
                active: true,
                message: "Extension de fichier non compatible!",
                classes: "warning",
                style: {},
              });
            } else {
              props.setFlash({
                active: true,
                message: "Une erreur est survenue, Envoi echoué.",
                classes: "warning",
                style: {},
              });
            }
            toggle();
            props.doneLoading();
            reload();
          }).catch((err) => props.doneLoading());
        }

      } else if(action.toUpperCase() == "SITE PLANNED" || action.toUpperCase() == "ADD CAPACITY PLANNED") {
        if((site_code == null || site_code == "")) {
          props.setFlash({
            active: true,
            message: "Vous devez insérer un code site",
            classes: "warning",
            style: {},
          });

        } else {
          props.isLoading({
            message: "Traitement en cours",
            animate: true,
          });
          
          //api.post('/api/cc/treat', data)
          api.post('/api/cc/treat', formdata, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => {
            if (response.data.success) {
              props.doneLoading();
              props.setFlash({
                active: true,
                message: "CC traitéé",
                classes: "success",
                style: {},
              });
            } else if (response.data.errcode == 102) {
              props.doneLoading();
              props.setFlash({
                active: true,
                message: "Extension de fichier non compatible!",
                classes: "warning",
                style: {},
              });
            } else {
              props.setFlash({
                active: true,
                message: "Une erreur est survenue, Envoi echoué.",
                classes: "warning",
                style: {},
              });
            }
            toggle();
            props.doneLoading();
            reload();
          }).catch((err) => props.doneLoading());
        }

      } else {

        props.isLoading({
          message: "Traitement en cours",
          animate: true,
        });
        
        //api.post('/api/cc/treat', data)
        api.post('/api/cc/treat', formdata, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(response => {
          if (response.data.success) {
            props.doneLoading();
            props.setFlash({
              active: true,
              message: "CC traitéé",
              classes: "success",
              style: {},
            });
          } else if (response.data.errcode == 102) {
            props.doneLoading();
            props.setFlash({
              active: true,
              message: "Extension de fichier non compatible!",
              classes: "warning",
              style: {},
            });
          } else {
            props.setFlash({
              active: true,
              message: "Une erreur est survenue, Envoi echoué.",
              classes: "warning",
              style: {},
            });
          }
          toggle();
          props.doneLoading();
          reload();
        }).catch((err) => props.doneLoading());
      }  

    } else {
      props.setFlash({
        active: true,
        message: "Veuillez d'abord remplir les champs obligatoire!",
        classes: "warning",
        style: {},
      });
    } 
  };

    return (
      <div className={"ui-overlay " + (open ? " open" : "")}>
        <div className={"ui-overlay-content site-overlay col-md-5"}>
          <button
            data-package-id="0"
            onClick={(e) => {
                toggle(e);
            }}
            className={"ui-overlay-close btn btn-danger btn-sm"}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <h5 className={"mb-3"}>{selectedList && selectedList.length > 0 ? "Traiter par lots" : "Traiter"}</h5>

          <form onSubmit={submitSiteForm}>
            {open && (
              <>
                <input
                  type="hidden"
                  name="cc_task_id"
                  //value={task.id}
                  value={selectedList && selectedList.length > 0 ? selectedList : [task.id.toString()]}
                />

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>ID plainte</label>
                  <input
                    type="text"
                    name="id"
                    className={"form-control-read col-md-8"}
                    //value={task.id}
                    value={selectedList && selectedList.length > 0 ? selectedList.join(', ') : task.id}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Problème</label>
                  <input
                    type="text"
                    name="problem"
                    className={"form-control-read col-md-8"}
                    value={task.problem}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Sous problème</label>
                  <input
                    type="text"
                    name="sub_problem"
                    className={"form-control-read col-md-8"}
                    value={task.sub_problem}
                    disabled
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Dernière action</label>
                  <input
                    type="text"
                    name="cc_history_last_action"
                    className={"form-control-read col-md-8"}
                    value={task.cc_history_last_action}
                    disabled
                  />
                </div>

                {
                  (props.session.user.service_name).toUpperCase() == "TCM" || (props.session.user.service_name).toUpperCase().includes("RADIO")
                  /*|| (props.session.user.service_name).toUpperCase() == "NSOC_BSS" || (props.session.user.service_name).toUpperCase() == "O&M"
                  || (props.session.user.service_name).toUpperCase() == "PRODUCTION" || (props.session.user.service_name).toUpperCase() == "ROLLOUT"*/
                  ?
                  <div className="form-group custom-inline-form form-inline mt-3"> 
                    <label className={"font-exp"}>Transférer</label>
                    <input 
                      name="transfer" 
                      type="checkbox" 
                      value={transfer}
                      defaultChecked={transfer}
                      onChange={(e) => handleTransferChange(e)}
                    />
                  </div>
                  :
                  null
                }

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Action *</label>
                  {
                    transfer
                    ?
                    <input
                      type="text"
                      name="action"
                      className={"form-control-read col-md-8"}
                      value={task.cc_history_last_action}
                      disabled
                    />
                    :
                    <Select
                      name="action"
                      classNamePrefix={(transfer ? "disabled-custom-select" : "custom-select")}
                      //className={(transfer ? "form-control-read col-md-8" : "form-control col-md-8")}
                      options={props.filters.cc_actions}
                      className={"col-md-8"}
                      //className={"form-control col-md-8"}
                      onChange={(e) => handleActionChange(e)}
                      //value={transfer? task.cc_history_last_action : null}
                      isDisabled={transfer ? true : false}
                    />
                  }
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Détails action *</label>
                  {
                    transfer
                    ?
                    <input
                      type="text"
                      name="action_details"
                      className={"form-control-read col-md-8"}
                      value={task.cc_history_last_action_details}
                      disabled
                    />
                    :
                    <Select
                      name="action_details"
                      classNamePrefix={(transfer ? "disabled-custom-select" : "custom-select")}
                      //options={props.filters.cc_action_details}
                      options={actionDetails}
                      className={"col-md-8"}
                      //value={transfer? task.cc_history_last_action_details : null}
                      isDisabled={transfer ? true : false}
                      onChange={(e) => handleActionDetailChange(e)}
                    />
                  }
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Service destination *</label>
                  <Select
                    name="dst_service_id"
                    classNamePrefix={"custom-select"}
                    options={transfer? props.filters.services : dstServices ? dstServices : props.filters.cc_dst_services}
                    className={"col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Commentaire</label>
                  <textarea
                    rows={2}
                    name="comment"
                    className={"col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>ID CM</label>
                  <WindowedSelect 
                    name={"cm_id"}
                    classNamePrefix={(wo_id ? "disabled-custom-select" : "custom-select")}
                    options={[
                      { label: "NA", value: null },
                      ...cm_ids,
                    ]}
                    className={"col-md-8"}
                    isDisabled={wo_id}
                    onChange={(e) => handleCmIdChange(e)}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>ID WO</label>
                  <WindowedSelect 
                    name={"wo_id"}
                    classNamePrefix={(cm_id ? "disabled-custom-select" : "custom-select")}
                    options={[
                      { label: "NA", value: null },
                      ...wo_ids,
                    ]}
                    className={"col-md-8"}
                    isDisabled={cm_id}
                    onChange={(e) => handleWoIdChange(e)}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Code site</label>
                  <input
                    type="text"
                    name="site_code"
                    className={"form-control col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Cell</label>
                  <input
                    type="text"
                    name="cell"
                    className={"form-control col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>GPS X</label>
                  <input
                    type="text"
                    name="gps_x"
                    className={"form-control col-md-8"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>GPS Y</label>
                  <input
                    type="text"
                    name="gps_y"
                    className={"form-control col-md-8"}
                  />
                </div>

                {
                  (props.session.user.service_name).toUpperCase() == "DRIVE TEST"
                  ?
                  <div className="form-group custom-inline-form form-inline mt-3">
                    <label className={"font-exp"}>Fichier log</label>
                    <input
                      type="file"
                      name="log_files"
                      className={"form-control col-md-8"}
                      //accept={"application/pdf"}
                    />
                  </div>
                  : 
                  null
                }

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Pièce jointe</label>
                  <input
                    type="file"
                    name="attachment"
                    className={"form-control col-md-8"}
                    //accept={"application/pdf"}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                  <input
                    type="submit"
                    className={"btn btn-primary col-md-12"}
                    value="Traiter"
                  />
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    );
  
}

export default TreatCCOverlay;
