import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";

const IGeneral = ({ data, ...props }) => {

  
  return (
    <>
      <h6>Information du site</h6>

      <div className={"row col-md-6"}>
        <label>Type : </label>
        {data.site.type ? data.site.type : data.site.site_shelter}
      </div>
      <div className={"row col-md-6"}>
        <label>Typology : </label>
        {data.site.typology ? data.site.typology : data.site.site_typology}
      </div>
      <div className={"row col-md-6"}>
        <label>Support antenne : </label>
        {data.site.antenne}
      </div>

      {data.site.site_antenne && data.site.site_antenne.pylones && data.site.site_antenne.pylones[0] && (
        <div className={"row col-md-6"}>
          <label>Type pylone : </label>
          {data.site.site_antenne.pylones[0].type}
        </div>
      )}

      {data.site.site_antenne && data.site.site_antenne.pylones && data.site.site_antenne.pylones[0] && (
        <div className={"row col-md-6"}>
          <label>Hauteur : </label>
          {data.site.site_antenne.pylones[0].hauteur}
        </div>
      )}

      <br />

      <h6>Sonelgaz</h6>

      <div className={"row col-md-6"}>
        <label>Compteur : </label>
        {data.compteur}
      </div>

      <div className={"row col-md-6"}>
        <label>Sous comptage : </label>
        {data.sous_comptage}
      </div>

      <div className={"row col-md-6"}>
        <label>Numéro de série : </label>
        {data.num_serie}
      </div>

      <div className={"row col-md-6"}>
        <label>KW : </label>
        {data.kw}
      </div>

      <h6>Groupes electrogènes ({data.groupe_elec.length})</h6>

      {data.groupe_elec.length > 0 && (
        <div className={"table-responsive my-2"}>
          <table className={"table"}>
            <tr>
              <td>Moteur</td>
              <td>Génératrice</td>
              <td>Puissance</td>
            </tr>

            {data.groupe_elec.map(groupe => (
              <tr>
                <td>{groupe.moteur}</td>
                <td>{groupe.generatrice}</td>
                <td>{groupe.puissance}</td>
              </tr>
            ))}
          </table>
        </div>
      )}

      <div className={"row col-md-6 mt-2"}>
        <label>Nombre de citerne : </label>
        {data.citerne}
      </div>

      <div className={"row col-md-6"}>
        <label>Disponibilité ATS : </label>
        {data.dispo_ats ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}
      </div>

      <div className={"row col-md-6"}>
        <label>Niveau d'huile : </label>
        {data.niveau_huile ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}
      </div>

      <div className={"row col-md-6"}>
        <label>Qualité d'huile : </label>
        {data.qualite_huile ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}
      </div>

      <div className={"row col-md-6"}>
        <label>Filtre à Gas Huile : </label>
        {data.filtre_gas ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}
      </div>

      <div className={"row col-md-6"}>
        <label>Filtre à air : </label>
        {data.filtre_air ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}
      </div>

      <div className={"row col-md-6"}>
        <label>La qulaité et la couleur des gaz d'échappement : </label>
        {data.qualite_gaz ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}
      </div>

      <div className={"row col-md-6"}>
        <label>Heure de marche : </label>
        {data.heure_marche}
      </div>
    </>
  );
};

export default IGeneral;
