import React, { Component } from "react";
import set from "lodash/set";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  setAuthAction,
  startLoading,
  doneLoading,
  setFlash,
} from "../../Redux/actions/index";
import { Trans } from "react-i18next";
import Select from "react-select";
import SocketContext from "../../Utils/socket-context";
import api from "../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExport,
  faPlus,
  faAngleRight,
  faAngleLeft,
  faChevronDown,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../Utils/Pagination";
import Constants from "../../Utils/Constants";
import { buildFilters } from "../../Utils/Helpers";
import CCTaskList from "../../Components/Task/CC/CCTaskList";
import Restricted from "../../Utils/Restricted";
import CCImportOverlay from "../../Components/Task/CC/CCImportOverlay";
import FilterElement from "../../Utils/Filter/FilterElement";
import FiltersWrap from "../../Utils/Filter/FiltersWrap";
import CCDeletedTaskList from "../../Components/Task/CC/CCDeletedTaskList";
import FixRequiredSelect from "../../Components/Task/CC/FixRequiredSelect";

const RequiredSelect = (props) => (
  <FixRequiredSelect
    {...props}
    SelectComponent={Select}
    options={props.options}
  />
);

const mapStateToProps = (state) => {
  return {
    session: state.session,
    filters: state.filters,
    user: state.session.user,
    auth: state.session.auth,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    isLoading: (e) => dispatch(startLoading(e)),
    doneLoading: (e) => dispatch(doneLoading()),
    setAuth: (e) => dispatch(setAuthAction(e)),
    setFlash: (f) => dispatch(setFlash(f)),
  };
}

class TaskListContainer extends Component {
  constructor(props) {
    super(props);

    this.props.isLoading({
      message: "Loading tasks",
      animate: true,
    });

    this.state = {
      communes: [],
      taskid: null,
      total: 0,
      managers: [],
      tasks: [],
      agents: [],
      status: [],
      types: [],
      rows: [],
      current: 1,
      pages: 1,
      limit: 15,
      filters: {
        agent: "",
        site: "",
        pm: "",
        site_owner: "",
        site_manager: "",
        date_from: "",
        date_to: "",
        site_ccanager: "",
        site_cluster: "",
        site_wilaya: "",
        mine: "mine",
        //cc_category: "",
        cc_code: "",
        ongoing: true,
        today: false,
      },
      selected_tab: "mine",
      showFilters: false,
      ccCode: this.props.filters.cm_code,
      activeOverlay: null,
      select_mode: false,
      selected_list: [],
      report: { label: "General", value: "general" },
    };

    this.handlers = {
      selectPage: this.selectPage.bind(this),
      loadTasks: this.loadTasks.bind(this),
      updateFilter: this.updateFilter.bind(this),
      updateTab: this.updateTab.bind(this),
    };

    //console.log("hello user", this.props.user);
  }

  showFilters = () => {
    this.setState({ showFilters: !this.state.showFilters });
    this.setState({ communes: this.props.filters.communes });
  };

  changeLimit = (e) => {
    if (window.ga) window.ga("send", "event", "TaskList", "changeLimit");

    this.props.isLoading();
    this.setState(
      {
        limit: e.value,
        current: 1,
      },
      () => this.loadTasks(),
    );
  };

  changeCCCode = (e) => {
    var parent = e.label;

    //take the matching code
    var code = this.props.filters.cc_code.map((obj) => {
      return String(obj.parent_label).toUpperCase() ===
        String(parent).toUpperCase()
        ? { label: obj.label, value: obj.value }
        : { label: null, value: null };
    });

    //remove empty ones
    var filtered = code.filter(function(el) {
      return el.label != null;
    });

    this.setState({ ccCode: filtered }, () => {
      /*console.log("hello state", this.state.ccCode);*/
    });
  };

  updateFilter = (event, name) => {
    if (name === "wilaya") {
      this.handleWilayaChange(event);
    }

    let that = this;
    const value = event.target ? event.target.value : event.value;

    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
        },
      },
      function() {
        that.props.isLoading();
        that.loadTasks(1);
      },
    );
  };

  toggleHidden = (e) => {
    if (window.ga) window.ga("send", "event", "TaskList", "toggleHidden");

    const i = e.target.getAttribute("data-row-index");
    let nstate = this.state;

    nstate.rows[i] &&
      set(nstate, "rows[" + i + "].hidden", !nstate.rows[i].hidden);

    this.setState(nstate);
  };

  componentDidMount() {
    if (this.props.match.params.taskid && this.props.match.params.taskid) {
      this.updateFilter({ value: this.props.match.params.taskid }, "pm");
    } else if (this.props.match.params.agentid) {
      this.updateFilter({ value: this.props.match.params.agentid }, "agent");
    } else if (this.props.match.params.site) {
      this.updateFilter({ value: this.props.match.params.site }, "site");
    } else this.loadTasks();

    //this.updateTab("mine", "mine");
  }

  componentDidUpdate(prev) {
    // if(this.props.match.params.taskid && this.props.match.params.taskid) {
    // 	this.setState({
    // 		taskid: this.props.match.params.taskid
    // 	})
    // }
  }

  loadTasks(p = this.state.current) {
    let nstate = this.state;
    let that = this;

    let crits = buildFilters(this.state.filters);

    api
      .get(`/api/cc/task/fetch?${crits}&page=${p}&limit=${this.state.limit}`)
      .then(function(response) {
        if (response.data.success) {
          response.data.tasks.map((r) => {
            nstate.rows.push({ hidden: true });
          });

          that.setState({
            tasks: response.data.tasks,
            pages: response.data.pages,
            rows: nstate.rows,
            total: response.data.total,
          });

          that.setState({
            selected_list: [],
            select_mode: false,
          });
        } else {
          if (response.data.errcode === 101) {
            that.props.setAuth(false);
          }
          that.props.setFlash({
            active: true,
            message: response.data.error,
            classes: "warning",
            style: {},
          });
        }
        that.props.doneLoading();
      })
      .catch((err) => {
        this.props.setFlash({
          active: true,
          message: `Une erreur est survenue: ${err.message}`,
          classes: "warning",
          style: {},
        });
        // console.log(`error:  ${error}`);
        this.props.doneLoading();
      });
  }

  exportAnomalies = (e) => {
    e.preventDefault();
    let crits = buildFilters(this.state.filters);
    let remote = Constants.apiURL;
    const url = remote + `/api/cc/task/fetch?${crits}&limit=-1&anomalies`;

    // window.location.href = url;
    // return;

    e.target.action = url;
    e.target.submit();
  };

  exportPMS = (e) => {
    e.preventDefault();

    let crits = buildFilters(this.state.filters);

    let remote = Constants.apiURL;

    //const url = remote + `/api/cc/task/fetch?${crits}&limit=-1&export=true`;

    var url = "";

    if (this.state.report.value == "general") {
      url = remote + `/api/cc/task/fetch?${crits}&limit=-1&export=true`;
      e.target.action = url;
      e.target.submit();
    } else if (this.state.report.value == "open_by_service") {
      url =
        remote + `/api/cc/task/fetch?${crits}&limit=-1&open_by_service=true`;
      e.target.action = url;
      e.target.submit();
    } else if(this.state.report.value == "complaint_sla") {
      url = remote + `/api/cc/task/fetch?${crits}&limit=-1&complaint_sla=true`;
      e.target.action = url;
      e.target.submit();
    } else if (this.state.report.value == "access_network") {
      url = remote + `/api/cc/task/fetch?${crits}&limit=-1&access_network=true`;
      e.target.action = url;
      e.target.submit();
    } else if (this.state.report.value == "complaint_status") {
      url = remote + `/api/cc/task/fetch?${crits}&limit=-1&complaint_status=true`;
      e.target.action = url;
      e.target.submit();
    }

    /*e.target.action = url;
    e.target.submit();*/
  };

  /*exportICT = (e) => {

    e.preventDefault();

    let crits = buildFilters(this.state.filters);
    let remote = Constants.apiURL;

    const url = remote + `/api/cc/task/fetch?${crits}&limit=-1&ict=true`;

    e.target.action = url;
    e.target.submit();
  };

  exportSV = (e) => {

    e.preventDefault();

    let crits = buildFilters(this.state.filters);
    let remote = Constants.apiURL;

    const url = remote + `/api/cc/task/fetch?${crits}&limit=-1&sv=true`;

    e.target.action = url;
    e.target.submit();
  };*/

  selectPage = (p) => {
    this.props.isLoading();
    this.setState({ current: p });
    this.loadTasks(p);
  };

  updateTab = (event, name) => {
    let that = this;
    const value = event;

    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
        },
      },
      function() {
        that.props.isLoading();
        that.loadTasks(1);
      },
    );
  };

  changeTab = (tab) => {
    var t = "" + tab;
    this.setState({ selected_tab: tab });
    if (tab == "mine") {
      this.updateTab("mine", "mine");
    } else if (tab == "all") {
      this.updateTab("all", "mine");
    } else if (tab == "deleted") {
      this.updateTab("deleted", "mine");
    }
  };

  handleWilayaChange(e) {
    var parent = e.label;

    //take the matching communes
    var commune = this.props.filters.communes.map((obj) => {
      return String(obj.parent).toUpperCase() === String(parent).toUpperCase()
        ? { label: obj.label, value: obj.value }
        : { label: null, value: null };
    });

    //remove empty ones
    var filtered = commune.filter(function(el) {
      return el.label != null;
    });

    this.setState({ communes: filtered }, () => {
      this.setState({
        commune: { label: null, value: null },
      }); /*console.log("hello state", this.state.communes);*/
    });
  }

  toggleSelectMode = (p) => {
    if (this.state.select_mode) {
      this.setState({
        selected_list: [],
        select_mode: false,
      });
    } else {
      this.setState({
        select_mode: true,
      });
    }
  };

  toggleSelectSingle = (id) => {
    let selected_list;
    selected_list = this.state.selected_list;

    const idx = selected_list.indexOf(id);

    //console.log("hello list", selected_list, id, idx);

    if (idx > -1) {
      selected_list.splice(idx, 1);
    } else {
      selected_list.push(id);
    }

    //console.log("hello list", selected_list, id, idx);

    this.setState({ selected_list });
  };

  handleReportChange(e) {
    this.setState({ report: { label: e.label, value: e.value } });
  }

  render() {
    //console.log("hello session", this.props.session.user);

    return (
      <React.Fragment>
        <div className={"container-header"}>
          CC Tasks
          {!this.props.session.auth ? <Redirect to='/login' /> : ""}
          {this.state.activeOverlay === "import" && (
            <CCImportOverlay
              close={() => this.setState({ activeOverlay: null })}
              open={true}
            />
          )}
          {this.props.session.user.service_name &&
          this.props.session.user.service_name.toUpperCase() == "TCM" ? (
            <div className={"float-right"}>
              {/*<Restricted scope={"cc"} permission={"import_task"}>*/}
              <button
                type='button'
                onClick={() => this.setState({ activeOverlay: "import" })}
                className={"btn btn-sm ml-2 btn-primary"}
              >
                <FontAwesomeIcon icon={faFileExcel} className={"mr-2"} />
                Importer
              </button>
              {/*</Restricted>*/}
            </div>
          ) : null}
        </div>

        <div className={"row"}>
          {this.state.showFilters ? (
            <>
              <div className={"col-md-2"}>
                <div className={"side-filters"}>
                  <h4>Recherche</h4>

                  <div className={"form-group"}>
                    <label>Etat</label>
                    <FiltersWrap
                      update={this.updateFilter}
                      session={this.props.user}
                    >
                      <FilterElement
                        type={"radio"}
                        name={"ongoing"}
                        options={[
                          { label: "All", value: "all" },
                          { label: "Open", value: true, default: true },
                          { label: "Closed", value: false },
                        ]}
                        onChange={(e) => this.updateFilter(e, "ongoing")}
                      />
                    </FiltersWrap>
                  </div>

                  <div className={"form-group"}>
                    <label>ID ticket</label>
                    <input
                      type={"text"}
                      name='id'
                      className={"form-control"}
                      onKeyUp={(e) => this.updateFilter(e, "id")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Classification</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={[
                        { label: "Tous", value: "" },
                        ...this.props.filters.cc_classifications,
                      ]}
                      placeholder='Séléctionnez...'
                      onChange={(e) => this.updateFilter(e, "classification")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>ID ticket parent</label>
                    <input
                      type={"text"}
                      name='idp'
                      className={"form-control"}
                      onKeyUp={(e) => this.updateFilter(e, "idp")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Wilaya</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={[
                        { label: "Tous", value: "" },
                        ...this.props.filters.new_wilayas,
                      ]}
                      placeholder='Séléctionnez...'
                      onChange={(e) => this.updateFilter(e, "wilaya")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Commune</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={[
                        { label: "Tous", value: "" },
                        //...this.props.filters.communes,
                        ...this.state.communes,
                      ]}
                      placeholder='Séléctionnez...'
                      onChange={(e) => this.updateFilter(e, "commune")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Problème</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={[
                        { label: "Tous", value: "" },
                        ...this.props.filters.cc_problems,
                      ]}
                      placeholder='Séléctionnez...'
                      onChange={(e) => this.updateFilter(e, "problem")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Sous problème</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={[
                        { label: "Tous", value: "" },
                        ...this.props.filters.cc_sub_problems,
                      ]}
                      placeholder='Séléctionnez...'
                      onChange={(e) => this.updateFilter(e, "sub_problem")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Dérnière action</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={[
                        { label: "Tous", value: "" },
                        ...this.props.filters.cc_actions,
                      ]}
                      placeholder='Séléctionnez...'
                      onChange={(e) => this.updateFilter(e, "action")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Dérnier détails action</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={[
                        { label: "Tous", value: "" },
                        ...this.props.filters.cc_action_details,
                      ]}
                      placeholder='Séléctionnez...'
                      onChange={(e) => this.updateFilter(e, "action_details")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Type plainte</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={[
                        { label: "Tous", value: "" },
                        ...this.props.filters.cc_complaint_types,
                      ]}
                      placeholder='Séléctionnez...'
                      onChange={(e) => this.updateFilter(e, "complaint_type")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Date réception</label>
                    <input
                      type='date'
                      className={"form-control"}
                      onChange={(e) => this.updateFilter(e, "reception_date")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Date insertion</label>
                    <input
                      type='date'
                      className={"form-control"}
                      onChange={(e) => this.updateFilter(e, "created_at")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Date problème</label>
                    <input
                      type='date'
                      className={"form-control"}
                      onChange={(e) => this.updateFilter(e, "problem_date")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Dernier service</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={[
                        { label: "Tous", value: "" },
                        ...this.props.filters.services,
                      ]}
                      placeholder='Séléctionnez...'
                      onChange={(e) => this.updateFilter(e, "service")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>ID SO</label>
                    <input
                      type={"text"}
                      name='so_id'
                      className={"form-control"}
                      onKeyUp={(e) => this.updateFilter(e, "so_id")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Wait Budget</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={[
                        { label: "Tous", value: "" },
                        ...this.props.filters.yes_no,
                      ]}
                      placeholder='Séléctionnez...'
                      onChange={(e) => this.updateFilter(e, "wait_budget")}
                      defaultValue={{ label: "Non", value: "Non" }}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Supprimées</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={[
                        { label: "Tous", value: "" },
                        ...this.props.filters.yes_no,
                      ]}
                      placeholder='Séléctionnez...'
                      onChange={(e) => this.updateFilter(e, "deleted")}
                    />
                  </div>

                  {this.props.session.user.role !== "agent" &&
                    this.props.session.user.role !== "service" &&
                    this.props.session.user.role !== "manager" && (
                      <>
                        <div className={"form-group"}>
                          <label>Inséré par</label>
                          <Select
                            classNamePrefix={"custom-select"}
                            options={[
                              { label: "Tous", value: "" },
                              ...this.props.filters.managers,
                            ]}
                            placeholder='Séléctionnez...'
                            onChange={(e) => this.updateFilter(e, "created_by")}
                          />
                        </div>
                      </>
                    )}
                </div>
              </div>
              <div className={"col-md-10"}>
                <div className={"row mb-5"}>
                  <div className={"col-md-6 items-found"}>
                    <div className={"col-md-5"}>
                      {this.state.showFilters ? (
                        <button
                          onClick={(e) => this.showFilters()}
                          className={"btn btn-primary btn-block mb-2"}
                        >
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            className={"mr-2"}
                          />
                          <Trans i18nKey='label_hide_filters' />
                        </button>
                      ) : (
                        <button
                          onClick={(e) => this.showFilters()}
                          className={"btn btn-primary btn-block mb-2"}
                        >
                          <FontAwesomeIcon
                            icon={faAngleLeft}
                            className={"mr-2"}
                          />
                          <Trans i18nKey='label_show_filters' />
                        </button>
                      )}
                      <>
                        {this.state.selected_tab === "mine" ? (
                          <button
                            className={"btn btn-primary btn-block mb-3"}
                            onClick={this.toggleSelectMode}
                          >
                            {this.state.select_mode
                              ? "Annuler"
                              : "Mode séléction"}
                          </button>
                        ) : null}
                      </>
                    </div>
                    <div className={"col-md-5"}>
                      <RequiredSelect
                        name={"classification"}
                        options={this.props.filters.cc_reports}
                        classNamePrefix={"custom-select"}
                        required
                        isSearchable
                        value={this.state.report}
                        onChange={(e) => this.handleReportChange(e)}
                      />
                      <Restricted scope={"cc"} permission={"export_task"}>
                        <form
                          action=''
                          method='POST'
                          onSubmit={(e) => this.exportPMS(e)}
                        >
                          <input
                            type='hidden'
                            name='token'
                            value={this.props.session.user.token}
                          />
                          <button
                            type='submit'
                            className={"btn btn-primary btn-block mb-2"}
                          >
                            Exporter le rapport
                          </button>
                        </form>
                      </Restricted>
                      {/*<Restricted scope={"cc"} permission={"export_task"}>
                    <form action="" method="POST" onSubmit={e => this.exportICT(e)}>
                      <input type="hidden" name="token" value={this.props.session.user.token} />
                      <button type="submit" className={"btn btn-primary btn-block mb-2"}>
                        Daily ICT
                      </button>
                    </form>
                  </Restricted>
                  <Restricted scope={"cc"} permission={"export_task"}>
                    <form action="" method="POST" onSubmit={e => this.exportSV(e)}>
                      <input type="hidden" name="token" value={this.props.session.user.token} />
                      <button type="submit" className={"btn btn-primary btn-block mb-2"}>
                        Daily Sans visibilité
                      </button>
                    </form>
                  </Restricted>*/}
                    </div>
                    <p>
                      <b>{this.state.total}</b> CC trouvés
                    </p>
                  </div>
                  <div className={"col-md-6 per-page-wrap"}>
                    <div className={"col-md-4"}>
                      {/*
                  this.props.user && this.props.user.role === "admin" 
                  ?
                  <Link
                      to={"/cc/new"}
                      className={"btn btn-primary btn-block mb-2"}
                    >
                      <FontAwesomeIcon icon={faPlus} className={"mr-2"} />
                      <Trans i18nKey="label_new_cc" />
                  </Link>
                  :
                  <Restricted scope={"cc"} permission={"plan_task"}>
                    <Link
                        to={"/cc/new"}
                        className={"btn btn-primary btn-block mb-2"}
                      >
                        <FontAwesomeIcon icon={faPlus} className={"mr-2"} />
                        <Trans i18nKey="label_new_cc" />
                    </Link>
                  </Restricted>*/
                      this.props.session.user.service_name &&
                      this.props.session.user.service_name.toUpperCase() ==
                        "TCM" ? (
                        <Restricted scope={"cc"} permission={"plan_task"}>
                          <Link
                            to={"/cc/new"}
                            className={"btn btn-primary btn-block mb-2"}
                          >
                            <FontAwesomeIcon icon={faPlus} className={"mr-2"} />
                            <Trans i18nKey='label_new_cc' />
                          </Link>
                        </Restricted>
                      ) : null}
                    </div>
                    <div className={"per-page-content"}>
                      <p>Résultat par page</p>
                      <Select
                        classNamePrefix={"custom-select"}
                        onChange={this.changeLimit}
                        className={"per-page nm"}
                        options={this.props.filters.result_per_page}
                        value={{
                          label: this.state.limit,
                          value: this.state.limit,
                        }}
                      />
                    </div>
                    <Pagination
                      data={this.state}
                      handleClick={this.selectPage}
                    />
                  </div>
                </div>

                <div className={"page-top-menu"}>
                  <ul>
                    <li
                      onClick={() => this.changeTab("mine")}
                      className={
                        this.state.selected_tab === "mine" && "selected"
                      }
                    >
                      Mes CCs
                    </li>
                    <li
                      onClick={() => this.changeTab("all")}
                      className={
                        this.state.selected_tab === "all" && "selected"
                      }
                    >
                      Tous
                    </li>
                    {this.props.session.user.service_name &&
                    this.props.session.user.service_name.toUpperCase() ==
                      "TCM" ? (
                      <li
                        onClick={() => this.changeTab("deleted")}
                        className={
                          this.state.selected_tab === "deleted" && "selected"
                        }
                      >
                        Supprimés
                      </li>
                    ) : null}
                  </ul>
                </div>

                {this.state.selected_tab === "deleted" ? (
                  <CCDeletedTaskList
                    data={this.state}
                    rows={this.state.rows}
                    toggleHidden={this.toggleHidden}
                    handlers={this.handlers}
                    mine={this.state.filters["mine"]}
                  />
                ) : (
                  <CCTaskList
                    data={this.state}
                    rows={this.state.rows}
                    toggleHidden={this.toggleHidden}
                    handlers={this.handlers}
                    mine={this.state.filters["mine"]}
                    rowProps={{
                      selected_list: this.state.selected_list,
                      selectMode: this.state.select_mode,
                      toggleSelectSingle: this.toggleSelectSingle,
                      isLoading: this.props.isLoading,
                      doneLoading: this.props.doneLoading,
                      filters: this.props.filters,
                    }}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <div className={"col-md-12"}>
                <div className={"row mb-3"}>
                  <div className={"col-md-6 items-found"}>
                    <div className={"col-md-4"}>
                      {this.state.showFilters ? (
                        <button
                          onClick={(e) => this.showFilters()}
                          className={"btn btn-primary btn-block mb-2"}
                        >
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            className={"mr-2"}
                          />
                          <Trans i18nKey='label_hide_filters' />
                        </button>
                      ) : (
                        <button
                          onClick={(e) => this.showFilters()}
                          className={"btn btn-primary btn-block mb-2"}
                        >
                          <FontAwesomeIcon
                            icon={faAngleLeft}
                            className={"mr-2"}
                          />
                          <Trans i18nKey='label_show_filters' />
                        </button>
                      )}
                      <>
                        {this.state.selected_tab === "mine" ? (
                          <button
                            className={"btn btn-primary btn-block mb-3"}
                            onClick={this.toggleSelectMode}
                          >
                            {this.state.select_mode
                              ? "Annuler"
                              : "Mode séléction"}
                          </button>
                        ) : null}
                      </>
                    </div>
                    <div className={"col-md-4"}>
                      <RequiredSelect
                        name={"classification"}
                        options={this.props.filters.cc_reports}
                        classNamePrefix={"custom-select"}
                        required
                        isSearchable
                        value={this.state.report}
                        onChange={(e) => this.handleReportChange(e)}
                      />
                      <Restricted scope={"cc"} permission={"export_task"}>
                        <form
                          action=''
                          method='POST'
                          onSubmit={(e) => this.exportPMS(e)}
                        >
                          <input
                            type='hidden'
                            name='token'
                            value={this.props.session.user.token}
                          />
                          <button
                            type='submit'
                            className={"btn btn-primary btn-block mb-2"}
                          >
                            Exporter le rapport
                          </button>
                        </form>
                      </Restricted>
                      {/*<Restricted scope={"cc"} permission={"export_task"}>
                    <form action="" method="POST" onSubmit={e => this.exportICT(e)}>
                      <input type="hidden" name="token" value={this.props.session.user.token} />
                      <button type="submit" className={"btn btn-primary btn-block mb-2"}>
                        Daily ICT
                      </button>
                    </form>
                  </Restricted>
                  <Restricted scope={"cc"} permission={"export_task"}>
                    <form action="" method="POST" onSubmit={e => this.exportSV(e)}>
                      <input type="hidden" name="token" value={this.props.session.user.token} />
                      <button type="submit" className={"btn btn-primary btn-block mb-2"}>
                        Daily Sans visibilité
                      </button>
                    </form>
                  </Restricted>*/}
                    </div>
                    <p>
                      <b>{this.state.total}</b> CC trouvés
                    </p>
                  </div>
                  <div className={"col-md-6 per-page-wrap"}>
                    <div className={"col-md-4"}>
                      {/*
                  this.props.user && this.props.user.role === "admin" 
                  ?
                  <Link
                      to={"/cc/new"}
                      className={"btn btn-primary btn-block mb-2"}
                    >
                      <FontAwesomeIcon icon={faPlus} className={"mr-2"} />
                      <Trans i18nKey="label_new_cc" />
                  </Link>
                  :*/
                      /*
                  <Restricted scope={"cc"} permission={"plan_task"}>
                    <Link
                        to={"/cc/new"}
                        className={"btn btn-primary btn-block mb-2"}
                      >
                        <FontAwesomeIcon icon={faPlus} className={"mr-2"} />
                        <Trans i18nKey="label_new_cc" />
                    </Link>
                  </Restricted>
                  */
                      this.props.session.user.service_name &&
                      this.props.session.user.service_name.toUpperCase() ==
                        "TCM" ? (
                        <Restricted scope={"cc"} permission={"plan_task"}>
                          <Link
                            to={"/cc/new"}
                            className={"btn btn-primary btn-block mb-2"}
                          >
                            <FontAwesomeIcon icon={faPlus} className={"mr-2"} />
                            <Trans i18nKey='label_new_cc' />
                          </Link>
                        </Restricted>
                      ) : null}
                    </div>
                    <div className={"per-page-content"}>
                      <p>Résultat par page</p>
                      <Select
                        classNamePrefix={"custom-select"}
                        onChange={this.changeLimit}
                        className={"per-page nm"}
                        options={this.props.filters.result_per_page}
                        value={{
                          label: this.state.limit,
                          value: this.state.limit,
                        }}
                      />
                    </div>
                    <Pagination
                      data={this.state}
                      handleClick={this.selectPage}
                    />
                  </div>
                </div>

                <div className={"page-top-menu"}>
                  <ul>
                    <li
                      onClick={() => this.changeTab("mine")}
                      className={
                        this.state.selected_tab === "mine" && "selected"
                      }
                    >
                      Mes CCs
                    </li>
                    <li
                      onClick={() => this.changeTab("all")}
                      className={
                        this.state.selected_tab === "all" && "selected"
                      }
                    >
                      Tous
                    </li>
                    {this.props.session.user.service_name &&
                    this.props.session.user.service_name.toUpperCase() ==
                      "TCM" ? (
                      <li
                        onClick={() => this.changeTab("deleted")}
                        className={
                          this.state.selected_tab === "deleted" && "selected"
                        }
                      >
                        Supprimés
                      </li>
                    ) : null}
                  </ul>
                </div>

                {this.state.selected_tab === "deleted" ? (
                  <CCDeletedTaskList
                    data={this.state}
                    rows={this.state.rows}
                    toggleHidden={this.toggleHidden}
                    handlers={this.handlers}
                    mine={this.state.filters["mine"]}
                  />
                ) : (
                  <CCTaskList
                    data={this.state}
                    rows={this.state.rows}
                    toggleHidden={this.toggleHidden}
                    handlers={this.handlers}
                    mine={this.state.filters["mine"]}
                    rowProps={{
                      selected_list: this.state.selected_list,
                      selectMode: this.state.select_mode,
                      toggleSelectSingle: this.toggleSelectSingle,
                      isLoading: this.props.isLoading,
                      doneLoading: this.props.doneLoading,
                      filters: this.props.filters,
                    }}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskListContainer);
