import React from "react";

import { Chart } from "chart.js";
import * as ChartGeo from "chartjs-chart-geo";
import { Pie, HorizontalBar, Bar } from "react-chartjs-2";
import { chartColors, gradientColors } from "../../../../Utils/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChartDataLabels from "chartjs-plugin-datalabels";

import Select from "react-select";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import api from "../../../../Utils/Api";


const statesData = require("../../../../../data/algeria.json");
const countryData = require("../../../../../data/africa.json");
Chart.plugins.unregister(ChartDataLabels);

class SitesPage extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      showAllWilayas: false,
      wilayas: [],
      clusters: [],
      site_owners: [],
      site_types: [],
      selectedCluster: "",
      selectedOwner: ""
    };



    this.ownersColorsAr = {
      "O&M": chartColors[0],
      "SASCC": chartColors[1],
      "MBS": chartColors[2],
      "PROTECH": chartColors[3],
      "AFRONET": chartColors[4]
    };

  }



  loadData = async () => {

    const sitesData = api.get(`/api/dashboard/sites?cluster=${this.state.selectedCluster}&owner=${this.state.selectedOwner}`);

    //const country = fetch("https://raw.githubusercontent.com/deldersveld/topojson/master/continents/africa.json").then(r => r.json());

    const country = new Promise(resolve => resolve(countryData));

    const states = new Promise(resolve => resolve(statesData));

    return Promise.all([states, country, sitesData]);


  }

  componentDidMount() {
    this.drawGraphs();
  }

  drawGraphs = () => {
    this.setState({ loading: true });

    
    this.loadData().then(data => {
      this.setState({
        loading: false,
        wilayas: data[2].data.data.sitesby_wilaya,
        clusters: data[2].data.data.sitesby_cluster,
        site_owners: data[2].data.data.sitesby_owners,
        sitesby_ownerswilayas: data[2].data.data.sitesby_ownerswilayas,
        site_types: data[2].data.data.sitesby_type
      });

      console.log(data[0]);

      const regions = ChartGeo.topojson
        .feature(data[0], data[0].objects.DZA_adm1)
        .features.filter(item => item.properties.NAME_0 === "Algeria");

      const countries = ChartGeo.topojson.feature(
        data[1],
        data[1].objects.continent_Africa_subunits
      ).features;
      const Algeria = countries.find(d => d.properties.geounit === "Algeria");

      // console.log(regions);

      const sitesByWilaya = data[2].data.data;

      let maxPerWilaya = 0;

      sitesByWilaya.sitesby_wilaya.forEach(item => {
        if (parseInt(item.number) > maxPerWilaya) {
          maxPerWilaya = item.number;
        }
      });

      const chart = new Chart(this.canvas.getContext("2d"), {
        type: "choropleth",
        data: {
          labels: regions.map(d => {
            const wilayaVal = sitesByWilaya.sitesby_wilaya.filter(
              item => item.address_wilaya === d.properties.NAME_1
            );
            if (wilayaVal.length)
              return wilayaVal[0].address_wilaya + " : " + wilayaVal[0].number;
            else return d.properties.NAME_1;
          }),
          datasets: [
            {
              label: "Countries",
              outline: Algeria,
              backgroundColor: context => {
                if (context.dataIndex == null) {
                  return null;
                }
                let value =
                  parseInt(context.dataset.data[context.dataIndex].color * 10) +
                  2;

                if (value > gradientColors.length - 1)
                  value = gradientColors.length - 1;
                else if (value < 1) value = 0;

                const color = gradientColors[value];
                return color;
              },
              data: regions.map(d => {
                const wilayaVal = sitesByWilaya.sitesby_wilaya.filter(
                  item => item.address_wilaya === d.properties.NAME_1
                );

                let colorCoef = 0;

                if (wilayaVal.length) {
                  colorCoef = wilayaVal[0].number / maxPerWilaya + 0.1;
                }

                return { feature: d, value: wilayaVal, color: colorCoef };
              })
            }
          ]
        },
        options: {
          plugins: {
            zoom: {
              pan: {
                enabled: true,
                mode: "xy"
              },
              zoom: {
                enabled: true,
                mode: "xy"
              }
            }
          },
          showOutline: false,
          showGraticule: false,
          legend: {
            display: false
          },
          scale: {
            projection: "equalEarth"
          },
          geo: {
            colorScale: {
              display: true
            }
          }
        }
      });

      const ownerByWilaya = data[2].data.data.sitesby_ownerswilayas;


      const chartOwner = new Chart(this.ownersCanvas.getContext("2d"), {
        type: "choropleth",
        data: {
          datasets: [
            {
              label: "Wilayas",
              outline: Algeria,
              backgroundColor: context => {
                if (context.dataIndex == null) {
                  return null;
                }
                let value = context.dataset.data[context.dataIndex];

                const color = this.ownersColorsAr[value.owner];
                return color;
              },
              data: regions.map(d => {
                const wilayaVal = ownerByWilaya.filter(item => {
                    const res = item.wilaya === d.properties.NAME_1 ;
                    return res
                  });
                
                if(wilayaVal[0]) {
                  
                  let ownersAr = [
                    { label: "O&M", value: wilayaVal[0]["inhouse"] },
                    { label: "SASCC", value: wilayaVal[0]["SASCC"] },
                    { label: "MBS", value: wilayaVal[0]["MBS"] },
                    { label: "PROTECH", value: wilayaVal[0]["PROTECH"] },
                    { label: "AFRONET", value: wilayaVal[0]["AFRONET"] }
                  ];
                  
                  ownersAr.sort((a, b) => parseInt(b.value) - a.value);
                  
                  return {
                    feature: d,
                    value: wilayaVal,
                    owner: ownersAr[0].label
                  };
                } else 
                  return {
                    feature: d,
                    value: [{wilaya: d.properties.NAME_1 }]
                  }
              }).filter(item => !!item)
            }
          ]
        },
        options: {
          plugins: {
            zoom: {
              pan: {
                enabled: true,
                mode: "xy"
              },
              zoom: {
                enabled: true,
                mode: "xy"
              }
            }
          },
          showOutline: false,
          showGraticule: false,
          legend: {
            display: false
          },
          scale: {
            projection: "equalEarth"
          },
          geo: {
            colorScale: {
              display: true
            }
          },
          tooltips: {
            callbacks: {
              title: function(tooltipItem, data) {
                const val =
                  data.datasets[0].data[tooltipItem[0].index].value[0];

                  const owner = data.datasets[0].data[tooltipItem[0].index].owner;

                if(owner) {

                  return (
                    val.wilaya +
                    " : " +
                    data.datasets[0].data[tooltipItem[0].index].owner
                  );
                } else {
                    return val.wilaya;
                }
              },

              label: function(tooltipItem, data) {

                const dataObj = data.datasets[tooltipItem.datasetIndex].data

                if(!dataObj[tooltipItem.index].owner) {
                  return "";
                }
                

                const val = dataObj[tooltipItem.index].value;

                let ownersAr = [
                  { label: "O&M", value: val[0]["inhouse"] },
                  { label: "SASCC", value: val[0]["SASCC"] },
                  { label: "MBS", value: val[0]["MBS"] },
                  { label: "PROTECH", value: val[0]["PROTECH"] },
                  { label: "AFRONET", value: val[0]["AFRONET"] }
                ];

                ownersAr.sort((a, b) => b.value - a.value);

                var label =
                  ownersAr
                    .reduce((acc, curr) => {
                      
                      if(curr.value)
                        return acc + "/ " + curr.label + ": " + curr.value + " ";
                      else
                        return acc
                    }, "")
                    .replace("/", "") || "";

                return label;
              }
            }
          }
        }
      });
    });
  }


  setStartDate = e => this.setState({ startDate: e });
  setEndDate = e => this.setState({ endDate: e });

  updateFilters = filters => {
    this.setState(filters);
  };


  render() {

    const totalSiteTypes = this.state.site_types.reduce((acc, curr) => acc + parseInt(curr.nb),0);
    const totalSites =  this.state.site_owners.reduce((acc, curr) => acc + parseInt(curr.number),0);

    return (
      <>
        <div
          className={"page-loading " + (this.state.loading ? "active" : "")}
        />

        <div className={"dashboard-page"}>
        <div className={"container-header header-spacing nmb"}>


          <div></div>
          <div className={"date-filters"}>
            <>
              <b>Owner</b>
              <Select
                classNamePrefix={"custom-select"}
                className={"select-menu"}
                options={this.props.filters.site_owners}
                placeholder={"Owner"}
                value={this.props.session.user.owner ? {label: this.props.session.user.owner, value: this.props.session.user.owner === "O&M" ? "inhouse" : this.props.session.user.owner} : undefined}
                isDisabled={!!this.props.session.user.owner}
                onChange={e => this.updateFilters({ selectedOwner: e.value })}
              />

              <b>Cluster</b>
              <Select
                classNamePrefix={"custom-select"}
                className={"select-menu"}
                options={[
                  { label: "Tous", value: "" },
                  ...this.props.filters.clusters
                ]}
                value={this.props.session.user.cluster ? {label: this.props.session.user.cluster, value: this.props.session.user.cluster} : undefined}
                isDisabled={!!this.props.session.user.cluster}
                placeholder={"Cluster"}
                onChange={e => this.updateFilters({ selectedCluster: e.value })}
              />

              
              <button
                onClick={this.drawGraphs}
                type={"button"}
                className={"filter-apply"}
              >
                <FontAwesomeIcon icon={faFilter} />
              </button>
            </>
          </div>
        </div>

        <div className={"row dashboard-row "}>
          <div className={"col-md-12"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                
                <div className={"dashboard-box"}>
                  <span className={"dashboard-box-title"}>Sites physique</span>
                  <span className={"dashboard-box-value"}>{totalSites}</span>
                </div>

                <div className={"single-chart"}>
                  
                  <Bar
                    options={{
                      layout: {
                        padding: {
                          right: 50
                        }
                      },
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              fontColor:"#333",
                              fontFamily:"Open sans"
                            },
                            gridLines: {
                              display: true
                            }
                          }
                        ],
                        xAxes: [
                          {
                            ticks: {
                              fontColor:"#333",
                              fontFamily:"Open sans"
                            },
                            gridLines: {
                              display: true
                            }
                          }
                        ]
                      },
                      plugins: {
                        datalabels: {
                          anchor: "end",
                          color: "#333",
                          align: "end",
                          backgroundColor: "rgba(0,0,0,.1)",
                          padding: 3,
                          font: {
                            family: "Open sans",
                            weight: "bold"
                          }
                        }
                      },
                      legend: {
                        display: false
                      }
                    }}
                    plugins={[ChartDataLabels]}
                    data={{
                      labels: this.state.site_owners.map(item => item.owner+" ("+(item.number/totalSites*100).toFixed(2)+"%)"), 
                      datasets: [
                        {
                          data: this.state.site_owners.map(item => item.number),
                          backgroundColor: this.state.site_owners.map(item => this.ownersColorsAr[item.owner]),
                          hoverBackgroundColor: this.state.site_owners.map(item => this.ownersColorsAr[item.owner])
                        }
                      ]
                    }}
                  />
                </div>
              </div>

              <div className={"col-md-6"}>
                <div className={"dashboard-box"}>
                  <span className={"dashboard-box-title"}>Sous site</span>
                  <span className={"dashboard-box-value"}>{totalSiteTypes}</span>
                </div>

                <div className={"single-chart"}>

                  <Pie
                      data={{
                          labels: this.state.site_types.map(item => item.site_type),
                          datasets: [{
                              data: this.state.site_types.map(item => item.nb),
                              backgroundColor: chartColors,
                              hoverBackgroundColor: chartColors
                          }]
                      }}
                  />

                </div>
                
              </div>
            </div>
            <div>
              <div className={"row mt-5"}>
                <div className={"col-md-4"}>
                  <div className={"single-chart"}>
                    <h4>Cluster</h4>
                    <table className={"table"}>
                      <thead>
                        {this.state.clusters[0] &&
                          Object.keys(this.state.clusters[0]).map(key => (
                            <tr>
                              <th>{key}</th>
                              <th>{this.state.clusters[0][key]}</th>
                            </tr>
                          ))}
                      </thead>
                    </table>
                  </div>
                </div>

                <div className={"col-md-4"}>
                  <div className={"single-chart"}>
                    <h4>
                      <a
                        className={"text-center view-more d-block float-left ml-3"}
                        href="javascript:void(0)"
                        onClick={() =>
                          this.setState({
                            showAllWilayas: !this.state.showAllWilayas
                          })
                        }
                      >
                        {this.state.showAllWilayas ? "Réduire" : "Tous afficher"}
                      </a>
                      Wilayas
                      </h4>
                    
                      
                    <table className={"table"}>
                      <thead>
                        {this.state.wilayas.map((item, k) => {
                          if (this.state.showAllWilayas || k < 9)
                            return (
                              <tr>
                                <th>{item.address_wilaya}</th>
                                <th>{item.number}</th>
                              </tr>
                            );
                        })}
                      </thead>
                    </table>
                  </div>
                </div>
                
                <div className={"col-md-4"}>

                  <canvas height={"300"} ref={ref => (this.ownersCanvas = ref)} />
                </div>

              </div>
            </div>
          </div>

          {/* <div className={"col-md-5"}>
            <canvas height={"300"} ref={ref => (this.ownersCanvas = ref)} />
          </div> */}
        </div>
        <div className={"row dashboard-row "}>
          
          <div className={"col-md-4"}>
            <div className={"single-chart"}>
              <h4>Map View</h4>
              <canvas height={"300"} ref={ref => (this.canvas = ref)} />
            </div>
          </div>
        </div>
        </div>
      </>
    );
  }
}

const mapState = state => {
  return {
    filters: state.filters,
    session: state.session
  }
}
export default connect(mapState)(SitesPage);
