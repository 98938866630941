import React from 'react';
import { NavLink, withRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Restricted from '../../Utils/Restricted';

class CommonMenu extends React.Component {

  render() {

    //console.log("hello here",this.props.session.user.role);
    //console.log("hello", this.props.filters.cc_enabled);

    return (
        <div>
            <span className={"msc"}>
            <ul className={"namespace-menu"}>

              {
                this.props.session.user.role === "problem_manager"
                ?
                (
                  <>
                    <Restricted role={"problem_manager"} scope={"pm"} ><li><NavLink to={"/cm"}>CM</NavLink></li></Restricted>
                  </>
                )
                :
                this.props.session.user.role === "cm_creator"
                ?
                (
                  <>
                    <Restricted role={"cm_creator"} scope={"pm"} ><li><NavLink to={"/cm"}>CM</NavLink></li></Restricted>
                  </>
                )
                :
                this.props.session.user.role === "service"
                ?
                (
                  <>
                    <Restricted role={"service"} scope={"pm"} ><li><NavLink to={"/flm"}>PM</NavLink></li></Restricted>
                    <Restricted role={"service"} scope={"pm"} ><li><NavLink to={"/cm"}>CM</NavLink></li></Restricted>
                    <Restricted role={"service"} scope={"gpm"} ><li><NavLink to={"/gpm"}>GPM</NavLink></li></Restricted>
                    {this.props.filters.cc_enabled == 'true' ? <Restricted role={"service"} scope={"cc"} ><li><NavLink to={"/cc"}>CC</NavLink></li></Restricted> : null}
                  </>
                )
                :
                ( <>
                    <Restricted scope={"pm"} ><li><NavLink to={"/flm"}>PM</NavLink></li></Restricted>
                    <Restricted role={"manager"} scope={"pm"} ><li><NavLink to={"/cm"}>CM</NavLink></li></Restricted>
                    {/*<Restricted role={"problem_manager"} scope={"pm"}><li><NavLink to={"/cm"}>CM</NavLink></li></Restricted>
                    <Restricted role={"manager"} scope={"gpm"} ><li><NavLink to={"/gpm"}>GPM</NavLink></li></Restricted>*/}
                    <Restricted scope={"gpm"} ><li><NavLink to={"/gpm"}>GPM</NavLink></li></Restricted>
                    {this.props.filters.cc_enabled == 'true' ? <Restricted role={"manager"} scope={"cc"} ><li><NavLink to={"/cc"}>CC</NavLink></li></Restricted> : null}
                  </>
                )
              }

                <Restricted role={"manager"} >

                    <li className={"has-children"}>
                        <a href="#">Utilisateurs</a>
                        <ul className={"main-submenu"}>

                            <Restricted role={"manager"} >
                                <li><NavLink to="/agents">Agents </NavLink></li>
                            </Restricted>

                            <Restricted role={"manager"} >
                                <li><NavLink to="/Teams">Site keepers </NavLink></li>
                            </Restricted>

                            <Restricted role={"cluster_manager"} >
                                <li><NavLink to="/managers">Managers</NavLink></li>
                            </Restricted>

                            <Restricted role={"admin"} >
                                <li><NavLink to="/clustermanagers">Cluster Managers </NavLink></li>
                            </Restricted>

                            {/*<Restricted role={"cluster_manager"} >
                                <li><NavLink to="/problemmanagers">CM Managers </NavLink></li>
                            </Restricted>

                            <Restricted role={"cluster_manager"} >
                                <li><NavLink to="/cmcreators">Groupes création CM </NavLink></li>
                            </Restricted>*/}

                            <Restricted role={"cluster_manager"} >
                                <li><NavLink to="/services">Services </NavLink></li>
                            </Restricted>

                        </ul>
                    </li>
                </Restricted>


                <Restricted role={"admin"} >
                    <li><NavLink to="/settings">Configuration </NavLink></li>
                </Restricted>

                {/*<Restricted role={"agent"} scope={"pm"}>
                    <li><NavLink to="/manuals">Fichiers & guides</NavLink></li>
                </Restricted>*/}
                <li><NavLink to="/manuals">Fichiers & guides</NavLink></li>

            </ul>

            <Switch>

                <Route path={"/gpm"} strict component={() => <>
                    <ul className="header-menu">
                        {/* {role !== "agent" && (<li><NavLink strict to="/glm/dashboard">Tableau de bord </NavLink></li>)} */}
                        <Restricted scope={"gpm"} permission={"read_task"} role={"manager"} >
                            <li><NavLink strict to="/gpm/dashboard">Tableau de bord </NavLink></li>
                        </Restricted>
                        <Restricted scope={"gpm"} permission={"read_task"} >
                            <li><NavLink to="/gpm/task">GPM </NavLink></li>
                        </Restricted>
                        <Restricted scope={"gpm"} permission={"read_site"} >
                            <li><NavLink to="/gpm/sites">Sites </NavLink></li>
                        </Restricted>
                        <Restricted scope={"gpm"} permission={"read_site"} role={"manager"} >
                            <li><NavLink to="/gpm/anomalies">Anomalies </NavLink></li>
                        </Restricted>


                        {/* {role !== "agent" && (<li><NavLink to="/gpm/anomalies">Anomalies </NavLink></li>)} */}
                        {/* {(role === "admin" || role === "cluster_manager") && (<li><NavLink to="/gpm/planning">Planning </NavLink></li>)} */}
                        {/* {role !== "agent" && (<li><NavLink to="/agents">Agents </NavLink></li>)} */}
                        {/* {(role === "admin" || role === "cluster_manager") && (<li><NavLink to="/managers">Managers</NavLink></li>)} */}
                        {/* {role === "admin" && (<li><NavLink to="/clustermanagers">Cluster Managers </NavLink></li>)} */}
                        {/* {role === "admin" && (<li><NavLink to="/settings">Configuration </NavLink></li>)} */}
                    </ul>
                </>} />

                <Route path={"/cm"} strict component={() => <>
                    <ul className="header-menu">
                        {/* {role !== "agent" && (<li><NavLink strict to="/glm/dashboard">Tableau de bord </NavLink></li>)} */}
                        <Restricted scope={"cm"} permission={"read_task"} role={"manager"} >
                            <li><NavLink strict to="/cm/dashboard">Tableau de bord</NavLink></li>
                        </Restricted>
                        <Restricted role={"agent"}>
                            <li><NavLink to="/cm/task">Tasks </NavLink></li>
                        </Restricted>
                        <Restricted role={"agent"}>
                            <li><NavLink to="/cm/sites">Sites </NavLink></li>
                        </Restricted>

                    </ul>
                </>} />

                <Route path={"/cm"} strict component={() => <>
                    <ul className="header-menu">
                        <Restricted role={"problem_manager"}>
                            <li><NavLink to="/cm/task">Tasks </NavLink></li>
                        </Restricted>
                    </ul>
                </>} />

                <Route path={"/cm"} strict component={() => <>
                    <ul className="header-menu">
                        <Restricted role={"cm_creator"}>
                            <li><NavLink to="/cm/task">Tasks </NavLink></li>
                        </Restricted>
                    </ul>
                </>} />

                <Route path={"/cm"} strict component={() => <>
                    <ul className="header-menu">
                        <Restricted role={"service"}>
                            <li><NavLink to="/cm/task">Tasks </NavLink></li>
                        </Restricted>
                        <Restricted role={"service"}>
                            <li><NavLink to="/cm/sites">Sites </NavLink></li>
                        </Restricted>
                    </ul>
                </>} />

                <Route path={"/cc"} strict component={() => <>
                    <ul className="header-menu">
                        <Restricted scope={"cc"} permission={"read_task"} role={"manager"} >
                            <li><NavLink strict to="/cc/dashboard">Tableau de bord</NavLink></li>
                        </Restricted>
                        <Restricted scope={"cc"} permission={"read_task"} role={"service"}>
                            <li><NavLink to="/cc/task">Tasks </NavLink></li>
                        </Restricted>
                    </ul>
                </>} />

                <Route path={"/"} strict component={() => <>
                    <ul className="header-menu">
                        <Restricted scope={"pm"} permission={"read_task"} role={"manager"} >
                            <li><NavLink strict to="/dashboard">Tableau de bord </NavLink></li>
                        </Restricted>
                        <Restricted scope={"pm"} permission={"read_task"} role={"agent"} >
                            <li><NavLink to="/flm/task">PM </NavLink></li>
                        </Restricted>
                        <Restricted scope={"pm"} permission={"read_site"} role={"agent"} >
                            <li><NavLink to="/flm/sites">Sites </NavLink></li>
                        </Restricted>
                        <Restricted scope={"pm"} permission={"read_site"} role={"manager"} >
                            <li><NavLink to="/flm/anomalies">Anomalies </NavLink></li>
                        </Restricted>
                        <Restricted scope={"pm"} permission={"read_site_plan"} role={"manager"} >
                            <li><NavLink to="/flm/planning">Planning </NavLink></li>
                        </Restricted>

                    </ul>
                </>} />

            </Switch>



            </span>


        </div>

    );
  }

}

const mapState = state => {
    return {
        session: state.session,
        filters: state.filters
    }
}
export default connect(mapState, null, null, {pure: false})(withRouter(CommonMenu));
